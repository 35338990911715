import colors from '@/assets/constants/colors';
import Column from '@/common/components/column';
import PieChart from '@/common/components/pie-chart';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Header } from '../../styled';
import { IStatsBox } from './types';
import { ReactComponent as ICSmileyNeutral } from '@icons/ic-smiley-neutral-alt.svg';
import { ReactComponent as ICSmileyNo } from '@icons/ic-smiley-no.svg';
import { ReactComponent as ICSmileyYes } from '@icons/ic-smiley-yes.svg';
import { Small, Subheadline } from '@/common/components/text';
import { useTranslation } from 'react-i18next';

const StatsBox = ({
    title,
    question,
    yesPercentage,
    neutralPercentage,
}: IStatsBox) => {
    const { t } = useTranslation();

    return (
        <div style={{ flex: 1 }}>
            <Header>
                <Subheadline quaternary>
                    {title}
                </Subheadline>
            </Header>
            <div>
                <Small style={{ minHeight: 50 }}>
                    {question}
                </Small>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Column style={{ gap: spacings.xxxs }} mt={spacings.xs}>
                        <Row>
                            <ICSmileyYes style={{
                                width: 30,
                                height: 30,
                            }} />
                            <Small ml={spacings.xxxs}>
                                <b>{yesPercentage}%</b> {t('actions.yes')}
                            </Small>
                        </Row>
                        <Row>
                            <ICSmileyNeutral style={{
                                width: 30,
                                height: 30,
                            }} />
                            <Small ml={spacings.xxxs}>
                                <b>{neutralPercentage}%</b> {t('actions.neutral')}
                            </Small>
                        </Row>
                        <Row>
                            <ICSmileyNo style={{
                                width: 30,
                                height: 30,
                            }} />
                            <Small ml={spacings.xxxs}>
                                <b>{100 - yesPercentage - neutralPercentage}%</b> {t('actions.no')}
                            </Small>
                        </Row>
                    </Column>
                    <PieChart
                        bgColor={colors.Beige3}
                        values={[
                            {
                                color: colors.Teal2,
                                percentage: yesPercentage,
                            }, {
                                color: colors.Mustard2,
                                percentage: neutralPercentage,
                            }, {
                                color: colors.Purple2,
                                percentage: 100 - yesPercentage - neutralPercentage,
                            }
                        ]}
                    />
                </Row>
            </div>
        </div>
    );
};

export default StatsBox;