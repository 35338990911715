import licencedRoutes from './licenced-routes';
import publicRoutes from './public-routes';
import unlicencedRoutes from './unlicenced-routes';
import { nanoid } from 'nanoid';
import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from '@/store/hooks';

const AppRoutes = () => {
    const user = useAppSelector((state) => state.auth.user);
    const organisation = useAppSelector((state) => state.auth.organisation);

    return (
        <Routes>
            {
                publicRoutes.map((route, index) =>
                    <Route
                        key={nanoid()}
                        path={route.path}
                        element={route.component}
                    />
                )
            }
            {user && user.role === 'admin' && organisation &&
                unlicencedRoutes.map((route, index) =>
                    <Route
                        key={nanoid()}
                        path={route.path}
                        element={route.component}
                    />
                )
            }
            {user && organisation && (organisation.active_subscription || organisation.paid_externally) &&
                licencedRoutes.map((route, index) =>
                    <Route
                        key={nanoid()}
                        path={route.path}
                        element={route.component}
                    />
                )
            }
        </Routes>
    );
}

export default AppRoutes;