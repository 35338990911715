import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const OuterWrapper = styled.div`
`;

export const InnerWrapper = styled.div`
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    padding-left: ${spacings.s}px;
    padding-right: ${spacings.s}px;
    padding-bottom: ${spacings.l}px;
`;