import store from '@/store';
import { AxiosError } from 'axios';
import { getOrganisation } from './organisation-service';
import { logout } from './auth-service';
import { setOrganisation } from '@/store/auth-slice';

export const handleApiError = async (error: AxiosError) => {
    if (error.response?.status === 401) {
        await logout();
    }

    if (error.response?.status === 403) {
        // TODO make this better eventually
        const [data, error] = await getOrganisation();
        if (data) {
            store.dispatch(setOrganisation(data));
        }
        // myHistory.replace('/calvah.beta/no-access');
    }

    return [null, error.response];
};