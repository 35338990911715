import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const LOGO_HEIGHT = 65;
export const HEADER_HEIGHT = LOGO_HEIGHT + 2 * spacings.xxxs;

export const Wrapper = styled.header`
    display: flex;
    justify-content: space-between;
    padding-left: ${spacings.xxs}px;
    padding-right: ${spacings.m}px;
    padding-top: ${spacings.xxxs}px;
    padding-bottom: ${spacings.xxxs}px;
    border-bottom: 1px solid ${colors.Beige1};
`;