import borderRadius from '@/assets/constants/border-radius';
import Button from '@/common/components/button';
import CancelPopup from './cancel-popup';
import colors from '@/assets/constants/colors';
import Illustration from '@/assets/images/illustration-1.png';
import OrganiserListItem from './organiser-list-item';
import ResumePopup from './resume-popup';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import {
    Body,
    Headline,
    Small,
    Subheadline
    } from '@/common/components/text';
import { ColoredBox } from '@/common/styled';
import { format } from 'date-fns';
import { getOrganisation, getOrganisers } from '@/services/api/organisation-service';
import { getProductPrices } from '@/services/api/payment-service';
import { IOrganiserResponse } from '@/services/api/organisation-service/types';
import { ListItem, NameWrapper } from './organiser-list-item/styled';
import { ReactComponent as ICUser } from '@icons/ic-user.svg';
import { RiCloseCircleLine } from 'react-icons/ri';
import { saveOrganisers } from '@/store/general-slice';
import { setOrganisation } from '@/store/auth-slice';
import { setProductPrices } from '@/store/licence-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Subscriptions = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.user);
    const organisers = useAppSelector((state) => state.general.organisers);
    const organisation = useAppSelector((state) => state.auth.organisation);
    const [maxUsers, setMaxUsers] = useState<number>(organisation?.subscription_data ? organisation.subscription_data.quantity : 0);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showResumePopup, setShowResumePopup] = useState<boolean>(false);

    const productPrices = useAppSelector(state => state.licence.productPrices);
    const pricePerLicence = productPrices?.find(price => price.interval === 'month')?.price
        ? productPrices?.find(price => price.interval === 'month')?.price
        : productPrices?.find(price => price.interval === 'month')?.tiers?.find(tier => tier.up_to >= 1)?.unit_amount;
    const pricePerLicenceUnlimited = productPrices?.find(price => price.interval === 'month')?.tiers?.find(tier => !tier.up_to)?.unit_amount;

    useEffect(() => {
        const fetchPrices = async () => {
            const [data, error] = await getProductPrices();
            if (data) dispatch(setProductPrices(data));
        };

        const fetchOrganisation = async () => {
            const [data, error] = await getOrganisation();
            if (data) {
                dispatch(setOrganisation(data));
                if(data.paid_externally) {
                    setMaxUsers(Infinity);
                }else{
                    setMaxUsers(data.subscription_data.quantity || 0);
                }
            }
        };

        const fetchOrganisers = async () => {
            const [data, error] = await getOrganisers();
            if (data) {
                dispatch(saveOrganisers(data
                    .map((_user: IOrganiserResponse) => ({
                        id: _user.id,
                        name: _user.name,
                        email: _user.email,
                        pronouns: _user.pronouns,
                        role: _user.role,
                    })))
                );
            } if (error) {
                dispatch(saveOrganisers([]));
            }
        };

        fetchPrices();
        fetchOrganisation();
        fetchOrganisers();
    }, []);

    return (
        <div>
            {!organisation?.paid_externally&&<ColoredBox
                color={colors.Teal3}
                style={{
                    position: 'relative',
                    marginBottom: spacings.l,
                }}
            >
                <img
                    src={Illustration}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                    }}
                />
                <div style={{ maxWidth: '70%' }}>
                    <Row>
                        <Headline style={{ lineHeight: 1 }} mb={spacings.xs}>
                            {maxUsers} {t('organisers.organisersAlt')}
                        </Headline>
                        <Subheadline tertiary ml={spacings.xxxs}>
                            ({organisers.length + 1} {t('organisers.added')})
                        </Subheadline>
                    </Row>
                    {pricePerLicence && <Small>
                        {t('subscription.priceInfoText', { price: pricePerLicence / 100 })}
                    </Small>}
                    {organisation?.subscription_data && organisation.subscription_data.ends_at &&
                        <Body bold mt={spacings.xs}>
                            {t('subscription.yourSubscriptionsEndsOn', { date: format(new Date(organisation?.subscription_data.ends_at), 'PPP p') })}
                        </Body>
                    }
                    {organisation?.subscription_data && organisation.subscription_data.trial_ends_at && !organisation.subscription_data.ends_at && organisation.subscription_data.stripe_status == "trialing" &&
                        <Body bold mt={spacings.xs}>
                            {t('subscription.yourTrialEndsOn', { date: format(new Date(organisation?.subscription_data.trial_ends_at), 'PPP p') })}
                        </Body>
                    }
                    <Row mt={spacings.xxs}>
                        {organisation?.subscription_data && organisation.subscription_data.ends_at
                            ? <>
                                <Button
                                    small
                                    style={{ backgroundColor: colors.Beige3 }}
                                    secondary
                                    label={t('licence.resumeSubscription')}
                                    onClick={() => setShowResumePopup(true)}
                                />
                            </>
                            : <>
                                <Button
                                    small
                                    style={{ backgroundColor: colors.Beige3 }}
                                    secondary
                                    label={t('subscription.upgradeForMore')}
                                    onClick={() => {
                                        navigate('/licences/amount');
                                    }}
                                />
                                <Button
                                    small
                                    tertiary
                                    label={t('subscription.cancelSubscription')}
                                    iconRight={<RiCloseCircleLine
                                        size={20}
                                        style={{ marginLeft: spacings.xxxs }}
                                    />}
                                    onClick={() => setShowPopup(true)}
                                />
                            </>
                        }
                    </Row>
                </div>
            </ColoredBox>}
            <Row mb={spacings.xxs} style={{ justifyContent: 'space-between' }}>
                <Body bold>
                    {t('settings.organisers')}
                </Body>
                {organisers.length > 0 && <Button
                    disabled={maxUsers - organisers.length - 1 === 0}
                    secondary
                    small
                    label={t('organisers.inviteOrganisers')}
                    onClick={() => navigate('/settings/organisers')}
                />}
            </Row>
            <div>
                {user && <OrganiserListItem
                    self
                    style={{ marginBottom: spacings.xxs }}
                    key={user.email}
                    organiser={user}
                    isFirst
                    isLast
                />}
                {organisers && organisers.map((organiser, index) => <OrganiserListItem
                    key={organiser.email}
                    organiser={organiser}
                    isFirst={index === 0}
                    isLast={index === organisers.length - 1}
                />)}
                {(!organisers || organisers.length === 0) && <ListItem
                    noHover
                    style={{
                        borderRadius: borderRadius.m,
                    }}
                >
                    <NameWrapper>
                        <Row>
                            <ICUser style={{
                                marginRight: spacings.s,
                                width: 40,
                                height: 40,
                            }} />
                            <Small bold mr={spacings.xxs}>
                                {t('settings.addOrganisersToTeam')}
                            </Small>
                        </Row>
                        <Row>
                            <Button
                                disabled={maxUsers - organisers.length - 1 === 0}
                                style={{
                                    backgroundColor: colors.Beige3,
                                }}
                                secondary
                                small
                                label={t('settings.inviteOrganiser')}
                                onClick={() => navigate('/settings/organisers')}
                            />
                        </Row>
                    </NameWrapper>
                </ListItem>}
            </div>
            {showPopup && <CancelPopup close={() => setShowPopup(false)} />}
            {showResumePopup && <ResumePopup close={() => setShowResumePopup(false)} />}
        </div >
    );
};

export default Subscriptions;