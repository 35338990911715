import * as Text from '../../../../common/components/text';
import Colors from '../../../../assets/constants/colors';
import Spacings from '../../../../assets/constants/spacings';
import { CloseIconWrapper, ListItem, NameWrapper } from './styled';
import { FaUser, FaUserSlash } from 'react-icons/fa';
import { IAttendee } from '../../../../store/create-meeting-slice/types';
import { nanoid } from 'nanoid';
import { removeAttendee } from '../../../../store/create-meeting-slice';
import { RiCloseCircleLine } from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import colors from '../../../../assets/constants/colors';

const AttendeeList = ({
    attendees,
}: {
    attendees: IAttendee[];
}) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);

    return (
        <div style={{ marginTop: Spacings.s }}>
            {attendees
                .filter(attendee => attendee.email !== user?.email)
                .map((attendee: IAttendee, index: number) => {

                    return (
                        <ListItem key={nanoid()}>
                            {attendee.email?
                                <NameWrapper>
                                    <FaUser style={{
                                        marginRight: Spacings.xs,
                                        padding: Spacings.xxxs,
                                        borderRadius: 100,
                                        backgroundColor: Colors.Mustard3,
                                    }} />
                                    <Text.Small bold mr={Spacings.xxs}>
                                        {attendee.name} {attendee.pronouns && `(${attendee.pronouns})`}
                                    </Text.Small>
                                    <Text.Small>
                                        ({attendee.email})
                                    </Text.Small>
                                </NameWrapper>
                            :
                                <NameWrapper>
                                    <FaUserSlash style={{
                                        marginRight: Spacings.xs,
                                        padding: Spacings.xxxs,
                                        borderRadius: 100,
                                        backgroundColor: Colors.Mustard3,
                                    }} />
                                    <Text.Small bold mr={Spacings.xxs} color={colors.Error}>
                                        {t('create.stepAttendees.deletedAccount')}
                                    </Text.Small>
                                </NameWrapper>
                            }
                            <CloseIconWrapper>
                                <RiCloseCircleLine
                                    size={24}
                                    onClick={() => dispatch(removeAttendee(attendee))}
                                    style={{ cursor: 'pointer' }} />
                            </CloseIconWrapper>
                        </ListItem>
                    );
                })}
        </div>
    );
}

export default AttendeeList;