"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subtract = void 0;
var negate_1 = require("./negate");
var sum_1 = require("./sum");
/**
 * Subtract durations from the first duration argument.
 *
 * @example
 * subtract({ days: 1 }, { hours: 12 })
 * // { days: 1, hours: -12 }
 */
var subtract = function (duration) {
    var durationsToSubtract = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        durationsToSubtract[_i - 1] = arguments[_i];
    }
    return sum_1.sum.apply(void 0, __spreadArrays([duration], durationsToSubtract.map(negate_1.negate)));
};
exports.subtract = subtract;
