import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import MeetingTile from '@/common/components/meeting-tile';
import Pagination from '@/common/components/pagination';
import Row from '@/common/components/row';
import Searchbar from '@/common/components/searchbar';
import { Headline, Small } from '@/common/components/text';
import { ToolbarArrow, ToolbarWrapper } from '@/common/styled';
import * as MeetingService from '@/services/api/meeting-service';
import { CreateMeetingState } from '@/store/create-meeting-slice/types';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { saveMeetings, setPastMeetings, setUpcomingMeetings } from '@/store/meetings-slice';
import { debounce } from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import TemplatesPopup from '../create-meeting/templates-popup';

const Meetings = () => {
    const { mode } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [currentPageIndexUpcoming, setCurrentPageIndexUpcoming] = useState<number>(0);
    const [currentPageIndexPast, setCurrentPageIndexPast] = useState<number>(0);

    const allMeetings = useAppSelector(state => state.meetings.meetings);
    const pastMeetings = useAppSelector(state => state.meetings.pastMeetings);
    const upcomingMeetings = useAppSelector(state => state.meetings.upcomingMeetings);
    const meetings = useAppSelector((state) => state.meetings.meetings);
    const user = useAppSelector((state) => state.auth.user);

    //TODO unnecessary call -> maybe create dispatch(addMeeting(newMeeting))
    useEffect(() => {
        const fetchMeetings = async () => {
            const [data, error] = await MeetingService.getMeetings();
            if (data) {
                if (user?.role === 'admin') {
                    dispatch(saveMeetings(data));
                } else {
                    dispatch(saveMeetings(data.filter((meeting: CreateMeetingState) => meeting.userId === user?.id)));
                }
            }
        };
        if(meetings.length==0){
            fetchMeetings();
        }
    }, []);

    const fetchMeetings = async (pageIndex: number, state: string, update: any) => {
        const [data, error] = await MeetingService.getMeetingsPaginated(
            pageIndex +1,
            search,
            state,
            state === 'UPCOMING' ? 'oldest' : 'latest'
        );
        if (data) {
            if(data.data.length==0 && data.current_page>1){
                if(state=='UPCOMING'){
                    setCurrentPageIndexUpcoming(currentPageIndexUpcoming-1)
                }else{
                    setCurrentPageIndexPast(currentPageIndexPast-1)
                }
            }else {
                dispatch(update(data));
            }
        }
    };

    const debounceFetchUpcomingMeetings = debounce(
        () => fetchMeetings(currentPageIndexUpcoming, 'UPCOMING', setUpcomingMeetings),
        500
    );

    const debounceFetchPastMeetings = debounce(
        () => fetchMeetings(currentPageIndexUpcoming, 'PAST', setPastMeetings),
        500
    );

    useEffect(() => {
        fetchMeetings(currentPageIndexUpcoming, 'UPCOMING', setUpcomingMeetings);
    }, [currentPageIndexUpcoming]);

    useEffect(() => {
        fetchMeetings(currentPageIndexPast, 'PAST', setPastMeetings);
    }, [currentPageIndexPast]);

    useEffect(() => {
        debounceFetchUpcomingMeetings();
    }, [search]);

    useEffect(() => {
        debounceFetchPastMeetings();
    }, [search]);

    useEffect(() => {
       fetchMeetings(currentPageIndexUpcoming, 'UPCOMING', setUpcomingMeetings);
       fetchMeetings(currentPageIndexPast, 'PAST', setPastMeetings);
    }, []);

    useEffect(() => {
        fetchMeetings(currentPageIndexUpcoming, 'UPCOMING', setUpcomingMeetings);
        fetchMeetings(currentPageIndexPast, 'PAST', setPastMeetings);
    }, [allMeetings]);

    useEffect(() => {
        return () => {
            debounceFetchUpcomingMeetings.cancel();
        };
    }, [debounceFetchUpcomingMeetings]);

    useEffect(() => {
        return () => {
            debounceFetchPastMeetings.cancel();
        };
    }, [debounceFetchPastMeetings]);

    return (
        <ContentWrapper>
            <Headline secondary mt={spacings.m} mb={spacings.xs}>
                {t('meetings.headline')}
            </Headline>
            <ToolbarWrapper>
                <Row>
                    <div style={{ position: 'relative', marginRight: spacings.s }}>
                        <Button
                            noPadding
                            small
                            tertiary
                            label={t('meetings.upcoming')}
                            onClick={() => navigate('/meetings/upcoming')}
                        />
                        {mode === 'upcoming' && <ToolbarArrow />}
                    </div>
                    <div style={{ position: 'relative' }}>
                        <Button
                            small
                            noPadding
                            tertiary
                            label={t('meetings.past')}
                            onClick={() => navigate('/meetings/past')}
                        />
                        {mode === 'past' && <ToolbarArrow />}
                    </div>
                </Row>
                <Row style={{ position: 'relative' }}>
                    <Searchbar
                        value={search}
                        setValue={(input)=>setSearch(input.replace(/(\r\n|\n|\r)/gm, ""))}
                    />
                    <Button
                        style={{ marginLeft: spacings.xxs }}
                        small
                        secondary
                        label={t('meetings.createMeeting')}
                        onClick={() => setShowPopup(true)}
                    />
                </Row>
            </ToolbarWrapper>
            <div style={{ marginBottom: spacings.m }}>
                {mode === 'past' &&
                    (!pastMeetings || pastMeetings?.data.length === 0
                        ? <Small color={colors.Brown1}>
                            {t('meetings.noMeetings')}
                        </Small>
                        : <>
                            {pastMeetings.data
                                .map(meeting => {
                                    return (
                                        <MeetingTile
                                            key={nanoid()}
                                            meeting={meeting}
                                            //setPageIndex={(setCurrentPageIndexPast)}
                                        />
                                    );
                                })}
                            {pastMeetings && pastMeetings.last_page > 1 && (
                                <Pagination
                                    pages={pastMeetings.last_page}
                                    current={currentPageIndexPast}
                                    setCurrent={setCurrentPageIndexPast}
                                    mt={spacings.s}
                                />
                            )}
                        </>
                    )
                }
                {mode === 'upcoming' &&
                    (!upcomingMeetings || upcomingMeetings?.data?.length === 0
                        ? <Small color={colors.Brown1}>
                            {t('meetings.noMeetings')}
                        </Small>
                        : <>
                            {upcomingMeetings?.data
                                .map(meeting => {
                                    return (
                                        <MeetingTile
                                            key={nanoid()}
                                            meeting={meeting}
                                            setPageIndex={setCurrentPageIndexUpcoming}
                                        />
                                    );
                                })}
                            {upcomingMeetings && upcomingMeetings.last_page > 1 && (
                                <Pagination
                                    pages={upcomingMeetings.last_page}
                                    current={currentPageIndexUpcoming}
                                    setCurrent={setCurrentPageIndexUpcoming}
                                    mt={spacings.s}
                                />
                            )}
                        </>
                    )
                }
            </div>
            {showPopup && <TemplatesPopup close={() => setShowPopup(false)} />}
        </ContentWrapper>
    );
};

export default Meetings