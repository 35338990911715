import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import ContentWrapper from '@/common/components/content-wrapper';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import TimeRow from '../time-row';
import {
    Body,
    Headline,
    Micro,
    Subheadline
    } from '@/common/components/text';
import { CreateMeetingState, IMeetingState } from '@/store/create-meeting-slice/types';
import { Dot } from '@/features/timer/timer-summary/styled';
import { FiArrowLeftCircle } from 'react-icons/fi';
import {
    getExceededTime,
    getPlannedTime,
    getSavedTime,
    getUniqueArrayBy
    } from '@/common/helpers';
import { getMeetings } from '@/services/api/meeting-service';
import { saveMeetings } from '@/store/meetings-slice';
import { ToolbarWrapper } from '@/common/styled';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TimeStatistics = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.user);
    const meetings = useAppSelector((state) => state.meetings.meetings);
    const attendees = getUniqueArrayBy(meetings.map(meeting => meeting.attendees).flat(), 'email');
    const pastMeetings = meetings.filter(
        (meeting: CreateMeetingState & IMeetingState) => meeting.meetingState.state === 'past'
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchMeetings = async () => {
            const [data, error] = await getMeetings();
            if (data) {
                if (user?.role === 'admin') {
                    dispatch(saveMeetings(data));
                } else {
                    dispatch(saveMeetings(data.filter((meeting: CreateMeetingState) => meeting.userId === user?.id)));
                }
            }
        };
        fetchMeetings();
    }, []);

    const plannedTime = pastMeetings.map(meeting => getPlannedTime(meeting)).reduce((acc, cur) => acc + cur, 0);
    const exceededTime = pastMeetings.map(meeting => getExceededTime(meeting)).reduce((acc, cur) => acc + cur, 0);
    const savedTime = pastMeetings.map(meeting => getSavedTime(meeting)).reduce((acc, cur) => acc + cur, 0);
    const calculatedTime = plannedTime - savedTime + exceededTime;

    return (
        <ContentWrapper style={{ position: 'relative' }}>
            <Button
                style={{ position: 'absolute', right: '100%', top: -6 }}
                small
                tertiary
                label={t('actions.back')}
                icon={<FiArrowLeftCircle size={18} style={{ marginRight: spacings.xxxs }} />}
                onClick={() => navigate('/statistics')}
            />
            <Body mt={spacings.m}>
                {t('statistics.allMeetings')}
            </Body>
            <Headline secondary mb={spacings.m}>
                {t('statistics.timeStats.title')}
            </Headline>
            <ToolbarWrapper style={{ marginBottom: 0 }}>
                <Subheadline quaternary>
                    {t('statistics.timeStats.speakerTime')}
                </Subheadline>
                <Row style={{ gap: spacings.xs }}>
                    <Row>
                        <Dot color={colors.Mustard2} />
                        <Micro>{t('timer.legend.timePlanned')}</Micro>
                    </Row>
                    <Row>
                        <Dot color={colors.Teal2} />
                        <Micro>{t('timer.legend.timeUsed')}</Micro>
                    </Row>
                    <Row>
                        <Dot color={colors.Purple2} />
                        <Micro>{t('timer.legend.timeExceeded')}</Micro>
                    </Row>
                </Row>
            </ToolbarWrapper>
            <TimeRow
                title={t('statistics.timeStats.total')}
                plannedTime={plannedTime}
                exceededTime={exceededTime}
                savedTime={savedTime}
                longestTime={plannedTime > calculatedTime ? plannedTime : calculatedTime}
            />
            {attendees.map(attendee => {
                console.log(attendees)
                const planned = pastMeetings.map(meeting => getPlannedTime(meeting, attendee.email));
                const exceeded = pastMeetings.map(meeting => getExceededTime(meeting, attendee.email));
                const saved = pastMeetings.map(meeting => getSavedTime(meeting, attendee.email));
                return (
                    <TimeRow
                        key={attendee.email}
                        title={attendee.name}
                        plannedTime={planned.reduce((acc, cur) => acc + cur, 0)}
                        exceededTime={exceeded.reduce((acc, cur) => acc + cur, 0)}
                        savedTime={saved.reduce((acc, cur) => acc + cur, 0)}
                        longestTime={plannedTime > calculatedTime ? plannedTime : calculatedTime}
                    />
                );
            })}
        </ContentWrapper>
    );
};

export default TimeStatistics;