import { AuthState, IOrganisation, IUser } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    user: undefined,
    organisation: undefined,
} as AuthState;

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        resetAuthState: () => {
            return initialState;
        },
        setUser: (state, action: PayloadAction<IUser | undefined>) => {
            return {
                ...state,
                user: action.payload,
            };
        },
        setOrganisation: (state, action: PayloadAction<IOrganisation | undefined>) => {
            return {
                ...state,
                organisation: action.payload,
            };
        },
    }
});

export const { resetAuthState, setUser, setOrganisation } = authSlice.actions;
export default authSlice;