import { resetLicenceState } from '@/store/licence-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Root = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const organisation = useAppSelector((state) => state.auth.organisation);

    useEffect(() => {
        console.log(organisation?.paid_externally)
        if (!user) {
            navigate('/login');
        } else {
            if (organisation && (organisation.active_subscription || organisation.paid_externally)) {
                navigate('/dashboard');
            } else if (user.role === 'admin') {
                dispatch(resetLicenceState());
                navigate('/licences/amount');
            } else {
                navigate('/no-access');
            }
        }
    }, [user, organisation]);

    return <div />;
};

export default Root;