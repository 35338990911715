import BorderRadius from '../../../assets/constants/border-radius';
import Colors from '../../../assets/constants/colors';
import Spacings from '../../../assets/constants/spacings';
import styled from 'styled-components';

export const StyledLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
`;

export const StyledBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${BorderRadius.m}px;
    border-width: 2px;
    border-color: ${Colors.Black};
    border-style: solid;
    margin-right: ${Spacings.xxs}px;
    cursor: pointer;
`;