import borderRadius from '../../../assets/constants/border-radius';
import colors from '../../../assets/constants/colors';
import spacings from '../../../assets/constants/spacings';
import styled from 'styled-components';

export const DateInputsWrapper = styled.div`
    
    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
        margin-left: -20px;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: ${colors.Mustard1};
    }

    input {
        font-family: 'NotoSans';
        font-size: 1.125rem;
        width: 100%;
        height: 48px;
        border: 1px solid ${colors.Beige1};
        background: ${colors.White};
        border-radius: ${borderRadius.m}px;
        padding-left: ${spacings.xs}px;
        padding-right: ${spacings.xs}px;
        box-sizing: border-box;
        outline: none;

        &:focus, &:hover {
            border-color: ${colors.Mustard1};
            border-width: 2px;
            padding-left: ${spacings.xs - 1}px;
            padding-right: ${spacings.xs - 1}px;
        }

        &:disabled {
            background-color: ${colors.Beige1};
        }
    }
`;