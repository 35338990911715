import ProfileMenuItem from './profile-menu-item';
import React from 'react';
import { BorderBottom, Wrapper } from './styled';
import { logout } from '@/services/api/auth-service';
import { useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProfileDropdown = ({ style }: { style?: React.CSSProperties; }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useAppSelector(state => state.auth.user);
    const organisation = useAppSelector(state => state.auth.organisation);

    return (
        <Wrapper style={style}>
            <BorderBottom />
            <ProfileMenuItem
                label={t('settings.account')}
                onClick={() => navigate('/settings/account')}
            />
            {user?.role === 'admin' && <>
                <ProfileMenuItem
                    label={t('settings.subscription')}
                    onClick={() => navigate('/settings/subscription')}
                />
                {!organisation?.paid_externally && <ProfileMenuItem
                    label={t('settings.paymentTitle')}
                    onClick={() => navigate('/settings/payment')}
                />}
            </>}
            <ProfileMenuItem
                label={t('settings.logout')}
                onClick={async () => {
                    const [data, error] = await logout();
                    if (data) {
                        navigate('/login');
                    }
                }}
            />
        </Wrapper>
    );
};

export default ProfileDropdown;