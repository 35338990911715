import borderRadius from '@/assets/constants/border-radius';
import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import React, { useState } from 'react';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';
import { BsArrowRightCircle, BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const Box = styled.div`
    border: 1px solid ${colors.Beige1};
    border-radius: ${borderRadius.s}px;
    overflow: hidden;
    display: flex;
    height: 100%;
`;

const ContentWrapper = styled.div`
    flex: 1;
    padding: ${spacings.xs}px;
    background-color: ${colors.White};
`;

const EditWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: ${spacings.xs}px;
    padding-left: ${spacings.s}px;
    background-color: ${colors.Beige3};
    border-left: 1px solid ${colors.Beige1};
    cursor: pointer;

    &:hover {
        background-color: ${colors.Mustard3};
    }

    p {
        line-height: 1;
    }
`;

const EditBox = ({
    renderContent,
    onClick,
}: {
    renderContent: React.ReactNode;
    onClick: () => void;
}) => {
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState<boolean>(false);

    return (
        <Box>
            <ContentWrapper>
                {renderContent}
            </ContentWrapper>
            <EditWrapper
                onClick={onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Button
                    small
                    style={{ height: 'auto' }}
                    noPadding
                    tertiary
                    label={t('actions.edit')}
                    iconRight={isHovered
                        ? <BsFillArrowRightCircleFill size={18} style={{ marginLeft: spacings.xxxs }} />
                        : <BsArrowRightCircle size={18} style={{ marginLeft: spacings.xxxs }} />
                    }
                />
            </EditWrapper>
        </Box>
    );
};

export default EditBox;