"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addMonths = exports.getDaysInMonth = void 0;
var getDaysInMonth = function (date) {
    var monthIndex = date.getMonth();
    var lastDayOfMonth = new Date(0);
    lastDayOfMonth.setFullYear(date.getFullYear(), monthIndex + 1, 0);
    lastDayOfMonth.setHours(0, 0, 0, 0);
    return lastDayOfMonth.getDate();
};
exports.getDaysInMonth = getDaysInMonth;
var addMonths = function (date, value) {
    var desiredMonth = date.getMonth() + value;
    var dateWithDesiredMonth = new Date(0);
    dateWithDesiredMonth.setFullYear(date.getFullYear(), desiredMonth, 1);
    dateWithDesiredMonth.setHours(0, 0, 0, 0);
    var daysInMonth = exports.getDaysInMonth(dateWithDesiredMonth);
    // Set the last day of the new month
    // if the original date was the last day of the longer month
    date.setMonth(desiredMonth, Math.min(daysInMonth, date.getDate()));
};
exports.addMonths = addMonths;
