"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apply = void 0;
var dateUtils_1 = require("./lib/dateUtils");
var parse_1 = require("./parse");
/**
 * Return a new date with the duration applied.
 *
 * @example
 * const newDate = apply('2020-01-01T00:00:00.000Z', { years: 2 })
 * newDate.toISOString() // '2022-01-01T00:00:00.000Z'
 */
var apply = function (date, duration) {
    var parsedDate = new Date(date);
    var _a = parse_1.parse(duration), years = _a.years, months = _a.months, weeks = _a.weeks, days = _a.days, hours = _a.hours, minutes = _a.minutes, seconds = _a.seconds, milliseconds = _a.milliseconds;
    dateUtils_1.addMonths(parsedDate, (years * 12) + months);
    parsedDate.setDate(parsedDate.getDate() + (weeks * 7) + days);
    parsedDate.setHours(parsedDate.getHours() + hours, parsedDate.getMinutes() + minutes, parsedDate.getSeconds() + seconds, parsedDate.getMilliseconds() + milliseconds);
    return parsedDate;
};
exports.apply = apply;
