import Button from '@/common/components/button';
import CalvahPattern from '@/assets/images/calvah-pattern-alt.png';
import ContentWrapper from '@/common/components/content-wrapper';
import spacings from '@/assets/constants/spacings';
import { Body, Headline, Subheadline } from '@/common/components/text';
import { HEADER_HEIGHT } from '@/router/header/styled';
import { reset } from '@/store/create-meeting-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TemplatesPopup from '@/features/create-meeting/templates-popup';
import { useState } from 'react';
import styled from 'styled-components';

const ImageWrapper= styled.div`
    @media (max-width:1000px){
        display:none
    }
`

const WelcomeScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.user);
    const dispatch = useAppDispatch();
    const [showPopup, setShowPopup] = useState<boolean>(false);

    return (
        <ContentWrapper style={{}}>
            <ImageWrapper>
                <img
                    src={CalvahPattern}
                    style={{
                        position: 'absolute',
                        top: HEADER_HEIGHT,
                        right: 0,
                        
                    
                    }}
                />
            </ImageWrapper>

            <div style={{ maxWidth: 650 }}>
                <Headline mt={spacings.xxl}>
                    {t('dashboard.greeting')} {user?.name},
                </Headline>
                <Subheadline tertiary>
                    {t('dashboard.welcomeToCalvah')}
                </Subheadline>
                <Headline tertiary mt={spacings.xl}>
                    {t('dashboard.weRiseTitle')}
                </Headline>
                <Body>
                    {t('dashboard.weRiseDescription')}
                </Body>
                <Button
                    mt={spacings.s}
                    label={t('dashboard.createFirstMeeting')}
                    onClick={() => {
                        dispatch(reset());
                        setShowPopup(true);
                        //navigate('/create-meeting/title');
                    }}
                />
            </div>
            {showPopup && <TemplatesPopup close={() => setShowPopup(false)} />}
        </ContentWrapper>
    );
};

export default WelcomeScreen;