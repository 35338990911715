import AgendaInfo from './agenda-info';
import format from 'date-fns/format';
import Row from '../../../common/components/row';
import spacings from '../../../assets/constants/spacings';
import SpeakerTimes from './speaker-times';
import StepInfo from './step-info';
import { Headline, Subheadline } from '../../../common/components/text';
import { ReactComponent as ICUser } from '@icons/ic-user.svg';
import { SeparatorLine } from '../agenda/agenda-item/styled';
import { useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styled';

const Summary = ({ }: any) => {

    const { t } = useTranslation();
    const title = useAppSelector((state) => state.createMeeting.title);
    const date = useAppSelector((state) => state.createMeeting.date);
    const attendees = useAppSelector((state) => state.createMeeting.attendees);
    const items = useAppSelector((state) => state.createMeeting.agenda.items);

    return (
        <Wrapper>
            <Row>
                <Headline quaternary>
                    {date && date.start && format(new Date(date.start), 'PP')}
                </Headline>
                <Subheadline quaternary ml={spacings.xxs}>
                    {date && date.start && format(new Date(date.start), 'H:mm a')} - {date && date.end && format(new Date(date.end), 'H:mm a')}
                </Subheadline>
            </Row>
            <Headline primary>
                {title}
            </Headline>
            <Row mt={spacings.xxs} mb={spacings.l}>
                {attendees.map(attendee => <ICUser
                    key={attendee.email}
                    style={{
                        height: 36,
                        width: 36,
                        marginRight: spacings.xxxs,
                    }}
                />)}
            </Row>
            <SeparatorLine />
            <SpeakerTimes />
            <AgendaInfo />
            {items.map((item, index) => <StepInfo
                key={item.id}
                index={index}
                isFirst={index === 0}
                isLast={index === items.length - 1}
                item={item}
            />)}
        </Wrapper>
    );
}

export default Summary;