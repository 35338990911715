import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import CalvahPattern from '@/assets/images/calvah-pattern-plain.png';
import Alert from '@/common/components/alert';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import Row from '@/common/components/row';
import { Headline, Small, Subheadline } from '@/common/components/text';
import { HEADER_HEIGHT } from '@/router/header/styled';
import { getOrganisation } from '@/services/api/organisation-service';
import { setupPayment } from '@/services/api/payment-service';
import { setOrganisation } from '@/store/auth-slice';
import { IOrganisation } from '@/store/auth-slice/types';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import InfoBox from '../components/info-box';

const LicenceSummary = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const amount = useAppSelector((state) => state.licence.amount);
    const paymentType = useAppSelector((state) => state.licence.paymentType);
    const setupIntent = useAppSelector((state) => state.licence.setupIntent);
    const cardHolder = useAppSelector((state) => state.licence.paymentDetails?.cardHolder);

    const company = useAppSelector((state) => state.licence.billingDetails?.company);
    const streetAndNo = useAppSelector((state) => state.licence.billingDetails?.streetAndNo);
    const zip = useAppSelector((state) => state.licence.billingDetails?.zip);
    const city = useAppSelector((state) => state.licence.billingDetails?.city);
    const country = useAppSelector((state) => state.licence.billingDetails?.country);

    const paymentMethodDetails = useAppSelector(state => state.licence.paymentMethodDetails);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const onClickSubmitAndSignup = async () => {
        setError(false);
        if (!setupIntent || !company || !country || !streetAndNo || !zip || !city) return;

        setLoading(true);
        const [data, error] = await setupPayment({
            name: company || '',
            payment_method: setupIntent.payment_method,
            subscription_quantity: amount,
            billing_cycle: paymentType,
            address: {
                country: country,
                street: streetAndNo,
                postal_code: zip,
                city: city
            },
        });

        setLoading(false);

        if (data) {
            setLoading(true)
            const [data, error] = await getOrganisation();
            const organisation: IOrganisation = data;
            if (data) {
                dispatch(setOrganisation(organisation));
            }
            setLoading(false);

            navigate('/dashboard');
        }
        if (error) {
            setError(true);
        }
    };

    return (
        <ContentWrapper>
            <img
                src={CalvahPattern}
                style={{
                    zIndex: -1,
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                }}
            />
            <Row
                mt={spacings.xxl}
                style={{
                    alignItems: 'flex-start',
                    gap: spacings.xl,
                }}
            >
                <div style={{ flex: 1, position: 'relative' }}>
                    <Button
                        style={{ position: 'absolute', top: -spacings.l }}
                        noPadding
                        small
                        tertiary
                        label={t('actions.back')}
                        icon={<FiArrowLeftCircle size={18} style={{ marginRight: spacings.xxxs }} />}
                        onClick={() => navigate('/licences/payment')}
                    />
                    <Headline mb={spacings.s}>
                        {t('licence.summary')}
                    </Headline>
                    <div>
                        <Row style={{
                            alignItems: 'flex-start',
                            paddingBottom: spacings.s,
                            borderBottom: `1px solid ${colors.Beige1}`,
                        }}>
                            <Small style={{ width: 215 }} bold mt={spacings.xxxs}>
                                {t('licence.paymentDetails')}
                            </Small>
                            <div>
                                <Subheadline quaternary>
                                    {paymentMethodDetails?.card.brand}
                                </Subheadline>
                                <Subheadline quaternary>
                                    {cardHolder}
                                </Subheadline>
                                <Subheadline quaternary>
                                    **** **** **** {paymentMethodDetails?.card.last4}
                                </Subheadline>
                                <Button
                                    small
                                    secondary
                                    mt={spacings.xxs}
                                    style={{ background: colors.Beige3 }}
                                    label={t('actions.edit')}
                                    onClick={() => navigate('/licences/payment')}
                                />
                            </div>
                        </Row>
                        <Row style={{
                            alignItems: 'flex-start',
                            paddingTop: spacings.s,
                            paddingBottom: spacings.s,
                            borderBottom: `1px solid ${colors.Beige1}`,
                        }}>
                            <Small style={{ width: 215 }} bold mt={spacings.xxxs}>
                                {t('licence.billingAddress')}
                            </Small>
                            <div>
                                <Subheadline quaternary>
                                    {company}
                                </Subheadline>
                                <Subheadline quaternary>
                                    {streetAndNo}
                                </Subheadline>
                                <Subheadline quaternary>
                                    {zip} {city}
                                </Subheadline>
                                <Subheadline quaternary>
                                    {country}
                                </Subheadline>
                                <Button
                                    small
                                    secondary
                                    mt={spacings.xxs}
                                    style={{ background: colors.Beige3 }}
                                    label={t('actions.edit')}
                                    onClick={() => navigate('/licences/billing')}
                                />
                            </div>
                        </Row>
                    </div>
                    {error && <Alert
                        mt={spacings.s}
                        bgColor={colors.Error}
                        text={t('licence.paymentError')}
                    />}
                    <Row mt={spacings.m}>
                        <Button
                            loading={loading}
                            primary
                            label={t('licence.submitAndSignup')}
                            onClick={onClickSubmitAndSignup}
                        />
                    </Row>
                </div>
                <InfoBox showEdit />
            </Row>
        </ContentWrapper>
    );
};

export default LicenceSummary;