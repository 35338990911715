import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import { ReactComponent as Logo } from '@/assets/images/calvah-logo.svg';
import Button from '@/common/components/button';
import Row from '@/common/components/row';
import { Micro, Subheadline } from '@/common/components/text';
import { Pill } from '@/common/styled';
import ProfileDropdown from '@/features/profile/profile-dropdown';
import { useAppSelector } from '@/store/hooks';
import { ReactComponent as ICProfile } from '@icons/ic-profile.svg';
import { differenceInDays } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRightCircle, BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGO_HEIGHT, Wrapper } from './styled';

const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const user = useAppSelector((state) => state.auth.user);
    const organisation = useAppSelector((state) => state.auth.organisation);
    const meetings = useAppSelector((state) => state.meetings.meetings);
    const [profileIsHovered, setProfileIsHovered] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    return (
        <Wrapper>
            <Row>
                <Row onClick={() => window.open('https://calvah.com/','_blank')}>
                    <Logo style={{ height: LOGO_HEIGHT, width: 65 }} />
                    <Subheadline secondary>CALVAH</Subheadline>
                </Row>

                {organisation?.subscription_data
                    && organisation.subscription_data.stripe_status === 'trialing'
                    && organisation?.subscription_data.trial_ends_at && (
                        <Pill
                            style={{ cursor: 'pointer', marginLeft: spacings.m }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            color={colors.Mustard3}
                            onClick={() => navigate('/settings/subscription')}
                        >
                            <Micro bold>
                                {t('licence.trialDaysLeft', { days: 1 + differenceInDays(new Date(organisation?.subscription_data.trial_ends_at), new Date()) })}
                            </Micro>
                            {organisation.subscription_data.ends_at && <>
                                <Micro bold ml={spacings.xxxs}>
                                    {t('licence.upgrade')}
                                </Micro>
                                {isHovered
                                    ? <BsFillArrowRightCircleFill size={16} style={{ marginLeft: spacings.xxxs }} />
                                    : <BsArrowRightCircle size={16} style={{ marginLeft: spacings.xxxs }} />
                                }
                            </>}
                        </Pill>
                    )
                }
            </Row>
            <Row style={{ gap: spacings.xxxs }}>
                {!user && <>
                    {!pathname.includes('login') && <Button
                        style={{
                            borderColor: pathname.includes('login')
                                ? colors.Black
                                : colors.Beige1
                        }}
                        onClick={() => navigate('/login')}
                        small
                        secondary
                        round
                        label={t('auth.login')}
                    />}
                    <Button
                        onClick={() => navigate('/register')}
                        small
                        round
                        label={t('auth.signUp')}
                    />
                </>}
                {user && <>
                    <Button
                        style={{
                            borderColor: pathname.includes('dashboard')
                                ? colors.Black
                                : colors.Beige1
                        }}
                        onClick={() => navigate('/dashboard')}
                        small
                        secondary
                        round
                        label={t('dashboard.title')}
                    />
                    <Button
                        style={{
                            borderColor: pathname.includes('meeting')
                                ? colors.Black
                                : colors.Beige1
                        }}
                        onClick={() => navigate('/meetings/upcoming')}
                        disabled={meetings.length === 0}
                        small
                        secondary
                        round
                        label={t('meetings.headline')}
                    />
                    <Button
                        style={{
                            borderColor: pathname.includes('statistics')
                                ? colors.Black
                                : colors.Beige1
                        }}
                        onClick={() => navigate('/statistics')}
                        disabled={meetings.length === 0}
                        small
                        secondary
                        round
                        label={t('statistics.headline')}
                    />
                    <Button
                        style={{
                            borderColor: pathname.includes('attendees')
                                ? colors.Black
                                : colors.Beige1
                        }}
                        onClick={() => navigate('/attendees')}
                        disabled={meetings.length === 0}
                        small
                        secondary
                        round
                        label={t('attendees.headline')}
                    />
                    <div
                        onMouseEnter={() => setProfileIsHovered(true)}
                        onMouseLeave={() => setProfileIsHovered(false)}
                        style={{ position: 'relative' }}
                    >
                        <Button
                            secondary
                            small
                            round
                            style={{
                                position: 'relative',
                                borderColor: pathname.includes('settings')
                                    ? colors.Black
                                    : colors.Beige1
                            }}
                            iconRight={<ICProfile style={{
                                marginLeft: spacings.xxxs,
                            }} />}
                            label={user.name}
                            onClick={() => navigate('/settings/account')}
                        />
                        {profileIsHovered && <div style={{
                            zIndex: 99,
                            position: 'absolute',
                            top: '100%',
                            right: 0,
                            paddingTop: spacings.xxxs,
                        }}>
                            <ProfileDropdown />
                        </div>}
                    </div>

                </>}
            </Row>
        </Wrapper>
    );
};

export default Header;