import { convertPHPDateStringToUTC } from '@/common/helpers';
import { CreateMeetingState, IMeetingState } from '@/store/create-meeting-slice/types';
import * as DurationFns from 'duration-fns';
import { nanoid } from 'nanoid';
import { IMeetingResponse } from './types';

export const prepareMeetingData = (meeting: CreateMeetingState, organisationId: number | undefined): any => {
    return {
        ...meeting,
        organisation_id: String(organisationId),
        meeting_id: meeting.externalId,
        planed_start_datetime: meeting.date?.start,
        planed_end_datetime: meeting.date?.end,
        place: meeting.date?.place,
        agenda: {
            ...meeting.agenda,
            links: meeting.agenda.links,
            items: meeting.agenda.items.map(item => {
                return {
                    ...item,
                    duration: DurationFns.toSeconds(item.duration),
                    speaker_slots: item.speakers.map(speaker => ({
                        attendee: speaker.attendee.email,
                        duration: DurationFns.toSeconds(speaker.duration)
                    })),
                    discussion: {
                        ...item.discussion,
                        duration: DurationFns.toSeconds(item.discussion.duration)
                    },
                    feedback: {
                        ...item.feedback,
                        duration: DurationFns.toSeconds(item.feedback.duration)
                    },
                };
            })
        }
    };
};

export const parseMeetingData = (data: IMeetingResponse): CreateMeetingState & IMeetingState => {
    return {
        id: data.id,
        userId: data.user_id,
        externalId: data.external_id,
        title: data.title,
        selfAttend: false,
        attendees: data.attendees.map((attendee: any) => ({
            email: attendee.email,
            name: attendee.name,
            pronouns: attendee.pronouns,
        })),
        date: {
            start: new Date(convertPHPDateStringToUTC(data.planed_start_datetime)),
            end: new Date(convertPHPDateStringToUTC(data.planed_end_datetime)),
            place: data.place
        },
        agenda: {
            items: data.agenda.agenda_items.map((agendaItem) => ({
                id: nanoid(),
                name: agendaItem.name,
                description: agendaItem.description,
                speakers: agendaItem.speaker_slots.map((speaker: any) => ({
                    duration: DurationFns.normalize({ seconds: speaker.duration }),
                    attendee: data.attendees.find((attendee: any) => attendee.id === speaker.user_id) || data.attendees[0] // TODO find is guaranteed but Typescript does not no
                })),
                duration: DurationFns.normalize({ seconds: agendaItem.duration }),
                discussion: {
                    question: agendaItem.discussion.question || undefined,
                    duration: DurationFns.normalize({ seconds: agendaItem.discussion.duration }),
                },
                feedback: {
                    question: agendaItem.feedback.question || undefined,
                    duration: DurationFns.normalize({ seconds: agendaItem.feedback.duration }),
                },
                links: agendaItem.links || undefined,
                editable: agendaItem.name !== 'Evaluation', // TODO make this better
                errors: undefined,
            })),
            goal: data.agenda.goal,
            links: data.agenda.links || undefined,
        },
        meetingState: data.meeting_state,
    };
};