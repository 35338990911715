import colors from '@/assets/constants/colors';
import { DAY_IN_SECONDS, HOUR_IN_SECONDS } from '@/assets/constants/common';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Row from '@/common/components/row';
import { Headline, Micro, Small } from '@/common/components/text';
import { formatDurationCustom, formatDurationHMS } from '@/common/helpers';
import { parseStepsResponse } from '@/features/timer/timer-runner/helpers';
import { IStep } from '@/features/timer/timer-runner/types';
import { useAppSelector } from '@/store/hooks';
import { ReactComponent as ICConfetti } from '@icons/ic-confetti.svg';
import { ReactComponent as ICOptionsFilled } from '@icons/ic-options-filled.svg';
import { ReactComponent as ICOptions } from '@icons/ic-options.svg';
import {
    Duration,
    format,
    intervalToDuration,
    isPast as isPastFns,
    isTomorrow as isTomorrowFns
} from 'date-fns';
import * as DurationFns from 'duration-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillPlayFill } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import EditMenu from './edit-menu';
import {
    ContentWrapper,
    DateWrapper,
    SettingsWrapper,
    Wrapper
} from './styled';
import { IMeetingTile } from './types';

const MeetingTile = ({
    meeting,
    setPageIndex,
}: IMeetingTile) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useAppSelector(state => state.auth.user);

    const hasEditRights = (user?.role === 'admin' || user?.id === meeting.userId);
    const [difference, setDifference] = useState<Duration>(intervalToDuration({ start: new Date(meeting.date?.start || new Date()), end: new Date() }));
    const [isEditHovered, setIsEditHovered] = useState<boolean>(false);
    const isInLessThanADay = DurationFns.toSeconds(difference) < DAY_IN_SECONDS;
    const isInLessThanAnHour = DurationFns.toSeconds(difference) < HOUR_IN_SECONDS;
    const isPast = isPastFns(new Date(meeting.date?.start || new Date()));
    const isTomorrow = isTomorrowFns(new Date(meeting.date?.start || new Date()));
    const steps: IStep[] = meeting.meetingState.steps ? parseStepsResponse(JSON.parse(meeting.meetingState.steps)) : [];
    const overflow = steps ? steps.reduce((acc, cur, index) => {
        const actualDuration = intervalToDuration({ start: cur.start, end: cur.end });
        const stepDuration = cur.duration;
        const actualDurationInSeconds = DurationFns.toSeconds(actualDuration) + 1;
        const stepDurationInSeconds = DurationFns.toSeconds(stepDuration);

        if (actualDurationInSeconds - stepDurationInSeconds > 0) {
            return acc + actualDurationInSeconds - stepDurationInSeconds;
        }

        return acc;
    }, 0) : 0;

    useEffect(() => {
        if (!isInLessThanADay) return;
        let timer = setInterval(
            () => {
                setDifference(intervalToDuration({ start: new Date(meeting.date?.start || new Date()), end: new Date() }));
            },
            1000
        );

        return () => clearInterval(timer)
    }, []);

    return (
        <Wrapper>
            <DateWrapper style={{
                backgroundColor: meeting.meetingState.state === 'past'
                    ? colors.White
                    : isPast
                        ? colors.Purple3
                        : DurationFns.toMinutes(difference) < 15
                            ? colors.Teal3
                            : isInLessThanADay
                                ? colors.Mustard3
                                : colors.White
            }}>
                <Micro style={{ height: 20 }} mt={2} mb={-2}>
                    {meeting.meetingState.state === 'past'
                        ? meeting.date && format(new Date(meeting.date.start), 'eee')
                        : isPast
                            ? t('meetings.overdue')
                            : (isInLessThanAnHour || isInLessThanADay)
                                ? t('meetings.startsIn')
                                : isTomorrow
                                    ? t('common.tomorrow')
                                    : meeting.date && format(new Date(meeting.date.start), 'eee')
                    }
                </Micro>
                {meeting.meetingState.state === 'past'
                    ? <Headline secondary style={{ height: 54 }}>
                        {meeting.date && format(new Date(meeting.date.start), 'dd')}
                    </Headline>
                    : isInLessThanAnHour
                        ? <Headline quaternary style={{ height: 54 }} mt={11} mb={-11}>
                            {formatDurationCustom(difference)}
                        </Headline>
                        : isInLessThanADay
                            ? <Headline quaternary style={{ height: 54 }} mt={11} mb={-11}>
                                {formatDurationHMS(difference)}
                            </Headline>
                            : <Headline secondary style={{ height: 54 }}>
                                {meeting.date && format(new Date(meeting.date.start), 'dd')}
                            </Headline>
                }
                <Small bold style={{ height: 24 }}>
                    {meeting.meetingState.state === 'past'
                        ? meeting.date && format(new Date(meeting.date.start), 'MMM')
                        : isInLessThanAnHour
                            ? t('common.minutes')
                            : isInLessThanADay
                                ? t('common.hours')
                                : meeting.date && format(new Date(meeting.date.start), 'MMM')
                    }
                </Small>
            </DateWrapper>
            <ContentWrapper>
                <Small style={{ height: 20 }}>
                    {meeting.date && format(new Date(meeting.date.start), 'h:mm aaa')}
                    {` - `}
                    {meeting.date && format(new Date(meeting.date.end), 'h:mm aaa')}
                </Small>
                <Headline nowrap quaternary style={{ height: 54 }} mt={11} mb={-11}>
                    {meeting.title}
                </Headline>
                <Small nowrap color={colors.Brown1} style={{ height: 24 }}>
                    {meeting.attendees.map(attendee => attendee.name).join(', ')}
                </Small>
            </ContentWrapper>
            {(DurationFns.toMinutes(difference) < 15 || isPast) && meeting.meetingState.state === 'upcoming' && <Button
                icon={hasEditRights ? <BsFillPlayFill
                    color={hasEditRights ? colors.White : colors.Black}
                    size={20}
                    style={{ marginRight: spacings.xxxs }}
                /> : <></>}
                mt={spacings.xs}
                mr={spacings.xs}
                small
                primary={hasEditRights}
                label={hasEditRights ? t('meetings.start') : t('meetings.join')}
                onClick={() => navigate(`/timer/${meeting.externalId}`)}
            />}
            {DurationFns.toMinutes(difference) >= 15 && meeting.meetingState.state === 'upcoming' && <Button
                mt={spacings.xs}
                mr={spacings.xs}
                small
                secondary
                style={{ background: colors.Beige3 }}
                label={t('meetings.showAgenda')}
                onClick={() => navigate(`/timer/${meeting.externalId}`)}
            />}
            {meeting.meetingState.state === 'running' && <Button
                mt={spacings.xs}
                mr={spacings.xs}
                small
                label={t('meetings.goToMeeting')}
                onClick={() => navigate(`/timer/${meeting.externalId}`)}
            />}
            {meeting.meetingState.state === 'past' &&
                <Row style={{
                    position: 'relative',
                    alignItems: 'flex-end',
                    paddingBottom: spacings.xxs,
                    paddingRight: spacings.xs,
                }}>
                    {overflow === 0 && <ICConfetti style={{
                        position: 'absolute',
                        top: 0,
                        right: spacings.xs,
                    }} />}
                    <Headline quaternary color={overflow > 0 ? colors.Purple1 : colors.Teal1}>
                        {overflow > 0
                            ? `+${formatDurationCustom({ seconds: overflow })} ${t('common.minutes')}`
                            : t('meetings.timeKept')
                        }
                    </Headline>
                </Row>
            }
            <SettingsWrapper
                onMouseEnter={() => setIsEditHovered(true)}
                onMouseLeave={() => setIsEditHovered(false)}
                style={{
                    pointerEvents: hasEditRights ? 'all' : 'none',
                }}
            >
                <div style={{
                    opacity: hasEditRights ? 1 : 0.3,
                    textAlign: 'center',
                }}>
                    {isEditHovered
                        ? <IoMdClose size={20} />
                        : isEditHovered
                            ? <ICOptionsFilled />
                            : <ICOptions />
                    }
                    <Micro>{t('common.more')}</Micro>
                    {isEditHovered && <EditMenu
                        meeting={meeting}
                        setPageIndex={setPageIndex}
                    />}
                </div>
            </SettingsWrapper>
        </Wrapper>
    );
}

export default MeetingTile;