import * as DurationFns from 'duration-fns';
import colors from '@/assets/constants/colors';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { BoxTitle, Wrapper } from './styled';
import { formatDurationCustom } from '@/common/helpers';
import {
    Headline,
    Micro,
    Small,
    Subheadline
    } from '@/common/components/text';
import { HiArrowRight, HiOutlineArrowCircleRight } from 'react-icons/hi';
import { IAgendaItem } from '@/store/create-meeting-slice/types';
import { ReactComponent as ICUser } from '@icons/ic-user.svg';
import { SeparatorLine } from '../../agenda/agenda-item/styled';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StepInfo = ({
    item,
    index,
    isFirst,
    isLast
}: {
    item: IAgendaItem;
    index: number;
    isFirst?: boolean;
    isLast?: boolean;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const totalDuration = DurationFns.sum(item.duration, item.discussion.duration, item.feedback.duration);
    const links = item.links?.split('\n');

    return (
        <Wrapper>
            <BoxTitle>
                <Subheadline quaternary>
                    {isFirst && t('common.start')}
                    {isLast && t('common.end')}
                    {!isFirst && !isLast && (index)}
                </Subheadline>
            </BoxTitle>
            <Headline quaternary mb={spacings.xxs}>
                {item.name}
            </Headline>
            <Row mb={spacings.xxs}>
                <Headline quaternary>
                    {formatDurationCustom(totalDuration)}
                </Headline>
                <Micro color={colors.Brown1} ml={spacings.xxxs} mt={4}>
                    {t('common.minutes')}
                </Micro>
            </Row>
            <Small mb={spacings.xs}>
                {item.description}
            </Small>
            {item.speakers.map(speaker => <Row
                key={speaker.attendee.email}
                mb={spacings.xxxs}
            >
                <ICUser />
                <Small bold ml={spacings.xxxs}>
                    {speaker.attendee.name} {speaker.attendee.pronouns && `(${speaker.attendee.pronouns})`}
                </Small>
                <Small ml={spacings.xxxs}>
                    - {formatDurationCustom(speaker.duration)} {t('common.minutes')}
                </Small>
            </Row>)}
            {links && <>
                <Micro color={colors.Brown1} mb={spacings.xxxs} mt={spacings.xs}>
                    {t('create.stepSummary.stepLinks')}
                </Micro>
                {links.map(link => <Small key={link} link mr={spacings.xxxs} mb={spacings.xxxs}>
                    {link}
                    <HiArrowRight
                        color={colors.Purple1}
                        size={16}
                        style={{ marginLeft: spacings.xxxs, marginBottom: -2 }}
                    />
                </Small>)}
            </>}
            {item.discussion.question && <>
                <Micro color={colors.Brown1} mb={spacings.xxxs} mt={spacings.xs}>
                    {t('create.stepSummary.discussion')}
                </Micro>
                <Small bold mb={spacings.xxxs}>
                    {item.discussion.question}
                </Small>
                <Row>
                    <Small bold>
                        {formatDurationCustom(item.discussion.duration)}
                    </Small>
                    <Small color={colors.Brown1} ml={spacings.xxxs}>
                        {t('common.minutes')}
                    </Small>
                </Row>
            </>}
            {item.feedback.question && <>
                <Micro color={colors.Brown1} mb={spacings.xxxs} mt={spacings.xs}>
                    {t('create.stepSummary.feedback')}
                </Micro>
                <Small bold mb={spacings.xxxs}>
                    {item.feedback.question}
                </Small>
                <Row>
                    <Small bold>
                        {formatDurationCustom(item.feedback.duration)}
                    </Small>
                    <Small color={colors.Brown1} ml={spacings.xxxs}>
                        {t('common.minutes')}
                    </Small>
                </Row>
            </>}
            <Small link mt={spacings.xs} onClick={() => navigate(`/create-meeting/agenda?step=${index}`)}>
                {t('actions.edit')}
                <HiOutlineArrowCircleRight
                    color={colors.Purple1}
                    size={22}
                    style={{ marginLeft: spacings.xxxs, marginBottom: -5 }}
                />
            </Small>
            {!isLast && <SeparatorLine />}
        </Wrapper>
    );
}

export default StepInfo;