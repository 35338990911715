import DatePicker from 'react-datepicker';
import spacings from '../../../assets/constants/spacings';
import { DateInputsWrapper } from './styled';
import { IDatepicker } from './types';
import { Small } from '../text';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

const Datepicker = ({ date, setDate, label, timeOnly, disabled }: IDatepicker) => {
    const { t } = useTranslation();
    return (
        <DateInputsWrapper>
            {label ? <Small mb={spacings.xxxs}>
                {label}
            </Small> : null}
            <DatePicker
                minDate={new Date()}
                selected={date}
                onChange={(d: Date) => setDate(d)}
                showTimeSelect={timeOnly}
                showTimeSelectOnly={timeOnly}
                timeIntervals={15}
                timeCaption={t('common.time')}
                dateFormat={timeOnly ? 'h:mm aa' : 'EEEE, P'}
                disabled={disabled}
            />
        </DateInputsWrapper>
    );
};

export default Datepicker;