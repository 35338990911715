import axios from 'axios';
import store from '@/store';
import { handleApiError } from '../helpers';
import { IAttendee } from '@/store/create-meeting-slice/types';

const API_BASE = process.env.REACT_APP_API_BASE;

export const getOrganisation = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getAllUsers = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/users?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getAllUsersPaginated = async (currentPage:number, search?: string) => {
    const state = store.getState();
    let endpoint = `${API_BASE}/organisation/usersPaginated?organisation_id=${state.auth.user?.organisatonId}`;
    endpoint = endpoint.concat(`&page=${currentPage}`)
    if (search) {
        endpoint = endpoint.concat(`&search=${search}`)
    }
    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getUser = async (userId: number) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/users/${userId}?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const addUser = async (data: IAttendee) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/attendee`;

    try {
        const result = await axios.post(endpoint, {
            ...data,
            organisation_id: state.auth.user?.organisatonId
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const editUser = async (data: IAttendee) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/attendee`;

    try {
        const result = await axios.put(endpoint, {
            ...data,
            organisation_id: state.auth.user?.organisatonId
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getOrganisers = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/organiser?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const inviteOrganisers = async (emails: string[]) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/organiser`;

    try {
        const result = await axios.post(endpoint, {
            emails: emails,
            organisation_id: state.auth.user?.organisatonId
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const removeUserFromOrganisation = async (email: string) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/removeUserFromOrganisation`;

    try {
        const result = await axios.post(endpoint, {
            email,
            organisation_id: state.auth.user?.organisatonId
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};