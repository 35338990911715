import React from "react";
import OrderByItem from "../order-by-item";
import { Wrapper } from '../styled';

const OrderByPopUp = ({
    popUpStyle,
    sortByOptions,
    current
}: {
    popUpStyle?: React.CSSProperties
    sortByOptions: { slug: string, value: number, label: string, onClick: () => void }[]
    current: string
}) => {

    return (
        <Wrapper
            style={{ ...popUpStyle }}
        >
            {
                sortByOptions.map((option) => {
                    return <OrderByItem
                        active={option.slug == current}
                        key={option.slug}
                        label={option.label}
                        onClick={option.onClick}
                    />
                })
            }

        </Wrapper>
    )
}

export default OrderByPopUp;
