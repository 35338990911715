import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import StatsBox from './stats-box';
import {getEvaluationQuestions} from '@/services/api/meeting-service';
import {
    IAnswer,
    IQuestion,
} from '@/features/timer/timer-runner/step-info/questionnaire/types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const EvaluationSummary = ({
    answers,
    singular,
}: {
    answers: IAnswer[];
    singular?: boolean;
}) => {
    const {t} = useTranslation();
    const [questions, setQuestions] = useState<IQuestion[]>([]);

    useEffect(() => {
        const fetchQuestions = async () => {
            const [data, error] = await getEvaluationQuestions();
            if (data) {
                setQuestions(data);
            }
        };
        fetchQuestions();
    }, []);

    return (
        <Row
            style={{
                gap: spacings.m,
                alignItems: 'flex-start',
            }}>
            {questions.map(question => (
                <StatsBox
                    key={question.question}
                    title={question.evaluation_question_category.category}
                    question={
                        singular ? question.question : question.question_eval
                    }
                    yesPercentage={Math.round(
                        (answers.filter(
                            answer =>
                                answer.question === question.id &&
                                answer.answer === 1,
                        ).length /
                            answers.filter(
                                answer => answer.question === question.id,
                            ).length) *
                            100,
                    )}
                    neutralPercentage={Math.round(
                        (answers.filter(
                            answer =>
                                answer.question === question.id &&
                                answer.answer === 2,
                        ).length /
                            answers.filter(
                                answer => answer.question === question.id,
                            ).length) *
                            100,
                    )}
                />
            ))}
        </Row>
    );
};

export default EvaluationSummary;
