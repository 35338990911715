import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import MeetingTile from '@/common/components/meeting-tile';
import Row from '@/common/components/row';
import { Headline, Small, Subheadline } from '@/common/components/text';
import { ToolbarWrapper } from '@/common/styled';
import { getMeetings } from '@/services/api/meeting-service';
import { getOrganisation } from '@/services/api/organisation-service';
import { setOrganisation } from '@/store/auth-slice';
import { IOrganisation } from '@/store/auth-slice/types';
import { CreateMeetingState } from '@/store/create-meeting-slice/types';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { saveMeetings } from '@/store/meetings-slice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowRightCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import TemplatesPopup from '../create-meeting/templates-popup';
import MeetingStats from '../statistics/statistics-dashboard/meeting-stats';
import SatisfactionStats from '../statistics/statistics-dashboard/satisfaction-stats';
import TimeStats from '../statistics/statistics-dashboard/time-stats';
import WelcomeScreen from './welcome-screen';
import { ReactComponent as Logo } from '@/assets/images/calvah-logo.svg';
import BounceLoader from "react-spinners/BounceLoader";

const Dashboard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const meetings = useAppSelector((state) => state.meetings.meetings);
    const user = useAppSelector((state) => state.auth.user);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        if(meetings.length==0){
            setLoading(true);
        }
        const fetchMeetings = async () => {
            const [data, error] = await getMeetings();
            if (data) {
                if (user?.role === 'admin') {
                    dispatch(saveMeetings(data));
                } else {
                    dispatch(saveMeetings(data.filter((meeting: CreateMeetingState) => meeting.userId === user?.id)));
                }
            }
            setLoading(false);
        };
        fetchMeetings();
    }, []);

    useEffect(() => {
        const fetchOrganisation = async () => {
            const [data, error] = await getOrganisation();
            const organisation: IOrganisation = data;
            if (data) {
                dispatch(setOrganisation(organisation));
            }
        };
        fetchOrganisation();
    }, []);

    const upcomingMeetings = meetings.filter(meeting => ['upcoming', 'running'].includes(meeting.meetingState.state));
    const pastMeetings = meetings.filter(meeting => meeting.meetingState.state === 'past');

    // if(loading){
    //     return <div style={{alignContent:"center", display:'flex', position:'relative', margin:'20%'}}>
    //         <BounceLoader
    //             speedMultiplier={0.5}
    //             loading={loading}
    //             color={colors.Black}
    //             size={400}
    //         />
    //         <Logo style={{ height: '80hv', alignSelf:'center', position:'absolute' }} />
    //     </div>
        
    // }

    return (
        <ContentWrapper>
            {!loading && meetings.length === 0
                ? <WelcomeScreen />
                : <>
                    <Headline tertiary mt={spacings.m} mb={spacings.l}>
                        {t('dashboard.headline', { name: user?.name })}
                    </Headline>
                    <ToolbarWrapper>
                        <Subheadline quaternary>
                            {t('dashboard.nextMeeting')}
                        </Subheadline>
                        <Row>
                            <Button
                                small
                                tertiary
                                label={t('meetings.showAll')}
                                onClick={() => navigate('/meetings/upcoming')}
                                iconRight={<FiArrowRightCircle size={22} style={{ marginLeft: spacings.xxxs }} />}
                            />
                            <Button
                                small
                                secondary
                                label={t('meetings.createMeeting')}
                                onClick={() => setShowPopup(true)}
                            />
                        </Row>
                    </ToolbarWrapper>
                    <div>
                        {upcomingMeetings.length === 0
                            ? <Small color={colors.Brown1}>
                                {t('meetings.noUpcomingMeetings')}
                            </Small>
                            : upcomingMeetings
                                .slice(0, 1)
                                .map(meeting => {
                                    return (
                                        <MeetingTile
                                            key={meeting.externalId}
                                            meeting={meeting}
                                        />
                                    );
                                })
                        }
                    </div>
                    <div style={{ marginTop: spacings.l }}>
                        <Row mb={spacings.xl} style={{ gap: spacings.m, alignItems: 'flex-start' }}>
                            <SatisfactionStats meetings={pastMeetings} />
                            <TimeStats />
                            <MeetingStats />
                        </Row>
                    </div>
                </>
            }
            {showPopup && <TemplatesPopup close={() => setShowPopup(false)} />}
        </ContentWrapper>
    );
};

export default Dashboard;