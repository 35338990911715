import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import { calculateSpacings } from '@/common/helpers';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';
import { IGenericElement, ISpacings } from '../../types';
import { Micro } from '../text';
import { StyledCircle, StyledWrapper } from './styled';
import { Props } from './types';

const Pagination = ({
    style,
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    pages,
    current,
    setCurrent,
}: Props & IGenericElement & ISpacings) => {

    return (
        <StyledWrapper
            style={{
                ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
                ...style,
            }}>
            <StyledCircle
                filled
                disabled={current === 0}
                onClick={() => setCurrent(Math.max(current - 1, 0))}
                style={{ marginRight: spacings.xs }}
            >
                <IoArrowBack
                    color={colors.White}
                    size={18}
                />
            </StyledCircle>

            {Array.from(Array(pages).keys()).map(index => {
                return (
                    (index > current + 2 || index < current - 2)
                    || (current > 2 && current < pages - 1 && index < current - 1)
                    || (current < pages - 3 && current > 0 && index > current + 1)
                ) ? (
                    (index === 0 || index === pages - 1) ? (
                        <>
                            {index === 0 && (
                                <StyledCircle
                                    key={index}
                                    onClick={() => setCurrent(index)}
                                >
                                    <Micro bold>
                                        {1}
                                    </Micro>
                                </StyledCircle>
                            )}
                            <Micro bold>
                                ...
                            </Micro>
                            {index === pages - 1 && (
                                <StyledCircle
                                    key={index}
                                    onClick={() => setCurrent(index)}
                                >
                                    <Micro bold>
                                        {pages}
                                    </Micro>
                                </StyledCircle>
                            )}
                        </>
                    ) : (
                        <></>
                    )
                ) : (
                    <StyledCircle
                        active={index === current}
                        key={index}
                        onClick={() => setCurrent(index)}
                    >
                        <Micro bold>
                            {index + 1}
                        </Micro>
                    </StyledCircle>
                );
            })}

            <StyledCircle

                filled
                disabled={current === pages - 1}
                onClick={() => setCurrent(Math.min(current + 1, pages - 1))}
                style={{ marginLeft: spacings.xs }}
            >
                <IoArrowForward
                    color={colors.White}
                    size={18}
                />
            </StyledCircle>
        </StyledWrapper>
    );
}

export default Pagination;