import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    border-radius: ${borderRadius.s}px;
    border: 1px solid ${colors.Beige1};
`;

export const BorderBottom = styled.div`
    position: absolute;
    bottom: -7px;
    left: 0;
    z-index: -1;
    width: calc(100% - 2px);
    height: 10px;
    background-color: ${colors.Beige2};
    border: 1px solid ${colors.Beige2};
    border-radius: ${borderRadius.s}px;
`;

export const MenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacings.xxs}px;
    background-color: ${colors.Beige3};
    cursor: pointer;
    width: 225px;

    &:hover {
        background-color: ${colors.Mustard3};
    }
`;