import Colors from '../../../../assets/constants/colors';
import styled from 'styled-components';
import { default as Spacings, default as spacings } from '../../../../assets/constants/spacings';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const ListItem = styled.div`
    flex: 1;
    position: relative;
    background-color: ${Colors.White};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -1px;
    overflow: hidden;
    border: 1px solid ${Colors.Beige1};
`;

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${Spacings.xxs}px;
    width: 100%;
`;

export const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.Beige3};
    margin-left: -1px;
    margin-bottom: -1px;
    border: 1px solid ${Colors.Beige1};
    padding-left: ${spacings.xxs}px;
    padding-right: ${spacings.xxs}px;
    cursor: pointer;

    &:hover {
        background-color: ${Colors.Mustard3};
    }
`;