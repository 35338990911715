import Spacings from '../../assets/constants/spacings';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from './sidebar/styled';

export const Wrapper = styled.div`
    display: flex;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-left: ${SIDEBAR_WIDTH}px;
`;

export const InnerWrapper = styled.div`
    width: 750px;
    max-width: 100%;
    box-sizing: border-box;
    padding: ${Spacings.xxl}px ${Spacings.s}px ${Spacings.xxxl}px;

    @media(max-width:1100px){
        padding-left:75px;
    }
`;