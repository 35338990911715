import * as DurationFns from 'duration-fns';
import * as MeetingService from '@/services/api/meeting-service';
import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Bar } from './styled';
import { formatDurationCustom } from '@/common/helpers';
import {
    getLongestDurationInSeconds,
    getShortestDurationInSeconds,
    getSpeakerDuration,
    SPEAKER_TIME_THRESHOLD_PERCENTAGE
    } from '../helpers';
import { Headline, Small } from '@/common/components/text';
import { ReactComponent as ICUser } from '@icons/ic-user.svg';
import { reset } from '@/store/create-meeting-slice';
import { useAppSelector } from '@/store/hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { nanoid } from 'nanoid';

const SpeakerTimesPopup = ({ close }: { close: () => void; }) => {
    const { t } = useTranslation();
    const attendees = useAppSelector((state) => state.createMeeting.attendees);
    const items = useAppSelector((state) => state.createMeeting.agenda.items);
    const meeting = useAppSelector((state) => state.createMeeting);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const timesPerSpeaker = attendees.map(attendee => ({
        attendee: attendee,
        duration: getSpeakerDuration(attendee.email, items)
    }));

    const longestDurationInSeconds = getLongestDurationInSeconds(timesPerSpeaker);
    const shortestDurationInSeconds = getShortestDurationInSeconds(timesPerSpeaker);
    const percentageOfShortestOnLongest = shortestDurationInSeconds / longestDurationInSeconds * 100;

    const [externalID, setExternalID]= useState<string>(nanoid());
    const [alreadySubmitted, setAlreadySubmitted] = useState<boolean>(false);
    const [loading , setLoading] = useState<boolean>(false);

    const onClickSubmit = async () => {
        setLoading(true)
        setAlreadySubmitted(true)
        if (meeting.id) {
            const [data, error] = await MeetingService.editMeeting(meeting);
            if (!error) {
                navigate('/meetings/upcoming');
                dispatch(reset());
            }
        } else {
            const [data, error] = await MeetingService.createMeeting({
                ...meeting,
                externalId: externalID
            });
            if (data) {
                navigate('/meetings/upcoming');
                dispatch(reset());
            }
        }
    };

    return (
        <Popup close={close}>
            <Headline quaternary mb={spacings.xxxs}>
                {t('create.stepSummary.speakingTimeSummary')}
            </Headline>
            <Small mb={spacings.s}>
                {t('create.stepSummary.unbalancedTimes')}
            </Small>
            <div>
                {timesPerSpeaker.map(times => {
                    const percentage = DurationFns.toSeconds(times.duration) / longestDurationInSeconds * 100;
                    return (
                        <Row
                            key={times.attendee.email}
                            mb={spacings.xxxs}
                            style={{ justifyContent: 'space-between', gap: spacings.xxxs }}
                        >
                            <ICUser style={{ width: 20, height: 20, flexShrink: 0 }} />
                            <Small bold style={{
                                width: '30%',
                                paddingRight: spacings.xxs,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}>
                                {times.attendee.name}
                            </Small>
                            <Row>
                                <Small bold color={percentageOfShortestOnLongest < SPEAKER_TIME_THRESHOLD_PERCENTAGE && percentage === 100 ? colors.Purple1 : colors.Black}>
                                    {formatDurationCustom(times.duration)}
                                </Small>
                                <Small ml={spacings.xxxs} color={colors.Brown1}>
                                    {/* TODO translate */}
                                    min
                                </Small>
                                <div style={{ width: 250, paddingLeft: spacings.xxs }}>
                                    <Bar
                                        color={percentageOfShortestOnLongest < SPEAKER_TIME_THRESHOLD_PERCENTAGE && percentage === 100 ? colors.Purple2 : colors.Mustard2}
                                        width={`${percentage}%`}
                                    />
                                </div>
                            </Row>
                        </Row>
                    );
                })}
            </div>
            <Row style={{ justifyContent: 'flex-end' }} mt={spacings.m}>
                <Button
                    secondary
                    small
                    label={t('create.editTimes')}
                    onClick={() => navigate('/create-meeting/agenda')}
                    mr={spacings.xxxs}
                />
                <Button
                    loading={loading}
                    small
                    label={t('create.confirmAndFinish')}
                    onClick={onClickSubmit}
                    disabled={alreadySubmitted}
                />
            </Row>
        </Popup>
    );
}

export default SpeakerTimesPopup;