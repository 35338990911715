"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validate = void 0;
var units_1 = require("./units");
var validate = function (duration) {
    Object.keys(duration).forEach(function (unit) {
        if (!units_1.UNITS.includes(unit)) {
            throw new TypeError("Unexpected property \"" + unit + "\" on Duration object.");
        }
        if (!Number.isInteger(duration[unit])) {
            throw new TypeError("Property \"" + unit + "\" must be a an integer. Received " + duration[unit] + ".");
        }
    });
};
exports.validate = validate;
