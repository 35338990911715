import Attendees from '@/features/attendees';
import CreateMeeting from '../features/create-meeting';
import Dashboard from '@/features/dashboard';
import Footer from './footer';
import Header from './header';
import InviteOrganisers from '@/features/settings/invite-organisers';
import LicenceAmount from '@/features/licences/amount';
import LicenceBilling from '@/features/licences/billing';
import LicenceHeader from './licence-header';
import LicencePayment from '@/features/licences/payment';
import LicenceSummary from '@/features/licences/summary';
import Meetings from '@/features/meetings';
import MeetingStatistics from '@/features/statistics/meeting-statistics';
import NotFound from '@/features/not-found';
import OrganiserStatistics from '@/features/statistics/organiser-statistics';
import Root from '@/features/root';
import SatisfactionStatistics from '@/features/statistics/satisfication-statistics';
import Settings from '@/features/settings';
import StatisticsDashboard from '@/features/statistics/statistics-dashboard';
import TimeStatistics from '@/features/statistics/time-statistics';
import { CreateStep } from '../features/create-meeting/steps';

const licencedRoutes = [
    {
        // TODO does nothing right now because of public routes
        path: '*',
        component: <><Header /><NotFound /><Footer /></>,
    },
    {
        path: '/',
        component: <Root />,
    },
    {
        path: '/dashboard',
        component: <><Header /><Dashboard /><Footer /></>,
    },
    {
        path: '/attendees',
        component: <><Header /><Attendees /><Footer /></>,
    },
    {
        path: '/statistics',
        component: <><Header /><StatisticsDashboard /><Footer /></>,
    },
    {
        path: '/statistics/satisfaction',
        component: <><Header /><SatisfactionStatistics /><Footer /></>
    },
    {
        path: '/statistics/time',
        component: <><Header /><TimeStatistics /><Footer /></>,
    },
    {
        path: '/statistics/:id',
        component: <><Header /><MeetingStatistics /><Footer /></>,
    },
    {
        path: '/statistics/organiser/:id',
        component: <><Header /><OrganiserStatistics /><Footer /></>,
    },
    {
        path: '/meetings/:mode',
        component: <><Header /><Meetings /><Footer /></>,
    },
    {
        path: '/settings/:page',
        component: <><Header /><Settings /><Footer /></>,
    },
    {
        path: '/settings/organisers',
        component: <><Header /><InviteOrganisers /><Footer /></>,
    },
    {
        path: '/licences/amount',
        component: <><LicenceHeader /><LicenceAmount /><Footer /></>,
    },
    {
        path: '/licences/billing',
        component: <><LicenceHeader /><LicenceBilling /><Footer /></>,
    },
    {
        path: '/licences/payment',
        component: <><LicenceHeader /><LicencePayment /><Footer /></>,
    },
    {
        path: '/licences/summary',
        component: <><LicenceHeader /><LicenceSummary /><Footer /></>,
    },
    {
        path: '/create-meeting',
        component: <CreateMeeting stepIndex={CreateStep.One} />,
    },
    {
        path: '/create-meeting/title',
        component: <CreateMeeting stepIndex={CreateStep.One} />,
    },
    {
        path: '/create-meeting/attendees',
        component: <CreateMeeting stepIndex={CreateStep.Two} />,
    },
    {
        path: '/create-meeting/agenda',
        component: <CreateMeeting stepIndex={CreateStep.Three} />,
    },
    {
        path: '/create-meeting/date',
        component: <CreateMeeting stepIndex={CreateStep.Four} />,
    },
    {
        path: '/create-meeting/summary',
        component: <CreateMeeting stepIndex={CreateStep.Five} />,
    },
];

export default licencedRoutes;
