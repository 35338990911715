export const Black = '#081731';
export const White = '#ffffff';
export const Brown1 = '#73726A';
export const Brown2 = '#99988C';
export const Beige1 = '#D7D5C3';
export const Beige2 = '#EFEEE0';
export const Beige3 = '#FAF9EF';
export const Purple1 = '#AB53B6';
export const Purple2 = '#D792DF';
export const Purple3 = '#F3D0F3';
export const Teal1 = '#1F837E';
export const Teal2 = '#65CCC7';
export const Teal3 = '#C5EEEE';
export const Mustard1 = '#AB9245';
export const Mustard2 = '#E7CB6F';
export const Mustard3 = '#FBE9AD';
export const Error = '#CB3100';
export const ErrorLight = '#FFD5C8';
export const Transparent = 'transparent';
export const Grey = '#7C848C';

export default {
    Black,
    White,
    Brown1, Brown2,
    Beige1, Beige2, Beige3,
    Purple1, Purple2, Purple3,
    Teal1, Teal2, Teal3,
    Mustard1, Mustard2, Mustard3,
    Error, ErrorLight,
    Transparent, Grey
};