import spacings from '@/assets/constants/spacings';
import { BsArrowRightCircleFill } from 'react-icons/bs';
import { MenuItem } from '../styled';
import { Small } from '@/common/components/text';
import { useState } from 'react';

const ProfileMenuItem = ({
    label,
    onClick,
}: {
    label: string;
    onClick: () => void;
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    return (
        <MenuItem
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
        >
            <Small>
                {label}
            </Small>
            {isHovered && <BsArrowRightCircleFill
                size={18}
                style={{ marginLeft: spacings.s }}
            />}
        </MenuItem>
    );
};
export default ProfileMenuItem;