import styled from 'styled-components';
import { calculateSpacings } from '../../../helpers';
import { IGenericElement, ISizes, ISpacings } from '../../../types';

const StyledText = styled.h2`
    font-family: Jost;
    line-height: 1.5;

    b {
        font-weight: bold;
    }
`;

const Subheadline = ({
    children,
    style,
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    primary,
    secondary,
    tertiary,
    quaternary
}: IGenericElement & ISpacings & ISizes) => {
    return (
        <StyledText style={{
            ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
            ...{ fontSize: primary ? '3.375rem' : secondary ? '2.5rem' : tertiary ? '2rem' : quaternary ? '1.625rem' : '3.375rem' },
            ...style,
        }}>
            {children}
        </StyledText>
    );
}

export default Subheadline;