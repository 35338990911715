import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import { Body, Headline } from '@/common/components/text';
import { IPopup } from '@/common/types';
import { deleteMeeting } from '@/services/api/meeting-service';
import { useAppDispatch } from '@/store/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDeletePopup } from './types';
import { removeMeeting } from '@/store/meetings-slice';


const DeleteMeetingPopup = ({
    close,
    meeting,
    setPageIndex,
}: IPopup & IDeletePopup) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();


    const onClickDelete = async () => {
        if (!meeting.externalId) return;
        setLoading(true);
        const [data, error] = await deleteMeeting(meeting.externalId);
        dispatch(removeMeeting(meeting))
        setLoading(false);
    };

    return (
        <Popup close={close} alert>
            <Headline quaternary mb={spacings.s}>
                {t('meetings.deleteMeeting')}
            </Headline>
            <Body mt={spacings.s}>
                {t('meetings.sureToDelete', { title: meeting.title })}
            </Body>
            <Row mt={spacings.m} style={{ justifyContent: 'flex-end' }}>
                <Button
                    mr={spacings.xxs}
                    tertiary
                    label={t('actions.noCancel')}
                    onClick={close} />
                <Button
                    loading={loading}
                    style={{ backgroundColor: colors.Error }}
                    label={t('actions.yesDelete')}
                    onClick={onClickDelete} />
            </Row>
        </Popup>
    );
}

export default DeleteMeetingPopup;