import BorderRadius from '../../../../assets/constants/border-radius';
import Colors from '../../../../assets/constants/colors';
import Spacings from '../../../../assets/constants/spacings';
import styled from 'styled-components';

export const ListItem = styled.div`
    position: relative;
    background-color: ${Colors.White};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -1px;
    border-radius: ${BorderRadius.m}px;
    border: 1px solid ${Colors.Beige1};
    overflow: hidden;
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: ${Spacings.xs}px;
    padding-right: ${65 + Spacings.xs}px;
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.Beige3};
    height: 100%;
    width: 65px;
    border-left: 1px solid ${Colors.Beige1};
`;