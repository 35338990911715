import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import {Small, Subheadline} from '@/common/components/text';
import {getMeetingsByDateRange} from '@/services/api/meeting-service';
import {
    CreateMeetingState,
    IMeetingState,
} from '@/store/create-meeting-slice/types';
import {ReactComponent as ICSmileyNeutral} from '@icons/ic-smiley-neutral.svg';
import {ReactComponent as ICSmileyNo} from '@icons/ic-smiley-no.svg';
import {ReactComponent as ICSmileyYes} from '@icons/ic-smiley-yes.svg';
import {endOfWeek, format, startOfWeek, sub} from 'date-fns';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Box, Header} from '../../styled';
import WeekPicker from '../week-picker';

const Circle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: ${colors.Beige2};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${spacings.s}px;
`;

const MeetingStats = () => {
    const {t} = useTranslation();

    const [meetings, setMeetings] = useState<
        (CreateMeetingState & IMeetingState)[]
    >([]);
    const [start, setStart] = useState<Date>(
        sub(startOfWeek(new Date(), {weekStartsOn: 1}), {weeks: 1}),
    );
    const [end, setEnd] = useState<Date>(
        sub(endOfWeek(new Date(), {weekStartsOn: 1}), {weeks: 1}),
    );

    useEffect(() => {
        const fetchMeetings = async () => {
            const [data, error] = await getMeetingsByDateRange(
                format(start, 'yyyy-MM-dd'),
                format(end, 'yyyy-MM-dd'),
            );
            if (data) {
                setMeetings(data);
            }
        };
        fetchMeetings();
    }, [start, end]);

    const smileyStyle = {
        width: 35,
        height: 35,
        position: 'absolute',
        bottom: 0,
        right: 0,
    } as const;

    return (
        <div style={{flex: 1}}>
            <Header>
                <Subheadline quaternary>
                    {t('statistics.meetingStats.title')}
                </Subheadline>
                <WeekPicker
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                />
            </Header>
            <Box>
                {meetings.length === 0 ? (
                    <Small color={colors.Brown1}>
                        {t('statistics.meetingStats.noStats')}
                    </Small>
                ) : (
                    <>
                        <Small>{t('statistics.meetingStats.question')}</Small>
                        <Circle>
                            <Subheadline secondary>
                                {meetings.length}
                            </Subheadline>
                            {meetings.length < 8 ? (
                                <ICSmileyYes style={smileyStyle} />
                            ) : meetings.length < 12 ? (
                                <ICSmileyNeutral style={smileyStyle} />
                            ) : (
                                <ICSmileyNo style={smileyStyle} />
                            )}
                        </Circle>
                    </>
                )}
            </Box>
        </div>
    );
};

export default MeetingStats;
