import AddUserPopup from '../add-user-popup';
import Button from '@/common/components/button';
import CalvahPattern from '@/assets/images/calvah-pattern-alt.png';
import ContentWrapper from '@/common/components/content-wrapper';
import spacings from '@/assets/constants/spacings';
import { Body, Headline } from '@/common/components/text';
import { HEADER_HEIGHT } from '@/router/header/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AttendeeNew = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState<boolean>(false);

    return (
        <ContentWrapper>
            <img
                src={CalvahPattern}
                style={{
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                }}
            />
            <Headline mt={spacings.xxl}>
                {t('attendees.headline')}
            </Headline>
            <Body mt={spacings.s}>
                {t('attendees.description')}
            </Body>
            <Button
                mt={spacings.xs}
                label={t('attendees.addAttendee')}
                onClick={() => setShow(true)}
            />
            <Body mt={spacings.l}>
                {t('attendees.contactForMassUpload')}
            </Body>
            <Button
                mt={spacings.xs}
                secondary
                label={t('attendees.contactSupport')}
                onClick={() => window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} //
            />
            {show &&
                <AddUserPopup
                    close={() => setShow(false)}
                />
            }
        </ContentWrapper>
    );
};

export default AttendeeNew;