"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalize = void 0;
var units_1 = require("./lib/units");
var between_1 = require("./between");
var apply_1 = require("./apply");
var toUnit_1 = require("./toUnit");
var parse_1 = require("./parse");
var createUnitsNormalizer = function (keys, getDivisor) {
    return function (duration, remaining) {
        var output = __assign({}, duration);
        keys.forEach(function (unit) {
            var divisor = getDivisor(unit);
            output[unit] = ~~(remaining / divisor);
            remaining -= output[unit] * divisor;
        });
        return output;
    };
};
var yearMonthNormalizer = createUnitsNormalizer(['years', 'months'], function (unit) { return units_1.UNITS_META_MAP_LITERAL[unit].months; });
var dayAndTimeNormalizer = createUnitsNormalizer(['days', 'hours', 'minutes', 'seconds', 'milliseconds'], function (unit) { return units_1.UNITS_META_MAP_LITERAL[unit].milliseconds; });
var baseNormalizer = function (duration) {
    var _a = parse_1.parse(duration), years = _a.years, months = _a.months, weeks = _a.weeks, days = _a.days, rest = __rest(_a, ["years", "months", "weeks", "days"]);
    var output = __assign({}, units_1.ZERO);
    // Normalize years and months between themselves.
    // They cannot be normalized with other units due to their variable length.
    output = yearMonthNormalizer(output, toUnit_1.toMonths({ years: years, months: months }));
    // Normalize unambiguous units. It could be argued that `days` is ambiguous as
    // a day is not always 24 hours long, but the ISO 8601 spec says a day is 24 hours.
    // When not changing timezones, a day is consistently 24 hours, whereas months
    // and years are consistently irregular.
    output = dayAndTimeNormalizer(output, toUnit_1.toMilliseconds(__assign(__assign({}, rest), { days: days + (weeks * 7) })));
    return output;
};
/**
 * Convert a duration object or number of milliseconds into a complete
 * duration object that expresses the value in the most appropriate units.
 *
 * If a `referenceDate` argument is provided, the returned duration is normalized
 * relative to that date. This means each day, month and year has an unambiguous
 * duration, and the `normalize` function can safely convert between these units.
 *
 * @example
 * normalize({ milliseconds 4000 }) // { ..., seconds: 4, milliseconds: 0 }
 * normalize('P28DT24H') // { ..., days: 29 }
 * normalize('P28DT24H', '2018-02-01') // { ..., months: 1, days: 1 }
 * normalize('P28DT24H', '2016-02-01') // { ..., months: 1, days: 0 } (leap year)
 */
var normalize = function (duration, referenceDate) {
    var durationToNormalize = referenceDate != null
        // When using a reference date, `between` may give a result like this:
        // { years: 1, months: -11 }
        // Because of this, we pass that through `baseNormalizer` too.
        ? between_1.between(referenceDate, apply_1.apply(referenceDate, duration))
        : duration;
    return baseNormalizer(durationToNormalize);
};
exports.normalize = normalize;
