import styled from 'styled-components';
import {default as Colors} from '../../../../assets/constants/colors';
import {default as Spacings} from '../../../../assets/constants/spacings';

export const BoxTitle = styled.div`
    position: absolute;
    right: calc(100% + ${Spacings.s}px);
    top: ${Spacings.xs}px;
    min-width: 16px; // This is the arrow icon width
    text-align: center;
`;

export const DeleteWrapper = styled.div`
    position: absolute;
    top: ${Spacings.xxxs}px;
    right: ${Spacings.xxxs}px;
`;

export const SeparatorLine = styled.div`
    background-color: ${Colors.Beige1};
    height: 1px;
    width: 100%;
    margin-top: ${Spacings.xs}px;
    margin-bottom: ${Spacings.xs}px;
`;
