import styled from 'styled-components';

export const StyledInput = styled.input`
    font-family: NotoSans;
    font-size: 1rem;
    font-weight: bold;
    background-color: transparent;
    border: none;
    padding: 0;

    &:focus {
        outline: none;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
`;