import Account from './account';
import Alert from '@/common/components/alert';
import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import ContentWrapper from '@/common/components/content-wrapper';
import Payment from './payment';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import Subscriptions from './subscription';
import { Headline } from '@/common/components/text';
import { ToolbarArrow, ToolbarWrapper } from '@/common/styled';
import { useAppSelector } from '@/store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@/common/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Settings = () => {
    const { page } = useParams();
    const query = useQuery();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState<boolean | null>(query.get('sent') === 'true');
    const user = useAppSelector(state => state.auth.user);
    const organisation = useAppSelector((state) => state.auth.organisation);

    return (
        <ContentWrapper style={{ position: 'relative' }}>
            {showAlert && <Alert
                noIcon
                style={{
                    position: 'absolute',
                    top: -spacings.s,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: 400,
                }}
                bgColor={colors.Teal1}
                text={t('organisers.invitesHasBeenSentAlert')}
                close={() => setShowAlert(false)}
            />}
            <Headline secondary mt={spacings.m} mb={spacings.xs}>
                {t('settings.headline')}
            </Headline>
            <ToolbarWrapper>
                <Row>
                    <div style={{ position: 'relative', marginRight: spacings.s }}>
                        <Button
                            noPadding
                            small
                            tertiary
                            label={t('settings.account')}
                            onClick={() => navigate('/settings/account')}
                        />
                        {page === 'account' && <ToolbarArrow />}
                    </div>
                    {user?.role === 'admin' && <>
                        <div style={{ position: 'relative', marginRight: spacings.s }}>
                            <Button
                                noPadding
                                small
                                tertiary
                                label={t('settings.subscription')}
                                onClick={() => navigate('/settings/subscription')}
                            />
                            {page === 'subscription' && <ToolbarArrow />}
                        </div>
                        {!organisation?.paid_externally && <div style={{ position: 'relative' }}>
                            <Button
                                noPadding
                                small
                                tertiary
                                label={t('settings.paymentTitle')}
                                onClick={() => navigate('/settings/payment')}
                            />
                            {page === 'payment' && <ToolbarArrow />}
                        </div>}
                    </>}
                </Row>
            </ToolbarWrapper>
            <div>
                {page === 'account' && <Account />}
                {page === 'subscription' && <Subscriptions />}
                {page === 'payment' && <Payment />}
            </div>
        </ContentWrapper>
    );
};

export default Settings;