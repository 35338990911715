import colors from '@/assets/constants/colors';
import styled from 'styled-components';
import { calculateSpacings } from '../../../helpers';
import { IGenericElement, ISpacings } from '../../../types';
import { ITextStyles } from '../types';

const StyledText = styled.p`
    font-family: NotoSans;
    font-size: 0.875rem;
    line-height: 1.5;

    b {
        font-weight: bold;
    }
`;

const Micro = ({
    children,
    style,
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    bold, italic, color,
    link,
    onClick
}: IGenericElement & ISpacings & ITextStyles) => {
    return (
        <StyledText
            onClick={onClick}
            style={{
                ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
                ...{ fontWeight: bold || link ? 'bold' : 'normal', fontStyle: italic ? 'italic' : 'normal' },
                ...color ? { color: color } : link ? { color: colors.Purple1 } : {},
                ...link ? { cursor: 'pointer' } : {},
                ...style,
            }}
        >
            {children}
        </StyledText>
    );
}

export default Micro;