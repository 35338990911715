import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    border: 1px solid ${colors.Beige1};
    border-radius: ${borderRadius.s}px;
    background-color: ${colors.White};
    margin-bottom: ${spacings.xxxs}px;
`;

export const DateWrapper = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    width: 120px;
    text-align: center;
    border-right: 1px solid ${colors.Beige1};
    padding: ${spacings.xxs}px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    justify-content: center;
    padding: ${spacings.xxs}px;
`;

export const SettingsWrapper = styled.div`
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${spacings.xxs}px;
    border-left: 1px solid ${colors.Beige1};
    background-color: ${colors.Beige3};
    cursor: pointer;

    &:hover {
        background-color: ${colors.Mustard3};
    }
`;
