import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import CalvahPattern from '@/assets/images/calvah-pattern-plain.png';
import CalvahPatternAlt from '@/assets/images/calvah-pattern.png';
import Button from '@/common/components/button';
import Checkbox from '@/common/components/checkbox';
import ContentWrapper from '@/common/components/content-wrapper';
import Dropdown from '@/common/components/dropdown';
import Row from '@/common/components/row';
import {Body, Headline, Small, Subheadline} from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import {getPronouns} from '@/common/helpers';
import {ColoredBox} from '@/common/styled';
import {HEADER_HEIGHT} from '@/router/header/styled';
import {getInviteData, register} from '@/services/api/auth-service';
import {getOrganisation} from '@/services/api/organisation-service';
import {setOrganisation, setUser} from '@/store/auth-slice';
import {ILoginResponse, IOrganisation} from '@/store/auth-slice/types';
import {useAppDispatch} from '@/store/hooks';
import {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {FiArrowRightCircle} from 'react-icons/fi';
import {useNavigate, useParams} from 'react-router-dom';
import {ErrorBox} from '../styled';
import {IRegisterErrors} from './types';
import PasswordStrengthBar from 'react-password-strength-bar';
import { getTrialDays } from '@/services/api/payment-service';

const Register = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [name, setName] = useState<string>('');
    const [pronouns, setPronouns] = useState<string>('she/her');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordStrong, setPasswordStrong] = useState<boolean>(false);
    const [acceptance, setAcceptance] = useState<boolean>(false);

    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [organisationId, setOrganisationId] = useState<number>();
    const [errors, setErrors] = useState<IRegisterErrors>({});
    const [showError, setShowError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [organiserError, setOrganiserError] = useState<boolean>(false);
    const [loadingOrganiserData, setloadingOrganiserData] = useState<boolean>(true);
    const [trialDays, setTrialDays] = useState<number|undefined>(undefined);
    const [productPrice, setProductPrice] = useState<number|undefined>(undefined);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!id) {
            setloadingOrganiserData(false);
            return;
        }
        setloadingOrganiserData(true);
        const fetchInviteData = async () => {
            const [data, error] = await getInviteData(id);
            if (data) {
                setEmail(data.email);
                setOrganisationId(data.organisation_id);
                setloadingOrganiserData(false);
            } else {
                if (error) {
                    setOrganiserError(true);
                    setloadingOrganiserData(false);
                }
            }
        };
        fetchInviteData();
    }, [id]);

    useEffect(()=>{
        const fetchTrialDays = async () => {
            const [data, error] = await getTrialDays();
            if(data){
                setTrialDays(data.trial_days)
                setProductPrice(data.price)
            }
        }
        fetchTrialDays();
    },[])

    const onClickRegister = async () => {
        setShowError(false);
        setErrors({
            name: !!!name,
            pronouns: !!!pronouns,
            email: !!!email,
            password: !!!password,
            confirmPassword: !!!confirmPassword,
            noMatch: password !== confirmPassword,
        });

        if (
            name &&
            pronouns &&
            email &&
            password &&
            confirmPassword &&
            password === confirmPassword
        ) {
            setLoading(true);
            const [data, error] = await register(
                {
                    name,
                    pronouns,
                    email,
                    password,
                    password_confirmation: confirmPassword,
                },
                organisationId,
            );
            setLoading(false);
            const response: ILoginResponse = data;
            if (response) {
                dispatch(
                    setUser({
                        id: response.id,
                        name: response.name,
                        email: response.email,
                        pronouns: response.pronouns,
                        organisatonId: response.organisation_id,
                        role: response.role,
                    }),
                );
                setLoading(true);
                const [data, error] = await getOrganisation();
                setLoading(false);
                const organisation: IOrganisation = data;
                if (data) {
                    dispatch(setOrganisation(organisation));
                    navigate('/dashboard');
                }
            }
            if (error) {
                console.log(error);
                setShowError(true);
            }
        }
    };

    if (loadingOrganiserData) {
        return <div></div>;
    }

    return (
        <ContentWrapper>
            <img
                src={id ? CalvahPatternAlt : CalvahPattern}
                style={{
                    zIndex: -1,
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                }}
            />
            <Row
                mt={spacings.xxl}
                style={{
                    gap: spacings.xxl,
                    alignItems: 'flex-start',
                }}>
                {organiserError ? (
                    <div style={{flex: 1, marginBottom: spacings.xxl}}>
                        <Headline>
                            {t('organisers.organiserInviteError')}
                        </Headline>
                        <Small mt={spacings.xxs}>
                            {t('organisers.organiserInviteErrorDescription')}
                        </Small>
                    </div>
                ) : (
                    <div style={{flex: 1, marginBottom: spacings.xxl}}>
                        <Headline>
                            {id
                                ? t('organisers.youHaveBeenInvited')
                                : t('auth.createAccount')}
                        </Headline>
                        {id ? (
                            <Small mt={spacings.xxs}>
                                {t('organisers.youHaveBeenInvitedDescription')}
                            </Small>
                        ) : (
                            <Row
                                mt={spacings.xxs}
                                onClick={() => navigate('/login')}>
                                <Small>
                                    {t('auth.alreadyHaveAccount')}{' '}
                                    <b>{t('auth.login')}</b>
                                </Small>
                                <FiArrowRightCircle
                                    size={20}
                                    style={{marginLeft: spacings.xxxs}}
                                />
                            </Row>
                        )}
                        <TextBox
                            mt={spacings.m}
                            label={t('forms.name')}
                            mandatory
                            value={name}
                            setValue={text => setName(text)}
                            error={errors.name}
                        />
                        <Dropdown
                            mandatory
                            mt={spacings.xxs}
                            options={getPronouns()}
                            label={t('forms.pronouns')}
                            value={pronouns}
                            setValue={setPronouns}
                        />
                        <TextBox
                            mt={spacings.xxs}
                            disabled={!!id}
                            label={t('forms.email')}
                            mandatory
                            value={email}
                            setValue={text => setEmail(text)}
                            error={errors.email}
                        />
                        <TextBox
                            mt={spacings.xxs}
                            label={t('forms.password')}
                            mandatory
                            value={password}
                            setValue={text => setPassword(text)}
                            type="password"
                            error={errors.password || errors.noMatch}
                        />
                        {password && (
                            <PasswordStrengthBar
                                style={{marginLeft: 5, marginRight: 5}}
                                password={password}
                                scoreWords={[
                                    t('auth.passwordStrength.tooWeak'),
                                    t('auth.passwordStrength.weak'),
                                    t('auth.passwordStrength.okay'),
                                    t('auth.passwordStrength.good'),
                                    t('auth.passwordStrength.strong'),
                                ]}
                                shortScoreWord={t(
                                    'auth.passwordStrength.tooShort',
                                )}
                                onChangeScore={(score, feedback) => {
                                    if (score >= 2) {
                                        setPasswordStrong(true);
                                    } else setPasswordStrong(false);
                                }}
                            />
                        )}
                        <TextBox
                            mt={spacings.xxs}
                            label={t('forms.repeatPassword')}
                            mandatory
                            value={confirmPassword}
                            setValue={text => setConfirmPassword(text)}
                            type="password"
                            error={errors.confirmPassword || errors.noMatch}
                        />
                        {errors.noMatch ||
                            (showError && (
                                <ErrorBox>
                                    <Row style={{alignItems: 'flex-start'}}>
                                        <AiOutlineInfoCircle
                                            size={22}
                                            color={colors.Error}
                                            style={{
                                                marginTop: 2,
                                                marginRight: spacings.xxxs,
                                                flexShrink: 0,
                                            }}
                                        />
                                        <Small>
                                            {errors.noMatch &&
                                                t('auth.passwordMismatchError')}
                                            {showError &&
                                                t('auth.registerFailed')}
                                        </Small>
                                    </Row>
                                </ErrorBox>
                            ))}
                        <Small mt={spacings.s}>
                            *{t('auth.mandatoryField')}
                        </Small>
                        <Checkbox
                            mt={spacings.s}
                            renderLabel={
                                <Trans
                                    i18nKey="auth.acceptance"
                                    components={{
                                        body: <Body />,
                                        link1: (
                                            <a
                                                className="real-link"
                                                href={
                                                    process.env
                                                        .REACT_APP_TOS_URL
                                                }
                                                target="_blank"></a>
                                        ),
                                        link2: (
                                            <a
                                                className="real-link"
                                                href={
                                                    process.env
                                                        .REACT_APP_PRIVACY_URL
                                                }
                                                target="_blank"></a>
                                        ),
                                    }}
                                />
                            }
                            checked={acceptance}
                            setChecked={setAcceptance}
                        />
                        <Button
                            disabled={!passwordStrong || !acceptance}
                            loading={loading}
                            mt={spacings.m}
                            label={t('auth.createAccountAlt')}
                            onClick={onClickRegister}
                        />
                    </div>
                )}
                <ColoredBox
                    color={colors.Mustard3}
                    style={{
                        width: 300,
                        opacity: id ? 0 : trialDays ? 1 : 0,
                        transition: 'all 0.5s'
                    }}>
                    <Headline style={{lineHeight: 1}}>
                        {t('subscription.freeTrial')}
                    </Headline>
                    <Headline quaternary>
                        {t('subscription.trialDuration', {days:trialDays})}
                    </Headline>
                    <Subheadline quaternary>
                        {t('subscription.allFeaturesIncluded')}
                    </Subheadline>
                    <Small mt={spacings.s}>{t('subscription.trialInfo', {days:trialDays, price:productPrice}) }</Small>
                </ColoredBox>
            </Row>
        </ContentWrapper>
    );
};

export default Register;
