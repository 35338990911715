import styled from 'styled-components';
import { calculateSpacings } from '@/common/helpers';
import { IGenericElement, ISpacings } from '../../types';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Row = ({
    children,
    style,
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    onClick
}: IGenericElement & ISpacings) => {
    return (
        <Wrapper
            onClick={onClick}
            style={{
                ...onClick ? { cursor: 'pointer' } : {},
                ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
                ...style,
            }}>
            {children}
        </Wrapper>
    );
}

export default Row;