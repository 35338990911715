import Button from '@/common/components/button';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Body, Headline } from '@/common/components/text';
import { IDeletePopup } from './types';
import { IPopup } from '@/common/types';
import { removeOrganiser } from '@/store/general-slice';
import { removeUserFromOrganisation } from '@/services/api/organisation-service';
import { useAppDispatch } from '@/store/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


const DeleteOrganiserPopup = ({ close, user }: IPopup & IDeletePopup) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string | undefined>(user.email);

    const onClickDelete = async () => {
        const [data, error] = await removeUserFromOrganisation(user.email);
        if (!error) {
            dispatch(removeOrganiser(user.email));
        }
    };

    return (
        <Popup close={close}>
            <Headline quaternary mb={spacings.s}>
                {user.id
                    ? t('organisers.deleteOrganiser')
                    : t('organisers.deleteInvite')
                }
            </Headline>
            <Body mt={spacings.s}>
                {user.id
                    ? t('organisers.sureToDelete', { name: user.name, email: user.email })
                    : t('organisers.sureToRevokeInvite', {email: user.email })
                }
            </Body>
            <Row mt={spacings.m} style={{ justifyContent: 'flex-end' }}>
                <Button
                    mr={spacings.xxs}
                    tertiary
                    label={t('actions.noCancel')}
                    onClick={close} />
                <Button
                    disabled={!email}
                    label={user.id ? t('actions.yesDelete') : t('actions.yesRevoke')}
                    onClick={onClickDelete} />
            </Row>
        </Popup>
    );
}

export default DeleteOrganiserPopup;