import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.footer`
    display: flex;
    justify-content: flex-end;
    padding-left: ${spacings.xxs}px;
    padding-right: ${spacings.m}px;
    padding-top: ${spacings.xxxs}px;
    padding-bottom: ${spacings.xxxs}px;
    background-color: ${colors.Beige2};
`;