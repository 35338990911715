import { Body } from '@/common/components/text';
import * as MeetingService from '@/services/api/meeting-service';
import { reset } from '@/store/create-meeting-slice';
import { IAgendaItemErrors } from '@/store/create-meeting-slice/types';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import spacings from '../../../../assets/constants/spacings';
import Button from '../../../../common/components/button';
import { getAgendaErrors } from '../../helpers';
import { StepIndex } from '../../steps';
import ErrorPopup from '../error-popup';
import {
    getLongestDurationInSeconds,
    getShortestDurationInSeconds,
    getSpeakerDuration,
    SPEAKER_TIME_THRESHOLD_PERCENTAGE
} from '../helpers';
import SpeakerTimesPopup from '../speaker-times-popup';
import { Wrapper } from './styled';

const SummaryNavbar = ({ index }: { index: StepIndex; }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const attendees = useAppSelector((state) => state.createMeeting.attendees);
    const items = useAppSelector((state) => state.createMeeting.agenda.items);
    const goal = useAppSelector((state) => state.createMeeting.agenda.goal);
    const date = useAppSelector((state) => state.createMeeting.date);
    const title = useAppSelector((state) => state.createMeeting.title);
    const meeting = useAppSelector((state) => state.createMeeting);
    const [error, setError] = useState<'title' | 'date' | 'agenda' | 'deletedAttendee' |undefined>();
    const [alreadySubmitted, setAlreadySubmitted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const timesPerSpeaker = attendees.map(attendee => ({
        attendee: attendee,
        duration: getSpeakerDuration(attendee.email, items)
    }));

    const longestDurationInSeconds = getLongestDurationInSeconds(timesPerSpeaker);
    const shortestDurationInSeconds = getShortestDurationInSeconds(timesPerSpeaker);
    const percentageOfShortestOnLongest = shortestDurationInSeconds / longestDurationInSeconds * 100;

    const meetingIsValid = () => {
        if (!title) {
            setError('title');
            return false;
        }
        if (!goal) {
            setError('agenda');
            return false;
        }
        if (!date || !date.start || !date.end) {
            setError('date');
            return false;
        }
        if (new Date(date.start)< new Date()){
            setError('date');
            return false;
        }
        
        attendees.forEach((attendee)=>{
            if(!attendee.email){
                setError('deletedAttendee');
                return false;
            }
        })

        const errors: IAgendaItemErrors[] = getAgendaErrors(items);
        if (errors.some(errors => Object.values(errors).some(error => error === true))) {
            setError('agenda');
            return false;
        }

        return true;
    };

    const onClickSubmit = async () => {
        if (!meetingIsValid()) return;
        setAlreadySubmitted(true);
        if (percentageOfShortestOnLongest < SPEAKER_TIME_THRESHOLD_PERCENTAGE) {
            setShow(true);
        } else {
            if (meeting.id) {
                setLoading(true);
                const [data, error] = await MeetingService.editMeeting(meeting);
                setLoading(false);
                if (!error) {
                    navigate('/meetings/upcoming');
                    dispatch(reset());
                }
            } else {
                setLoading(true);
                const [data, error] = await MeetingService.createMeeting({
                    ...meeting,
                    externalId: nanoid(),
                });
                setLoading(false);
                if (data) {
                    navigate('/meetings/upcoming');
                    dispatch(reset());
                }
            }
        }
    };

    return (
        <Wrapper>
            <Body mr={spacings.m}>
                {t('create.allAttendeesWillGet')}
            </Body>
            <Button
                loading={loading}
                label={t('create.submitMeeting')}
                small
                disabled={alreadySubmitted}
                onClick={onClickSubmit} />
            {show && <SpeakerTimesPopup close={() => {setAlreadySubmitted(false); setShow(false)}} />}
            {error && <ErrorPopup
                close={() => {setAlreadySubmitted(false); setError(undefined)}}
                error={error}
            />}
        </Wrapper>
    );
};

export default SummaryNavbar;