import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styled';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Button
                small
                tertiary
                label={t('footer.tos')}
                ml={spacings.xs}
                onClick={() => window.open(process.env.REACT_APP_TOS_URL, '_blank')}
            />
            <Button
                small
                tertiary
                label={t('footer.legal')}
                ml={spacings.xs}
                onClick={() => window.open(process.env.REACT_APP_LEGAL_URL, '_blank')}
            />
            <Button
                small
                tertiary
                label={t('footer.privacy')}
                ml={spacings.xs}
                onClick={() => window.open(process.env.REACT_APP_PRIVACY_URL, '_blank')}
            />
            <Button
                small
                tertiary
                label={t('footer.support')}
                ml={spacings.xs}
                //onClick={() => window.open(process.env.REACT_APP_SUPPORT_URL, '_blank')} 
                onClick={() => window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}

            />
        </Wrapper>
    );
};

export default Footer;