import * as DurationFns from 'duration-fns';
import Alert from '../../../../common/components/alert';
import Button from '../../../../common/components/button';
import spacings from '../../../../assets/constants/spacings';
import { formatDurationCustom } from '../../../../common/helpers';
import { getAgendaErrors } from '../../helpers';
import { IAgendaItem, IAgendaItemErrors } from '../../../../store/create-meeting-slice/types';
import { ProgressBarBackground, ProgressElement, Wrapper } from './styled';
import { resetErrors, setAgendaGoalError, setAgendaItemErrors } from '../../../../store/create-meeting-slice';
import { Small } from '../../../../common/components/text';
import { StepIndex, STEPS } from '../../steps';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ALERT_VISIBLE_DURATION = 2000;
const ALERT_FADE_DURATION = 500;

const AgendaNavbar = ({ index, itemRefs }: { index: StepIndex; itemRefs: React.RefObject<HTMLDivElement>[]; }) => {
    const { t } = useTranslation();
    const step = STEPS[index];
    const dispatch = useAppDispatch();
    const items = useAppSelector((state) => state.createMeeting.agenda.items);
    const goal = useAppSelector((state) => state.createMeeting.agenda.goal);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertOpacity, setAlertOpacity] = useState<number>(1);
    const navigate = useNavigate();

    const duration = items.reduce(
        (acc, cur) => DurationFns.sum(acc, cur.duration),
        { seconds: 0 }
    );
    const discussion = items.reduce(
        (acc, cur) => DurationFns.sum(acc, cur.discussion.duration),
        { seconds: 0 }
    );
    const feedback = items.reduce(
        (acc, cur) => DurationFns.sum(acc, cur.feedback.duration),
        { seconds: 0 }
    );

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => setAlertOpacity(0), ALERT_VISIBLE_DURATION);
            setTimeout(() => setShowAlert(false), ALERT_VISIBLE_DURATION + ALERT_FADE_DURATION);
        }
    }, [showAlert]);

    const validateAgenda = (items: IAgendaItem[], goal: string | undefined) => {
        dispatch(resetErrors());
        if (!goal) {
            setShowAlert(true);
            dispatch(setAgendaGoalError({
                error: true
            }));
        } else {
            dispatch(setAgendaGoalError({
                error: false
            }));
        }

        const errors: IAgendaItemErrors[] = getAgendaErrors(items);

        if (errors.some(errors => Object.values(errors).some(error => error === true))) {
            setShowAlert(true);
        }
        dispatch(setAgendaItemErrors({
            errors: errors,
            goToNext: () => step.next && navigate(step.next)
        }));
    };

    return (
        <Wrapper>
            <ProgressBarBackground>
                {items.map((item, index) => <ProgressElement
                    onClick={() => itemRefs[index].current?.scrollIntoView()}
                    style={{
                        flex: DurationFns.toSeconds(
                            DurationFns.sum(
                                item.duration,
                                item.discussion.duration,
                                item.feedback.duration)
                        )
                    }}
                    key={index} />
                )}
            </ProgressBarBackground>
            <Small bold ml={spacings.xxxs}>
                {formatDurationCustom(
                    DurationFns.sum(
                        duration,
                        discussion,
                        feedback)
                )}
            </Small>
            {step.prev &&
                <Button
                    label={t('actions.back')}
                    small
                    secondary
                    onClick={() => step.prev && navigate(step.prev)}
                    ml={spacings.xxs}
                    mr={spacings.xxxs} />
            }
            {step.next &&
                <Button
                    label={t('actions.next')}
                    small
                    disabled={false}
                    onClick={() => {
                        setShowAlert(false);
                        setAlertOpacity(1);
                        validateAgenda(items, goal);
                    }} />
            }
            {showAlert && <Alert
                text={t('create.stepAgenda.dataErrorMessage')}
                type="Error"
                close={() => setShowAlert(false)}
                style={{
                    position: 'absolute',
                    bottom: `calc(100% + ${spacings.xxxs}px)`,
                    right: spacings.xxxl,
                    opacity: alertOpacity,
                    transition: `opacity ${ALERT_FADE_DURATION}ms ease-in-out`,
                }} />}
        </Wrapper>
    );
}

export default AgendaNavbar;