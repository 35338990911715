import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const ErrorBox = styled.div`
    padding: ${spacings.xxs}px;
    background-color: ${colors.ErrorLight};
    border-radius: ${borderRadius.s}px;
    margin-top: ${spacings.m}px;
`;