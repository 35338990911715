import AppRoutes from './router/AppRoutes';
import store, { persistor } from './store';
import { HistoryRouter } from './router/history-router';
import { myHistory } from './router/history';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

const App = () => {

    return (
        <Provider store={store}>
            {/* TODO add loading animation */}
            <PersistGate persistor={persistor}>
                <HistoryRouter
                    basename={'/'}
                    children={<AppRoutes />}
                    history={myHistory}
                />
            </PersistGate>
        </Provider>
    );
}

export default App;
