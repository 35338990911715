import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import { Body, Headline } from '@/common/components/text';
import { IPopup } from '@/common/types';
import { getOrganisation } from '@/services/api/organisation-service';
import { deleteSubscription } from '@/services/api/payment-service';
import { setOrganisation } from '@/store/auth-slice';
import { useAppDispatch } from '@/store/hooks';
import { t } from 'i18next';
import { useState } from 'react';

const CancelPopup = ({ close }: IPopup) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const onClickDelete = async () => {
        setLoading(true);
        const [data, error] = await deleteSubscription();
        if (!error) {
            const [data, error] = await getOrganisation();
            if (data) {
                dispatch(setOrganisation(data));
                close();
            }
        }
    };

    return (
        <Popup close={close} alert>
            <Headline quaternary mb={spacings.s}>
                {t('licence.cancelSubscription')}
            </Headline>
            <Body mt={spacings.s}>
                {t('licence.cancelSubscriptionDescription')}
            </Body>
            <Row mt={spacings.m} style={{ justifyContent: 'flex-end' }}>
                <Button
                    mr={spacings.xxs}
                    tertiary
                    label={t('actions.back')}
                    onClick={close} />
                <Button
                    loading={loading}
                    style={{ background: colors.Error }}
                    label={t('actions.yesCancel')}
                    onClick={onClickDelete} />
            </Row>
        </Popup>
    );
};

export default CancelPopup;