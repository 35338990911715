import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import Column from '@/common/components/column';
import ContentWrapper from '@/common/components/content-wrapper';
import EvaluationSummary from '../evaluation-summary';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import TimeRow from '../time-row';
import {
    Body,
    Headline,
    Micro,
    Small,
    Subheadline,
} from '@/common/components/text';
import {
    CreateMeetingState,
    IMeetingState,
} from '@/store/create-meeting-slice/types';
import {Dot} from '@/features/timer/timer-summary/styled';
import {FiArrowLeftCircle} from 'react-icons/fi';
import {format} from 'date-fns';
import {getEvaluations, getMeeting} from '@/services/api/meeting-service';
import {getExceededTime, getPlannedTime, getSavedTime} from '@/common/helpers';
import {IAnswer} from '@/features/timer/timer-runner/step-info/questionnaire/types';
import {ToolbarWrapper} from '@/common/styled';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const MeetingStatistics = ({}) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const meetingId = id;
    const [meeting, setMeeting] = useState<
        CreateMeetingState & IMeetingState
    >();
    const [plannedTime, setPlannedTime] = useState<number>(0);
    const [exceededTime, setExceededTime] = useState<number>(0);
    const [savedTime, setSavedTime] = useState<number>(0);
    const [answers, setAnswers] = useState<IAnswer[]>([]);

    useEffect(() => {
        const fetchMeeting = async () => {
            if (!meetingId) return;

            const [data, error] = await getMeeting(meetingId);
            const meeting: CreateMeetingState & IMeetingState = data;
            if (meeting) {
                setMeeting(meeting);
            }
        };
        fetchMeeting();
    }, []);

    useEffect(() => {
        if (!meeting) return;
        setPlannedTime(getPlannedTime(meeting));
        setSavedTime(getSavedTime(meeting));
        setExceededTime(getExceededTime(meeting));

        const fetchEvaluations = async () => {
            if (!meeting.externalId) return;
            const [data, error] = await getEvaluations(meeting.externalId);
            if (data) {
                const answers: IAnswer[] = data.map((entry: any) => {
                    return {question: entry.question_id, answer: entry.answer};
                });
                setAnswers(answers);
            }
        };
        fetchEvaluations();
    }, [meeting]);

    const calculatedTime = plannedTime - savedTime + exceededTime;

    return (
        <ContentWrapper style={{position: 'relative'}}>
            <Button
                style={{position: 'absolute', right: '100%', top: spacings.s}}
                small
                tertiary
                label={t('actions.back')}
                icon={
                    <FiArrowLeftCircle
                        size={18}
                        style={{marginRight: spacings.xxxs}}
                    />
                }
                onClick={() => navigate('/statistics')}
            />
            <Row
                mb={spacings.m}
                style={{
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}>
                <Column>
                    {meeting && meeting.date && (
                        <Body mt={spacings.m}>
                            <b>
                                {format(
                                    new Date(meeting.date.start),
                                    'EEE dd. LLL',
                                )}
                            </b>
                            {` `}
                            {format(new Date(meeting.date.start), 'H:m aaa')}
                            {` - `}
                            {format(new Date(meeting.date.start), 'H:m aaa')}
                        </Body>
                    )}
                    <Headline secondary mb={spacings.xxxs}>
                        {meeting?.title}
                    </Headline>
                    <Small>
                        {meeting?.attendees
                            .map(attendee => attendee.name)
                            .join(', ')}
                    </Small>
                </Column>
                <Button
                    small
                    secondary
                    label={t('meetings.showAgenda')}
                    onClick={() => navigate(`/timer/${meetingId}`)}
                />
            </Row>
            {answers.length > 0 && (
                <Row mb={spacings.xxl}>
                    <EvaluationSummary answers={answers} singular />
                </Row>
            )}
            <ToolbarWrapper style={{marginBottom: 0}}>
                <Subheadline quaternary>
                    {t('statistics.timeStats.speakerTime')}
                </Subheadline>
                <Row style={{gap: spacings.xs}}>
                    <Row>
                        <Dot color={colors.Mustard2} />
                        <Micro>{t('timer.legend.timePlanned')}</Micro>
                    </Row>
                    <Row>
                        <Dot color={colors.Teal2} />
                        <Micro>{t('timer.legend.timeUsed')}</Micro>
                    </Row>
                    <Row>
                        <Dot color={colors.Purple2} />
                        <Micro>{t('timer.legend.timeExceeded')}</Micro>
                    </Row>
                </Row>
            </ToolbarWrapper>
            <div style={{marginBottom: spacings.m}}>
                <TimeRow
                    title={t('statistics.timeStats.total')}
                    plannedTime={plannedTime}
                    exceededTime={exceededTime}
                    savedTime={savedTime}
                    longestTime={
                        plannedTime > calculatedTime
                            ? plannedTime
                            : calculatedTime
                    }
                />
                {meeting?.attendees.map(attendee => {
                    const planned = getPlannedTime(meeting, attendee.email);
                    const exceeded = getExceededTime(meeting, attendee.email);
                    const saved = getSavedTime(meeting, attendee.email);
                    return (
                        <TimeRow
                            key={attendee.email}
                            title={attendee.name}
                            plannedTime={planned}
                            exceededTime={exceeded}
                            savedTime={saved}
                            longestTime={
                                plannedTime > calculatedTime
                                    ? plannedTime
                                    : calculatedTime
                            }
                        />
                    );
                })}
            </div>
        </ContentWrapper>
    );
};

export default MeetingStatistics;
