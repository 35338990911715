import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
`;

export const InfoBox = styled.div`
    position: absolute;
    bottom: calc(100% + 12px);
    width: 175px;
    right: -${spacings.m}px;
    background-color: ${colors.Black};
    color: ${colors.White};
    border-radius: ${borderRadius.m}px;
    padding: ${spacings.xxxs}px ${spacings.xxs}px;
`;

export const Arrow = styled.div`
    position: absolute;
    top: 100%;
    right: ${spacings.m}px;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${colors.Black};
`;