import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import Column from '@/common/components/column';
import PieChart from '@/common/components/pie-chart';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Box, Header } from '../../styled';
import { FiArrowRightCircle } from 'react-icons/fi';
import { getAllEvaluation } from '@/services/api/meeting-service';
import { IEvaluation } from '@/services/api/meeting-service/types';
import { ISatisfactionStats } from './types';
import { Micro, Small, Subheadline } from '@/common/components/text';
import { ReactComponent as ICSmileyNeutral } from '@icons/ic-smiley-neutral-alt.svg';
import { ReactComponent as ICSmileyNo } from '@icons/ic-smiley-no.svg';
import { ReactComponent as ICSmileyYes } from '@icons/ic-smiley-yes.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IAnswer } from '@/features/timer/timer-runner/step-info/questionnaire/types';

const SatisfactionStats = ({ meetings }: ISatisfactionStats) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState<number>(1);
    const [yesCount, setYesCount] = useState<number>(0);
    const [neutralCount, setNeutralCount] = useState<number>(0);

    useEffect(() => {
        const fetchAllEvaluation = async () => {
            const [data, error] = await getAllEvaluation();
            if (data) {
                const answers: IAnswer[] = data.map((entry: any) => {return {'question':entry.question_id, 'answer':entry.answer}})
               // const answers = data.map((entry: any) => JSON.parse(entry.answer)).flat();
                setTotalCount(answers.length);
                setYesCount(answers.filter((e: IEvaluation) => e.answer === 1).length);
                setNeutralCount(answers.filter((e: IEvaluation) => e.answer === 2).length);
            }
        };
        fetchAllEvaluation();
    }, []);

    const yesPercentage = Math.round(yesCount / totalCount * 100);
    const neutralPercentage = Math.round(neutralCount / totalCount * 100);

    return (
        <div style={{ flex: 1 }}>
            <Header>
                <Subheadline quaternary>
                    {t('statistics.satisfactionStats.title')}
                </Subheadline>
                <Micro>
                    {t('statistics.allMeetings')}
                </Micro>
            </Header>
            <Box>
                {meetings.length === 0
                    ? <Small color={colors.Brown1}>
                        {t('statistics.satisfactionStats.noStats')}
                    </Small>
                    : <>
                        <Small>
                            {t('statistics.satisfactionStats.question')}
                        </Small>
                        <Row onClick={() => navigate('/statistics/satisfaction')}>
                            <Button
                                mr={spacings.xxxs}
                                tertiary
                                noPadding
                                small
                                label={t('actions.showMore')}
                            />
                            <FiArrowRightCircle size={22} style={{ marginTop: 1 }} />
                        </Row>
                        <Row style={{ justifyContent: 'space-between' }}>
                            <Column style={{ gap: spacings.xxxs }} mt={spacings.xxs}>
                                <Row>
                                    <ICSmileyYes style={{
                                        width: 30,
                                        height: 30,
                                    }} />
                                    <Small ml={spacings.xxxs}>
                                        <b>{yesPercentage}%</b> {t('actions.yes')}
                                    </Small>
                                </Row>
                                <Row>
                                    <ICSmileyNeutral style={{
                                        width: 30,
                                        height: 30,
                                    }} />
                                    <Small ml={spacings.xxxs}>
                                        <b>{neutralPercentage}%</b> {t('actions.neutral')}
                                    </Small>
                                </Row>
                                <Row>
                                    <ICSmileyNo style={{
                                        width: 30,
                                        height: 30,
                                    }} />
                                    <Small ml={spacings.xxxs}>
                                        <b>{100 - yesPercentage - neutralPercentage}%</b> {t('actions.no')}
                                    </Small>
                                </Row>
                            </Column>
                            <PieChart values={[
                                {
                                    color: colors.Teal2,
                                    percentage: yesPercentage,
                                }, {
                                    color: colors.Mustard2,
                                    percentage: neutralPercentage,
                                }, {
                                    color: colors.Purple2,
                                    percentage: 100 - yesPercentage - neutralPercentage,
                                }
                            ]} />
                        </Row>
                    </>
                }
            </Box>
        </div>
    );
};

export default SatisfactionStats;