import { INavbar } from './types';
import { NavbarContent, Wrapper } from './styled';

const Navbar = ({
    children
}: INavbar) => {

    return (
        <Wrapper>
            <NavbarContent>
                {children}
            </NavbarContent>
        </Wrapper >
    );
}

export default Navbar;