import Button from '@/common/components/button';
import CalvahPattern from '@/assets/images/calvah-pattern-plain.png';
import ContentWrapper from '@/common/components/content-wrapper';
import InfoBox from '../components/info-box';
import PaymentForm from './payment-form';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Elements } from '@stripe/react-stripe-js';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { HEADER_HEIGHT } from '@/router/header/styled';
import { Headline, Small } from '@/common/components/text';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

const LicencePayment = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <ContentWrapper>
            <img
                src={CalvahPattern}
                style={{
                    zIndex: -1,
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                }}
            />
            <Row
                mt={spacings.xxl}
                style={{
                    alignItems: 'flex-start',
                    gap: spacings.xl,
                }}
            >
                <div style={{ flex: 1, position: 'relative' }}>
                    <Button
                        style={{ position: 'absolute', top: -spacings.l }}
                        noPadding
                        small
                        tertiary
                        label={t('actions.back')}
                        icon={<FiArrowLeftCircle size={18} style={{ marginRight: spacings.xxxs }} />}
                        onClick={() => navigate('/licences/billing')}
                    />
                    <Headline>
                        {t('licence.paymentDetails')}
                    </Headline>
                    <div>
                        <Elements stripe={stripe}>
                            <PaymentForm />
                        </Elements>
                    </div>
                    <Small mt={spacings.m}>
                        *{t('auth.mandatoryField')}
                    </Small>
                </div>
                <InfoBox />
            </Row>
        </ContentWrapper>
    );
};

export default LicencePayment;