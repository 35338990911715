import { IAnswer } from '@/features/timer/timer-runner/step-info/questionnaire/types';
import { IStep } from '@/features/timer/timer-runner/types';
import store from '@/store';
import { CreateMeetingState } from '@/store/create-meeting-slice/types';
import axios from 'axios';
import { handleApiError } from '../helpers';
import { parseMeetingData, prepareMeetingData } from './helpers';

const API_BASE = process.env.REACT_APP_API_BASE;

export const getMeetingsPaginated = async (currentPage: number, search?: string, meetingState?: string, orderBy?: string) => {
    const state = store.getState();
    let endpoint = `${API_BASE}/meetingsPaginated?organisation_id=${state.auth.user?.organisatonId}&page=${currentPage}`;
    if (search) {
        endpoint = endpoint.concat(`&search=${search}`)
    }
    if (meetingState) {
        endpoint = endpoint.concat(`&state=${meetingState}`)
    }
    if (orderBy) {
        endpoint = endpoint.concat(`&orderBy=${orderBy}`)
    }

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            const data = result.data.data.map((item: any) => parseMeetingData(item))
            result.data.data = data
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getMeetings = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meetings?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data.map((item: any) => parseMeetingData(item)), null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getMeetingsByDateRange = async (start: string, end: string) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meetingsDateRange?organisation_id=${state.auth.user?.organisatonId}&from=${start}&to=${end}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data.map((item: any) => parseMeetingData(item)), null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getMeetingsByUser = async (userId: number) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/meetings/${userId}?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data.map((item: any) => parseMeetingData(item)), null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getMeeting = async (id: string) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meeting?meeting_id=${id}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [parseMeetingData(result.data), null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const deleteMeeting = async (id: string) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meeting?meeting_id=${id}&organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.delete(endpoint);
        if (result.status === 204) {
            return ['success', null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const createMeeting = async (meeting: CreateMeetingState) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meeting`;
    const data = prepareMeetingData(meeting, state.auth.user?.organisatonId);
    //data.external_id = nanoid();
    data.external_id = meeting.externalId;


    try {
        const result = await axios.post(endpoint, data);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const editMeeting = async (meeting: CreateMeetingState) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meeting`;
    const data = prepareMeetingData(meeting, state.auth.user?.organisatonId);

    try {
        const result = await axios.put(endpoint, data);
        if (result.status === 204) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const updateMeetingState = async (id: string, steps: IStep[], current: number, initialSteps?: IStep[]) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meetingControl/meetingState`;
    const data = {
        organisation_id: state.auth.user?.organisatonId,
        meeting_id: id,
        current_step: current,
        steps: steps,
        ...initialSteps ? { initial_steps: initialSteps } : {},
    }

    try {
        const result = await axios.post(endpoint, data);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const endMeeting = async (id: string, steps: IStep[]) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meetingControl/endMeeting`;
    const data = {
        organisation_id: state.auth.user?.organisatonId,
        meeting_id: id,
        steps: steps,
    }

    try {
        const result = await axios.post(endpoint, data);
        if (result.status === 204) {
            return [result, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const setMeetingScore = async (id: string, score: number) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meetingScore`;
    const data = {
        organisation_id: state.auth.user?.organisatonId,
        meeting_id: id,
        score: score,
    }

    try {
        const result = await axios.post(endpoint, data);
        if (result.status === 204) {
            return [result, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getEvaluations = async (id: string) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/meetingEvaluations?meeting_id=${id}&organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getEvaluationByUser = async (userId: number) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/evaluations/${userId}?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getAllEvaluation = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/evaluations?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getEvaluationQuestions = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/evaluationQuestions?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const sendEvaluation = async (id: string, answers: IAnswer[]) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/evaluation`;
    const data = {
        organisation_id: state.auth.user?.organisatonId,
        meeting_id: id,
        answers: answers,
    }

    try {
        const result = await axios.post(endpoint, data);
        if (result.status === 200) {
            return [result, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getAllFeedback = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/feedback?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getFeedback = async (id: string, itemIndex: number) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/feedback?meeting_id=${id}&agenda_item_index=${itemIndex}&organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const sendFeedback = async (id: string, itemIndex: number, feedback: string) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/feedback`;
    const data = {
        organisation_id: state.auth.user?.organisatonId,
        meeting_id: id,
        agenda_item_index: itemIndex,
        feedback: feedback,
    }

    try {
        const result = await axios.post(endpoint, data);
        if (result.status === 200) {
            return [result, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};