import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import RadioButton from '@/common/components/radiobutton';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import TextBox from '@/common/components/textbox';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { AmountButton, SelectWrapper } from './styled';
import {
    Body,
    Headline,
    Small,
    Subheadline
    } from '@/common/components/text';
import { ColoredBox } from '@/common/styled';
import { getProductPrices } from '@/services/api/payment-service';
import { setAmount, setPaymentType, setProductPrices } from '@/store/licence-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AmountBox = ({
    activeSubscription
}: {
    activeSubscription?: boolean;
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const MAX = useAppSelector((state) => state.licence.max);
    const MIN = useAppSelector((state) => state.licence.min);
    const amount = useAppSelector((state) => state.licence.amount);
    const paymentType = useAppSelector((state) => state.licence.paymentType);
    const user = useAppSelector((state) => state.auth.user);
    const [email, setEmail] = useState<string>(user?.email || '');

    const productPrices = useAppSelector(state => state.licence.productPrices);
    const pricePerLicence = productPrices?.find(price => price.interval === paymentType)?.price
        ? productPrices?.find(price => price.interval === paymentType)?.price
        : productPrices?.find(price => price.interval === paymentType)?.tiers?.find(tier => tier.up_to >= amount)?.unit_amount;
    const pricePerLicenceUnlimited = productPrices?.find(price => price.interval === paymentType)?.tiers?.find(tier => !tier.up_to)?.unit_amount;

    useEffect(() => {
        const fetchPrices = async () => {
            const [data, error] = await getProductPrices();
            if (data) dispatch(setProductPrices(data));
        };
        fetchPrices();
    }, []);

    const calculatedPrice = pricePerLicence
        ? (pricePerLicence / 100) * amount
        : pricePerLicenceUnlimited && (pricePerLicenceUnlimited / 100) * amount;

    const singleP = productPrices?.find(price => price.interval === paymentType)?.tiers?.find(tier => tier.up_to)?.unit_amount;
    const singlePrice = singleP?singleP/100:0;
    const groupPrice = pricePerLicenceUnlimited?pricePerLicenceUnlimited/100:0;

    return (
        <ColoredBox
            color={(amount === MAX || amount === 0) ? colors.Purple3 : colors.Teal3}
            style={{ marginTop: spacings.s }}
        >
            <Row style={{
                alignItems: 'stretch',
                gap: 1,
            }}>
                <div style={{
                    flex: 1,
                    backgroundColor: colors.White,
                    borderTopLeftRadius: borderRadius.m,
                    borderBottomLeftRadius: borderRadius.m,
                    padding: spacings.xs
                }}>
                    <Headline style={{ lineHeight: 1 }}>
                        {amount}
                    </Headline>
                </div>
                <AmountButton onClick={() => dispatch(setAmount(Math.max(amount - 1, MIN)))}>
                    <AiOutlineMinus size={34} />
                </AmountButton>
                <AmountButton
                    onClick={() => dispatch(setAmount(Math.min(amount + 1, MAX)))}
                    style={{
                        borderTopRightRadius: borderRadius.m,
                        borderBottomRightRadius: borderRadius.m,
                    }}
                >
                    <AiOutlinePlus size={34} />
                </AmountButton>
            </Row>
            <Headline quaternary mt={spacings.xxs}>
                {amount === MAX
                    ? t('licence.youNeedMoreThanMax')
                    : t('organisers.organisersAlt')
                }
            </Headline>
            <div style={{ marginTop: spacings.s }}>
                {amount === MAX && <>
                    <Body>
                        {t('licence.offer')}
                    </Body>
                    <TextBox
                        label={t('forms.email')}
                        value={email}
                        setValue={(text) => setEmail(text)}
                        mt={spacings.xs} />
                </>}
                {amount === 0 && <>
                    <Body>
                        {t('licence.licenceWillBeCanceled')}
                    </Body>
                </>}
                {amount !== 0 && amount !== MAX && <>
                    {!activeSubscription && <SelectWrapper>
                        <Body bold mr={spacings.xxs}>
                            {(t('licence.payment'))}
                        </Body>
                        <Row mr={spacings.xxs} onClick={() => dispatch(setPaymentType('month'))}>
                            <RadioButton
                                active={paymentType === 'month'}
                            />
                            <Body ml={spacings.xxxs}>
                                {t('licence.monthly')}
                            </Body>
                        </Row>
                        <Row onClick={() => dispatch(setPaymentType('year'))}>
                            <RadioButton
                                active={paymentType == 'year'}
                            />
                            <Body ml={spacings.xxxs}>
                                {t('licence.yearly')}
                            </Body>
                        </Row>
                    </SelectWrapper>}
                    <Subheadline secondary mt={spacings.xs}>
                        {calculatedPrice} €
                    </Subheadline>
                    <Small>
                        {paymentType === 'year'
                            ? t('licence.perYearInfo', { singlePrice: singlePrice, groupPrice: groupPrice})
                            : t('licence.perMonthInfo', { price: singlePrice, groupPrice: groupPrice })
                        }
                    </Small>
                    <Small mt={spacings.xs}>
                        {paymentType === 'year'
                            ? t('licence.cancelableInfoYear')
                            : t('licence.cancelableInfoMonth')
                        }
                    </Small>
                </>}
            </div>
        </ColoredBox>
    );
};

export default AmountBox;