import AddUserPopup from './add-user-popup';
import AttendeeList from './attendee-list';
import Checkbox from '../../../common/components/checkbox';
import Spacings from '../../../assets/constants/spacings';
import TextBoxBorderless from '../../../common/components/textbox-borderless';
import UserSelection from './user-selection';
import { addAttendee, removeAttendee } from '../../../store/create-meeting-slice';
import { getAllUsers } from '@/services/api/organisation-service';
import { Headline, Subheadline } from '../../../common/components/text';
import { IAttendee } from '../../../store/create-meeting-slice/types';
import { IUserResponse } from '@/services/api/organisation-service/types';
import { saveUserlist } from '@/store/general-slice';
import { SPACINGS } from '../../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styled';

const Attendees = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const userlist = useAppSelector((state) => state.general.userlist);
    const attendees = useAppSelector((state) => state.createMeeting.attendees);
    const [attendeeMails, setAttendeeMails] = useState<string[]>(attendees.map((a:IAttendee)=>a.email));

    const [filteredUsers, setFilteredUsers] = useState<IAttendee[]>(userlist);
    const [show, setShow] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [input, setInput] = useState<string>();

    const selectAttendeeContainer = createRef<HTMLDivElement>();
    useEffect(()=> document.addEventListener("mousedown", handleClickOutside))

    const handleClickOutside=(event:any)=>{
        if(
            selectAttendeeContainer.current &&
            !selectAttendeeContainer.current.contains(event.target)
        ){
            setShowResults(false)
        }
    }

    const onItemClick = (item: IAttendee) => dispatch(addAttendee(item));
    const setSelfAttend = (attend: boolean) => {
        if (!user) return;
        if (attend) {
            dispatch(addAttendee({
                name: user?.name,
                email: user?.email,
                pronouns: user?.pronouns
            }));
        } else {
            dispatch(removeAttendee({
                name: user?.name,
                email: user?.email,
                pronouns: user?.pronouns
            }))
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const [data, error] = await getAllUsers();
            if (data) {
                dispatch(saveUserlist(data
                    .filter((_user: IUserResponse) => _user.email !== user?.email)
                    .map((_user: IUserResponse) => ({
                        id: _user.id,
                        name: _user.name,
                        email: _user.email,
                        pronouns: _user.pronouns,
                        role: _user.role,
                    })))
                );
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        if (input) {
            setFilteredUsers(
                filteredUsers.filter((u: IAttendee) => 
                    u.name.toLowerCase().includes(input.toLowerCase()) 
                    && !attendeeMails.includes(u.email) )
            );
        } else {
            setFilteredUsers(
                userlist.filter((u: IAttendee) => !attendeeMails.includes(u.email))
            );
        }
    }, [input]);

    useEffect(()=>{
        const attendeeMail = attendees.map(attendee=>attendee.email)
        setAttendeeMails(attendeeMail)
    },[attendees]);

    useEffect(() => {
        setFilteredUsers(userlist.filter((a:IAttendee)=>!attendeeMails.includes(a.email)));
    }, [userlist]);

    const filterSelectedAttendees=()=>{
        const attendeeMail = attendees.map(attendee=>attendee.email)
        setAttendeeMails(attendeeMail)
        if(!input){
                setFilteredUsers(userlist.filter((u: IAttendee) =>!attendeeMails.includes(u.email)))
        }
    }

    return (
        <Wrapper>
            <Headline primary>
                {t('create.stepAttendees.title')}
            </Headline>
            <Subheadline quaternary mb={Spacings.l}>
                {t('create.stepAttendees.subtitle')}
            </Subheadline>
            <Checkbox
                label={t('create.stepAttendees.selfAttend')}
                checked={!!attendees.find(attendee => attendee.email === user?.email)}
                setChecked={setSelfAttend}
                mb={Spacings.s} />

            <div style={{ marginBottom: SPACINGS.xlarge }}>
                <div ref={selectAttendeeContainer}>
                    <TextBoxBorderless
                        noLinebreak
                        value={input}
                        setValue={setInput}
                        placeholder={t('create.stepAttendees.placeholder')}
                        onFocus={() => {
                            filterSelectedAttendees()
                            setShowResults(true)}} 
                        />
                        
                    {showResults && <UserSelection
                        users={filteredUsers}
                        onItemClick={onItemClick}
                        hideList={() => setShowResults(false)}
                        showPopup={() => setShow(true)} />
                    }
                </div>
                {attendees && <AttendeeList
                    attendees={attendees} />
                }
            </div>
            {show && <AddUserPopup
                close={() => setShow(false)} />
            }
        </Wrapper >
    );
}

export default Attendees;