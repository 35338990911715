import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const BarWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${spacings.xxxs}px;
`;

export const LegendWrapper = styled.div`
    border-top: 1px solid ${colors.Beige1};
    background-color: ${colors.White};
    padding-top: ${spacings.xs}px;
    padding-bottom: ${spacings.s}px;
`;

export const LegendInnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${colors.White};
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    padding-left: ${spacings.xs}px;
    padding-right: ${spacings.xs}px;
`;

export const Dot = styled.div<{ color: string; }>`
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: ${props => props.color};
    margin-right: ${spacings.xxxs}px;
`;