import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import ContentWrapper from '@/common/components/content-wrapper';
import EvaluationSummary from '../evaluation-summary';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import TimeRow from '../time-row';
import {
    Body,
    Headline,
    Micro,
    Small,
    Subheadline
    } from '@/common/components/text';
import { CreateMeetingState, IMeetingState } from '@/store/create-meeting-slice/types';
import { Dot } from '../statistics-dashboard/time-stats/styled';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { getEvaluationByUser, getMeetingsByUser } from '@/services/api/meeting-service';
import {
    getExceededTime,
    getPlannedTime,
    getSavedTime,
    getUniqueArrayBy
    } from '@/common/helpers';
import { getUser } from '@/services/api/organisation-service';
import { IAnswer } from '@/features/timer/timer-runner/step-info/questionnaire/types';
import { IUser } from '@/store/auth-slice/types';
import { saveMeetings } from '@/store/meetings-slice';
import { ToolbarWrapper } from '@/common/styled';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OrganiserStatistics = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [answers, setAnswers] = useState<IAnswer[]>([]);
    const dispatch = useAppDispatch();
    const meetings = useAppSelector((state) => state.meetings.meetings);
    const attendees = getUniqueArrayBy(meetings.map(meeting => meeting.attendees).flat(), 'email');
    const pastMeetings = meetings.filter(
        (meeting: CreateMeetingState & IMeetingState) => meeting.meetingState.state === 'past'
    );
    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        const fetchUser = async () => {
            const [data, error] = await getUser(Number(id));
            if (data) {
                setUser(data);
            }
        };
        const fetchMeetings = async () => {
            const [data, error] = await getMeetingsByUser(Number(id));
            if (data) {
                dispatch(saveMeetings(data));
            }
        };
        const fetchEvaluation = async () => {
            const [data, error] = await getEvaluationByUser(Number(id));
            if (data) {
                const answers: IAnswer[] = data.map((entry: any) => {return {'question':entry.question_id, 'answer':entry.answer}})
                setAnswers(answers);
            }
        };
        fetchUser();
        fetchEvaluation();
        fetchMeetings();
    }, []);

    const plannedTime = pastMeetings.map(meeting => getPlannedTime(meeting)).reduce((acc, cur) => acc + cur, 0);
    const exceededTime = pastMeetings.map(meeting => getExceededTime(meeting)).reduce((acc, cur) => acc + cur, 0);
    const savedTime = pastMeetings.map(meeting => getSavedTime(meeting)).reduce((acc, cur) => acc + cur, 0);
    const calculatedTime = plannedTime - savedTime + exceededTime;

    return (
        <ContentWrapper style={{ position: 'relative' }}>
            <Button
                style={{ position: 'absolute', right: '100%', top: -6 }}
                small
                tertiary
                label={t('actions.back')}
                icon={<FiArrowLeftCircle size={18} style={{ marginRight: spacings.xxxs }} />}
                onClick={() => navigate('/statistics')}
            />
            <Body mt={spacings.m}>
                {t('statistics.allMeetingsOrganisedBy')}
            </Body>
            <Headline secondary mb={spacings.m}>
                {user?.name}
            </Headline>
            {answers.length > 0 && <EvaluationSummary answers={answers} />}
            <ToolbarWrapper style={{ marginBottom: 0, marginTop: spacings.xl }}>
                <Subheadline quaternary>
                    {t('statistics.timeStats.speakerTime')}
                </Subheadline>
                <Row style={{ gap: spacings.xs }}>
                    <Row>
                        <Dot color={colors.Mustard2} />
                        <Micro>{t('timer.legend.timePlanned')}</Micro>
                    </Row>
                    <Row>
                        <Dot color={colors.Teal2} />
                        <Micro>{t('timer.legend.timeUsed')}</Micro>
                    </Row>
                    <Row>
                        <Dot color={colors.Purple2} />
                        <Micro>{t('timer.legend.timeExceeded')}</Micro>
                    </Row>
                </Row>
            </ToolbarWrapper>
            {pastMeetings.length > 0
                ? <>
                    <TimeRow
                        title={t('statistics.timeStats.total')}
                        plannedTime={plannedTime}
                        exceededTime={exceededTime}
                        savedTime={savedTime}
                        longestTime={plannedTime > calculatedTime ? plannedTime : calculatedTime}
                    />
                    {attendees.map(attendee => {
                        const planned = pastMeetings.map(meeting => getPlannedTime(meeting, attendee.email));
                        const exceeded = pastMeetings.map(meeting => getExceededTime(meeting, attendee.email));
                        const saved = pastMeetings.map(meeting => getSavedTime(meeting, attendee.email));
                        return (
                            <TimeRow
                                key={attendee.email}
                                title={attendee.name}
                                plannedTime={planned.reduce((acc, cur) => acc + cur, 0)}
                                exceededTime={exceeded.reduce((acc, cur) => acc + cur, 0)}
                                savedTime={saved.reduce((acc, cur) => acc + cur, 0)}
                                longestTime={plannedTime > calculatedTime ? plannedTime : calculatedTime}
                            />
                        );
                    })}
                </>
                : <Small color={colors.Brown1} mt={spacings.xs}>
                    {t('statistics.noDataYet')}
                </Small>}
        </ContentWrapper >
    );
};

export default OrganiserStatistics;