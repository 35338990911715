import Colors from '../../../assets/constants/colors';
import spacings from '../../../assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.label<{ disabled: boolean | undefined }>`
    display: flex;
    align-items: center;
    gap: ${spacings.xxs}px;

    .react-toggle:hover {

        .react-toggle-track {
            background-color: ${Colors.Beige3};
        }
    }

    .react-toggle-track {
        background-color: ${Colors.Beige3};
        border: 2px solid ${Colors.Black};
        box-sizing: border-box;
        height: 30px;
        width: 42px;
    }

    .react-toggle-thumb {
        position: absolute;
        top: 9px;
        left: 9px;
        width: 12px;
        height: 12px;
        border: none;
        background-color: ${Colors.Black};
    }

    .react-toggle--checked {

        .react-toggle-track {
            background-color: ${Colors.Black};
        }

        .react-toggle-thumb {
            left: auto;
            right: 9px;
            background-color: ${Colors.White};
        }

        &:hover {
            
             .react-toggle-track {
                background-color: ${Colors.Black};
            }
        }
    }
`;