import BillingPopup from './billing-popup';
import Column from '@/common/components/column';
import EditBox from './edit-box';
import InvoiceListItem from './invoice-list-item';
import PaymentPopup from './payment-popup';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Body, Small } from '@/common/components/text';
import { getBillingDetails, getInvoiceList, getPaymentMethodDetailsAlt } from '@/services/api/payment-service';
import { setPaymentMethodDetails, setUserBillingDetails } from '@/store/licence-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Payment = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showBilling, setShowBilling] = useState<boolean>(false);
    const [showPayment, setShowPayment] = useState<boolean>(false);
    const paymentMethodDetails = useAppSelector(state => state.licence.paymentMethodDetails);
    const userBillingDetails = useAppSelector(state => state.licence.userBillingDetails);
    const [invoices, setInvoices] = useState<any[]>([]);

    useEffect(() => {
        const fetchBillingDetails = async () => {
            const [data, error] = await getBillingDetails();
            if (data) {
                console.log(data)
                dispatch(setUserBillingDetails(data));
            }
            if(error) {
                console.log(error)
            }
        };
        const fetchInvoiceList = async () => {
            const [data, error] = await getInvoiceList();
            if (data) {
                setInvoices(data);
            }
        };
        const fetchPaymentDetails = async () => {
            const [data, error] = await getPaymentMethodDetailsAlt();
            if (data) {
                dispatch(setPaymentMethodDetails(data));
            }
        };

        fetchBillingDetails();
        fetchInvoiceList();
        fetchPaymentDetails();
    }, []);

    return (
        <div style={{ marginTop: spacings.l }}>
            <Row style={{ gap: spacings.m, alignItems: 'stretch' }}>
                <Column style={{ flex: 1 }}>
                    <Body bold mb={spacings.xxs}>
                        {t('settings.payment.paymentDetails')}
                    </Body>
                    <EditBox
                        onClick={() => setShowPayment(true)}
                        renderContent={<>
                            <Small>{paymentMethodDetails?.card.brand}</Small>
                            <Small>{paymentMethodDetails?.billing_details.name}</Small>
                            <Small>**** **** **** {paymentMethodDetails?.card.last4}</Small>
                        </>}
                    />
                </Column>
                <Column style={{ flex: 1 }}>
                    <Body bold mb={spacings.xxs}>
                        {t('settings.payment.billingAddress')}
                    </Body>
                    <EditBox
                        onClick={() => setShowBilling(true)}
                        renderContent={<>
                            <Small>{userBillingDetails?.name}</Small>
                            <Small>{userBillingDetails?.address?.line1}</Small>
                            <Small>{userBillingDetails?.address?.postal_code} {userBillingDetails?.address?.city}</Small>
                            <Small>{userBillingDetails?.address?.country}</Small>
                        </>}
                    />
                </Column>
            </Row>
            <Row mt={spacings.m}>
                <Body bold mb={spacings.xxs}>
                    {t('settings.payment.invoices')}
                </Body>
            </Row>
            <div>
                {invoices.map((invoice, index) => <InvoiceListItem
                    key={invoice.number}
                    invoice={invoice}
                    isFirst={index === 0}
                    isLast={index === invoices.length - 1}
                />)}
            </div>
            {showPayment && <PaymentPopup close={() => setShowPayment(false)} />}
            {showBilling && <BillingPopup close={() => setShowBilling(false)} />}
        </div>
    );
};

export default Payment;