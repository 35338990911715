import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Popup from '@/common/components/popup';
import { Body, Headline, Small } from '@/common/components/text';
import { formatDurationCustom } from '@/common/helpers';
import { getFeedback } from '@/services/api/meeting-service';
import { IFeedback, INewFeedbackStateResponse } from '@/services/api/meeting-service/types';
import { intervalToDuration, isPast } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CollectFeedbackPopup = ({
    question,
    end,
    close,
    next,
    meetingId,
    itemIndex
}: any) => {
    const { t } = useTranslation();
    const intervalToEnd = intervalToDuration({ start: new Date(new Date().setMilliseconds(0)), end: new Date(end) });
    const endIsPast = isPast(new Date(end));
    const [feedbacks, setFeedbacks] = useState<IFeedback[]>([]);

    useEffect(() => {
        const fetchFeedback = async () => {
            const [data, error] = await getFeedback(meetingId, itemIndex);
            if (data) setFeedbacks(data.reverse());
        };
        fetchFeedback();

        //@ts-ignore
        Echo.channel(`meeting.${meetingId}.${itemIndex}`)
            .listen('NewFeedback', (e: INewFeedbackStateResponse) => {
                setFeedbacks([...e.feedback.reverse()]);
            });
    }, []);

    return (
        <Popup close={close}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Headline tertiary>
                    {t('timer.feedback')}
                </Headline>
                <Body mt={spacings.xxxs}>
                    {t('timer.fromEveryone')}
                </Body>
                <Headline
                    secondary
                    color={endIsPast ? colors.Purple1 : colors.Black}
                >
                    {endIsPast && '+'}{formatDurationCustom(intervalToEnd)}
                </Headline>
                <Button
                    mt={spacings.s}
                    secondary
                    label={t('timer.nextAgendaItem')}
                    onClick={() => {
                        close();
                        next();
                    }}
                />
            </div>
            <div style={{
                maxHeight: 400,
                marginTop: spacings.s,
                overflowY: 'scroll',
            }}>
                {feedbacks.length === 0 && <Small>
                    {t('timer.noFeedbackYet')}
                </Small>}
                {feedbacks.map((feedback, index) =>
                    <Small key={index} mb={spacings.xs}>
                        {feedback.feedback}
                    </Small>
                )}
            </div>
        </Popup>
    );
};

export default CollectFeedbackPopup;