import borderRadius from '@/assets/constants/border-radius';
import Button from '@/common/components/button';
import DeleteUserPopup from '../../delete-user-popup';
import EditUserPopup from '../../edit-user-popup';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import {
    CloseIconWrapper,
    ListItem,
    NameWrapper,
    Wrapper
    } from './styled';
import { FiArrowRightCircle } from 'react-icons/fi';
import { IAttendee } from '@/store/create-meeting-slice/types';
import { Micro, Small } from '@/common/components/text';
import { Pill } from '@/common/styled';
import { ReactComponent as ICUser } from '@icons/ic-user.svg';
import { RiCloseCircleFill, RiCloseCircleLine } from 'react-icons/ri';
import { useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AttendeeListItem = ({
    attendee,
    isFirst,
    isLast,
}: {
    attendee: IAttendee
    isFirst: boolean;
    isLast: boolean;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.user);
    const [deleteIsHovered, setDeleteIsHovered] = useState<boolean>(false);
    const [itemIsHovered, setItemIsHovered] = useState<boolean>(false);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const deleteNotAllowed = user?.role !== 'admin' ;//&& attendee.role === 'organiser'

    const onClickEdit = () => {
        if (attendee.email === user?.email) {
            navigate('/settings/account');
        } else {
            setShowEditPopup(true);
        }
    };

    const onClickDelete = () => {
        if (deleteNotAllowed) {
            return;
        }
        setShowDeletePopup(true);
    };

    return (
        <Wrapper>
            <ListItem
                onMouseEnter={() => setItemIsHovered(true)}
                onMouseLeave={() => setItemIsHovered(false)}
                onClick={onClickEdit}
                style={{
                    borderTopLeftRadius: isFirst ? borderRadius.m : 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: isLast ? borderRadius.m : 0,
                    borderBottomRightRadius: 0,
                }}
            >
                <NameWrapper>
                    <Row>
                        <ICUser style={{
                            marginRight: spacings.s,
                            width: 40,
                            height: 40,
                        }} />
                        <Small bold mr={spacings.xxs}>
                            {attendee.name} {attendee.pronouns && `(${attendee.pronouns})`}
                        </Small>
                        <Small>
                            {attendee.email}
                        </Small>
                    </Row>
                    <Row>
                        {attendee.role === 'organiser' && <Pill
                            style={{ marginRight: spacings.xs }}
                        >
                            <Micro bold>
                                {attendee.email === user?.email
                                    ? t('organisers.self')
                                    : t('organisers.organiser')
                                }
                            </Micro>
                        </Pill>}
                        <Button
                            tertiary
                            small
                            noPadding
                            iconRight={<FiArrowRightCircle size={20} style={{ marginLeft: spacings.xxxs }} />}
                            label={t('actions.edit')}
                        />
                    </Row>
                </NameWrapper>
            </ListItem>
            <CloseIconWrapper
                onMouseEnter={() => setDeleteIsHovered(true)}
                onMouseLeave={() => setDeleteIsHovered(false)}
                onClick={onClickDelete}
                style={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: isFirst ? borderRadius.m : 0,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: isLast ? borderRadius.m : 0,
                    pointerEvents: deleteNotAllowed ? 'none' : 'all',
                }}
            >
                {deleteIsHovered
                    ? <RiCloseCircleFill
                        size={24} />
                    : <RiCloseCircleLine
                        style={{
                            opacity: deleteNotAllowed ? 0.3 : 1,
                        }}
                        size={24} />
                }
            </CloseIconWrapper>

            {showEditPopup &&
                <EditUserPopup
                    close={() => setShowEditPopup(false)}
                    user={attendee}
                />
            }
            {showDeletePopup &&
                <DeleteUserPopup
                    close={() => setShowDeletePopup(false)}
                    user={attendee}
                />
            }
        </Wrapper>
    );
};

export default AttendeeListItem;