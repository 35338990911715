import Button from '../../../../common/components/button';
import colors from '@/assets/constants/colors';
import Row from '@/common/components/row';
import spacings from '../../../../assets/constants/spacings';
import { IoMdCheckmark } from 'react-icons/io';
import { Small } from '@/common/components/text';
import { StepIndex, STEPS } from '../../steps';
import { useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styled';

const VISIBLE_DURATION = 2000;
const FADE_DURATION = 500;

const TitleNavbar = ({ index }: { index: StepIndex; }) => {
    const step = STEPS[index];
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [opacity, setOpacity] = useState<number>(0);
    const title = useAppSelector((state) => state.createMeeting.title);
    const [initialTitle, setInitialTitle] = useState<string | undefined>(title);

    useEffect(() => {
        if (title && title.length > 0 && title !== initialTitle) {
            setOpacity(1);
            setTimeout(() => setOpacity(0), VISIBLE_DURATION);
        }
    }, [title]);

    return (
        <Wrapper>
            <Row style={{ opacity: opacity, transition: `${FADE_DURATION}ms all ease-in-out` }}>
                <IoMdCheckmark color={colors.Brown2} size={18} />
                <Small color={colors.Brown2} ml={spacings.xxxs}>
                    {t('create.automaticSave')}
                </Small>
            </Row>
            <Row>
                {step.next &&
                    <Button
                        label={t('actions.next')}
                        small
                        disabled={!!!title}
                        onClick={() => step.next && navigate(step.next)} />
                }
            </Row>
        </Wrapper>
    );
};

export default TitleNavbar;