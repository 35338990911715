import colors from '@/assets/constants/colors';
import { COUNTRIES } from '@/assets/constants/countries';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Dropdown from '@/common/components/dropdown';
import Row from '@/common/components/row';
import { Small } from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import { getPaymentMethodDetailsAlt, getSetupIntent, updatePaymentMethod } from '@/services/api/payment-service';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setPaymentMethodDetails } from '@/store/licence-slice';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from './styled';

const PaymentForm = ({ close }: { close: () => void; }) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useAppDispatch();

    const _paymentMethod = useAppSelector((state) => state.licence.paymentDetails?.paymentMethod);
    const _cardHolder = useAppSelector((state) => state.licence.paymentDetails?.cardHolder);
    const _country = useAppSelector((state) => state.licence.paymentDetails?.country);
    const [paymentMethod, setPaymentMethod] = useState<string>(_paymentMethod || 'credit');
    const [cardHolder, setCardHolder] = useState<string>(_cardHolder || '');
    const [country, setCountry] = useState<string>(_country || 'DE');
    const [loading, setLoading] = useState<boolean>(false);

    const cardStyle = {
        style: {
            base: {
                fontFamily: 'NotoSans',
                fontSize: '18px',
                backgroundColor: colors.White,
                color: colors.Black,
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: colors.Brown2
                }
            },
            invalid: {
                color: colors.Error,
                iconColor: colors.Error,
            }
        }
    };

    const handleSubmit = (stripe: any, elements: any) => async () => {
        const cardElement = elements.getElement(CardNumberElement);
        setLoading(true);
        const [data, error] = await getSetupIntent();
        setLoading(false);
        if (data && data.client_secret) {
            setLoading(true);
            const { setupIntent, error } = await stripe.confirmCardSetup(data.client_secret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: cardHolder,
                        address: {
                            country: country,
                        },
                    },
                },
            });
            setLoading(false);

            if (error) {
                console.log('[error]', error);
            } else {
                setLoading(true);
                const [data, error] = await updatePaymentMethod(setupIntent.payment_method);
                setLoading(false);
                if (!error) {
                    setLoading(true);
                    const [data, error] = await getPaymentMethodDetailsAlt();
                    setLoading(false);
                    if (data) {
                        dispatch(setPaymentMethodDetails(data));
                    }
                    close();
                }
            }
        }
    };

    return (
        <>
            <Dropdown
                mt={spacings.s}
                label={t('forms.paymentMethod')}
                options={[{ label: 'Credit Card', value: 'credit' }]}
                mandatory
                value={paymentMethod}
                setValue={setPaymentMethod}
            />
            <TextBox
                mt={spacings.xxs}
                label={t('forms.cardHolder')}
                mandatory
                value={cardHolder}
                setValue={setCardHolder}
            />
            <div>
                <Small
                    mt={spacings.xxs}
                    mb={spacings.xxxs}
                    color={colors.Black}
                >
                    {t('forms.cardNumber')}*
                </Small>
                <InputWrapper>
                    <CardNumberElement options={cardStyle} />
                </InputWrapper>
            </div>
            <Row style={{ gap: spacings.xxs }}>
                <div style={{ flex: 1 }}>
                    <Small
                        mt={spacings.xxs}
                        mb={spacings.xxxs}
                        color={colors.Black}
                    >
                        {t('forms.expiryDate')}*
                    </Small>
                    <InputWrapper>
                        <CardExpiryElement options={cardStyle} />
                    </InputWrapper>
                </div>
                <div style={{ flex: 1 }}>
                    <Small
                        mt={spacings.xxs}
                        mb={spacings.xxxs}
                        color={colors.Black}
                    >
                        {t('forms.cvc')}*
                    </Small>
                    <InputWrapper>
                        <CardCvcElement options={cardStyle} />
                    </InputWrapper>
                </div>
            </Row>
            <Dropdown
                mt={spacings.xxs}
                label={t('forms.country')}
                options={COUNTRIES.map(country => ({ label: country.name, value: country.code }))}
                mandatory
                value={country}
                setValue={setCountry}
            />
            <Row mt={spacings.m} style={{ justifyContent: 'flex-end' }}>
                <Button
                    mr={spacings.xxs}
                    tertiary
                    label={t('actions.cancel')}
                    onClick={close} />
                <Button
                    loading={loading}
                    label={t('actions.saveChanges')}
                    onClick={handleSubmit(stripe, elements)} />
            </Row>
        </>
    );
}

export default PaymentForm;