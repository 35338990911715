import colors from '@/assets/constants/colors';
import styled from 'styled-components';

const Pie = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    border-radius: 100%;
`;

const InnerCircle = styled.div`
    height: 14px;
    width: 14px;
    border-radius: 100%;
`;

const PartialPieChart = ({
    percentage,
    bgColor,
}: {
    percentage: number;
    bgColor?: string;
}) => {
    const color = percentage >= 80
        ? colors.Teal2 : percentage >= 50
            ? colors.Mustard2 : colors.Purple2;

    return (
        <Pie style={{
            background: `conic-gradient(${color} ${0}%, ${color} ${percentage}%, ${colors.Beige2} ${percentage}%, ${colors.Beige2} ${100}%)`
        }}>
            <InnerCircle style={{
                backgroundColor: bgColor ? bgColor : colors.White
            }} />
        </Pie>
    );
};

export default PartialPieChart;