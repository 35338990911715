import * as DurationFns from 'duration-fns';
import {nanoid} from 'nanoid';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AiFillPlusCircle} from 'react-icons/ai';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import {TiDeleteOutline} from 'react-icons/ti';
import Colors from '../../../../assets/constants/colors';
import {default as Spacings} from '../../../../assets/constants/spacings';
import Checkbox from '../../../../common/components/checkbox';
import Row from '../../../../common/components/row';
import * as Text from '../../../../common/components/text';
import TextBoxBorderless from '../../../../common/components/textbox-borderless';
import Toggle from '../../../../common/components/toggle';
import {divideDuration} from '../../../../common/helpers';
import {
    addAgendaItem,
    addAgendaItemSpeaker,
    moveAgendaItemDown,
    moveAgendaItemUp,
    newAgendaItem,
    removeAgendaItem,
    removeAgendaItemSpeaker,
    setAgendaItemDescription,
    setAgendaItemDiscussion,
    setAgendaItemDuration,
    setAgendaItemFeedback,
    setAgendaItemLinks,
    setAgendaItemTitle,
    setSpeakerDuration,
} from '../../../../store/create-meeting-slice';
import {IAttendee} from '../../../../store/create-meeting-slice/types';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import UserSelection from '../../attendees/user-selection';
import DurationPicker from '../duration-picker';
import {Box} from '../styled';
import SpeakerList from './speaker-list';
import {BoxTitle, DeleteWrapper, SeparatorLine} from './styled';
import {IAgendaItemComponent} from './types';

const AgendaItem = ({
    item,
    index,
    isFirst,
    isLast,
    itemCount,
    itemRef,
    itemRefs,
    yPositions,
}: IAgendaItemComponent) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const attendees = useAppSelector(state => state.createMeeting.attendees);
    const [showSpeakerList, setShowSpeakerList] = useState<boolean>(false);
    const [hasLinks, setHasLinks] = useState<boolean>(!!item.links);
    const [hasEqualised, setHasEqualised] = useState<boolean>(false);
    const [hasDiscussion, setHasDiscussion] = useState<boolean>(
        DurationFns.toSeconds(item.discussion.duration) > 0,
    );
    const [hasFeedback, setHasFeedback] = useState<boolean>(
        DurationFns.toSeconds(item.feedback.duration) > 0,
    );

    const speakerSumEqualsDuration = () =>{
        if(item.speakers.length==0) return true;
        const equalDuration =divideDuration(
            item.duration,
            item.speakers.length,
        )
       const firstSpeakerTime = item.speakers[0].duration

       return DurationFns.toSeconds(equalDuration) == DurationFns.toSeconds(firstSpeakerTime)
        return true
    }

    const hasEqualSpeakingTimes = speakerSumEqualsDuration() && item.speakers.every(
            speaker =>
                DurationFns.toSeconds(speaker.duration) ===
                DurationFns.toSeconds(item.speakers[0].duration),
        );
    
    console.log("queal speakingtime?= " + hasEqualSpeakingTimes)
    const [search, setSearch] = useState<string>();

    const selectAttendeeContainer = React.createRef<HTMLDivElement>();
    useEffect(() => document.addEventListener('mousedown', handleClickOutside));

    const handleClickOutside = (event: any) => {
        if (
            selectAttendeeContainer.current &&
            !selectAttendeeContainer.current.contains(event.target)
        ) {
            setShowSpeakerList(false);
        }
    };

    const addNewItem = () => {
        dispatch(
            addAgendaItem({
                insertIndex: index + 1,
                item: newAgendaItem(nanoid()),
            }),
        );
    };

    const removeItem = () => {
        dispatch(removeAgendaItem(item));
    };

    const addSpeaker = (attendee: IAttendee) => {
        if(item.speakers.length>0){equalizeDurations();}
    
        dispatch(
            addAgendaItemSpeaker({
                attendee: attendee,
                index: index,
            }),
        );
    };

    const addAllSpeakers = (attendees: IAttendee[]) => {
        attendees.map(attendee => {
            dispatch(
                addAgendaItemSpeaker({
                    attendee: attendee,
                    index: index,
                }),
            );
        });
    };

    const removeSpeaker = (attendee: IAttendee) => {   
        equalizeDurations()
        dispatch(
            removeAgendaItemSpeaker({
                attendee: attendee,
                index: index,
            }),
        );

    };

    const editDuration = (duration: Duration) => {
        setHasEqualised(true);
        dispatch(
            setAgendaItemDuration({
                duration: duration,
                index: index,
            }),
        );
        setHasEqualised(false);
    };

    const editDiscussionDuration = (duration: Duration) => {
        dispatch(
            setAgendaItemDiscussion({
                question: item.discussion.question,
                duration: duration,
                index: index,
            }),
        );
    };

    const editFeedbackDuration = (duration: Duration) => {
        dispatch(
            setAgendaItemFeedback({
                question: item.feedback.question,
                duration: duration,
                index: index,
            }),
        );
    };

    const equalizeDurations = () => {
        const equalDuration = divideDuration(
            item.duration,
            item.speakers.length,
        );

        item.speakers.forEach(speaker => {
            setHasEqualised(true);
            dispatch(
                setSpeakerDuration({
                    attendee: speaker.attendee,
                    duration: equalDuration,
                    index: index,
                }),
            );
        });
        setTimeout(() => setHasEqualised(false), 1);
    };

    useEffect(() => {
        if (hasLinks) return;
        dispatch(
            setAgendaItemLinks({
                links: undefined,
                index: index,
            }),
        );
    }, [hasLinks]);

    useEffect(() => {
        if (hasDiscussion) return;
        dispatch(
            setAgendaItemDiscussion({
                question: undefined,
                duration: {seconds: 0},
                index: index,
            }),
        );
    }, [hasDiscussion]);

    useEffect(() => {
        if (hasFeedback) return;
        dispatch(
            setAgendaItemFeedback({
                question: undefined,
                duration: {seconds: 0},
                index: index,
            }),
        );
    }, [hasFeedback]);

    const filterUsers = () => {
        const filteredAttendees = attendees.filter(attendee => {
            if (!search) return attendee;
            return attendee.name.toLowerCase().includes(search?.toLowerCase());
        });
        const attMails = item.speakers.map(speaker => speaker.attendee.email);

        return filteredAttendees.filter(
            attendee => !attMails.includes(attendee.email),
        );
    };

    return (
        <div ref={itemRef}>
            <Box
                style={{
                    backgroundColor: item.editable
                        ? Colors.White
                        : Colors.Transparent,
                    borderColor: item.editable
                        ? Colors.Transparent
                        : Colors.Beige1,
                    pointerEvents: item.editable ? 'all' : 'none',
                }}>
                <BoxTitle>
                    {!isFirst && !isLast && index !== 1 && (
                        <IoIosArrowUp
                            onClick={() => dispatch(moveAgendaItemUp(index))}
                            size={16}
                            style={{
                                position: 'absolute',
                                right: 0,
                                top: -Spacings.xxs,
                                cursor: 'pointer',
                            }}
                        />
                    )}
                    <Text.Subheadline quaternary>
                        {isFirst && t('common.start')}
                        {isLast && t('common.end')}
                        {!isFirst && !isLast && index}
                    </Text.Subheadline>
                    {!isFirst && !isLast && index !== itemCount - 2 && (
                        <IoIosArrowDown
                            onClick={() => dispatch(moveAgendaItemDown(index))}
                            size={16}
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: -Spacings.xxs,
                                cursor: 'pointer',
                            }}
                        />
                    )}
                </BoxTitle>
                <TextBoxBorderless
                    mb={Spacings.s}
                    noIcon
                    bold
                    placeholder={t('create.stepAgenda.namePlaceholder')}
                    error={item.errors?.name}
                    value={item.name}
                    setValue={(value: string) =>
                        dispatch(
                            setAgendaItemTitle({
                                title: value,
                                index: index,
                            }),
                        )
                    }
                />
                <TextBoxBorderless
                    label={t('create.stepAgenda.description')}
                    placeholder={t('create.stepAgenda.contentPlaceholder')}
                    error={item.errors?.description}
                    noIcon={isLast}
                    size="small"
                    value={item.description}
                    setValue={(value: string) =>
                        dispatch(
                            setAgendaItemDescription({
                                description: value,
                                index: index,
                            }),
                        )
                    }
                />
                <SeparatorLine />
                <Text.Small bold mb={Spacings.xxxs}>
                    {t('create.stepAgenda.time')}
                </Text.Small>
                <DurationPicker
                    error={item.errors?.duration || item.errors?.speakerTime}
                    noIcon={!item.editable}
                    minutes={item.duration.minutes || 0}
                    seconds={item.duration.seconds || 0}
                    editDuration={editDuration}
                />
                {!isLast && (
                    <>
                        <SeparatorLine />
                        <div ref={selectAttendeeContainer}>
                            <TextBoxBorderless
                                noLinebreak
                                style={{width: '60%'}}
                                label={t('create.stepAgenda.speaker')}
                                placeholder={t(
                                    'create.stepAgenda.selectSpeaker',
                                )}
                                error={item.errors?.speakers}
                                size="small"
                                value={search}
                                setValue={setSearch}
                                onFocus={() => setShowSpeakerList(true)}
                            />
                            {showSpeakerList && (
                                <UserSelection
                                    width={`calc(100% - ${2 * Spacings.xs}px)`}
                                    onItemClick={addSpeaker}
                                    onAddAll={addAllSpeakers}
                                    users={filterUsers()}
                                    hideList={() => setShowSpeakerList(false)}
                                />
                            )}
                        </div>
                        <div style={{position: 'relative'}}>
                            {item.speakers.length > 0 && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: -45,
                                        right: 0,
                                    }}>
                                    <Toggle
                                        label={t('create.stepAgenda.equalTime')}
                                        checked={hasEqualSpeakingTimes}
                                        small
                                        revert
                                        onChange={event => {
                                            if (event.target.checked)
                                                equalizeDurations();
                                        }}
                                    />
                                </div>
                            )}
                            {item.speakers && (
                                <SpeakerList
                                    // Hacky, so <SpeakerList> re-renders with new values
                                    // when equalising times (check function above).
                                    // Needed because we cannot re-render on change of the
                                    // duration values itself, otherwise we lose focus on the
                                    // <DurationPicker> in <SpeakerList> and children of
                                    // <SpeakerList> were not re-rendering anyway because of
                                    // the key staying the same (speaker email), see
                                    // <SpeakerList> component for that.
                                    key={hasEqualised ? 'true' : 'false'}
                                    errors={item.errors}
                                    index={index}
                                    removeSpeaker={removeSpeaker}
                                />
                            )}
                        </div>
                        <SeparatorLine />
                        <Checkbox
                            label={t('create.stepAgenda.stepLinks')}
                            checked={hasLinks}
                            setChecked={setHasLinks}
                            bold
                            optional
                            small
                        />
                        {hasLinks && (
                            <>
                                <TextBoxBorderless
                                    mt={Spacings.xs}
                                    mb={Spacings.xxs}
                                    placeholder={t(
                                        'create.stepAgenda.agendaLinksPlaceholder',
                                    )}
                                    size="small"
                                    value={item.links}
                                    setValue={(value: string) =>
                                        dispatch(
                                            setAgendaItemLinks({
                                                links: value,
                                                index: index,
                                            }),
                                        )
                                    }
                                />
                            </>
                        )}
                        <SeparatorLine />
                        <Checkbox
                            label={t('create.stepAgenda.addDiscussion')}
                            checked={hasDiscussion}
                            setChecked={setHasDiscussion}
                            bold
                            optional
                            small
                        />
                        {hasDiscussion && (
                            <>
                                <TextBoxBorderless
                                    error={item.errors?.discussionQuestion}
                                    mt={Spacings.xs}
                                    mb={Spacings.xxs}
                                    placeholder={t(
                                        'create.stepAgenda.questionPlaceholder',
                                    )}
                                    size="small"
                                    value={item.discussion.question}
                                    setValue={(value: string) =>
                                        dispatch(
                                            setAgendaItemDiscussion({
                                                question: value,
                                                duration:
                                                    item.discussion.duration,
                                                index: index,
                                            }),
                                        )
                                    }
                                />
                                <DurationPicker
                                    error={item.errors?.discussion}
                                    minutes={
                                        item.discussion.duration.minutes || 0
                                    }
                                    seconds={
                                        item.discussion.duration.seconds || 0
                                    }
                                    editDuration={editDiscussionDuration}
                                />
                            </>
                        )}
                        <SeparatorLine />
                        <Checkbox
                            mb={Spacings.xxs}
                            label={t('create.stepAgenda.addFeedback')}
                            checked={hasFeedback}
                            setChecked={setHasFeedback}
                            bold
                            optional
                            small
                        />
                        {hasFeedback && (
                            <>
                                <TextBoxBorderless
                                    error={item.errors?.feedbackQuestion}
                                    mt={Spacings.xs}
                                    mb={Spacings.xxs}
                                    placeholder={t(
                                        'create.stepAgenda.questionPlaceholder',
                                    )}
                                    size="small"
                                    value={item.feedback.question}
                                    setValue={(value: string) =>
                                        dispatch(
                                            setAgendaItemFeedback({
                                                question: value,
                                                duration:
                                                    item.feedback.duration,
                                                index: index,
                                            }),
                                        )
                                    }
                                />
                                <DurationPicker
                                    error={item.errors?.feedback}
                                    minutes={
                                        item.feedback.duration.minutes || 0
                                    }
                                    seconds={
                                        item.feedback.duration.seconds || 0
                                    }
                                    editDuration={editFeedbackDuration}
                                />
                            </>
                        )}
                    </>
                )}
                {!isLast && !isFirst && (
                    <DeleteWrapper>
                        <Text.Small link onClick={removeItem}>
                            {t('actions.delete')}
                            <TiDeleteOutline
                                size={24}
                                style={{
                                    marginBottom: -6,
                                    marginLeft: Spacings.xxxs,
                                }}
                            />
                        </Text.Small>
                    </DeleteWrapper>
                )}
            </Box>
            {!isLast && (
                <Row>
                    <AiFillPlusCircle color={Colors.Purple1} size={24} />
                    <Text.Small
                        link
                        mt={Spacings.xxs}
                        mb={Spacings.xxs}
                        ml={Spacings.xxxs}
                        onClick={addNewItem}>
                        {t('create.stepAgenda.addAgendaItem')}
                    </Text.Small>
                </Row>
            )}
        </div>
    );
};

export default AgendaItem;
