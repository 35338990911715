import BorderRadius from '../../../assets/constants/border-radius';
import Colors from '../../../assets/constants/colors';
import styled from 'styled-components';
import { default as Spacings } from '../../../assets/constants/spacings';

export const Wrapper = styled.div`
    position: relative;
`;

export const Box = styled.div`
    position: relative;
    padding: ${Spacings.xs}px;
    background-color: ${Colors.White};
    border-color: ${Colors.Transparent};
    border-width: 1px;
    border-style: solid;
    border-radius: ${BorderRadius.m}px;
`;