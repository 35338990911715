import * as DurationFns from 'duration-fns';
import { IAgendaItem, IAgendaItemErrors } from '@/store/create-meeting-slice/types';

export const getAgendaErrors = (items: IAgendaItem[]) => items.map((item, index) => {
    const errors: IAgendaItemErrors = {};
    if (!item.name) {
        errors.name = true;
    }
    if (!item.description) {
        errors.description = true;
    }
    if (DurationFns.toSeconds(item.duration) === 0) {
        errors.duration = true;
    }
    if (item.speakers.length === 0 && index !== items.length - 1) {
        // Do not check speakers for last step
        errors.speakers = true;
    }
    if (item.speakers.length > 0 && index !== items.length - 1) {
        // Do not check speakers for last step
        const duration = DurationFns.toSeconds(item.duration);
        const sum = DurationFns.toSeconds(
            item.speakers.reduce(
                (acc, cur) => DurationFns.sum(acc, cur.duration),
                { seconds: 0 }
            )
        );
        errors.speakerTime = duration !== sum;
    }
    if (item.discussion.question && DurationFns.toSeconds(item.discussion.duration) === 0) {
        errors.discussion = true;
    }
    if (item.feedback.question && DurationFns.toSeconds(item.feedback.duration) === 0) {
        errors.feedback = true;
    }
    if (!item.discussion.question && DurationFns.toSeconds(item.discussion.duration) > 0) {
        errors.discussionQuestion = true;
    }
    if (!item.feedback.question && DurationFns.toSeconds(item.feedback.duration) > 0) {
        errors.feedbackQuestion = true;
    }
    return errors;
});