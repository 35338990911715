import axios from 'axios';
import store from '@/store';
import { handleApiError } from '../helpers';
import { IBillingDetails, IPaymentSetupData } from './types';

const API_BASE = process.env.REACT_APP_API_BASE;

export const getProductPrices = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/productPrices?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getPaymentMethodDetails = async (paymentMethodId: string) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/paymentDetails?organisation_id=${state.auth.user?.organisatonId}&payment_method=${paymentMethodId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getPaymentMethodDetailsAlt = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/method?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getBillingDetails = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/billingAddress?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};


export const updatePaymentMethod = async (paymentMethodId: string) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/method`;

    try {
        const result = await axios.post(endpoint, {
            organisation_id: state.auth.user?.organisatonId,
            payment_method: paymentMethodId,
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const updateBillingAddress = async (data: IBillingDetails) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/billingAddress`;

    try {
        const result = await axios.post(endpoint, {
            ...data,
            organisation_id: state.auth.user?.organisatonId,
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getSetupIntent = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/setupIntent?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {

            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const setupPayment = async (data: IPaymentSetupData) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/setup`;

    try {
        const result = await axios.post(endpoint, {
            ...data,
            organisation_id: state.auth.user?.organisatonId
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const updateSubscriptionQuantity = async (amount: number) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/subscriptionQuantity`;

    try {
        const result = await axios.post(endpoint, {
            organisation_id: state.auth.user?.organisatonId,
            subscription_quantity: amount,
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};


export const deleteSubscription = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/subscription?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.delete(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const resumeSubscription = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/resumeSubscription`;

    try {
        const result = await axios.post(endpoint, {
            organisation_id: state.auth.user?.organisatonId
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getInvoiceList = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/organisation/invoiceList?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const getTrialDays = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/trialDays`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};