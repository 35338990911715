import { MINI_TIMER_WINDOW_HEIGHT_THRESHOLD, MINI_TIMER_WINDOW_WIDTH_THRESHOLD } from '@/assets/constants';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Row from '@/common/components/row';
import {
    Body,
    Headline,
    Micro,
    Small
} from '@/common/components/text';
import { truncate } from '@/common/helpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { ImArrowDownRight2 } from 'react-icons/im';
import GiveFeedbackPopup from './give-feedback-popup';
import Questionnaire from './questionnaire';
import { Arrow, Popup, StyledExtendWrapper, Wrapper } from './styled';
import { IStepInfo } from './types';

const StepInfo = ({ meeting, currentStep, showEndMeeting, setShowEndMeeting }: IStepInfo) => {
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    // TODO make this not so ugly, maybe a custom hook?
    const [windowWidth, setWindowWidth] = useState<number>(0)
    const [windowHeight, setWindowHeight] = useState<number>(0)
    useEffect(() => {

        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    }, [])
    const updateDimensions = () => {
        const width = window.innerWidth
        const height = window.innerHeight
        setWindowWidth(width);
        setWindowHeight(height);
    };

    const isMini = windowWidth <= MINI_TIMER_WINDOW_WIDTH_THRESHOLD
        || windowHeight <= MINI_TIMER_WINDOW_HEIGHT_THRESHOLD;

    return (
        <Wrapper style={{
            alignItems: isMini ? 'flex-start' : 'center',
            marginTop: isMini ? spacings.xxs : spacings.s,
        }}>
            {typeof currentStep.type === 'number' &&
                <>
                    <Headline tertiary={!isMini} quaternary={isMini}>
                        {meeting.agenda.items[currentStep.item].name}
                    </Headline>
                    <Body style={{ fontSize: isMini ? '1rem' : '1.125rem' }}>
                        {meeting.agenda.items[currentStep.item].speakers[currentStep.type].attendee.name}
                        {` `}
                        ({meeting.agenda.items[currentStep.item].speakers[currentStep.type].attendee.pronouns})
                    </Body>
                    <Row mt={spacings.xxxs}>
                        <Small style={{ textAlign: isMini ? 'left' : 'center' }}>
                            {/* @ts-ignore */}
                            {truncate(meeting.agenda.items[currentStep.item].description, 50)}
                        </Small>
                        {/* @ts-ignore // TODO do not ignore */}
                        {!isMini && meeting.agenda.items[currentStep.item].description?.length > 50 && (
                            <Small
                                link
                                ml={spacings.xxxs}
                                style={{ position: 'relative' }}
                                onClick={() => setShowPopup(!showPopup)}
                            >
                                {showPopup 
                                ? t('actions.hide') 
                                : <Row>
                                    {t('actions.show')}
                                    <AiOutlinePlusCircle color={colors.Purple1} size={24} style={{marginLeft:'10px'}}/>
                                </Row> }
                                {showPopup && <Popup>
                                    <Micro>
                                        {meeting.agenda.items[currentStep.item].description}
                                    </Micro>
                                    <Arrow />
                                </Popup>}
                            </Small>
                        )}
                    </Row>
                </>
            }
            {currentStep.type === 'discussion' &&
                <>
                    <Headline tertiary>
                        {t('timer.discussion')}
                    </Headline>
                    <Body style={{ fontSize: isMini ? '1rem' : '1.125rem' }}>
                        {meeting.agenda.items[currentStep.item].discussion.question}
                    </Body>
                </>
            }
            {currentStep.type === 'feedback' && (
                isMini ? (
                    <>
                        <Headline tertiary>
                            {t('timer.timeForFeedback')}
                        </Headline>
                        <Body bold>
                            {t('timer.timeForFeedbackInfo')}
                        </Body>
                        <StyledExtendWrapper>
                            <Row style={{ justifyContent: 'flex-end' }}>
                                <Body bold mr={spacings.xxs}>
                                    {t('timer.pleaseExtend')}
                                </Body>
                                <ImArrowDownRight2 size={20} />
                            </Row>
                        </StyledExtendWrapper>
                    </>
                ) : (
                    <>
                        <Headline tertiary>
                            {t('timer.feedback')}
                        </Headline>
                        <Body style={{ fontSize: isMini ? '1rem' : '1.125rem' }}>
                            {meeting.agenda.items[currentStep.item].feedback.question}
                        </Body>
                        <Button
                            small={isMini}
                            mt={spacings.xs}
                            primary
                            label={t('timer.giveFeedback')}
                            onClick={() => setShow(true)}
                        />
                        {show && <GiveFeedbackPopup
                            close={() => setShow(false)}
                            question={meeting.agenda.items[currentStep.item].feedback.question}
                            end={currentStep.end}
                            meetingId={meeting.externalId || ''}
                            itemIndex={currentStep.item}
                        />}
                    </>
                )
            )}
            {currentStep.type === 'evaluation' && (
                isMini ? (
                    <>
                        <Headline tertiary>
                            {t('timer.timeToEvaluate')}
                        </Headline>
                        <Body bold>
                            {t('timer.timeToEvaluateInfo')}
                        </Body>
                        <StyledExtendWrapper>
                            <Row style={{ justifyContent: 'flex-end' }}>
                                <Body bold mr={spacings.xxs}>
                                    {t('timer.pleaseExtend')}
                                </Body>
                                <ImArrowDownRight2 size={20} />
                            </Row>
                        </StyledExtendWrapper>
                    </>
                ) : (
                    <>
                        <Headline tertiary>
                            {showEndMeeting ? t('timer.thankYou') : t('timer.evaluation')}
                        </Headline>
                        {showEndMeeting
                            ?
                            <Body>
                                {t('timer.feedbackSentAnonymously')}
                            </Body>
                            : <Questionnaire
                                meetingId={meeting.externalId || ''}
                                setShowEndMeeting={setShowEndMeeting}
                            />
                        }
                    </>
                )
            )}
        </Wrapper>
    );
};

export default StepInfo;