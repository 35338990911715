import { TEMPLATES, TEMPLATE_OPTIONS } from '@/assets/constants/meeting-templates';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Dropdown from '@/common/components/dropdown';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import { Headline } from '@/common/components/text';
import { IPopup } from '@/common/types';
import { load } from '@/store/create-meeting-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const TemplatesPopup = ({ close }: IPopup) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [templateIndex, setTemplateIndex] = useState<string>('0');
    const dispatch = useAppDispatch();
    const saved = useAppSelector(state => state.createMeeting);

    useEffect(() => {
        if (saved) {
            setTemplateIndex('-1');
        } else {
            setTemplateIndex('0');
        }
    }, [saved]);

    return (
        <Popup close={close}>
            <Headline quaternary mb={spacings.s}>
                {t('create.chooseTemplate')}
            </Headline>
            <Dropdown
                options={[
                    { label: t('create.lastEdited'), value: '-1' },
                    ...TEMPLATE_OPTIONS
                ]}
                label={t('create.templates')}
                value={templateIndex}
                setValue={setTemplateIndex}
            />
            <Row mt={spacings.m} style={{ justifyContent: 'flex-end' }}>
                <Button
                    mr={spacings.xxs}
                    tertiary
                    label={t('actions.cancel')}
                    onClick={close} />
                <Button
                    label={t('meetings.createMeeting')}
                    onClick={() => {
                        if (saved && templateIndex === '-1') {
                            dispatch(load(saved));
                        } else {
                            dispatch(load(TEMPLATES[Number(templateIndex)]));
                        }
                        navigate('/create-meeting/title');
                    }}
                />
            </Row>
        </Popup>
    );
};

export default TemplatesPopup;