import colors from '@/assets/constants/colors';
import styled from 'styled-components';

const Pie = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border-radius: 100%;
`;

const InnerCircle = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 100%;
`;

const PieChart = ({
    values,
    bgColor,
}: {
    values: { color: string; percentage: number; }[];
    bgColor?: string;
}) => {
    let colorStrings: string[] = [];
    let currentPercentage = 0;
    values.forEach(value => {
        colorStrings.push(`${value.color} ${currentPercentage}% ${value.percentage + currentPercentage}%`);
        currentPercentage += value.percentage;
    });

    return (
        <Pie style={{
            background: `conic-gradient(${colorStrings.join(',')})`
        }}>
            <InnerCircle style={{
                backgroundColor: bgColor ? bgColor : colors.White
            }} />
        </Pie>
    );
};

export default PieChart;