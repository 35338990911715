import { getAllUsers } from '@/services/api/organisation-service';
import { IUserResponse } from '@/services/api/organisation-service/types';
import { saveUserlist } from '@/store/general-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect } from 'react';
import AttendeeList from './attendee-list';
import AttendeeNew from './attendee-new';

const Attendees = () => {
    const dispatch = useAppDispatch();
    const userlist = useAppSelector((state) => state.general.userlist);
    const user = useAppSelector((state) => state.auth.user);

    useEffect(() => {
        const fetchUsers = async () => {
            const [data, error] = await getAllUsers();
            dispatch(saveUserlist(data
                .filter((_user: IUserResponse) => _user.role !== 'admin')
                .map((_user: IUserResponse) => ({
                    id: _user.id,
                    name: _user.name,
                    email: _user.email,
                    pronouns: _user.pronouns,
                    role: _user.role,
                })))
            );
        };
        fetchUsers();
    }, []);

    return (
        userlist.length === 0
            ? <AttendeeNew />
            : <AttendeeList attendees={userlist} />
    );
};

export default Attendees;