import colors from "@/assets/constants/colors";
import spacings from "@/assets/constants/spacings";
import styled from "styled-components";

export const CIRCLE_SIZE = 36;

export const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${spacings.xxxs}px;
`;

export const StyledCircle = styled.div <{
    color?: string;
    filled?: boolean;
    active?: boolean;
    disabled?: boolean;
}>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${CIRCLE_SIZE}px;
    width: ${CIRCLE_SIZE}px;
    border-radius: ${CIRCLE_SIZE}px;
    background-color: ${props => props.color ? props.color : props.filled ? colors.Black : colors.Transparent};
    border: 2px solid ${props => props.active ? colors.Black : colors.Transparent};
    opacity: ${props => props.disabled ? 0.4 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'all'};
`;