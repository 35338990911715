import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import EvaluationSummary from '../evaluation-summary';
import spacings from '@/assets/constants/spacings';
import { Body, Headline } from '@/common/components/text';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { getAllEvaluation } from '@/services/api/meeting-service';
import { IAnswer } from '@/features/timer/timer-runner/step-info/questionnaire/types';
import { useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SatisfactionStatistics = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [answers, setAnswers] = useState<IAnswer[]>([]);
    const user = useAppSelector(state => state.auth.user);

    useEffect(() => {
        const fetchAllEvaluation = async () => {
            const [data, error] = await getAllEvaluation();
            if (data) {
                const answers: IAnswer[] = data.map((entry: any) => {return {'question':entry.question_id, 'answer':entry.answer}})
                setAnswers(answers);
            }

        };
        fetchAllEvaluation();
    }, []);

    return (
        <ContentWrapper style={{ position: 'relative' }}>
            <Button
                style={{ position: 'absolute', right: '100%', top: -6 }}
                small
                tertiary
                label={t('actions.back')}
                icon={<FiArrowLeftCircle size={18} style={{ marginRight: spacings.xxxs }} />}
                onClick={() => navigate('/statistics')}
            />
            <Body mt={spacings.m}>
                {t('statistics.allMeetings')}
            </Body>
            <Headline secondary mb={spacings.m}>
                {t('statistics.satisfactionStats.title')}
            </Headline>
            <EvaluationSummary answers={answers} />
        </ContentWrapper>
    );
};

export default SatisfactionStatistics;