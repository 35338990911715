import * as Text from '../../../../common/components/text';
import Colors from '../../../../assets/constants/colors';
import {
    AddAllInnerWrapper,
    AddAllWrapper,
    AddNewInnerWrapper,
    AddNewWrapper,
    InnerWrapper,
    OuterWrapper
    } from './styled';
import { default as Spacings } from '../../../../assets/constants/spacings';
import { FaUser } from 'react-icons/fa';
import { IAttendee } from '../../../../store/create-meeting-slice/types';
import { IUserSelection } from './types';
import { useAppDispatch } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import Button from '@/common/components/button';

const UserSelection = ({onAddAll, users, hideList, showPopup, onItemClick, width }: IUserSelection) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <OuterWrapper style={{ width: width || '100%' }}>
            <InnerWrapper>
                {users.length > 0
                    ? users.map((user: IAttendee, index: number) => <div
                        key={`${user.email}-${index}`}
                        onClick={() => {
                            onItemClick && onItemClick(user);
                            hideList();
                        }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: Spacings.xxxs,
                            cursor: 'pointer',
                            marginTop: index === 0 ? Spacings.xxxs : 0,
                            marginBottom: !showPopup ? Spacings.xxxs : (index === users.length - 1) ? Spacings.xxl : 0,
                        }}
                    >
                        <FaUser style={{
                            backgroundColor: Colors.Mustard3,
                            padding: Spacings.xxxs,
                            marginRight: Spacings.xxs,
                            borderRadius: '100%',
                        }} />
                        <Text.Small bold mr={Spacings.xxxs}>
                            {user.name} {user.pronouns && `(${user.pronouns})`}
                        </Text.Small>
                        <Text.Small>
                            ({user.email})
                        </Text.Small>
                    </div>)
                    : <Text.Small
                        mt={Spacings.xxs}
                        ml={Spacings.xxs}
                        mb={showPopup ? Spacings.xxl : Spacings.xxs}
                        color={Colors.Brown1}
                    >
                        {showPopup
                            ? t('create.stepAttendees.noUsersYet')
                            : t('create.stepAttendees.userNotAvailable')
                        }
                    </Text.Small>
                }
            </InnerWrapper>
            {showPopup && <AddNewWrapper>
                <AddNewInnerWrapper>
                    <Text.Small>
                        {t('create.stepAttendees.addNew')}
                    </Text.Small>
                    <Text.Small link onClick={showPopup}>
                        {t('create.stepAttendees.createProfile')}
                    </Text.Small>
                    <AiOutlinePlusCircle color={Colors.Purple1} size={20} style={{alignSelf:'end', marginLeft:'-10px'}}/>
                </AddNewInnerWrapper>
            </AddNewWrapper>}
            {!showPopup && onAddAll && users.length>1 && <AddAllWrapper>
                <AddAllInnerWrapper>    
                    <Button
                        small
                        secondary
                        onClick={()=>{onAddAll(users); hideList()}}
                        label={t('create.stepAgenda.addAll')}
                    />
                </AddAllInnerWrapper>
            </AddAllWrapper>}




        </OuterWrapper>
    );
}

export default UserSelection;