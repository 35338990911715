import borderRadius from "@/assets/constants/border-radius";
import colors from "@/assets/constants/colors";
import spacings from "@/assets/constants/spacings";
import styled from "styled-components";

export const Wrapper = styled.div`
    text-align: left;
    z-index: 99;
    position: absolute;
    right: -120px;
    top: ${spacings.m}px;
    border-radius: ${borderRadius.s}px;
    border: 1px solid ${colors.Beige1};
    background-color: ${colors.Beige3};
    width: 250px;
`;

export const MenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacings.xxs}px;
    background-color: ${colors.Beige3};
    cursor: pointer;

    &:hover {
        background-color: ${colors.Mustard3};
    }
`;

export const OrderByWrapper = styled.div`
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: ${spacings.xxs}px;
    border-left: 1px solid ${colors.Beige1};
    background-color: ${colors.Beige3};
    cursor: pointer;
`;