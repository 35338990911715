import borderRadius from '../../../assets/constants/border-radius';
import spacings from '../../../assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: ${spacings.xxs}px;
    border-radius: ${borderRadius.xs}px;
`;