import spacings from '../../../assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
`;

export const Section = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${spacings.m}px;
`;