import spacings from "@/assets/constants/spacings";
import React, { useState } from "react";
import { IoIosCheckmark } from "react-icons/io";
import { Small } from "../../text";
import { MenuItem } from '../styled';

const OrderByItem = ({
    label,
    onClick,
    active
}: {
    label: string;
    onClick: () => void;
    active: boolean;
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    return (
        <MenuItem
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
        >
            <Small>
                {label}
            </Small>
            {active && <IoIosCheckmark
                size={25}
                style={{ marginLeft: spacings.s }}
            />}
        </MenuItem>
    );
}

export default OrderByItem;