import {
    MINI_TIMER_WINDOW_HEIGHT_THRESHOLD,
    MINI_TIMER_WINDOW_WIDTH_THRESHOLD,
} from '@/assets/constants';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import {Headline} from '@/common/components/text';
import {formatDurationCustom} from '@/common/helpers';
import {ReactComponent as ICUser} from '@icons/ic-user.svg';
import {ReactComponent as ICDiscussion} from '@icons/ic-discussion.svg';
import * as DurationFns from 'duration-fns';
import {useEffect, useState} from 'react';
import {
    AVATAR_SIZE,
    CircleBackground,
    GrowingCircle,
    OverflowCircle,
    Wrapper,
} from './styled';
import {ICircleIndicator} from './types';

const CircleIndicator = ({
    totalOverflow,
    totalDuration,
    isPast,
    progress,
    difference,
    currentStep,
}: ICircleIndicator) => {
    // TODO make this not so ugly, maybe a custom hook?
    const BASE_CIRCLE_SIZE = 250;
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [windowHeight, setWindowHeight] = useState<number>(0);
    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const updateDimensions = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setWindowWidth(width);
        setWindowHeight(height);
    };

    const isMini =
        windowWidth <= MINI_TIMER_WINDOW_WIDTH_THRESHOLD ||
        windowHeight <= MINI_TIMER_WINDOW_HEIGHT_THRESHOLD;

    const totalDurationInSeconds = DurationFns.toSeconds(totalDuration);
    const totalOverflowInSeconds = DurationFns.toSeconds(totalOverflow);
    const overflowCircleSize =
        BASE_CIRCLE_SIZE +
        (BASE_CIRCLE_SIZE * totalOverflowInSeconds) / totalDurationInSeconds;

    return (
        <Wrapper
            style={{
                flexDirection: isMini ? 'row' : 'column',
                paddingTop: isMini ? spacings.s : spacings.l,
            }}>
            {!isMini ? (
                <CircleBackground size={BASE_CIRCLE_SIZE}>
                    <OverflowCircle
                        style={{
                            width: overflowCircleSize,
                            height: overflowCircleSize,
                        }}
                    />
                    <GrowingCircle
                        style={{
                            width: Math.min(
                                BASE_CIRCLE_SIZE,
                                (BASE_CIRCLE_SIZE - AVATAR_SIZE) *
                                    (isPast ? 1 : progress) +
                                    AVATAR_SIZE,
                            ),
                            height: Math.min(
                                BASE_CIRCLE_SIZE,
                                (BASE_CIRCLE_SIZE - AVATAR_SIZE) *
                                    (isPast ? 1 : progress) +
                                    AVATAR_SIZE,
                            ),
                        }}
                    />
                    {['discussion', 'feedback', 'evaluation'].includes(
                        currentStep.type.toString(),
                    ) ? ( //(currentStep?.type==='feedback'|| currentStep?.type==='evaluation' discussion)
                        <ICDiscussion
                            style={{
                                position: 'relative',
                                zIndex: 9,
                                borderRadius: '100%',
                                width: AVATAR_SIZE,
                                height: AVATAR_SIZE,
                            }}
                        />
                    ) : (
                        <ICUser
                            style={{
                                position: 'relative',
                                zIndex: 9,
                                borderRadius: '100%',
                                width: AVATAR_SIZE,
                                height: AVATAR_SIZE,
                            }}
                        />
                    )}
                </CircleBackground>
            ) : (
                <ICUser
                    style={{
                        position: 'relative',
                        zIndex: 9,
                        borderRadius: '100%',
                        width: AVATAR_SIZE / 1.3,
                        height: AVATAR_SIZE / 1.3,
                    }}
                />
            )}
            <Headline
                tertiary={isMini}
                color={isPast ? colors.Purple1 : colors.Black}
                mt={spacings.xxs}
                ml={isMini ? spacings.xxs : 0}>
                {isPast && '+'}
                {formatDurationCustom(difference)}
            </Headline>
        </Wrapper>
    );
};

export default CircleIndicator;
