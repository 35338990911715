import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import format from 'date-fns/format';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import {
    ActionWrapper,
    ListItem,
    TextWrapper,
    Wrapper
    } from './styled';
import { BsArrowDownCircle, BsArrowDownCircleFill } from 'react-icons/bs';
import { formatPrice } from '@/common/helpers';
import { Micro, Small } from '@/common/components/text';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const InvoiceListItem = ({
    invoice,
    isFirst,
    isLast,
}: {
    invoice: any;
    isFirst: boolean;
    isLast: boolean;
}) => {
    const { t } = useTranslation();
    const [downloadIsHovered, setDownloadIsHovered] = useState<boolean>(false);

    return (
        <Wrapper>
            <ListItem
                style={{
                    borderTopLeftRadius: isFirst ? borderRadius.m : 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: isLast ? borderRadius.m : 0,
                    borderBottomRightRadius: 0,
                }}
            >
                <TextWrapper>
                    <Row style={{ flex: 1 }}>
                        <Small bold mr={spacings.xxs}>
                            {format(new Date(invoice.created * 1000), 'PPP')}
                        </Small>
                    </Row>
                    <Row style={{ flex: 1 }}>
                        <Micro color={colors.Grey} style={{ width: '100%', textAlign: 'center' }}>
                            Invoice: {invoice.number}
                        </Micro>
                    </Row>
                    <Row style={{ flex: 1 }}>
                        <Small bold style={{ width: '100%', textAlign: 'right' }}>
                            {formatPrice(invoice.amount_paid / 100)}
                        </Small>
                    </Row>
                </TextWrapper>
            </ListItem>
            <ActionWrapper
                onMouseEnter={() => setDownloadIsHovered(true)}
                onMouseLeave={() => setDownloadIsHovered(false)}
                onClick={() => window.location.href = invoice.invoice_pdf}
                style={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: isFirst ? borderRadius.m : 0,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: isLast ? borderRadius.m : 0,
                }}
            >
                <Small bold mr={spacings.xxxs}>
                    {t('actions.download')}
                </Small>
                {downloadIsHovered
                    ? <BsArrowDownCircleFill
                        size={20} />
                    : <BsArrowDownCircle
                        size={20} />
                }
            </ActionWrapper>
        </Wrapper>
    );
};

export default InvoiceListItem;