import colors from '@/assets/constants/colors';
import styled from 'styled-components';

const Outer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 2px solid ${colors.Black};
    border-radius: 100%;
`;

const Inner = styled.div`
    width: 14px;
    height: 14px;
    background-color: ${colors.Black};
    border-radius: 100%;
`;

const RadioButton = ({ active }: { active: boolean; }) => {

    return (
        <Outer>
            {active && <Inner />}
        </Outer>
    );
};

export default RadioButton;