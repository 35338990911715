import {
    MINI_TIMER_WINDOW_HEIGHT_THRESHOLD,
    MINI_TIMER_WINDOW_WIDTH_THRESHOLD,
} from '@/assets/constants';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import {Body, Headline} from '@/common/components/text';
import {endMeeting, updateMeetingState} from '@/services/api/meeting-service';
import {add} from 'date-fns';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import CollectFeedbackPopup from './collect-feedback-popup';
import {Wrapper} from './styled';
import {IActions} from './types';

const Actions = ({
    steps,
    setSteps,
    setProgress,
    current,
    setCurrent,
    meeting,
    showEndMeeting,
}: IActions) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isLastStep = current === steps.length - 1;
    const nextIsSpeaker =
        !isLastStep &&
        steps[current + 1].item === steps[current].item &&
        typeof steps[current + 1].type === 'number';
    const nextIsDiscussion =
        !isLastStep &&
        steps[current + 1].item === steps[current].item &&
        steps[current + 1].type === 'discussion';
    const nextIsFeedback =
        !isLastStep &&
        steps[current + 1].item === steps[current].item &&
        steps[current + 1].type === 'feedback';
    const itemHasDiscussion =
        meeting.agenda.items[steps[current].item].discussion.question;
    const itemHasFeedback =
        meeting.agenda.items[steps[current].item].feedback.question;
    const [show, setShow] = useState<boolean>(false);
    const [showLastStepPopup, setShowLastStepPopup] = useState<boolean>(false);

    const nextStep = (count: number = 1) => {
        let _steps = steps;
        let _count = count;
        let _current = current;
        while (_count > 0) {
            _steps = _steps.map((step, index) => {
                if (index === _current) {
                    return {
                        ...step,
                        end: new Date(new Date().setMilliseconds(0)),
                    };
                }
                if (index > _current) {
                    return {
                        ...step,
                        start: add(new Date(new Date().setMilliseconds(0)), {
                            seconds: 1,
                        }),
                        end: add(
                            add(
                                new Date(new Date().setMilliseconds(0)),
                                step.duration,
                            ),
                            {},
                        ),
                    };
                }
                return step;
            });
            _count--;
            _current++;
        }
        setSteps(_steps);
        setProgress(0);
        setCurrent(current + count);
        if (meeting.externalId) {
            // TODO if check is ugly
            updateMeetingState(meeting.externalId, _steps, current + count);
        }
    };

    // TODO make this not so ugly, maybe a custom hook?
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [windowHeight, setWindowHeight] = useState<number>(0);
    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const updateDimensions = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setWindowWidth(width);
        setWindowHeight(height);
    };

    const isMini =
        windowWidth <= MINI_TIMER_WINDOW_WIDTH_THRESHOLD ||
        windowHeight <= MINI_TIMER_WINDOW_HEIGHT_THRESHOLD;

    return (
        <Wrapper
            style={{
                justifyContent: isMini ? 'flex-start' : 'center',
                marginTop: isMini ? spacings.xs : spacings.l,
            }}>
            {!isLastStep && (
                <Row style={{gap: spacings.xxxs}}>
                    <Button
                        small={isMini}
                        secondary
                        label={
                            nextIsSpeaker
                                ? t('timer.nextSpeaker')
                                : t('timer.nextAgendaItem')
                        }
                        onClick={() => {
                            const jumps =
                                itemHasDiscussion &&
                                itemHasFeedback &&
                                nextIsDiscussion
                                    ? 3
                                    : (itemHasFeedback && nextIsFeedback) ||
                                      (itemHasDiscussion && nextIsDiscussion)
                                    ? 2
                                    : 1;
                            if (current + jumps === steps.length - 1) {
                                // next step will be the last
                                setShowLastStepPopup(true);
                            } else {
                                nextStep(jumps);
                            }
                        }}
                    />
                    {steps[current].type === 'feedback' && !isMini && (
                        <Button
                            primary
                            label={t('timer.collectFeedback')}
                            onClick={() => setShow(true)}
                        />
                    )}
                    {show && (
                        <CollectFeedbackPopup
                            close={() => setShow(false)}
                            question={
                                meeting.agenda.items[steps[current].item]
                                    .feedback.question
                            }
                            end={steps[current].end}
                            next={() => {
                                if (current === steps.length - 2) {
                                    // next step will be the last
                                    setShowLastStepPopup(true);
                                } else {
                                    nextStep();
                                }
                            }}
                            meetingId={meeting.externalId}
                            itemIndex={steps[current].item}
                        />
                    )}
                </Row>
            )}
            {nextIsDiscussion && (
                <Button
                    small={isMini}
                    primary
                    label={t('timer.startDiscussion')}
                    onClick={() => {
                        nextStep();
                    }}
                />
            )}
            {nextIsFeedback && (
                <Button
                    small={isMini}
                    primary
                    label={t('timer.startFeedback')}
                    onClick={() => {
                        nextStep();
                    }}
                />
            )}
            {isLastStep && showEndMeeting && (
                <Button
                    small={isMini}
                    primary
                    label={t('timer.endMeeting')}
                    onClick={async () => {
                        if (!meeting.externalId) return;

                        const _steps = steps.map((step, index) => {
                            if (index === steps.length - 1) {
                                return {
                                    ...step,
                                    end: new Date(
                                        new Date().setMilliseconds(0),
                                    ),
                                };
                            }
                            return step;
                        });
                        const [data, error] = await endMeeting(
                            meeting.externalId,
                            _steps,
                        );
                        if (data) {
                            navigate(`/timer/summary/${meeting.externalId}`);
                        }
                    }}
                />
            )}
            {showLastStepPopup && (
                <Popup close={() => setShowLastStepPopup(false)}>
                    <Headline quaternary mb={spacings.s}>
                        {t('timer.lastStepDisclaimerTitle')}
                    </Headline>
                    <Body>{t('timer.lastStepDisclaimerText')}</Body>{' '}
                    <Row mt={spacings.m} style={{justifyContent: 'flex-end'}}>
                        <Button
                            mr={spacings.xxs}
                            tertiary
                            label={t('actions.close')}
                            onClick={() => setShowLastStepPopup(false)}
                        />
                        <Button
                            label={t('timer.goToEvaluation')}
                            onClick={() => {
                                setShowLastStepPopup(false);
                                const jumps =
                                    itemHasDiscussion &&
                                    itemHasFeedback &&
                                    nextIsDiscussion
                                        ? 3
                                        : (itemHasFeedback && nextIsFeedback) ||
                                          (itemHasDiscussion &&
                                              nextIsDiscussion)
                                        ? 2
                                        : 1;
                                nextStep(jumps);
                            }}
                        />
                    </Row>
                </Popup>
            )}
        </Wrapper>
    );
};

export default Actions;
