import BounceLoader from "react-spinners/BounceLoader";
import { default as Colors, default as colors } from '../../../assets/constants/colors';
import * as Helpers from '../../helpers';
import { IGenericElement, ISpacings } from '../../types';
import { Body, Small } from "../text";
import { Row, StyledButton } from './styled';
import { IButton } from './types';
import styled from 'styled-components';

export const LoaderWrapper = styled.div<{ loaderSize: number; }>`
    position: absolute;
    display:flex;
    justify-content:center;
    left: ${props => `calc(50% - ${props.loaderSize / 2}px)`};
`;

const Button = ({
    label,
    small,
    primary,
    secondary,
    tertiary,
    disabled,
    style,
    icon,
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    onClick,
    round,
    noPadding,
    iconRight,
    children,
    loading
}: IButton & ISpacings & IGenericElement) => {
    const loaderSize = small ? 12 : 20;
    const size = {
        height: small ? 40 : 48,
    } as const;
    const styles = {
        color: disabled ?
            Colors.Brown1 : (secondary || tertiary) ?
                Colors.Black : Colors.White,
        backgroundColor: disabled ?
            Colors.Beige1 : (secondary || tertiary) ?
                Colors.Transparent : Colors.Black,
        borderColor: secondary ?
            disabled
                ? Colors.Beige1
                : Colors.Black
            : Colors.Transparent
    } as const;

    return (
        <StyledButton
            onClick={onClick}
            style={{
                ...size,
                ...styles,
                ...Helpers.calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
                ...round ? { borderRadius: 100 } : {},
                ...noPadding ? { padding: 0 } : {},
                ...tertiary ? { borderWidth: 0 } : {},
                ...style,
            }}
            disabled={disabled}
        >
            <Row style={{ opacity: loading ? 0 : 1 }}>
                {icon && icon}
                {small
                    ? <Small bold>{label}</Small>
                    : <Body bold>{label}</Body>
                }
                {iconRight && iconRight}
                {children}
            </Row>
            {loading && (
                <LoaderWrapper loaderSize={loaderSize}>
                    <BounceLoader
                        loading={loading}
                        color={(secondary || tertiary) ? colors.Black : colors.White}
                        size={loaderSize}
                    />
                </LoaderWrapper>
            )}
        </StyledButton>
    );
}

export default Button;