import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import { ReactComponent as Logo } from '@/assets/images/calvah-logo.svg';
import Button from '@/common/components/button';
import Row from '@/common/components/row';
import { Small, Subheadline } from '@/common/components/text';
import { logout } from '@/services/api/auth-service';
import { useAppSelector } from '@/store/hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LOGO_HEIGHT, Wrapper } from './styled';

const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    border: 2px solid ${colors.Black};
    font-size: 0.8rem;
    font-weight: bold;
    margin-right: ${spacings.xxxs}px;
`;

const LicenceHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const organisation = useAppSelector(state => state.auth.organisation);
    const location = useLocation();
    const pathname = location.pathname;

    return (
        <Wrapper>
            <Row onClick={() => navigate('/')}>
                <Logo style={{ height: LOGO_HEIGHT, width: 65 }} />
                <Subheadline secondary>CALVAH</Subheadline>
            </Row>
            {
                !organisation?.active_subscription && <Row style={{ gap: spacings.s }}>
                    <Row>
                        <Circle style={{
                            background: pathname.includes('amount') ? colors.Black : colors.Transparent,
                            color: pathname.includes('amount') ? colors.White : colors.Black,
                        }}>
                            1
                        </Circle>
                        <Small>
                            {t('licence.title')}
                        </Small>
                    </Row>
                    <Row>
                        <Circle style={{
                            background: pathname.includes('billing') ? colors.Black : colors.Transparent,
                            color: pathname.includes('billing') ? colors.White : colors.Black,
                        }}>
                            2
                        </Circle>
                        <Small>
                            {t('licence.billing')}
                        </Small>
                    </Row>
                    <Row>
                        <Circle style={{
                            background: pathname.includes('payment') ? colors.Black : colors.Transparent,
                            color: pathname.includes('payment') ? colors.White : colors.Black,
                        }}>
                            3
                        </Circle>
                        <Small>
                            {t('licence.paymentDetails')}
                        </Small>
                    </Row>
                    <Row>
                        <Circle style={{
                            background: pathname.includes('summary') ? colors.Black : colors.Transparent,
                            color: pathname.includes('summary') ? colors.White : colors.Black,
                        }}>
                            4
                        </Circle>
                        <Small>
                            {t('licence.summary')}
                        </Small>
                    </Row>
                </Row>
            }
            <Row style={{ opacity: 1 }}>
                <Button
                    style={{
                        opacity: organisation?.active_subscription ? 0 : 1,
                        pointerEvents: organisation?.active_subscription ? 'none' : 'all',
                    }}
                    primary
                    small
                    round
                    label={t('auth.logout')}
                    onClick={async () => {
                        const [data, error] = await logout();
                        if (data) {
                            navigate('/login');
                        }
                    }}
                />
            </Row>
        </Wrapper >
    );
};

export default LicenceHeader;