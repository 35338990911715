import { parseStepsResponse } from '@/features/timer/timer-runner/helpers';
import { IStep } from '@/features/timer/timer-runner/types';
import store from '@/store';
import { CreateMeetingState, IMeetingState } from '@/store/create-meeting-slice/types';
import { intervalToDuration } from 'date-fns';
import * as DurationFns from 'duration-fns';
import i18next from 'i18next';
import { IOption, ISpacings } from './types';

export const isOrganizer = () => {
    const state = store.getState();
    const role = state.auth.user?.role;

    return role && (role === 'admin' || role === 'organiser');
};

export const calculateSpacings = ({
    mt, mr, mb, ml,
    pt, pr, pb, pl
}: ISpacings) => {
    return {
        ...mt ? { marginTop: mt } : {},
        ...mr ? { marginRight: mr } : {},
        ...mb ? { marginBottom: mb } : {},
        ...ml ? { marginLeft: ml } : {},
        ...pt ? { paddingTop: pt } : {},
        ...pr ? { paddingRight: pr } : {},
        ...pb ? { paddingBottom: pb } : {},
        ...pl ? { paddingLeft: pl } : {},
    };
};

export const getDurationAsSeconds = (duration: Duration) => {
    if (!duration) return 0;

    const hoursInSeconds = duration.hours ? duration.hours * 60 * 60 : 0;
    const minutesInSeconds = duration.minutes ? duration.minutes * 60 : 0;
    const secondsInSeconds = duration.seconds ? duration.seconds : 0;

    return hoursInSeconds + minutesInSeconds + secondsInSeconds;
};

export const addDurations = (d1: Duration, d2: Duration) => {
    return {
        years: d1.years && d2.years ? d1.years + d2.years : !d1.years && d2.years ? d2.years : d1.years && !d2.years ? d1.years : 0,
        months: d1.months && d2.months ? d1.months + d2.months : !d1.months && d2.months ? d2.months : d1.months && !d2.months ? d1.months : 0,
        weeks: d1.weeks && d2.weeks ? d1.weeks + d2.weeks : !d1.weeks && d2.weeks ? d2.weeks : d1.weeks && !d2.weeks ? d1.weeks : 0,
        days: d1.days && d2.days ? d1.days + d2.days : !d1.days && d2.days ? d2.days : d1.days && !d2.days ? d1.days : 0,
        hours: d1.hours && d2.hours ? d1.hours + d2.hours : !d1.hours && d2.hours ? d2.hours : d1.hours && !d2.hours ? d1.hours : 0,
        minutes: d1.minutes && d2.minutes ? d1.minutes + d2.minutes : !d1.minutes && d2.minutes ? d2.minutes : d1.minutes && !d2.minutes ? d1.minutes : 0,
        seconds: d1.seconds && d2.seconds ? d1.seconds + d2.seconds : !d1.seconds && d2.seconds ? d2.seconds : d1.seconds && !d2.seconds ? d1.seconds : 0,
    }
};

export const formatDurationCustom = (duration: Duration) => {
    const minutes = Math.floor(DurationFns.toMinutes(duration));
    const seconds = DurationFns.toSeconds(duration) - (minutes * 60);
    return `${String(minutes)}:${String(seconds).padStart(2, '0')}`;
};

export const formatDurationHM = (duration: Duration) => {
    const hours = Math.floor(DurationFns.toHours(duration));
    const minutes = Math.floor(DurationFns.toMinutes(duration)) - (hours * 60);
    return `${hours}h ${minutes}${i18next.t('common.minutes')}`;
};

export const formatDurationHMS = (duration: Duration) => {
    const hours = Math.floor(DurationFns.toHours(duration));
    const minutes = Math.floor(DurationFns.toMinutes(duration)) - (hours * 60);
    const seconds = DurationFns.toSeconds(duration) - (hours * 60 * 60) - (minutes * 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const divideDuration = (duration: Duration, divisor: number) => {
    const seconds = DurationFns.toSeconds(duration);
    const divided = Math.floor(seconds / divisor);
    return DurationFns.normalize({ seconds: divided });
};

export const isDurationEqual = (d1: Duration, d2: Duration) => {
    return DurationFns.toSeconds(d1) === DurationFns.toSeconds(d2);
};

export const truncate = (text: string, length: number = 25) => {
    if (text.length <= 25) {
        return text;
    } else {
        return text.substring(0, length) + '...';
    }
}

export const getPlannedTime = (meeting: CreateMeetingState & IMeetingState, attendee?: string) => {
    if (attendee) {
        const steps: IStep[] = parseStepsResponse(JSON.parse(meeting.meetingState.steps));
        return DurationFns.toSeconds(steps.reduce((acc, cur) => {
            if (
                typeof cur.type === 'number' &&
                meeting.agenda.items[cur.item].speakers[cur.type]?.attendee.email === attendee
            ) {
                return DurationFns.sum(acc, cur.duration);
            }
            return acc;
        }, {}));
    }

    return DurationFns.toSeconds(intervalToDuration({
        start: meeting.date?.start ? new Date(meeting.date.start) : new Date(),
        end: meeting.date?.end ? new Date(meeting.date.end) : new Date()
    }));
};

export const getExceededTime = (meeting: CreateMeetingState & IMeetingState, attendee?: string) => {
    const steps: IStep[] = parseStepsResponse(JSON.parse(meeting.meetingState.steps));
    return steps.reduce((acc, cur, index) => {
        if (attendee) {
            if (typeof cur.type === 'number') {
                if (meeting.agenda.items[cur.item].speakers[cur.type]?.attendee.email !== attendee) {
                    // Return of not the certain speaker
                    return acc;
                }
            } else {
                // Return if not a speaker step
                return acc;
            }
        }

        const actualDuration = intervalToDuration({ start: cur.start, end: cur.end });
        const stepDuration = cur.duration;
        const actualDurationInSeconds = DurationFns.toSeconds(actualDuration) + 1;
        const stepDurationInSeconds = DurationFns.toSeconds(stepDuration);

        if (actualDurationInSeconds - stepDurationInSeconds > 0) {
            return acc + actualDurationInSeconds - stepDurationInSeconds;
        }

        return acc;
    }, 0);
};

export const getSavedTime = (meeting: CreateMeetingState & IMeetingState, attendee?: string) => {
    const steps: IStep[] = parseStepsResponse(JSON.parse(meeting.meetingState.steps));
    return Math.abs(steps.reduce((acc, cur, index) => {
        if (attendee) {
            if (typeof cur.type === 'number') {
                if (meeting.agenda.items[cur.item].speakers[cur.type]?.attendee.email !== attendee) {
                    // Return of not the certain speaker
                    return acc;
                }
            } else {
                // Return if not a speaker step
                return acc;
            }
        }

        const actualDuration = intervalToDuration({ start: cur.start, end: cur.end });
        const stepDuration = cur.duration;
        const actualDurationInSeconds = DurationFns.toSeconds(actualDuration) + 1;
        const stepDurationInSeconds = DurationFns.toSeconds(stepDuration);

        if (actualDurationInSeconds - stepDurationInSeconds < 0) {
            return acc + actualDurationInSeconds - stepDurationInSeconds;
        }

        return acc;
    }, 0));
};

export const getUniqueArrayBy = (arr: any[], key: string) => {
    //@ts-ignore
    return [...new Map(arr.map(item => [item[key], item])).values()];
};

export const getPronouns = (): IOption[] => {
    return [
        { label: 'she/her', value: 'she/her' },
        { label: 'he/him', value: 'he/him' },
        { label: 'they/them', value: 'they/them' }
    ];
};

export const formatPrice = (price: number) => {
    return price.toFixed(2).toString().replace('.', ',') + ' €';
};

export const obscureText = (text: string) => {
    return text.split('').map((char, index) => {
        if (index === text.length - 1) return char;
        if (index === text.length - 2) return char;
        if (index === text.length - 3) return char;
        if (index === text.length - 4) return char;
        return '*';
    });
};

export const convertPHPDateStringToUTC = (date: string) => {
    return date.replace(' ', 'T') + 'Z';
};