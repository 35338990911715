import Row from "@/common/components/row";
import { Micro } from "@/common/components/text";
import { add, format, isFuture, sub } from "date-fns";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

const WeekPicker = ({
    start,
    setStart,
    end,
    setEnd,
}: {
    start: Date;
    setStart: (date: Date) => void;
    end: Date;
    setEnd: (date: Date) => void;
}) => {
    return (
        <Row style={{ justifyContent: 'space-between' }}>
            <Micro>
                {format(start, 'dd.')} - {format(end, 'dd.MM.yyyy')}

            </Micro>
            <Row>
                <BsArrowLeftShort
                    size={20}
                    onClick={() => {
                        setStart(sub(start, { weeks: 1 }));
                        setEnd(sub(end, { weeks: 1 }));
                    }}
                    style={{
                        cursor: 'pointer',
                    }}
                />
                <BsArrowRightShort
                    size={20}
                    onClick={() => {
                        setStart(add(start, { weeks: 1 }));
                        setEnd(add(end, { weeks: 1 }));
                    }}
                    style={{
                        cursor: 'pointer',
                        opacity: isFuture(end) ? 0.3 : 1,
                        pointerEvents: isFuture(end) ? 'none' : 'all',
                    }}
                />
            </Row>
        </Row>
    );
};

export default WeekPicker;