import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import CalvahPattern from '@/assets/images/calvah-pattern.png';
import Alert from '@/common/components/alert';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import { Body, Headline } from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import { HEADER_HEIGHT } from '@/router/header/styled';
import { forgotPassword } from '@/services/api/auth-service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const onClickReset = async () => {
        setShowError(false);
        setLoading(true);
        const [data, error] = await forgotPassword(email);
        setLoading(false);
        if (!error) {
            navigate('/login?alert=reset-init')
        } else {
            setShowError(true);
        }
    };

    return (
        <ContentWrapper style={{
        }}>
            <img
                src={CalvahPattern}
                style={{
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                }}
            />
            <div style={{ width: 500, marginBottom: spacings.xxl }}>
                <Button
                    mt={spacings.xxl}
                    tertiary
                    noPadding
                    small
                    icon={<FiArrowLeftCircle size={20} style={{ marginRight: spacings.xxxs }} />}
                    label={t('actions.back')}
                    onClick={() => navigate('/login')}
                />
                <Headline mt={spacings.xs}>
                    {t('auth.forgotPassword')}
                </Headline>
                <Body bold mt={spacings.xs}>
                    {t('auth.forgotPasswordSendLink')}
                </Body>
                <TextBox
                    mt={spacings.xxs}
                    label={t('auth.email')}
                    value={email}
                    setValue={setEmail}
                />
                {showError && <Alert
                    mt={spacings.xs}
                    bgColor={colors.Error}
                    text={t('common.serverError')}
                />}
                <Button
                    loading={loading}
                    mt={spacings.m}
                    label={t('auth.resetPassword')}
                    onClick={onClickReset}
                />
            </div>
        </ContentWrapper>
    );
}

export default ForgotPassword;