import colors from '../../../assets/constants/colors';
import spacings from '../../../assets/constants/spacings';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { calculateSpacings } from '@/common/helpers';
import { IAlert } from './types';
import { IGenericElement, ISpacings } from '../../types';
import { MdClose } from 'react-icons/md';
import { Small } from '../text';
import { Wrapper } from './styled';

const Alert = ({
    type,
    bgColor,
    text,
    style,
    close,
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    noIcon,
}: IAlert & IGenericElement & ISpacings) => {
    return (
        <Wrapper style={{
            ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
            ...{ backgroundColor: bgColor ? bgColor : type === 'Error' ? colors.Error : colors.Beige1 },
            ...style
        }}>
            {!noIcon && <AiOutlineInfoCircle
                color={colors.White}
                style={{ marginRight: spacings.s, flexShrink: 0, marginTop: 1 }}
                size={22} />}
            <Small
                color={colors.White}
                mr={spacings.s}
            >
                {text}
            </Small>
            {close && <MdClose
                onClick={close}
                color={colors.White}
                size={22}
                style={{
                    position: 'absolute',
                    top: spacings.xxxs,
                    right: spacings.xxxs,
                    cursor: 'pointer',
                }} />}
        </Wrapper>
    );
}

export default Alert;