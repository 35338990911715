import * as Text from '../../../../../common/components/text';
import BorderRadius from '../../../../../assets/constants/border-radius';
import Colors from '../../../../../assets/constants/colors';
import DurationPicker from '../../duration-picker';
import Spacings from '../../../../../assets/constants/spacings';
import {
    CloseIconWrapper,
    DurationWrapper,
    ListItem,
    NameWrapper,
} from './styled';
import {FaUser} from 'react-icons/fa';
import {
    IAttendee,
    ISpeaker,
} from '../../../../../store/create-meeting-slice/types';
import {ISpeakerList} from './types';
import {nanoid} from 'nanoid';
import {RiCloseCircleLine} from 'react-icons/ri';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '@/store/hooks';
import {setSpeakerDuration} from '@/store/create-meeting-slice';

const SpeakerList = ({removeSpeaker, index, errors}: ISpeakerList) => {
    const dispatch = useAppDispatch();
    const speakers = useAppSelector(
        state => state.createMeeting.agenda.items[index].speakers,
    );

    const editSpeakerDuration = (duration: Duration, attendee?: IAttendee) => {
        if (!attendee) return;
        dispatch(
            setSpeakerDuration({
                attendee: attendee,
                duration: duration,
                index: index,
            }),
        );
    };

    return (
        <div style={{marginTop: Spacings.xs}}>
            {speakers.map((speaker: ISpeaker, index: number) => {
                return (
                    <ListItem
                        key={`${speaker.attendee.email}`}
                        style={{
                            borderTopLeftRadius:
                                index === 0 ? BorderRadius.m : 0,
                            borderTopRightRadius:
                                index === 0 ? BorderRadius.m : 0,
                            borderBottomLeftRadius:
                                index === speakers.length - 1
                                    ? BorderRadius.m
                                    : 0,
                            borderBottomRightRadius:
                                index === speakers.length - 1
                                    ? BorderRadius.m
                                    : 0,
                        }}>
                        <NameWrapper>
                            <FaUser
                                style={{
                                    marginRight: Spacings.xxs,
                                    padding: Spacings.xxxs,
                                    borderRadius: 100,
                                    backgroundColor: Colors.Mustard3,
                                }}
                            />
                            <Text.Small bold mr={Spacings.xxs}>
                                {speaker.attendee.name}{' '}
                                {speaker.attendee.pronouns &&
                                    `(${speaker.attendee.pronouns})`}
                            </Text.Small>
                        </NameWrapper>
                        <DurationWrapper>
                            <DurationPicker
                                error={errors?.speakerTime}
                                noIcon
                                minutes={speaker.duration.minutes || 0}
                                seconds={speaker.duration.seconds || 0}
                                speaker={speaker}
                                editDuration={editSpeakerDuration}
                            />
                        </DurationWrapper>
                        <CloseIconWrapper>
                            <RiCloseCircleLine
                                size={24}
                                onClick={() => removeSpeaker(speaker.attendee)}
                                style={{cursor: 'pointer'}}
                            />
                        </CloseIconWrapper>
                    </ListItem>
                );
            })}
        </div>
    );
};

export default SpeakerList;
