import {
    MINI_TIMER_WINDOW_HEIGHT_THRESHOLD,
    MINI_TIMER_WINDOW_WIDTH_THRESHOLD,
} from '@/assets/constants';
import ContentWrapper from '@/common/components/content-wrapper';
import {isOrganizer} from '@/common/helpers';
import {getMeeting, updateMeetingState} from '@/services/api/meeting-service';
import {IMeetingUpdatedStateResponse} from '@/services/api/meeting-service/types';
import {
    CreateMeetingState,
    IMeetingState,
} from '@/store/create-meeting-slice/types';
import {useAppSelector} from '@/store/hooks';
import audio10Seconds from '@sounds/Calvah_10s.mp3';
import audio60Seconds from '@sounds/Calvah_60s.mp3';
import {intervalToDuration} from 'date-fns';
import isPast from 'date-fns/isPast';
import * as DurationFns from 'duration-fns';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Sound from 'react-sound';
import Actions from './actions';
import CircleIndicator from './circle-indicator';
import {parseMeetingSteps, parseStepsResponse} from './helpers';
import StepInfo from './step-info';
import {Wrapper} from './styled';
import Timeline from './timeline';
import {IStep} from './types';

const TimerRunner = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const meetingId = id;

    const user = useAppSelector(state => state.auth.user);
    const [meeting, setMeeting] = useState<
        CreateMeetingState & IMeetingState
    >();
    const [totalDuration, setTotalDuration] = useState<Duration>({});
    const [initialSteps, setInitialSteps] = useState<IStep[]>();

    const [steps, setSteps] = useState<IStep[]>();
    const [current, setCurrent] = useState<number>(0);

    const [difference, setDifference] = useState<Duration>({});
    const [timer, setTimer] = useState<any>();
    const [progress, setProgress] = useState<number>(0);
    const [overflow, setOverflow] = useState<Duration>({});
    const [showEndMeeting, setShowEndMeeting] = useState<boolean>(false);
    const [play10SecondsSound, setPlay10SecondsSound] =
        useState<boolean>(false);
    const [play60SecondsSound, setPlay60SecondsSound] =
        useState<boolean>(false);

    const hasEditRights =
        user?.role === 'admin' || user?.id === meeting?.userId;

    useEffect(() => {
        if (!steps) return;

        clearInterval(timer);
        const t = setInterval(() => {
            setDifference(
                intervalToDuration({
                    start: new Date(new Date().setMilliseconds(0)),
                    end: steps[current].end,
                }),
            );
        }, 250); // TODO adjust for experience
        setTimer(t);
        stopAudio();
    }, [current, steps]);

    useEffect(() => {
        if (!steps) return;
        setProgress(
            1 -
                DurationFns.toSeconds(difference) /
                    DurationFns.toSeconds(steps[current].duration),
        );
    }, [difference]);

    useEffect(() => {
        if (!steps) return;

        setOverflow(
            steps.reduce((acc, cur, index) => {
                const actualDuration = intervalToDuration({
                    start: cur.start,
                    end: cur.end,
                });
                const stepDuration = DurationFns.subtract(cur.duration, {
                    seconds: 1,
                });
                const actualDurationInSeconds =
                    DurationFns.toSeconds(actualDuration);
                const stepDurationInSeconds =
                    DurationFns.toSeconds(stepDuration);
                return DurationFns.sum(
                    acc,
                    actualDurationInSeconds - stepDurationInSeconds < 0
                        ? {}
                        : DurationFns.subtract(actualDuration, stepDuration),
                );
            }, {}),
        );
    }, [steps]);

    useEffect(() => {
        const fetchMeeting = async () => {
            if (!meetingId) return;

            const [data, error] = await getMeeting(meetingId);
            if (data) {
                setMeeting(data);
                setTotalDuration(
                    intervalToDuration({
                        start: new Date(data.date.start),
                        end: new Date(data.date.end),
                    }),
                );
            }
            // TODO refactor this shit
            if (data.meetingState.state === 'upcoming' && isOrganizer()) {
                // Start the meeting
                const now = new Date(new Date().setMilliseconds(0));
                const steps = parseMeetingSteps(data, now).filter(
                    step => DurationFns.toSeconds(step.duration) > 0,
                );
                setInitialSteps(steps);
                setSteps(steps);
                setDifference(steps[current].duration);
                const [updateData, updateError] = await updateMeetingState(
                    meetingId,
                    steps,
                    current,
                    steps,
                );
            }
            if (data.meetingState.state === 'running') {
                // Init a running meeting
                const _initialSteps = parseStepsResponse(
                    JSON.parse(data.meetingState.initial_steps),
                );
                const _steps = parseStepsResponse(
                    JSON.parse(data.meetingState.steps),
                );
                setInitialSteps(_initialSteps);
                setSteps(_steps);
                setDifference(_steps[current].duration);
                setCurrent(data.meetingState.current_step);
            }
        };
        fetchMeeting();

        //@ts-ignore
        Echo.channel(`meeting.${meetingId}`).listen(
            'MeetingStateUpdated',
            (e: IMeetingUpdatedStateResponse) => {
                if (e.state === 'past') {
                    navigate(`/timer/summary/${meetingId}`);
                }

                // TODO check why backend is returning parsed JSON data sometimes
                const _initialSteps = parseStepsResponse(
                    typeof e.initial_steps === 'string'
                        ? JSON.parse(e.initial_steps)
                        : e.initial_steps,
                );
                const _steps = parseStepsResponse(
                    typeof e.steps === 'string' ? JSON.parse(e.steps) : e.steps,
                );
                setCurrent(e.current_step);
                setInitialSteps(_initialSteps);
                setSteps(_steps);
            },
        );
    }, []);

    useEffect(() => {
        const seconds = DurationFns.toSeconds(difference);
        if (seconds === 60 && !stepIsTrulyPast) {
            setPlay60SecondsSound(true);
        }
        if (seconds === 10 && !stepIsTrulyPast) {
            setPlay10SecondsSound(true);
        }
    }, [difference]);

    const stepIsTrulyPast =
        !!steps &&
        DurationFns.toSeconds(difference) !== 0 &&
        isPast(steps[current].end);
    const totalOverflow = DurationFns.sum(
        overflow,
        stepIsTrulyPast ? difference : {},
    );

    const stopAudio = () => {
        setPlay60SecondsSound(false);
        setPlay10SecondsSound(false);
    };

    // TODO make this not so ugly, maybe a custom hook?
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [windowHeight, setWindowHeight] = useState<number>(0);
    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const updateDimensions = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setWindowWidth(width);
        setWindowHeight(height);
    };

    const isMini =
        windowWidth <= MINI_TIMER_WINDOW_WIDTH_THRESHOLD ||
        windowHeight <= MINI_TIMER_WINDOW_HEIGHT_THRESHOLD;

    return (
        <ContentWrapper>
            {play10SecondsSound && (
                <Sound
                    url={audio10Seconds}
                    playStatus={Sound.status.PLAYING}
                    onFinishedPlaying={stopAudio}
                />
            )}
            {play60SecondsSound && (
                <Sound
                    url={audio60Seconds}
                    playStatus={Sound.status.PLAYING}
                    onFinishedPlaying={stopAudio}
                />
            )}
            {meeting && initialSteps && steps && (
                <Wrapper>
                    <div>
                        <CircleIndicator
                            currentStep={steps[current]}
                            totalOverflow={totalOverflow}
                            totalDuration={totalDuration}
                            isPast={stepIsTrulyPast}
                            difference={difference}
                            progress={progress}
                        />
                        <>
                            <StepInfo
                                meeting={meeting}
                                currentStep={steps[current]}
                                showEndMeeting={showEndMeeting}
                                setShowEndMeeting={setShowEndMeeting}
                            />
                            {isOrganizer() && hasEditRights && (
                                <Actions
                                    steps={steps}
                                    setSteps={setSteps}
                                    current={current}
                                    setCurrent={setCurrent}
                                    setProgress={setProgress}
                                    meeting={meeting}
                                    showEndMeeting={showEndMeeting}
                                />
                            )}
                        </>
                    </div>
                    {!isMini && (
                        <Timeline
                            meeting={meeting}
                            initialSteps={initialSteps}
                            current={current}
                            isPast={stepIsTrulyPast}
                            progress={progress}
                            totalDuration={totalDuration}
                            totalOverflow={totalOverflow}
                        />
                    )}
                </Wrapper>
            )}
        </ContentWrapper>
    );
};

export default TimerRunner;
