import Button from '@/common/components/button';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import {Body, Headline} from '@/common/components/text';
import {IDeletePopup} from './types';
import {IPopup} from '@/common/types';
import {removeUser} from '@/store/general-slice';
import {removeUserFromOrganisation} from '@/services/api/organisation-service';
import {useAppDispatch} from '@/store/hooks';
import {useTranslation} from 'react-i18next';

const DeleteUserPopup = ({close, user}: IPopup & IDeletePopup) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const onClickDelete = async () => {
        const [data, error] = await removeUserFromOrganisation(user.email);
        if (!error) {
            dispatch(removeUser(user.email));
            close();
        }
    };

    return (
        <Popup close={close}>
            <Headline quaternary mb={spacings.s}>
                {t('create.stepAttendees.deleteProfile')}
            </Headline>
            <Body mt={spacings.s}>
                {t('attendees.sureToDelete', {
                    name: user.name,
                    email: user.email,
                })}
            </Body>
            <Row mt={spacings.m} style={{justifyContent: 'flex-end'}}>
                <Button
                    mr={spacings.xxs}
                    tertiary
                    label={t('actions.noCancel')}
                    onClick={close}
                />
                <Button
                    disabled={!user.email}
                    label={t('actions.yesDelete')}
                    onClick={onClickDelete}
                />
            </Row>
        </Popup>
    );
};

export default DeleteUserPopup;
