import * as Text from '../../../common/components/text';
import Colors from '../../../assets/constants/colors';
import Spacings from '../../../assets/constants/spacings';
import TextBoxBorderless from '../../../common/components/textbox-borderless';
import { setTitle } from '../../../store/create-meeting-slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';

const Title = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const title = useAppSelector((state) => state.createMeeting.title);
    const setValue = (value: string) => dispatch(setTitle(value));

    const titleLength = title? title.length : 0;

    return (
        <div>
            <div>
                <TextBoxBorderless
                    value={title}
                    setValue={(value)=>{titleLength<50?setValue(value):setValue(value.substring(0,50))}}
                    placeholder={t('create.stepTitle.placeholder')}
                    size="large" 
                    autoGrow
                    />
            </div>

            <div style={{ marginLeft: 32, marginTop: Spacings.xxs }}>
                <Text.Small color={Colors.Brown1}>
                    {t('create.stepTitle.charactersLeft', { amount: 50 - titleLength })}
                </Text.Small>
            </div>
        </div>
    );
}

export default Title;