import Colors from '../../../assets/constants/colors';
import Spacings from '../../../assets/constants/spacings';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from '../sidebar/styled';

export const Wrapper = styled.div`
    bottom: 0;
    right: 0;
    position: fixed;
    background-color: ${Colors.Beige2};
    width: calc(100% - ${SIDEBAR_WIDTH}px);
    box-sizing: border-box;
    padding: ${Spacings.xs}px ${Spacings.l}px;
    display: flex;
    justify-content: flex-end;
`;

export const NavbarContent = styled.div`
    flex: 1;
    margin-right: ${Spacings.xs}px;
`;
