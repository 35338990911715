import colors from '../../../assets/constants/colors';
import styled from 'styled-components';

export const StyledInput = styled.textarea<{ error: boolean | undefined }>`
    font-family: Jost;
    background-color: transparent;
    border: none;
    resize: none;
    flex: 1;
    padding: 0;

    &:focus {
        outline: none;
    }

    &::-webkit-input-placeholder {
        ${props => props.error && `color: ${colors.Error};`}
    }
`;