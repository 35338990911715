import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const ToolbarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid ${colors.Black};
    padding-bottom: ${spacings.xxxs}px;
    margin-bottom: ${spacings.xs}px;
`;

export const ToolbarArrow = styled.div`
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${colors.Black};
`;

export const SpeakerBar = styled.div`
    height: 20px;
    width: 100%;
    border-radius: ${borderRadius.s}px;
    background-color: ${colors.Mustard2};
`;

export const ColoredBox = styled.div<{ color: string; }>`
    border-radius: ${borderRadius.m}px;
    padding: ${spacings.m}px;
    background-color: ${props => props.color};
`;

export const Pill = styled.div<{ color?: string; }>`
    display: flex;
    align-items: center;
    background-color: ${props => props.color ? props.color : colors.Teal3};
    border-radius: 50px;
    padding-top: 2px;
    padding-right: ${spacings.xxs}px;
    padding-bottom: 2px;
    padding-left: ${spacings.xxs}px;

    p {
        white-space: nowrap;
    }
`;