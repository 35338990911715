import { useEffect, useRef, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import Colors from '../../../assets/constants/colors';
import Spacings from '../../../assets/constants/spacings';
import { calculateSpacings } from '../../helpers';
import { ISpacings } from '../../types';
import * as Text from '../text';
import { StyledInput } from './styled';
import { ITextBoxBorderless } from './types';

const TextBoxBorderless = ({
    autoGrow,
    noLinebreak,
    label,
    disabled,
    error,
    size,
    style,
    value,
    placeholder,
    noIcon,
    bold,
    setValue,
    onClick,
    onFocus,
    onBlur,
    mt, mr, mb, ml,
    pt, pr, pb, pl
}: ITextBoxBorderless & ISpacings) => {

    const ref = useRef(null);

    useEffect(() => {
        if (!ref || !ref.current) return;
        if (!autoGrow) return;
        //@ts-ignore
        ref.current.style.height = "81px";
        //@ts-ignore
        const scrollHeight = ref.current.scrollHeight;
        //@ts-ignore
        ref.current.style.height = scrollHeight + "px";
    }, [value]);

    return (
        <div style={{
            ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
            ...{ pointerEvents: disabled ? 'none' : 'all' },
        }}>
            {label && <Text.Small bold mb={Spacings.xxxs}>
                {label}
            </Text.Small>}
            <div style={{
                display: 'flex',
                alignItems: 'flex-start',
                ...style,
            }}>
                {!noIcon && <MdEdit
                    color={error ? Colors.Error : Colors.Brown1}
                    style={{
                        height: 16,
                        width: 16,
                        marginTop: size === 'large' ? 24 : size === 'small' ? 4 : 10,
                        marginRight: Spacings.xxs,
                    }} />}
                <StyledInput
                    ref={ref}
                    style={{
                        overflow: 'hidden',
                        fontFamily: size === 'small' ? 'NotoSans' : 'Jost',
                        fontWeight: (value && (size !== 'small' || bold)) ? 'bold' : 'normal',
                        fontSize: size === 'large' ? '3.375rem' : size === 'small' ? '1rem' : '1.625rem',
                        lineHeight: 1.5,
                        height: noLinebreak? (size === 'large' ? 81 : size === 'small' ? 24 : 39): 'auto', //TODO repalce dirty fix (textarea doesn't fit parent on firefox)
                    }}
                    value={value}
                    placeholder={placeholder}
                    error={error}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onClick={onClick}
                    onChange={event => {
                        if (onClick) {
                            return;
                        }
                        if(noLinebreak){
                            setValue(event.target.value.replace(/(\r\n|\n|\r)/gm, ""));
                        }else{
                            setValue(event.target.value);
                        }
                    }}
                    rows={1}
                />
            </div>
        </div>
    );
}

export default TextBoxBorderless;