import Colors from '../../../../assets/constants/colors';
import Spacings from '../../../../assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const ListItem = styled.div`
    flex: 1;
    position: relative;
    background-color: ${Colors.White};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -1px;
    overflow: hidden;
    border: 1px solid ${Colors.Beige1};
    cursor: pointer;

    &:hover {
        background-color: ${Colors.Mustard3};
    }
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${Spacings.xxs}px;
    width: 100%;
`;

export const CloseIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.Beige3};
    width: 65px;
    margin-left: -1px;
    margin-bottom: -1px;
    border: 1px solid ${Colors.Beige1};
    cursor: pointer;

    &:hover {
        background-color: ${Colors.Mustard3};
    }
`;