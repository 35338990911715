import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Row from '@/common/components/row';
import { Micro, Small } from '@/common/components/text';
import { IAnswer } from '@/features/timer/timer-runner/step-info/questionnaire/types';
import { getEvaluationByUser } from '@/services/api/meeting-service';
import { IOrganiser } from '@/store/create-meeting-slice/types';
import { ReactComponent as ICUser } from '@icons/ic-user.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowRightCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import PartialPieChart from './partial-pie-chart';
import { ListItem, NameWrapper, Wrapper } from './styled';

const OrganiserListItem = ({
    organiser,
    isFirst,
    isLast,
}: {
    organiser: IOrganiser
    isFirst: boolean;
    isLast: boolean;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [answers, setAnswers] = useState<IAnswer[]>([]);

    useEffect(() => {
        const fetchEvaluation = async () => {
            if (!organiser.id) return;
            const [data, error] = await getEvaluationByUser(organiser.id);
            if (data) {
                const answers: IAnswer[] = data.map((entry: any) => {return {'question':entry.question_id, 'answer':entry.answer}})
                setAnswers(answers);
            }
        };
        fetchEvaluation();
    }, []);

    // Answer 1 = 100%, answer 2 = 50%, answer 3 = 0% satisfaction
    const percentage = answers.reduce((acc, cur) => acc + (cur.answer === 1 ? 100 : cur.answer === 2 ? 50 : 0), 0) / answers.length;

    return (
        <Wrapper>
            <ListItem
                onClick={() => {
                    if (isNaN(percentage)) {
                        return;
                    }
                    navigate(`/statistics/organiser/${organiser.id}`);
                }}
                style={{
                    borderTopLeftRadius: isFirst ? borderRadius.m : 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: isLast ? borderRadius.m : 0,
                    borderBottomRightRadius: 0,
                }}
            >
                <NameWrapper>
                    <Row>
                        <ICUser style={{
                            marginRight: spacings.s,
                            width: 40,
                            height: 40,
                        }} />
                        <Small bold mr={spacings.xxs}>
                            {organiser.name} {organiser.pronouns && `(${organiser.pronouns})`}
                        </Small>
                        <Small>
                            {organiser.email}
                        </Small>
                    </Row>
                    {isNaN(percentage) ? (
                        <Micro color={colors.Brown1}>
                            {t('statistics.noDataYet')}
                        </Micro>
                    ) : (
                        <Row>
                            <PartialPieChart percentage={percentage} />
                            <Small
                                ml={spacings.xxs}
                                mr={spacings.xxxs}
                            >
                                <b>{Math.round(percentage)}%</b> {t('statistics.happy')}
                            </Small>
                            <Button
                                tertiary
                                small
                                noPadding
                                iconRight={<FiArrowRightCircle size={20} style={{ marginLeft: spacings.xxxs }} />}
                                label={''}
                            />
                        </Row>
                    )}
                </NameWrapper>
            </ListItem>
        </Wrapper>
    );
};

export default OrganiserListItem;