import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    text-align: left;
    z-index: 99;
    position: absolute;
    left: calc(100% - ${spacings.s}px);
    top: ${spacings.m}px;
    border-radius: ${borderRadius.s}px;
    border: 1px solid ${colors.Beige1};
    width: 200px;
`;

export const BorderBottom = styled.div`
    position: absolute;
    bottom: -7px;
    left: -1px;
    z-index: -1;
    width: 100%;
    height: 10px;
    background-color: ${colors.Beige2};
    border: 1px solid ${colors.Beige2};
    border-radius: ${borderRadius.s}px;
`;

export const MenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacings.xxs}px;
    background-color: ${colors.Beige3};
    cursor: pointer;

    &:hover {
        background-color: ${colors.Mustard3};
    }
`;