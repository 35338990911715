import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import 'react-toggle/style.css';
import { default as Spacings } from '../../../assets/constants/spacings';
import Checkbox from '../../../common/components/checkbox';
import * as Text from '../../../common/components/text';
import TextBoxBorderless from '../../../common/components/textbox-borderless';
import { setAgendaGoal, setAgendaLinks } from '../../../store/create-meeting-slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import AgendaItem from './agenda-item';
import { SeparatorLine } from './agenda-item/styled';
import { Box, Wrapper } from './styled';
import { IAgenda } from './types';

const Agenda = ({ itemRefs }: IAgenda) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const goalError = useAppSelector((state) => state.createMeeting.agenda.goalError);
    const items = useAppSelector((state) => state.createMeeting.agenda.items);
    const goal = useAppSelector((state) => state.createMeeting.agenda.goal);
    const links = useAppSelector((state) => state.createMeeting.agenda.links);
    const [hasLinks, setHasLinks] = useState<boolean>(!!links);
    const goalsRef: React.RefObject<HTMLDivElement> = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [yPositions, setYPositions] = useState<number[]>([]);

    useEffect(() => {
        if (hasLinks) return;
        dispatch(setAgendaLinks(undefined));
    }, [hasLinks]);

    useEffect(() => {
        setYPositions(itemRefs.map(ref => {
            if (ref && ref.current && ref.current.getBoundingClientRect()) {
                return ref.current.getBoundingClientRect().y;
            } else {
                return 0;
            }
        }));
        const goal: string | null = searchParams.get('goal');
        if (goal) {
            goalsRef.current?.scrollIntoView();
        }
        const stepIndex: string | null = searchParams.get('step');
        if (stepIndex && Number(stepIndex) < itemRefs.length) {
            if (itemRefs[Number(stepIndex)]) {
                itemRefs[Number(stepIndex)].current?.scrollIntoView();
            }
        }
    }, []);

    return (
        <Wrapper>
            <Text.Headline primary>
                {t('create.stepAgenda.title')}
            </Text.Headline>
            <Text.Subheadline quaternary mb={Spacings.s}>
                {t('create.stepAgenda.subtitle')}
            </Text.Subheadline>
            <Box ref={goalsRef}>
                <TextBoxBorderless
                    error={goalError}
                    label={t('create.stepAgenda.goalsTitle')}
                    placeholder={t('create.stepAgenda.goalsPlaceholder')}
                    size="small"
                    value={goal}
                    setValue={(value: string) => dispatch(setAgendaGoal(value))} />
                <SeparatorLine />
                <Checkbox
                    mb={hasLinks ? 0 : Spacings.xxs}
                    label={t('create.stepAgenda.agendaLinks')}
                    checked={hasLinks}
                    setChecked={setHasLinks}
                    bold optional small />
                {hasLinks && <>
                    <TextBoxBorderless
                        mt={Spacings.xs}
                        mb={Spacings.xxs}
                        placeholder={t('create.stepAgenda.agendaLinksPlaceholder')}
                        size="small"
                        value={links}
                        setValue={(value: string) => dispatch(setAgendaLinks(value))} />
                </>}
            </Box>
            <Text.Headline tertiary mt={Spacings.s} mb={Spacings.s}>
                {t('create.stepAgenda.structureTitle')}
            </Text.Headline>
            {items.map((item, index) => <AgendaItem
                yPositions={yPositions}
                itemRef={itemRefs[index]}
                itemRefs={itemRefs}
                key={`${item.id}-${yPositions[index]}`}
                item={item}
                index={index}
                itemCount={items.length}
                isFirst={index === 0}
                isLast={index === items.length - 1} />)
            }
        </Wrapper >
    );
}

export default Agenda;