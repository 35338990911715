import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateMeetingState, IMeetingState, IPaginationObject } from '../create-meeting-slice/types';
import { MeetingsState } from './types';

const initialState = {
    meetings: [],
    pastMeetings: undefined,
    upcomingMeetings: undefined,
} as MeetingsState;

const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: initialState,
    reducers: {
        resetMeetingsState: () => {
            return initialState;
        },
        saveMeetings: (state, action: PayloadAction<(CreateMeetingState & IMeetingState)[]>) => {
            return {
                ...state,
                meetings: action.payload,
            };
        },
        setPastMeetings: (state, action: PayloadAction<IPaginationObject | undefined>) => {
            return {
                ...state,
                pastMeetings: action.payload,
            };
        },
        setUpcomingMeetings: (state, action: PayloadAction<IPaginationObject | undefined>) => {
            return {
                ...state,
                upcomingMeetings: action.payload,
            };
        },
        removeMeeting: (state, action: PayloadAction<CreateMeetingState>) => {
            const meetings = state.meetings.filter(meeting => meeting.externalId !== action.payload.externalId);
            return {
                ...state,
                meetings: meetings,
            };
        },
    }
})

export const {
    resetMeetingsState,
    saveMeetings,
    setPastMeetings,
    setUpcomingMeetings,
    removeMeeting,
} = meetingsSlice.actions;
export default meetingsSlice;