import store from '@/store';
import { resetAuthState } from '@/store/auth-slice';
import { resetCreateMeetingState } from '@/store/create-meeting-slice';
import { IAttendee } from '@/store/create-meeting-slice/types';
import { resetGeneralState } from '@/store/general-slice';
import { resetLicenceState } from '@/store/licence-slice';
import { resetMeetingsState } from '@/store/meetings-slice';
import axios from 'axios';
import { handleApiError } from '../helpers';
import { IRegisterData } from './types';

const API_BASE = process.env.REACT_APP_API_BASE;
//const API_BASE = 'http://localhost:8000';

export const login = async (email: string, password: string) => {
    const endpoint = `${API_BASE}/login`;
    try {
        const cookies = await axios.get(`${API_BASE}/sanctum/csrf-cookie`);
        const result = await axios.post(endpoint, { email, password });

        if (result.status === 200) {
            // TODO initialise date on successful login?
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return [null, error.response];
    }
};

export const logout = async () => {
    const endpoint = `${API_BASE}/logout`;

    try {
        const result = await axios.post(endpoint);
        if (result.status === 204) {
            // Reset all redux stores on successful logout
            store.dispatch(resetAuthState());
            store.dispatch(resetGeneralState());
            store.dispatch(resetLicenceState());
            store.dispatch(resetMeetingsState());
            store.dispatch(resetCreateMeetingState());
            return [result, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return [null, error.response];
    }
};

export const register = async (data: IRegisterData, organisationId?: number) => {
    const endpoint = `${API_BASE}/register`;
    try {
        const cookies = await axios.get(`${API_BASE}/sanctum/csrf-cookie`);
        const result = await axios.post(endpoint, {
            ...data,
            ...organisationId ? { organisation_id: organisationId } : {}
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return [null, error.response];
    }
};

export const deleteSelf = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/self?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const cookies = await axios.get(`${API_BASE}/sanctum/csrf-cookie`);
        const result = await axios.delete(endpoint);
        if (result.status === 204) {            
            store.dispatch(resetAuthState());
            store.dispatch(resetGeneralState());
            store.dispatch(resetLicenceState());
            store.dispatch(resetMeetingsState());
            store.dispatch(resetCreateMeetingState());
            return [result, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return [null, error.response];
    }
};

export const getInviteData = async (id: string) => {
    const endpoint = `${API_BASE}/inviteData?invite_token=${id}`;

    try {
        const cookies = await axios.get(`${API_BASE}/sanctum/csrf-cookie`);
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return [null, error.response];
    }
};

export const getSelf = async () => {
    const state = store.getState();
    const endpoint = `${API_BASE}/self?organisation_id=${state.auth.user?.organisatonId}`;

    try {
        const result = await axios.get(endpoint);
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const selfEdit = async (data: IAttendee) => {
    const state = store.getState();
    const endpoint = `${API_BASE}/self`;

    try {
        const result = await axios.post(endpoint, {
            ...data,
            organisation_id: state.auth.user?.organisatonId
        });
        if (result.status === 200) {
            return [result.data, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return handleApiError(error);
    }
};

export const forgotPassword = async (email: string) => {
    const endpoint = `${API_BASE}/forgot-password`;

    try {
        const result = await axios.post(endpoint, { email });
        if (result.status === 204) {
            return [result, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return [null, error.response];
    }
};

export const resetPassword = async (email: string, password: string, confirmPassword: string, token: string) => {
    const endpoint = `${API_BASE}/reset-password`;

    try {
        const result = await axios.post(endpoint, { email, password, token, password_confirmation: confirmPassword });
        if (result.status === 204) {
            return [result, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return [null, error.response];
    }
};


export const changePassword = async (oldPassword: string, password: string, confirmPassword: string) => {
    const endpoint = `${API_BASE}/user/password`;

    try {
        const result = await axios.put(endpoint, {
            current_password: oldPassword,
            password: password,
            password_confirmation: confirmPassword
        });
        if (result.status === 204) {
            return [result, null];
        } else {
            return [null, result.data];
        }
    } catch (error: any) {
        return [null, error.response];
    }
};