import * as Text from '../../../../common/components/text';
import Colors from '../../../../assets/constants/colors';
import Spacings from '../../../../assets/constants/spacings';
import {calculateSpacings} from '../../../../common/helpers';
import {IAttendee} from '../../../../store/create-meeting-slice/types';
import {IDurationPicker} from './types';
import {ISpacings} from '../../../../common/types';
import {MdEdit} from 'react-icons/md';
import {StyledInput} from './styled';
import {useDebouncedCallback} from 'use-debounce';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const DurationPicker = ({
    disabled,
    error,
    minutes,
    seconds,
    style,
    noIcon,
    speaker,
    onClick,
    editDuration,
    onFocus,
    onBlur,
    mt,
    mr,
    mb,
    ml,
    pt,
    pr,
    pb,
    pl,
}: IDurationPicker & ISpacings) => {
    const {t} = useTranslation();
    const [_minutes, _setMinutes] = useState<number>(minutes);
    const [_seconds, _setSeconds] = useState<number>(seconds);
    const formattedMinutes = String(_minutes).padStart(2, '0');
    const formattedSeconds = String(_seconds).padStart(2, '0');

    const debounced = useDebouncedCallback(
        (duration: Duration, attendee?: IAttendee) => {
            if (attendee) {
                editDuration(duration, attendee);
            } else {
                editDuration(duration);
            }
        },
        250,
    ); // Debounce this function for more responsive UI when changing durations

    const setSpeakerDuration = (duration: Duration) => {
        if (speaker) {
            debounced(duration, speaker.attendee);
        } else {
            debounced(duration);
        }
    };

    return (
        <div
            style={{
                ...calculateSpacings({mt, mr, mb, ml, pt, pr, pb, pl}),
                ...{pointerEvents: disabled ? 'none' : 'all'},
            }}>
            <div
                style={{
                    display: 'flex',
                    ...style,
                }}>
                {!noIcon && (
                    <MdEdit
                        color={error ? Colors.Error : Colors.Brown1}
                        style={{
                            height: 16,
                            width: 16,
                            marginTop: 4,
                            marginRight: Spacings.xxs,
                        }}
                    />
                )}
                <StyledInput
                    type="number"
                    style={{
                        width: formattedMinutes.length + 'ch',
                        color: error ? Colors.Error : Colors.Black,
                    }}
                    min={0}
                    value={formattedMinutes}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={event => {
                        _setMinutes(Number(event.target.value));
                        setSpeakerDuration({
                            minutes: Number(event.target.value),
                            seconds: _seconds,
                        });
                    }}
                />
                <Text.Small
                    bold
                    mt={-1}
                    color={error ? Colors.Error : Colors.Black}>
                    :
                </Text.Small>
                <StyledInput
                    type="number"
                    style={{
                        width: formattedSeconds.length + 'ch',
                        color: error ? Colors.Error : Colors.Black,
                    }}
                    min={0}
                    value={formattedSeconds}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={event => {
                        _setSeconds(
                            Number(event.target.value) < 60
                                ? Number(event.target.value)
                                : 59,
                        );
                        setSpeakerDuration({
                            minutes: _minutes,
                            seconds:
                                Number(event.target.value) < 60
                                    ? Number(event.target.value)
                                    : 59,
                        });
                    }}
                />
                <Text.Small
                    ml={Spacings.xxxs}
                    color={error ? Colors.Error : Colors.Brown1}>
                    {t('common.minutes')}
                </Text.Small>
            </div>
        </div>
    );
};

export default DurationPicker;
