export const COLORS = {
    0: '#ffffff',
    1.1: '#081731',
    1.2: '#69717C',
    2.1: '#99988C',
    2.2: '#D7D5C3',
    2.3: '#EFEEE0',
    2.4: '#FAF9EF',
    3.1: '#AB53B6',
    3.2: '#D792DF',
    3.3: '#F3D0F3',
    4.1: '#1F837E',
    4.2: '#65CCC7',
    4.3: '#C5EEEE',
    5.1: '#AB9245',
    5.2: '#E7CB6F',
    5.3: '#FBE9AD',
}

export const SPACINGS = {
    xsmall: 6,
    small: 10,
    medium: 16,
    large: 26,
    xlarge: 38,
}

export const MINI_TIMER_WINDOW_WIDTH_THRESHOLD = 500;
export const MINI_TIMER_WINDOW_HEIGHT_THRESHOLD = 500;