import colors from '../../../assets/constants/colors';
import spacings from '../../../assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
`;

export const CalendarWrapper = styled.div`
    * {
        /* font-family: 'NotoSans'; */
    }

    .rbc-time-view {
        border: none;
    }

    .rbc-header {
        border-bottom: none;
    }

    .rbc-time-content > * + * > *,
    .rbc-header + .rbc-header,
    .rbc-time-content {
        border-color: ${colors.Beige1};
    }

    .rbc-off-range-bg {
        background: ${colors.Beige2};
        color: ${colors.Brown2};
    }

    .rbc-today {
        background-color: ${colors.Mustard3};
    }

    .rbc-day-slot .rbc-time-slot {
        border: none;
    }

    .rbc-current-time-indicator {
        background-color: ${colors.Purple1};
    }

    .rbc-time-column .rbc-label {
        color: ${colors.Black};
        font-size: 0.8rem;
        background-color: ${colors.Beige3};
        margin-top: -7px;
        margin-right: ${spacings.xxxs}px;
        display: block;
    }

    .rbc-allday-cell {
        display: none;
    }

    .rbc-event {
        border-radius: 0;
        border: none;
        background-color: ${colors.Mustard1};
        color: ${colors.Black};
        font-size: 0.7rem;
        font-weight: bold;
        padding: 5px 5px;

        .rbc-event-label {
            display: none;
        }
    }

    .rbc-events-container {
        margin-right: 0;
    }

    .rbc-event-content {
        line-height: 1.4;
    }

    .rbc-toolbar {

        .rbc-btn-group:first-child {
            display: none;
        }

        button {
            padding: ${spacings.xxxs}px ${spacings.s}px;
            background-color: ${colors.Beige1};
            color: ${colors.Black};
            border: none;
            font-weight: bold;
        }

        button.rbc-active {
            color: #ffffff;
            background-color: ${colors.Black};
        }
    }
`;

export const SwitchButton = styled.button`
    border: none;
    outline: none;
    padding: 3px;
    width: 100px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
`;