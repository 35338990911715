import ContentWrapper from '@/common/components/content-wrapper';
import spacings from '@/assets/constants/spacings';
import { Body, Headline } from '@/common/components/text';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <ContentWrapper>
            <Headline
                mt={spacings.xxl}
                style={{ textAlign: 'center' }}
            >
                404
            </Headline>
            <Headline
                style={{ textAlign: 'center' }}
            >
                {t('common.notFoundTitle')}
            </Headline>
            <Body
                mt={spacings.xs}
                mb={spacings.xxl}
                style={{ textAlign: 'center' }}
            >
                {t('common.notFoundDescription')}
            </Body>
        </ContentWrapper>
    );
};

export default NotFound;