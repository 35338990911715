import Colors from '../../../assets/constants/colors';
import spacings from '../../../assets/constants/spacings';
import {Body, Small} from '../text';
import {calculateSpacings} from '../../helpers';
import {ICheckbox} from './types';
import {IoMdCheckmark} from 'react-icons/io';
import {ISpacings} from '../../types';
import {StyledBox, StyledLabel} from './styled';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const Checkbox = ({
    label,
    renderLabel,
    checked,
    setChecked,
    disabled,
    bold,
    optional,
    small,
    mt,
    mr,
    mb,
    ml,
    pt,
    pr,
    pb,
    pl,
}: ICheckbox & ISpacings) => {
    const {t} = useTranslation();
    const [showCheckmark, setShowCheckmark] = useState<boolean>(false);

    return (
        <StyledLabel
            style={{
                pointerEvents: disabled ? 'none' : 'all',
                ...calculateSpacings({mt, mr, mb, ml, pt, pr, pb, pl}),
            }}
            onMouseEnter={() => setShowCheckmark(true)}
            onMouseLeave={() => setShowCheckmark(false)}>
            <input
                type="checkbox"
                checked={checked}
                onChange={e => setChecked(e.target.checked)}
            />
            <StyledBox
                style={{
                    height: small ? 24 : 30,
                    width: small ? 24 : 30,
                    borderColor: disabled ? Colors.Beige1 : Colors.Black,
                    backgroundColor: disabled
                        ? Colors.Beige1
                        : checked
                        ? Colors.Black
                        : Colors.Transparent,
                }}>
                {(showCheckmark || checked) && (
                    <IoMdCheckmark
                        size={28}
                        color={
                            disabled
                                ? Colors.Brown1
                                : checked
                                ? Colors.White
                                : Colors.Black
                        }
                    />
                )}
            </StyledBox>
            {small && label ? (
                <>
                    <Small
                        color={disabled ? Colors.Brown1 : Colors.Black}
                        bold={bold}>
                        {label}
                    </Small>
                    {optional && (
                        <Small
                            color={disabled ? Colors.Brown1 : Colors.Black}
                            ml={spacings.xxxs}>
                            ({t('common.optional')})
                        </Small>
                    )}
                </>
            ) : (
                label && (
                    <>
                        <Body
                            color={disabled ? Colors.Brown1 : Colors.Black}
                            bold={bold}>
                            {label}
                        </Body>
                        {optional && (
                            <Body
                                color={disabled ? Colors.Brown1 : Colors.Black}
                                ml={spacings.xxxs}>
                                ({t('common.optional')})
                            </Body>
                        )}
                    </>
                )
            )}
            {renderLabel && renderLabel}
        </StyledLabel>
    );
};

export default Checkbox;
