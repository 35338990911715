import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import CalvahPattern from '@/assets/images/calvah-pattern.png';
import Alert from '@/common/components/alert';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import Row from '@/common/components/row';
import {Body, Headline, Small} from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import {useQuery} from '@/common/hooks';
import {HEADER_HEIGHT} from '@/router/header/styled';
import {login, logout} from '@/services/api/auth-service';
import {getOrganisation} from '@/services/api/organisation-service';
import {setOrganisation, setUser} from '@/store/auth-slice';
import {ILoginResponse, IOrganisation} from '@/store/auth-slice/types';
import {useAppDispatch} from '@/store/hooks';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {FiArrowRightCircle} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {ErrorBox} from '../styled';
import {TLoginMessages} from './types';

const Login = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const query = useQuery();
    const alert = query.get('alert') as TLoginMessages;

    const [showAlert, setShowAlert] = useState<boolean>(
        alert === 'reset-init' || alert === 'reset-complete',
    );
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errors, setErrors] = useState<{email?: boolean; password?: boolean}>(
        {},
    );
    const [showError, setShowError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onClickLogin = async () => {
        setShowError(false);
        setErrors({
            email: !!!email,
            password: !!!password,
        });

        if (email && password) {
            setLoading(true);
            const [data, error] = await login(email, password);
            setLoading(false);
            const response: ILoginResponse = data;
            if (response) {
                dispatch(
                    setUser({
                        id: response.id,
                        name: response.name,
                        email: response.email,
                        pronouns: response.pronouns,
                        organisatonId: response.organisation_id,
                        role: response.role,
                    }),
                );
                setLoading(true);
                const [data, error] = await getOrganisation();
                setLoading(false);
                const organisation: IOrganisation = data;
                if (data) {
                    dispatch(setOrganisation(organisation));
                    navigate('/dashboard');
                }
            }
            if (error) {
                await logout();
                setShowError(true);
            }
        }
    };

    return (
        <ContentWrapper style={{}}>
            <img
                src={CalvahPattern}
                style={{
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                    zIndex: -1,
                    maxWidth: '65%',
                }}
            />
            <div style={{width: 500, marginBottom: spacings.xxl}}>
                {showAlert && (
                    <Alert
                        mt={spacings.xxl}
                        text={
                            alert === 'reset-init'
                                ? t('login.passwordResetInit')
                                : alert === 'reset-complete'
                                ? t('login.passwordResetComplete')
                                : ''
                        }
                        bgColor={colors.Teal1}
                        close={() => setShowAlert(false)}
                    />
                )}
                <Headline mt={showAlert && alert ? spacings.s : spacings.xxl}>
                    {t('auth.login')}
                </Headline>
                <TextBox
                    mt={spacings.s}
                    label={t('auth.email')}
                    value={email}
                    setValue={text => setEmail(text)}
                    error={errors.email}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            onClickLogin();
                        }
                    }}
                />
                <TextBox
                    mt={spacings.xxs}
                    label={t('auth.password')}
                    value={password}
                    setValue={text => setPassword(text)}
                    type="password"
                    error={errors.password}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            onClickLogin();
                        }
                    }}
                />
                {showError && (
                    <ErrorBox>
                        <Row style={{alignItems: 'flex-start'}}>
                            <AiOutlineInfoCircle
                                size={22}
                                color={colors.Error}
                                style={{
                                    marginTop: 2,
                                    marginRight: spacings.xxxs,
                                    flexShrink: 0,
                                }}
                            />
                            <Small>{t('auth.loginErrorMessage')}</Small>
                        </Row>
                    </ErrorBox>
                )}
                <Row
                    mt={spacings.s}
                    onClick={() => navigate('/forgot-password')}>
                    <Body bold link>
                        {t('auth.forgotPassword')}
                    </Body>
                    <FiArrowRightCircle
                        size={20}
                        color={colors.Purple1}
                        style={{marginLeft: spacings.xxxs}}
                    />
                </Row>
                <Button
                    loading={loading}
                    mt={spacings.s}
                    label={t('auth.login')}
                    onClick={onClickLogin}
                />
                <Row mt={spacings.xxl} onClick={() => navigate('/register')}>
                    <Small>
                        {t('auth.noAccountYet')}{' '}
                        <b style={{color: colors.Purple1}}>
                            {t('auth.signUp')}
                        </b>
                    </Small>
                    <FiArrowRightCircle
                        size={20}
                        color={colors.Purple1}
                        style={{marginLeft: spacings.xxxs}}
                    />
                </Row>
            </div>
        </ContentWrapper>
    );
};

export default Login;
