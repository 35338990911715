import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const AmountButton = styled.button`
    border: none;
    background-color: ${colors.Black};
    color: ${colors.White};
    width: 75px;
    cursor: pointer;

    &:hover {
        background-color: ${colors.Brown1};
    }
`;

export const SelectWrapper = styled.div`
    display: flex;
    width: 100%;
    padding-top: ${spacings.xxs}px;
    padding-bottom: ${spacings.xxs}px;
    border-top: 1px solid ${colors.Black};
    border-bottom: 1px solid ${colors.Black};
`;