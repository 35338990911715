import Button from '@/common/components/button';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Headline, Small } from '@/common/components/text';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ErrorPopup = ({
    close,
    error,
}: {
    close: () => void;
    error: 'title' | 'agenda' | 'date' | 'deletedAttendee';
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Popup close={close}>
            <Headline quaternary mb={spacings.xxxs}>
                {t('create.stepSummary.missingInfoTitle')}
            </Headline>
            <Small mb={spacings.s}>
                {error === 'title' && t('create.stepSummary.missingTitle')}
                {error === 'date' && t('create.stepSummary.missingDate')}
                {error === 'agenda' && t('create.stepSummary.faultyAgenda')}
                {error === 'deletedAttendee' && t('create.stepSummary.deletedAttendee')}
            </Small>
            <Row style={{ justifyContent: 'flex-end' }} mt={spacings.m}>
                <Button
                    secondary
                    small
                    label={t('actions.ok')}
                    onClick={() => close()}
                    mr={spacings.xxxs}
                />
                <Button
                    small
                    label={t('actions.edit')}
                    onClick={() => {
                        error === 'title' && navigate('/create-meeting/title');
                        error === 'date' && navigate('/create-meeting/date');
                        error === 'agenda' && navigate('/create-meeting/agenda');
                        error === 'deletedAttendee' && navigate('/create-meeting/attendees');
                    }}
                />
            </Row>
        </Popup>
    );
}

export default ErrorPopup;