import borderRadius from '@/assets/constants/border-radius';
import Button from '@/common/components/button';
import DeleteOrganiserPopup from './delete-organiser-popup';
import EditOrganiserPopup from './edit-organiser-popup';
import React, { useState } from 'react';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import {
    CloseIconWrapper,
    ListItem,
    NameWrapper,
    Wrapper
    } from './styled';
import { FiArrowRightCircle } from 'react-icons/fi';
import { IOrganiser } from '@/store/create-meeting-slice/types';
import { Micro, Small } from '@/common/components/text';
import { Pill } from '@/common/styled';
import { ReactComponent as ICUser } from '@icons/ic-user.svg';
import { RiCloseCircleFill, RiCloseCircleLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

const OrganiserListItem = ({
    organiser,
    isFirst,
    isLast,
    style,
    self,
}: {
    organiser: IOrganiser
    isFirst: boolean;
    isLast: boolean;
    style?: React.CSSProperties;
    self?: boolean;
}) => {
    const { t } = useTranslation();
    const [deleteIsHovered, setDeleteIsHovered] = useState<boolean>(false);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    return (
        <Wrapper style={style}>
            <ListItem
                onClick={() => !organiser.id && setShowEditPopup(true)}
                style={{
                    borderTopLeftRadius: isFirst ? borderRadius.m : 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: isLast ? borderRadius.m : 0,
                    borderBottomRightRadius: 0,
                }}
            >
                <NameWrapper>
                    <Row>
                        <ICUser style={{
                            marginRight: spacings.s,
                            width: 40,
                            height: 40,
                        }} />
                        {!organiser.id && <Pill>
                            <Micro bold>
                                {t('organisers.invitePending')}
                            </Micro>
                        </Pill>}
                        <Small bold mr={spacings.xxs}>
                            {organiser.name} {organiser.pronouns && `(${organiser.pronouns})`}
                        </Small>
                        <Small>
                            {organiser.email}
                        </Small>
                        {self && <Pill style={{ marginLeft: spacings.xs }}>
                            <Micro bold>
                                {t('organisers.self')}
                            </Micro>
                        </Pill>}
                    </Row>
                    {!organiser.id && <Row>
                        <Button
                            tertiary
                            small
                            noPadding
                            iconRight={<FiArrowRightCircle size={20} style={{ marginLeft: spacings.xxxs }} />}
                            label={t('actions.edit')}
                        />
                    </Row>}
                </NameWrapper>
            </ListItem>
            {!self && <CloseIconWrapper
                onMouseEnter={() => setDeleteIsHovered(true)}
                onMouseLeave={() => setDeleteIsHovered(false)}
                onClick={() => setShowDeletePopup(true)}
                style={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: isFirst ? borderRadius.m : 0,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: isLast ? borderRadius.m : 0,
                }}
            >
                {deleteIsHovered
                    ? <RiCloseCircleFill
                        size={24} />
                    : <RiCloseCircleLine
                        size={24} />
                }
            </CloseIconWrapper>}

            {showEditPopup && <EditOrganiserPopup
                user={organiser}
                close={() => setShowEditPopup(false)}
            />}
            {showDeletePopup && <DeleteOrganiserPopup
                user={organiser}
                close={() => setShowDeletePopup(false)}
            />}
        </Wrapper>
    );
};

export default OrganiserListItem;