import colors from '@/assets/constants/colors';
import isAfter from 'date-fns/isAfter';
import TimelinePill from './pill';
import { formatDurationCustom } from '@/common/helpers';
import { intervalToDuration } from 'date-fns';
import { ITimeline } from './types';
import { Small } from '@/common/components/text';
import { Stats, Wrapper } from './styled';
import { useTranslation } from 'react-i18next';

const Timeline = ({
    initialSteps,
    current,
    totalDuration,
    isPast,
    progress,
    totalOverflow,
    meeting,
}: ITimeline) => {
    const { t } = useTranslation();
    const now = new Date(new Date().setMilliseconds(0));

    return (
        <div>
            <Stats>
                <Small>
                    {t('timer.timeExceeded')}:
                    {` `}
                    <b style={{ color: colors.Purple1 }}>
                        +{formatDurationCustom(totalOverflow)}
                    </b>
                </Small>
                <Small>
                    {t('timer.timeLeft')}:
                    {` `}
                    <b>
                        {isAfter(now, initialSteps[initialSteps.length - 1].end)
                            ? formatDurationCustom({})
                            : formatDurationCustom(intervalToDuration({
                                start: now,
                                end: initialSteps[initialSteps.length - 1].end
                            }))}
                    </b>
                </Small>
            </Stats>
            <Wrapper>
                {initialSteps
                    .map((step, index) => {
                        return (
                            <TimelinePill
                                key={index}
                                step={step}
                                index={index}
                                current={current}
                                totalDuration={totalDuration}
                                initialSteps={initialSteps}
                                meeting={meeting}
                                isPast={isPast}
                                progress={progress}
                            />
                        );
                    })
                }
            </Wrapper>
        </div>
    );
};

export default Timeline;