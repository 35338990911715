"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUnitCount = void 0;
var units_1 = require("./units");
var parse_1 = require("../parse");
var getUnitCount = function (duration) {
    var parsed = __assign({}, parse_1.parse(duration));
    var count = 0;
    units_1.UNITS.forEach(function (unit) {
        if (parsed[unit] !== 0) {
            count++;
        }
    });
    return count;
};
exports.getUnitCount = getUnitCount;
