import Button from '../../../../common/components/button';
import Dropdown from '@/common/components/dropdown';
import Popup from '../../../../common/components/popup';
import Row from '@/common/components/row';
import Spacings from '../../../../assets/constants/spacings';
import TextBox from '../../../../common/components/textbox';
import { addUser } from '@/services/api/organisation-service';
import { getPronouns } from '@/common/helpers';
import { Headline } from '../../../../common/components/text';
import { IAddUserPopup } from './types';
import { saveUser } from '@/store/general-slice';
import { useAppDispatch } from '../../../../store/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Text from '../../../../common/components/text';
import colors from '../../../../assets/constants/colors';

const AddUserPopup = ({ close }: IAddUserPopup) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [pronouns, setPronouns] = useState<string>('she/her');
    const [addAttendeeError, setAddattendeeError] = useState<boolean>(false);

    return (
        <Popup close={close}>
            <Headline quaternary mb={Spacings.s}>
                {t('create.stepAttendees.addProfile')}
            </Headline>
            <TextBox
                label={t('forms.name')}
                value={name}
                setValue={setName} />
            <TextBox
                label={t('forms.email')}
                value={email}
                setValue={setEmail}
                mt={Spacings.xs} />
            {addAttendeeError && <Text.Small color={colors.Error} bold mb={Spacings.xxxs}>{t('create.stepAttendees.checkMail')}</Text.Small>}
            <Dropdown
                options={getPronouns()}
                label={t('forms.pronouns')}
                mt={Spacings.xs}
                value={pronouns}
                setValue={setPronouns}
            />

            <Row mt={Spacings.m} style={{ justifyContent: 'flex-end' }}>
                <Button
                    mr={Spacings.xxs}
                    tertiary
                    label={t('actions.cancel')}
                    onClick={close} />
                <Button
                    disabled={!(name && email && pronouns)}
                    label={t('actions.save')}
                    onClick={async () => {
                        if (!(name && email && pronouns)) return;
                        const [data, error] = await addUser({
                            name, email, pronouns
                        });
                        if (error){setAddattendeeError(true)}
                        if (!error) {
                            dispatch(saveUser({ name, email, pronouns }));
                            close();
                        }
                        
                    }} />
            </Row>
        </Popup>
    );
}

export default AddUserPopup;