import * as DurationFns from 'duration-fns';
import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import {
    add,
    intervalToDuration,
    isAfter,
    isBefore,
    isWithinInterval
    } from 'date-fns';
import {
    Indicator,
    INDICATOR_WIDTH,
    Pill,
    PILL_BORDER_WIDTH,
    Popup
    } from '../styled';
import { ITimelinePill } from './types';
import { Micro, Small } from '@/common/components/text';
import { ReactComponent as ICUser } from '@icons/ic-user.svg';
import { truncate } from '@/common/helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TimelinePill = ({
    step,
    index,
    current,
    totalDuration,
    initialSteps,
    meeting,
    isPast,
    progress
}: ITimelinePill) => {
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const actualDuration = intervalToDuration({ start: step.start, end: step.end });
    const durationInSeconds = DurationFns.toSeconds(step.duration);
    const timeLeftInSeconds = DurationFns.toSeconds(intervalToDuration({ start: new Date(new Date().setMilliseconds(0)), end: step.end }));
    const now = new Date(new Date().setMilliseconds(0));

    const totalProgress = (1 - (timeLeftInSeconds / durationInSeconds)) * 100;
    const stepProgress = isPast ? 100 : Math.max(0, progress * 100);

    const isLast = index === initialSteps.length - 1;
    const isFirst = index === 0;
    const nextIsDiscussion = !isLast && initialSteps[index + 1].item === initialSteps[index].item && initialSteps[index + 1].type === 'discussion';
    const nextIsFeedback = !isLast && initialSteps[index + 1].item === initialSteps[index].item && initialSteps[index + 1].type === 'feedback';
    const nextIsSpeaker = !isLast && initialSteps[index + 1].item === initialSteps[index].item && typeof initialSteps[index + 1].type === 'number';
    const previousIsSpeaker = !isFirst && initialSteps[index - 1].item === initialSteps[index].item && typeof initialSteps[index - 1].type === 'number';
    const isDiscussion = initialSteps[index].type === 'discussion';
    const isFeedback = initialSteps[index].type === 'feedback';

    return (
        <Pill
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
            key={index}
            style={{
                flex: DurationFns.toSeconds(step.duration) / DurationFns.toSeconds(totalDuration) * 10,
                backgroundColor: current === index ? colors.Brown2 : colors.Black,
                // backgroundColor: colors.Black,
                marginRight: (isLast) ? 0 : (nextIsDiscussion || nextIsFeedback || nextIsSpeaker) ? 1 : spacings.xxxs,
                borderTopRightRadius: (nextIsDiscussion || nextIsFeedback || nextIsSpeaker) ? 0 : borderRadius.xs,
                borderBottomRightRadius: (nextIsDiscussion || nextIsFeedback || nextIsSpeaker) ? 0 : borderRadius.xs,
                borderTopLeftRadius: (isDiscussion || isFeedback || previousIsSpeaker) ? 0 : borderRadius.xs,
                borderBottomLeftRadius: (isDiscussion || isFeedback || previousIsSpeaker) ? 0 : borderRadius.xs,
            }}
        >
            {current === index &&
                <Indicator style={{
                    left: `min(calc(${stepProgress}% - ${PILL_BORDER_WIDTH}px),calc(100% - ${INDICATOR_WIDTH - PILL_BORDER_WIDTH}px))`,
                    backgroundColor: stepProgress > totalProgress && isBefore(now, initialSteps[current].end)
                        ? colors.Teal1
                        : colors.Purple1,
                }} />
            }
            {(isWithinInterval(now, { start: add(step.start, { seconds: -1 }), end: add(step.end, { seconds: -1 }) })) &&
                <Indicator style={{
                    left: `min(calc(${totalProgress}% - ${PILL_BORDER_WIDTH}px),calc(100% - ${INDICATOR_WIDTH - PILL_BORDER_WIDTH}px))`,
                    backgroundColor: colors.Black,
                }} />
            }
            {(index === initialSteps.length - 1 && isAfter(now, initialSteps[initialSteps.length - 1].end)) &&
                <Indicator style={{
                    left: `calc(100% - ${INDICATOR_WIDTH - PILL_BORDER_WIDTH}px)`,
                    backgroundColor: colors.Black,
                }} />
            }
            {showPopup && <Popup>
                <Row>
                    <ICUser style={{ marginRight: spacings.xxs }} />
                    <div>
                        {typeof initialSteps[index].type === 'number' && <>
                            <Small bold>
                                {/* @ts-ignore */}
                                {truncate(meeting.agenda.items[initialSteps[index].item].name, 15)}
                            </Small>
                            <Micro>
                                {/* @ts-ignore */}
                                {meeting.agenda.items[initialSteps[index].item].speakers[initialSteps[index].type].attendee.name}
                            </Micro>
                        </>}
                        {typeof initialSteps[index].type !== 'number' && <>
                            <Small bold>
                                {initialSteps[index].type === 'discussion' && t('timer.discussion')}
                                {initialSteps[index].type === 'feedback' && t('timer.feedback')}
                                {initialSteps[index].type === 'evaluation' && t('timer.evaluation')}
                            </Small>
                            <Micro>
                                {t('timer.everybody')}
                            </Micro>
                        </>}
                    </div>
                </Row>
            </Popup>}
        </Pill>
    );
};

export default TimelinePill;