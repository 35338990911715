import Agenda from './agenda';
import AgendaNavbar from './agenda/agenda-navbar';
import AttendeeNavbar from './attendees/attendee-navbar';
import Attendees from './attendees';
import ChooseDate from './choose-date';
import ChooseDateNavbar from './choose-date/choose-date-navbar';
import Navbar from './navbar';
import Sidebar from './sidebar';
import Summary from './summary';
import SummaryNavbar from './summary/summary-navbar';
import Title from './title';
import TitleNavbar from './title/title-navbar';
import { ContentWrapper, InnerWrapper, Wrapper } from './styled';
import { createRef, useRef } from 'react';
import { CreateStep, StepIndex } from './steps';
import { useAppSelector } from '@/store/hooks';

const CreateMeeting = ({ stepIndex }: { stepIndex: StepIndex }) => {

    const componentRef = useRef<HTMLDivElement | null>(null);
    const items = useAppSelector((state) => state.createMeeting.agenda.items);
    // TODO this can be done more elegant with a context maybe
    const refs: React.RefObject<HTMLDivElement>[] = items.map(item => createRef());

    return (
        <Wrapper>
            <Sidebar stepIndex={stepIndex} />
            <ContentWrapper ref={componentRef}>
                <InnerWrapper>
                    {stepIndex === CreateStep.One && <Title />}
                    {stepIndex === CreateStep.Two && <Attendees />}
                    {stepIndex === CreateStep.Three && <Agenda itemRefs={refs} />}
                    {stepIndex === CreateStep.Four && <ChooseDate />}
                    {stepIndex === CreateStep.Five && <Summary />}
                </InnerWrapper>
            </ContentWrapper>
            <Navbar>
                {stepIndex === CreateStep.One && <TitleNavbar index={stepIndex} />}
                {stepIndex === CreateStep.Two && <AttendeeNavbar index={stepIndex} />}
                {stepIndex === CreateStep.Three && <AgendaNavbar index={stepIndex} itemRefs={refs} />}
                {stepIndex === CreateStep.Four && <ChooseDateNavbar index={stepIndex} />}
                {stepIndex === CreateStep.Five && <SummaryNavbar index={stepIndex} />}
            </Navbar>
        </Wrapper>
    );
}

export default CreateMeeting;