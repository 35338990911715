import axios from 'axios';
import Echo from 'laravel-echo';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/styles/index.scss';
import './i18n/index.tsx';

//@ts-ignore
window.Pusher = require('pusher-js');

//@ts-ignore
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_WS_KEY,
    wsHost: process.env.REACT_APP_WS_HOST,
    wssHost: process.env.REACT_APP_WS_HOST,
    wsPort: process.env.REACT_APP_WS_PORT ? parseInt(process.env.REACT_APP_WS_PORT) : 6001,
    wssPort: process.env.REACT_APP_WSS_PORT ? parseInt(process.env.REACT_APP_WSS_PORT) : 6002,
    forceTLS: process.env.REACT_APP_WS_FORCE_TLS === 'true',
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});

// Activate cookies for all axios calls bei default
axios.defaults.withCredentials = true;

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
