import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import Row from '@/common/components/row';
import {Body, Headline, Micro, Small, Subheadline} from '@/common/components/text';
import {formatDurationCustom, isOrganizer} from '@/common/helpers';
import {getMeeting} from '@/services/api/meeting-service';
import {IMeetingUpdatedStateResponse} from '@/services/api/meeting-service/types';
import {
    CreateMeetingState,
    IMeetingState,
    TMeetingStatus,
} from '@/store/create-meeting-slice/types';
import {useAppSelector} from '@/store/hooks';
import {ReactComponent as ICUser} from '@icons/ic-user.svg';
import {format, intervalToDuration, isPast as isPastFns} from 'date-fns';
import * as DurationFns from 'duration-fns';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MdClose} from 'react-icons/md';
import { Link } from 'react-router-dom';
import {useNavigate, useParams} from 'react-router-dom';
import style from './linkStyle.module.css'
import Autolinker from 'autolinker';


const TimerLobby = ({
    setShowTimerRunner,
}: {
    setShowTimerRunner: (start: boolean) => void;
}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {id} = useParams();
    const meetingId = id;

    const user = useAppSelector(state => state.auth.user);
    const [meeting, setMeeting] = useState<
        CreateMeetingState & IMeetingState
    >();
    const start =
        meeting && meeting.date ? new Date(meeting.date.start) : new Date();
    const end =
        meeting && meeting.date ? new Date(meeting.date.end) : new Date();

    const [difference, setDifference] = useState<any>(
        intervalToDuration({start: new Date(), end: start}),
    );
    const isInLessThanADay = DurationFns.toDays(difference) < 1;
    const isInLessThan60Mins = DurationFns.toMinutes(difference) < 60;
    const isPast = isPastFns(start);
    const [meetingStatus, setMeetingStatus] = useState<TMeetingStatus>();

    const hasEditRights =
        user?.role === 'admin' || user?.id === meeting?.userId;

    const placeString = meeting?.date?.place ? meeting?.date?.place : ''
    const placeLink = Autolinker.link(placeString ,{ 
            truncate: { length: 32, location: 'middle'} ,
            sanitizeHtml:true,
            className:style.linkStyle
        })

    useEffect(() => {
        let timer = setInterval(() => {
            setDifference(
                intervalToDuration({start: new Date(), end: new Date(start)}),
            );
        }, 1000);

        return () => clearInterval(timer);
    }, [start]);

    useEffect(() => {
        const fetchMeeting = async () => {
            if (!meetingId) return;

            const [data, error] = await getMeeting(meetingId);
            const meeting: CreateMeetingState & IMeetingState = data;
            if (meeting) {
                setMeeting(meeting);
                if (meeting.meetingState.state) {
                    setMeetingStatus(meeting.meetingState.state);
                }
            }
        };
        fetchMeeting();

        //@ts-ignore
        Echo.channel(`meeting.${meetingId}`).listen(
            'MeetingStateUpdated',
            (e: IMeetingUpdatedStateResponse) => {
                setMeetingStatus(e.state);
            },
        );
    }, []);

    return (
        <>
            <MdClose
                onClick={() => navigate(-1)}
                size={34}
                style={{
                    position: 'absolute',
                    top: spacings.s,
                    right: spacings.s,
                    cursor: 'pointer',
                }}
            />
            <ContentWrapper style={{paddingBottom: 0}}>
                <Row mt={spacings.l}>
                    <Headline quaternary>{format(start, 'PP')}</Headline>
                    <Subheadline quaternary ml={spacings.xxs}>
                        {format(start, 'H:mm a')} - {format(end, 'H:mm a')}
                    </Subheadline>
                </Row>

                <Headline primary>{meeting?.title}</Headline>
                <Body bold>{t('create.stepAgenda.goalsTitle')}</Body>
                <Small>{meeting?.agenda.goal}</Small>
                {meeting?.date?.place && <>
                    <Body bold> {t('forms.place')}</Body>
                    <Small mb={spacings.xxxs}>
                        <div dangerouslySetInnerHTML={{__html: placeLink} }/>
                    </Small>
                </>}
                
                
                {meeting?.agenda.links && (
                    <Body bold mt={spacings.xs}>
                        {' '}
                        {t('create.stepSummary.agendaLinks')}{' '}
                    </Body>
                )}
                {meeting?.agenda.links &&
                    meeting?.agenda.links.split('\n').map(link => (
                        <Small
                            link
                            mb={spacings.xxxs}
                            onClick={() => window.open(link, '_blank')}>
                            {link}
                        </Small>
                    ))}
                <Row mt={spacings.xxs} mb={spacings.s}>
                    {meeting?.attendees.map(attendee => (
                        <ICUser
                            key={attendee.email}
                            style={{
                                height: 36,
                                width: 36,
                                marginRight: spacings.xxxs,
                            }}
                        />
                    ))}
                </Row>
                {meetingStatus !== 'past' && (
                    <Row>
                        <Subheadline
                            tertiary
                            style={{textTransform: 'lowercase'}}>
                            {isPast
                                ? t('meetings.overdue')
                                : t('meetings.startsIn')}
                            {` `}
                            <b
                                style={{
                                    color: isPast
                                        ? colors.Purple1
                                        : colors.Black,
                                }}>
                                {isInLessThan60Mins
                                    ? formatDurationCustom(difference)
                                    : isInLessThanADay
                                    ? `${difference.hours} ${t('common.hours')}`
                                    : `${Math.floor(
                                          DurationFns.toDays(difference),
                                      )} ${t('common.days')}`}
                            </b>
                        </Subheadline>
                    </Row>
                )}
                {!isOrganizer() && meetingStatus === 'upcoming' && (
                    <Row mt={spacings.s}>
                        <Small>{t('timer.meetingNotStartedDisclaimer')}</Small>
                    </Row>
                )}
                {meetingStatus === 'past' && (
                    <Row mt={spacings.m} mb={spacings.l}>
                        <Small>{t('timer.meetingOverDisclaimer')}</Small>
                    </Row>
                )}

                {isOrganizer() &&
                    meetingStatus === 'upcoming' &&
                    hasEditRights && (
                        <Button
                            label={t('meetings.start')}
                            mt={spacings.m}
                            mb={-spacings.xs}
                            style={{position: 'relative', zIndex: 99}}
                            onClick={() => setShowTimerRunner(true)}
                        />
                    )}
                {isOrganizer() &&
                    meetingStatus === 'running' &&
                    hasEditRights && (
                        <Button
                            label={t('meetings.goToMeeting')}
                            mt={spacings.m}
                            mb={-spacings.xs}
                            style={{position: 'relative', zIndex: 99}}
                            onClick={() => setShowTimerRunner(true)}
                        />
                    )}
                {(!isOrganizer() || !hasEditRights) &&
                    meetingStatus !== 'past' && (
                        <Button
                            disabled={meetingStatus === 'upcoming'}
                            label={t('meetings.join')}
                            mt={spacings.m}
                            mb={-spacings.xs}
                            style={{position: 'relative', zIndex: 99}}
                            onClick={() => setShowTimerRunner(true)}
                        />
                    )}
                {meetingStatus === 'past' && (
                    <Button
                        label={t('meetings.viewSummary')}
                        mt={spacings.m}
                        mb={-spacings.xs}
                        style={{position: 'relative', zIndex: 99}}
                        onClick={() => navigate(`/timer/summary/${meetingId}`)}
                    />
                )}
            </ContentWrapper>
            <ContentWrapper bgColor={colors.White}>
                <div style={{paddingTop: spacings.xxl}}>
                    {meeting?.agenda.items.map(item => {
                        const links = item.links?.split('\n');
                        const totalDuration = DurationFns.sum(item.duration, item.discussion.duration, item.feedback.duration);

                        return (
                            <Row
                                style={{alignItems: 'flex-start'}}
                                mb={spacings.s}
                                key={item.id}>
                                <Row style={{width: 120, flexShrink: 0}}>
                                    <Body bold mr={spacings.xxxs}>
                                        {formatDurationCustom(totalDuration)}
                                    </Body>
                                    <Body color={colors.Brown1} mr={spacings.s}>
                                        {t('common.minutes')}
                                    </Body>
                                </Row>
                                <div>
                                    <Body bold mb={spacings.xxs}>
                                        {item.name}
                                    </Body>
                                    <Small mb={spacings.xxs}>
                                        {item.description}
                                    </Small>
                                    {links &&
                                        links.map(link => (
                                            <Small
                                                link
                                                mb={spacings.xxxs}
                                                onClick={() =>
                                                    window.open(link, '_blank')
                                                }>
                                                {link}
                                            </Small>
                                        ))}
                                    <Small bold mt={spacings.xxs} mb={spacings.xxxs}>
                                        {item.speakers
                                            .map(
                                                speaker =>
                                                    speaker.attendee.name,
                                            )
                                            .join(', ')}
                                    </Small>
                                    {(item.discussion.question || item.feedback.question) && <Row>
                                        <Small bold>
                                            {formatDurationCustom(item.duration)}
                                        </Small>
                                        <Small color={colors.Brown1} ml={spacings.xxxs}>
                                            {t('common.minutes')}
                                        </Small>
                                    </Row>}

                                    {item.discussion.question && <>
                                        <Micro color={colors.Brown1} mb={spacings.xxxs} mt={spacings.xs}>
                                            {t('create.stepSummary.discussion')}
                                        </Micro>
                                        <Small bold mb={spacings.xxxs}>
                                            {item.discussion.question}
                                        </Small>
                                        <Row>
                                            <Small bold>
                                                {formatDurationCustom(item.discussion.duration)}
                                            </Small>
                                            <Small color={colors.Brown1} ml={spacings.xxxs}>
                                                {t('common.minutes')}
                                            </Small>
                                        </Row>
                                    </>}
                                    {item.feedback.question && <>
                                        <Micro color={colors.Brown1} mb={spacings.xxxs} mt={spacings.xs}>
                                            {t('create.stepSummary.feedback')}
                                        </Micro>
                                        <Small bold mb={spacings.xxxs}>
                                            {item.feedback.question}
                                        </Small>
                                        <Row>
                                            <Small bold>
                                                {formatDurationCustom(item.feedback.duration)}
                                            </Small>
                                            <Small color={colors.Brown1} ml={spacings.xxxs}>
                                                {t('common.minutes')}
                                            </Small>
                                        </Row>
                                    </>}
                                </div>
                            </Row>
                        );
                    })}
                </div>
            </ContentWrapper>
        </>
    );
};

export default TimerLobby;
