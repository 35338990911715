import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import { HiArrowRight, HiOutlineArrowCircleRight } from 'react-icons/hi';
import { Micro, Small } from '@/common/components/text';
import { SeparatorLine } from '../../agenda/agenda-item/styled';
import { useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AgendaInfo = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goal = useAppSelector((state) => state.createMeeting.agenda.goal);
    const links = useAppSelector((state) => state.createMeeting.agenda.links)?.split('\n');

    return (
        <div>
            <Micro color={colors.Brown1} mb={spacings.xxxs}>
                {t('create.stepSummary.goals')}
            </Micro>
            <Small>
                {goal}
            </Small>
            {links && <>
                <Micro color={colors.Brown1} mb={spacings.xxxs} mt={spacings.xs}>
                    {t('create.stepSummary.agendaLinks')}
                </Micro>
                {links.map(link => <Small link mr={spacings.xxxs} mb={spacings.xxxs} onClick={()=>window.open(link, '_blank')}>
                    {link}
                    <HiArrowRight
                        color={colors.Purple1}
                        size={16}
                        style={{ marginLeft: spacings.xxxs, marginBottom: -2 }}
                    />
                </Small>)}
            </>}
            <Small link mt={spacings.xs} onClick={() => navigate('/create-meeting/agenda?goal=true')}>
                {t('actions.edit')}
                <HiOutlineArrowCircleRight
                    color={colors.Purple1}
                    size={22}
                    style={{ marginLeft: spacings.xxxs, marginBottom: -5 }}
                />
            </Small>
            <SeparatorLine />
        </div>
    );
}

export default AgendaInfo;