import { ReactComponent as Logo } from '@/assets/images/calvah-logo.svg';
import Button from '@/common/components/button';
import Row from '@/common/components/row';
import { Subheadline } from '@/common/components/text';
import { logout } from '@/services/api/auth-service';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LOGO_HEIGHT, Wrapper } from './styled';

const LogoutOnlyHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Wrapper>
            <Row onClick={() => navigate('/')}>
                <Logo style={{ height: LOGO_HEIGHT, width: 65 }} />
                <Subheadline secondary>CALVAH</Subheadline>
            </Row>
            <Row style={{ opacity: 1 }}>
                <Button
                    primary
                    small
                    round
                    label={t('auth.logout')}
                    onClick={async () => {
                        const [data, error] = await logout();
                        if (data) {
                            navigate('/login');
                        }
                    }}
                />
            </Row>
        </Wrapper>
    );
};

export default LogoutOnlyHeader;