import * as DurationFns from 'duration-fns';
import { Duration } from 'date-fns';
import { IAgendaItem, IAttendee } from '@/store/create-meeting-slice/types';

export const SPEAKER_TIME_THRESHOLD_PERCENTAGE = 51;

export const getSpeakerDuration = (email: string, items: IAgendaItem[]) => {
    return items.reduce((itemAcc, item) => {
        const stepDuration = item.speakers.reduce((speakerAcc, speaker) => {
            if (speaker.attendee.email === email) {
                return DurationFns.sum(speakerAcc, speaker.duration);
            }
            return speakerAcc;
        }, { seconds: 0 });
        if (stepDuration) {
            return DurationFns.sum(itemAcc, stepDuration);
        }
        return itemAcc;
    }, { seconds: 0 });
};

export const getLongestDurationInSeconds = (timesPerSpeaker: { attendee: IAttendee; duration: Duration }[]) => timesPerSpeaker.reduce((acc, cur) => {
    if (acc > DurationFns.toSeconds(cur.duration)) {
        return acc;
    } else {
        return DurationFns.toSeconds(cur.duration);
    }
}, 0);

export const getShortestDurationInSeconds = (timesPerSpeaker: { attendee: IAttendee; duration: Duration }[]) => timesPerSpeaker.reduce((acc, cur) => {
    if (acc <= DurationFns.toSeconds(cur.duration)) {
        return acc;
    } else {
        return DurationFns.toSeconds(cur.duration);
    }
}, DurationFns.toSeconds(timesPerSpeaker[0].duration));
