import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Header = styled.div`
    border-bottom: 1px solid ${colors.Black};
    padding-bottom: ${spacings.xxs}px;
    margin-bottom: ${spacings.xs}px;
`;

export const Box = styled.div`
    background-color: ${colors.White};
    border-radius: ${borderRadius.s}px;
    border: 1px solid ${colors.Beige1};
    padding: ${spacings.xs}px;
`;