import BorderRadius from '../../../../assets/constants/border-radius';
import Colors from '../../../../assets/constants/colors';
import Spacings from '../../../../assets/constants/spacings';
import styled from 'styled-components';

export const OuterWrapper = styled.div`
    position: absolute;
    overflow: hidden;
    max-height: 275px;
    border: 1px solid ${Colors.Beige1};
    background-color: ${Colors.White};
    border-radius: ${BorderRadius.s}px;
    box-sizing: border-box;
    margin-top: ${Spacings.xs}px;
    padding-left: ${Spacings.xxxs}px;
    padding-right: ${Spacings.xxxs}px;
    z-index: 99;
`;

export const InnerWrapper = styled.div`
    width: 100%;
    max-height: 275px;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const AddNewWrapper = styled.div`
    position: absolute;
    right: 0; 
    bottom: 0; 
    left: 0; 
    padding-left: ${Spacings.xs}px;
    padding-right: ${Spacings.xs}px;
    background-color: ${Colors.White};
`;


export const AddNewInnerWrapper = styled.div`
    display: flex;
    gap: ${Spacings.xxs}px;
    width: 100%;
    height: 100%;
    padding-top: ${Spacings.xs}px;
    padding-bottom: ${Spacings.xs}px;
    border-top: 1px solid ${Colors.Beige1};
`;

export const AddAllWrapper = styled.div`
    position: absolute;
    right: 0; 
    bottom: 0;
    padding-left: ${Spacings.xxxs}px;
    padding-right: ${Spacings.xxxs}px;
    //background-color: ${Colors.White};
`;

export const AddAllInnerWrapper = styled.div`
    display: flex;
    gap: ${Spacings.xxxs}px;
    width: 100%;
    height: 100%;
    padding-top: ${Spacings.xxxs}px;
    padding-bottom: ${Spacings.xxxs}px;
    justify-content: end;
`;