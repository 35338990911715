import styled from 'styled-components';
import { calculateSpacings } from '../../../helpers';
import { IGenericElement, ISpacings } from '../../../types';

const StyledText = styled.h1`
    font-family: Jost;
    font-weight: bold;
    font-size: 4.375rem;
    line-height: 1.5;
`;

const Huge = ({
    children,
    style,
    mt, mr, mb, ml,
    pt, pr, pb, pl
}: IGenericElement & ISpacings) => {
    return (
        <StyledText style={{
            ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
            ...style,
        }}>
            {children}
        </StyledText>
    );
}

export default Huge;