import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import Pagination from '@/common/components/pagination';
import Row from '@/common/components/row';
import Searchbar from '@/common/components/searchbar';
import { Small, Subheadline } from '@/common/components/text';
import { ToolbarWrapper } from '@/common/styled';
import { getAllUsers, getAllUsersPaginated } from '@/services/api/organisation-service';
import { IAttendee, IPaginationObject } from '@/store/create-meeting-slice/types';
import { useAppSelector } from '@/store/hooks';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddUserPopup from '../add-user-popup';
import AttendeeListItem from './attendee-list-item';

const AttendeeList = ({ attendees }: { attendees: IAttendee[]; }) => {
    const { t } = useTranslation();
    const userlist = useAppSelector((state) => state.general.userlist);

    const [showAddPopup, setShowAddPopup] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [filteredAttendees, setFilteredAttendees] = useState<IPaginationObject>();

    const fetchUsers=()=>{
        const fetchUsersPaginated = async () => {
            const [data, error] = await getAllUsersPaginated(currentPageIndex +1, search);
            if(data){
                if(data.data.length==0 && data.current_page>1){
                    setCurrentPageIndex(currentPageIndex-1);
                }else{
                    setFilteredAttendees(data)
                }
            }
        };
        fetchUsersPaginated();
    }

    useEffect(()=>{
        fetchUsers();
    },[])

    const debounceFetchUsers = debounce(
        () => fetchUsers(),
        500
    );

    useEffect(() => {
        debounceFetchUsers()
    }, [search]);

    useEffect(() => {
        return () => {
            debounceFetchUsers.cancel();
        };
    }, [debounceFetchUsers]);

    useEffect(() => {
        fetchUsers()
    }, [currentPageIndex, userlist]);

    return (
        <ContentWrapper>
            <ToolbarWrapper style={{ marginTop: spacings.l }}>
                <Subheadline quaternary>
                    {t('attendees.meetingAttendees')}
                </Subheadline>
                <Row>
                    <Searchbar
                        value={search}
                        setValue={setSearch}
                        style={{ marginRight: spacings.xxs }}
                    />
                    <Button
                        small
                        secondary
                        label={t('attendees.addAttendee')}
                        onClick={() => setShowAddPopup(true)}
                    />
                </Row>
            </ToolbarWrapper>
            <div>
                {!filteredAttendees || filteredAttendees.data.length === 0 ? (
                    <Small color={colors.Brown1}>
                        {t('attendees.noAttendeesFound')}
                    </Small>
                ) : (
                    filteredAttendees.data.map((attendee:any, index) => {
                    return <AttendeeListItem
                        key={attendee.email}
                        attendee={{...attendee, role:attendee.roles[0].name}}
                        isFirst={index === 0}
                        isLast={index === attendees.length - 1}
                    />})
                )}
                {filteredAttendees && filteredAttendees.last_page > 1 && (
                    <Pagination
                        pages={filteredAttendees.last_page}
                        current={currentPageIndex}
                        setCurrent={setCurrentPageIndex}
                        mt={spacings.s}
                    />
                )}
                
            </div>
            {showAddPopup &&
                <AddUserPopup
                    close={() => setShowAddPopup(false)}
                />
            }
        </ContentWrapper>
    );
};

export default AttendeeList;