import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Column from '@/common/components/column';
import Row from '@/common/components/row';
import {Small, Subheadline} from '@/common/components/text';
import {
    formatDurationCustom,
    formatDurationHM,
    getExceededTime,
    getPlannedTime,
    getSavedTime,
} from '@/common/helpers';
import {getMeetingsByDateRange} from '@/services/api/meeting-service';
import {
    CreateMeetingState,
    IMeetingState,
} from '@/store/create-meeting-slice/types';
import {endOfWeek, format, startOfWeek, sub} from 'date-fns';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FiArrowRightCircle} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {Box, Header} from '../../styled';
import WeekPicker from '../week-picker';
import PieChart from './pie-chart';
import {Dot} from './styled';

const TimeStats = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [meetings, setMeetings] = useState<
        (CreateMeetingState & IMeetingState)[]
    >([]);
    const [start, setStart] = useState<Date>(
        sub(startOfWeek(new Date(), {weekStartsOn: 1}), {weeks: 1}),
    );
    const [end, setEnd] = useState<Date>(
        sub(endOfWeek(new Date(), {weekStartsOn: 1}), {weeks: 1}),
    );

    const plannedTimes = meetings.map(meeting => getPlannedTime(meeting));
    const exceededTimes = meetings.map(meeting => getExceededTime(meeting));
    const savedTimes = meetings.map(meeting => getSavedTime(meeting));

    const plannedSeconds = plannedTimes.reduce((acc, cur) => acc + cur, 0);
    const exceededSeconds = exceededTimes.reduce((acc, cur) => acc + cur, 0);
    const savedSeconds = savedTimes.reduce((acc, cur) => acc + cur, 0);

    useEffect(() => {
        const fetchMeetings = async () => {
            const [data, error] = await getMeetingsByDateRange(
                format(start, 'yyyy-MM-dd'),
                format(end, 'yyyy-MM-dd'),
            );
            if (data) {
                setMeetings(data);
            }
        };
        fetchMeetings();
    }, [start, end]);

    return (
        <div style={{flex: 1}}>
            <Header>
                <Subheadline quaternary>
                    {t('statistics.timeStats.title')}
                </Subheadline>
                <WeekPicker
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                />
            </Header>
            <Box>
                {meetings.length === 0 ? (
                    <Small color={colors.Brown1}>
                        {t('statistics.timeStats.noStats')}
                    </Small>
                ) : (
                    <>
                        <Small>{t('statistics.timeStats.question')}</Small>
                        <Row onClick={() => navigate('/statistics/time')}>
                            <Button
                                mr={spacings.xxxs}
                                tertiary
                                noPadding
                                small
                                label={t('actions.showMore')}
                            />
                            <FiArrowRightCircle
                                size={22}
                                style={{marginTop: 1}}
                            />
                        </Row>
                        <Row
                            style={{
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                            }}
                            mt={spacings.xs}>
                            <Column>
                                <Row
                                    style={{alignItems: 'flex-start'}}
                                    mb={spacings.xxxs}>
                                    <Dot
                                        color={colors.Mustard2}
                                        style={{marginTop: 5}}
                                    />
                                    <Small ml={spacings.xxxs}>
                                        {t('statistics.timeStats.setTime')}{' '}
                                        <br />
                                        <b>
                                            {formatDurationHM({
                                                seconds: plannedTimes.reduce(
                                                    (acc, cur) => acc + cur,
                                                    0,
                                                ),
                                            })}
                                        </b>
                                    </Small>
                                </Row>
                                {exceededSeconds > 0 && (
                                    <Row style={{alignItems: 'flex-start'}}>
                                        <Dot
                                            color={colors.Purple2}
                                            style={{marginTop: 5}}
                                        />
                                        <Small ml={spacings.xxxs}>
                                            <b>
                                                +{' '}
                                                {formatDurationCustom({
                                                    seconds: exceededSeconds,
                                                })}{' '}
                                                {t('common.minutes')}
                                            </b>{' '}
                                            <br />
                                            {t(
                                                'statistics.timeStats.exceededTime',
                                            )}
                                        </Small>
                                    </Row>
                                )}
                                {exceededSeconds === 0 && savedSeconds > 0 && (
                                    <Row style={{alignItems: 'flex-start'}}>
                                        <Dot
                                            color={colors.Teal2}
                                            style={{marginTop: 5}}
                                        />
                                        <Small ml={spacings.xxxs}>
                                            <b>
                                                -{' '}
                                                {formatDurationCustom({
                                                    seconds: savedSeconds,
                                                })}{' '}
                                                {t('common.minutes')}
                                            </b>{' '}
                                            <br />
                                            {t('statistics.timeStats.underrun')}
                                        </Small>
                                    </Row>
                                )}
                            </Column>
                            <PieChart
                                setTime={plannedSeconds}
                                actualTime={
                                    exceededSeconds > 0
                                        ? exceededSeconds
                                        : savedSeconds
                                }
                                innerCircle={exceededSeconds === 0}
                            />
                        </Row>
                    </>
                )}
            </Box>
        </div>
    );
};

export default TimeStats;
