import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { calculateSpacings } from '@/common/helpers';
import { IDropdown } from './types';
import { ISpacings } from '@/common/types';
import { Small } from '../text';

const Select = styled.select`
    flex:1;
    width: 100%;
    border: 1px solid ${colors.Beige1};
    border-radius: ${borderRadius.s}px; 
    font-family: NotoSans;
    font-size: 1.125rem;
    padding-left: ${spacings.xs}px;
    padding-right: ${spacings.xs}px;
    height: 48px;
	appearance: none;

    &:hover:not(:disabled), &:focus:not(:disabled) {
        outline: none;
        border-color: ${colors.Mustard1};
        border-width: 2px;
        padding-left: ${props => spacings.xs - 1}px;
        padding-right: ${props => spacings.xs - 1}px;
    }

    &:disabled {
        background-color: ${colors.Beige1};
        padding-left: ${spacings.xs - 1}px;
        padding-right: ${spacings.xs - 1}px;
        opacity: 1;
    }
`;

const Dropdown = ({
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    options,
    label,
    mandatory,
    disabled,
    value,
    setValue,
}: IDropdown & ISpacings) => {
    return (
        <div style={{
            ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
            ...{ position: 'relative' }
        }}>
            <Small
                mb={spacings.xxxs}
                color={colors.Black}
            >
                {label}{mandatory && '*'}
            </Small>
            <Select
                value={value}
                disabled={disabled}
                onChange={e => setValue(e.target.value)}
                style={{ color: disabled ? colors.Brown1 : colors.Black, backgroundColor:colors.White}}
            >
                {options.map(option => <option
                    key={option.value}
                    value={option.value}
                >
                    {option.label}
                </option>)}
            </Select>
            {!disabled && <AiOutlineCaretDown
                color={colors.Black}
                size={14}
                style={{
                    position: 'absolute',
                    right: spacings.xxs,
                    bottom: 17,
                    pointerEvents:'none',
                }}
            />}
        </div>
    );
};

export default Dropdown;