import AmountBox from './amount-box';
import Button from '@/common/components/button';
import CalvahPattern from '@/assets/images/calvah-pattern-plain.png';
import ContentWrapper from '@/common/components/content-wrapper';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { getOrganisation, getOrganisers } from '@/services/api/organisation-service';
import { HEADER_HEIGHT } from '@/router/header/styled';
import { Headline, Small, Subheadline } from '@/common/components/text';
import { setAmount, setMax, setMin } from '@/store/licence-slice';
import { setOrganisation } from '@/store/auth-slice';
import { updateSubscriptionQuantity } from '@/services/api/payment-service';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LicenceAmount = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const organisation = useAppSelector(state => state.auth.organisation);
    const amount = useAppSelector((state) => state.licence.amount); 
    const [loading , setLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(setMax(50));

        const fetchOrganisation = async () => {
            const [data, error] = await getOrganisation();
            if (data) {
                if (!data.subscription_data) {
                    dispatch(setAmount(5));
                } else {
                    dispatch(setAmount(data.subscription_data.quantity));
                }
                dispatch(setOrganisation(data));
            }
        };
        fetchOrganisation();
    }, []);

    useEffect(() => {
        if (!(organisation?.active_subscription || organisation?.paid_externally)) {
            return;
        }

        const fetchOrganisers = async () => {
            const [data, error] = await getOrganisers();
            if (data && data.length > 0) {
                dispatch(setMin(data.length + 1));
            } else {
                dispatch(setMin(1));
            }
        };
        fetchOrganisers();
    }, [organisation]);

    return (
        <ContentWrapper>
            <img
                src={CalvahPattern}
                style={{
                    zIndex: -1,
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                }}
            />
            <div style={{ maxWidth: 600 }}>
                <Headline mt={spacings.xxl}>
                    {t('licence.title')}
                </Headline>
                <Subheadline quaternary>
                    {t('licence.howManyDoYouNeed')}
                </Subheadline>
                <Small mt={spacings.xxs}>
                    {t('licence.organisersHaveFeatures')}
                </Small>
                <AmountBox activeSubscription={organisation?.active_subscription} />
                <Row mt={spacings.m} style={{ gap: spacings.xxs }}>
                    <Button
                        loading={loading}
                        primary={organisation?.active_subscription}
                        secondary={!organisation?.active_subscription}
                        label={organisation?.active_subscription ? t('licence.updateLicence') : t('actions.next')}
                        onClick={async () => {
                            setLoading(true);
                            if (organisation?.active_subscription) {
                                const [data, error] = await updateSubscriptionQuantity(amount);
                                if (!error) {
                                    navigate('/settings/subscription');
                                }
                            } else {
                                navigate('/licences/billing');
                            }
                        }}
                    />
                    {(organisation?.active_subscription) && <Button
                        tertiary
                        label={t('actions.back')}
                        onClick={() => navigate('/settings/subscription')}
                    />}
                </Row>
            </div>
        </ContentWrapper>
    );
};

export default LicenceAmount;