import * as DurationFns from 'duration-fns';
import {
    CreateMeetingState,
    IAgendaItem,
    IAgendaItemErrors,
    IAttendee,
    ISpeaker
    } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Duration } from 'date-fns';
import { equalizeSpeakerTime } from './helpers';
import { nanoid } from 'nanoid';

export const newAgendaItem = (id: string) => ({
    id: id,
    name: undefined,
    description: undefined,
    speakers: [],
    duration: { minutes: 5 },
    discussion: { question: undefined, duration: { seconds: 0 } },
    feedback: { question: undefined, duration: { seconds: 0 } },
    links: undefined,
    editable: true,
    errors: undefined,
});

export const initialAgendaItems: IAgendaItem[] = [
    {
        id: nanoid(),
        name: 'Introduction', // TODO translate
        description: undefined,
        speakers: [],
        duration: { minutes: 5 },
        discussion: { question: undefined, duration: { seconds: 0 } },
        feedback: { question: undefined, duration: { seconds: 0 } },
        links: undefined,
        editable: true,
        errors: undefined,
    }, {
        id: nanoid(),
        name: 'Evaluation', // TODO translate
        description: 'Time for all to reflect on the meetings quality by answering 3 key questions anonymously.', // TODO translate
        speakers: [],
        duration: { minutes: 2 },
        discussion: { question: undefined, duration: { seconds: 0 } },
        feedback: { question: undefined, duration: { seconds: 0 } },
        links: undefined,
        editable: false,
        errors: undefined,
    }
];

const initialState = {
    id: undefined,
    userId: undefined,
    externalId: undefined,
    title: undefined,
    selfAttend: false,
    attendees: [],
    agenda: {
        goal: undefined,
        links: undefined,
        items: initialAgendaItems,
    },
    date: undefined,
} as CreateMeetingState;

const createMeetingSlice = createSlice({
    name: 'create-meeting',
    initialState: initialState,
    reducers: {
        resetCreateMeetingState: () => {
            return initialState;
        },
        reset: () => {
            return initialState;
        },
        load: (state, action: PayloadAction<CreateMeetingState>) => {
            return action.payload;
        },
        resetErrors: (state) => {
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    goaError: false,
                    items: state.agenda.items.map(item => {
                        return {
                            ...item,
                            errors: undefined,
                        }
                    })
                },
            };
        },
        resetAgendaItemSpeakers: (state) => {
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: state.agenda.items.map(item => {
                        return {
                            ...item,
                            speakers: [],
                        }
                    })
                },
            };
        },
        setTitle: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                title: action.payload,
            };
        },
        setSelfAttend: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                selfAttend: action.payload,
            };
        },
        setMeetingDate: (state, action: PayloadAction<{ start: Date; end: Date; place: string | undefined; }>) => {
            return {
                ...state,
                date: {
                    start: action.payload.start,
                    end: action.payload.end,
                    place: action.payload.place,
                },
            };
        },
        addAttendee: (state, action: PayloadAction<IAttendee>) => {
            const attendees = [...state.attendees];
            const find = attendees.find(attendee => attendee.email === action.payload.email);
            if (!find) attendees.push(action.payload);
            return {
                ...state,
                attendees: attendees,
                // agenda: {
                //     ...state.agenda,
                //     items: state.agenda.items.map(item => {
                //         const speakers = item.speakers.filter(speaker => speaker.attendee.email !== action.payload.email);
                //         return {
                //             ...item,
                //             speakers: speakers,
                //         }
                //     })
                // }
            };
        },
        removeAttendee: (state, action: PayloadAction<IAttendee>) => {
            const attendees = state.attendees.filter(attendee => attendee.email !== action.payload.email);
            return {
                ...state,
                attendees: attendees,
                agenda: {
                    ...state.agenda,
                    items: state.agenda.items.map(item => {
                        const speakers = item.speakers.filter(speaker => speaker.attendee.email !== action.payload.email);
                        return {
                            ...item,
                            speakers: speakers,
                        }
                    })
                }
            };
        },
        setAgendaGoal: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    goal: action.payload,
                    goalError: false,
                },
            };
        },
        setAgendaLinks: (state, action: PayloadAction<string | undefined>) => {
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    links: action.payload
                },
            };
        },
        setAgendaIsValid: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    isValid: action.payload,
                },
            };
        },
        addAgendaItem: (state, action: PayloadAction<{ item: IAgendaItem; insertIndex: number; }>) => {
            const items = [...state.agenda.items];
            items.splice(action.payload.insertIndex, 0, action.payload.item);
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
                date: undefined,
            };
        },
        removeAgendaItem: (state, action: PayloadAction<IAgendaItem>) => {
            const items = state.agenda.items.filter(item => item.id !== action.payload.id);
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
                date: undefined,
            };
        },
        setAgendaItemTitle: (state, action: PayloadAction<{ title: string | undefined, index: number; }>) => {
            const items = [...state.agenda.items];
            const item = items[action.payload.index];
            if (item) {
                items[action.payload.index] = {
                    ...item,
                    name: action.payload.title
                };
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
            };
        },
        setAgendaItemDescription: (state, action: PayloadAction<{ description: string | undefined, index: number; }>) => {
            const items = [...state.agenda.items];
            const item = items[action.payload.index];
            if (item) {
                items[action.payload.index] = {
                    ...item,
                    description: action.payload.description,
                    errors: {
                        ...item.errors,
                        description: false
                    }
                };
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
            };
        },
        setAgendaItemDuration: (state, action: PayloadAction<{ duration: Duration, index: number; }>) => {
            const items = [...state.agenda.items];
            const item = items[action.payload.index];
            if (item) {
                items[action.payload.index] = {
                    ...item,
                    speakers: equalizeSpeakerTime(item.speakers, action.payload.duration),
                    duration: action.payload.duration,
                    errors: {
                        ...item.errors,
                        duration: false,
                        speakerTime: false,
                    }
                };
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
                date: undefined,
            };
        },
        setAgendaItemLinks: (state, action: PayloadAction<{ links: string | undefined, index: number; }>) => {
            const items = [...state.agenda.items];
            const item = items[action.payload.index];
            if (item) {
                items[action.payload.index] = {
                    ...item,
                    links: action.payload.links
                };
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
            };
        },
        setAgendaItemDiscussion: (state, action: PayloadAction<{ duration: Duration, question: string | undefined, index: number; }>) => {
            const items = [...state.agenda.items];
            const item = items[action.payload.index];
            if (item) {
                items[action.payload.index] = {
                    ...item,
                    discussion: {
                        question: action.payload.question,
                        duration: action.payload.duration
                    },
                    errors: {
                        ...item.errors,
                        discussion: false,
                        discussionQuestion: false
                    }
                };
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
                date: DurationFns.toSeconds(action.payload.duration) === 0 && DurationFns.toSeconds(item.discussion.duration) === 0
                    ? state.date : undefined,
            };
        },
        setAgendaItemFeedback: (state, action: PayloadAction<{ duration: Duration, question: string | undefined, index: number; }>) => {
            const items = [...state.agenda.items];
            const item = items[action.payload.index];
            if (item) {
                items[action.payload.index] = {
                    ...item,
                    feedback: {
                        question: action.payload.question,
                        duration: action.payload.duration
                    },
                    errors: {
                        ...item.errors,
                        feedback: false,
                        feedbackQuestion: false,
                    }
                };
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
                date: DurationFns.toSeconds(action.payload.duration) === 0 && DurationFns.toSeconds(item.feedback.duration) === 0
                    ? state.date : undefined,
            };
        },
        addAgendaItemSpeaker: (state, action: PayloadAction<{ attendee: IAttendee, index: number; }>) => {
            const items = [...state.agenda.items];
            const item = items[action.payload.index];
            if (item) {
                const speakers: ISpeaker[] = [...item.speakers];
                const find = speakers.find(speaker => speaker.attendee.email === action.payload.attendee.email);
                if (!find) {
                    speakers.push({
                        attendee: action.payload.attendee,
                        duration: { seconds: 0 },
                    });

                }
                items[action.payload.index] = {
                    ...item,
                    speakers: equalizeSpeakerTime(speakers, item.duration),
                    errors: {
                        ...item.errors,
                        speakers: false,
                    }
                };
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
            };
        },
        removeAgendaItemSpeaker: (state, action: PayloadAction<{ attendee: IAttendee, index: number; }>) => {
            const items = [...state.agenda.items];
            const item = items[action.payload.index];
            if (item) {
                const speakers = item.speakers.filter((speaker: ISpeaker) => speaker.attendee.email !== action.payload.attendee.email);
                items[action.payload.index] = {
                    ...item,
                    speakers: equalizeSpeakerTime(speakers, item.duration),
                };
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
            };
        },
        setSpeakerDuration: (state, action: PayloadAction<{ attendee: IAttendee, duration: Duration, index: number; }>) => {
            const items = [...state.agenda.items];
            const item = items[action.payload.index];
            if (item) {
                const speakers: ISpeaker[] = item.speakers.map(speaker => {
                    return speaker.attendee.email === action.payload.attendee.email ? {
                        ...speaker,
                        duration: action.payload.duration
                    } : speaker;
                });
                items[action.payload.index] = {
                    ...item,
                    speakers: speakers,
                    errors: {
                        ...item.errors,
                        speakerTime: false,
                    }
                };
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
            };
        },
        moveAgendaItemUp: (state, action: PayloadAction<number>) => {
            if (action.payload === 0 || action.payload === 1) {
                // Do not allow moving of first and second item
                return state;
            }

            const items = [...state.agenda.items];
            items[action.payload - 1] = state.agenda.items[action.payload];
            items[action.payload] = state.agenda.items[action.payload - 1];
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
            };
        },
        moveAgendaItemDown: (state, action: PayloadAction<number>) => {
            if (action.payload === state.agenda.items.length - 1 || action.payload === state.agenda.items.length - 2) {
                // Do not allow moving of last and second last item
                return state;
            }

            const items = [...state.agenda.items];
            items[action.payload + 1] = state.agenda.items[action.payload];
            items[action.payload] = state.agenda.items[action.payload + 1];
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
            };
        },
        setAgendaGoalError: (state, action: PayloadAction<{ error: boolean | undefined; }>) => {
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    goalError: action.payload.error,
                },
            };
        },
        setAgendaItemErrors: (state, action: PayloadAction<{ errors: IAgendaItemErrors[]; goToNext: () => void; }>) => {
            const items = state.agenda.items.map((item, index) => ({
                ...item,
                errors: action.payload.errors[index]
            }));
            if (!state.agenda.goalError && items.every(item => {
                if (!item.errors) {
                    return true;
                }
                return Object.values(item.errors).every(value => value === false);
            })) {
                action.payload.goToNext();
            }
            return {
                ...state,
                agenda: {
                    ...state.agenda,
                    items: items,
                },
            };
        },
    }
})

export const {
    resetCreateMeetingState,
    reset,
    load,
    resetAgendaItemSpeakers,
    setTitle,
    setMeetingDate,
    setSelfAttend,
    addAttendee,
    removeAttendee,
    setAgendaGoal,
    setAgendaLinks,
    addAgendaItem,
    removeAgendaItem,
    setAgendaItemTitle,
    setAgendaItemDescription,
    setAgendaItemDuration,
    addAgendaItemSpeaker,
    removeAgendaItemSpeaker,
    setSpeakerDuration,
    setAgendaItemLinks,
    setAgendaItemDiscussion,
    setAgendaItemFeedback,
    moveAgendaItemDown,
    moveAgendaItemUp,
    resetErrors,
    setAgendaGoalError,
    setAgendaItemErrors,
    setAgendaIsValid
} = createMeetingSlice.actions;
export default createMeetingSlice;