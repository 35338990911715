import { IContentWrapper } from './types';
import { IGenericElement } from '@/common/types';
import { InnerWrapper, OuterWrapper } from './styled';

const ContentWrapper = ({
    children,
    style,
    outerStyle,
    bgColor
}: IGenericElement & IContentWrapper) => {

    return (
        <OuterWrapper style={{
            backgroundColor: bgColor || 'transparent',
            ...outerStyle,
        }}>
            <InnerWrapper style={style}>
                {children}
            </InnerWrapper>
        </OuterWrapper>
    );
};

export default ContentWrapper;