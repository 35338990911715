import RNToggle from 'react-toggle';
import { Body, Small } from '../text';
import { IToggle } from './types';
import { Wrapper } from './styled';
import 'react-toggle/style.css';

const Toggle = ({ label, checked, onChange, disabled, small, revert }: IToggle) => {
    return (
        <Wrapper
            disabled={disabled}
            style={{ flexDirection: revert ? 'row-reverse' : 'row' }}
        >
            <RNToggle
                icons={false}
                checked={checked}
                onChange={onChange} />
            {label && !small && <Body>{label}</Body>}
            {label && small && <Small>{label}</Small>}
        </Wrapper>
    );
};

export default Toggle;