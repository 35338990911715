import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const AVATAR_SIZE = 85;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${spacings.l}px;
`;

export const CircleBackground = styled.div<{ size: number }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    border-radius: 100%;
    background-color: ${colors.Beige2};
    margin-left: auto;
    margin-right: auto;
`;

export const GrowingCircle = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 100%;
    background-color: ${colors.Black};
    transition: all 0.3s ease-in-out;
`;

export const OverflowCircle = styled.div`
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 100%;
    background-color: ${colors.Purple3};
    min-width: 250px;
    min-height: 250px;
    max-width: 100vw;
    max-height: 100vw;
    transition: all 0.3s ease-in-out;
`;