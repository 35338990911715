import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeneralState } from './types';
import { IAttendee, IOrganiser } from '../create-meeting-slice/types';

const initialState = {
    userlist: [],
    organisers: [],
} as GeneralState;

const generalSlice = createSlice({
    name: 'general',
    initialState: initialState,
    reducers: {
        resetGeneralState: () => {
            return initialState;
        },
        saveUserlist: (state, action: PayloadAction<IAttendee[]>) => {
            return {
                ...state,
                userlist: action.payload,
            };
        },
        saveOrganisers: (state, action: PayloadAction<IOrganiser[]>) => {
            return {
                ...state,
                organisers: action.payload,
            };
        },
        saveUser: (state, action: PayloadAction<IAttendee>) => {
            const userlist = [...state.userlist];
            const find = userlist.find(user => user.email === action.payload.email);
            if (!find) userlist.push(action.payload);
            return {
                ...state,
                userlist: userlist,
            };
        },
        editUser: (state, action: PayloadAction<IAttendee>) => {
            const userlist = [...state.userlist];
            const index = userlist.findIndex(user => user.id === action.payload.id);
            if (index !== undefined) userlist[index] = {
                ...userlist[index],
                email: action.payload.email,
                name: action.payload.name,
                pronouns: action.payload.pronouns,
            };
            return {
                ...state,
                userlist: userlist,
            };
        },
        removeUser: (state, action: PayloadAction<string>) => {
            const userlist = state.userlist.filter(user => user.email !== action.payload);
            return {
                ...state,
                userlist: userlist,
            };
        },
        removeOrganiser: (state, action: PayloadAction<string>) => {
            const organisers = state.organisers.filter(organiser => organiser.email !== action.payload);
            return {
                ...state,
                organisers: organisers,
            };
        },
    }
})

export const {
    resetGeneralState,
    saveUserlist,
    saveOrganisers,
    saveUser,
    editUser,
    removeUser,
    removeOrganiser,
} = generalSlice.actions;
export default generalSlice;