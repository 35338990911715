export const xxxs = 6;
export const xxs = 14;
export const xs = 18;
export const s = 24;
export const m = 32;
export const l = 44;
export const xl = 56;
export const xxl = 72;
export const xxxl = 128;

export default { xxxs, xxs, xs, s, m, l, xl, xxl, xxxl };