import BorderRadius from '../../../assets/constants/border-radius';
import Colors from '../../../assets/constants/colors';
import Spacings from '../../../assets/constants/spacings';
import styled from 'styled-components';

export const StyledInput = styled.input<{ error: boolean | undefined; }>`
    font-family: NotoSans;
    font-size: 1.125rem;
    line-height: 1.6;
    border-color: ${props => props.error ? Colors.Error : Colors.Beige1};
    border-radius: ${BorderRadius.m}px;
    border-width: ${props => props.error ? 2 : 1}px;
    width: 100%;
    height: 48px;
    padding: 0;    
    box-sizing: border-box;
    box-shadow: none;
    border-style: solid;
    padding-left: ${props => Spacings.xs - (props.error ? 1 : 0)}px;
    padding-right: ${props => Spacings.xs - (props.error ? 1 : 0)}px;

    &:hover:not(:disabled), &:focus:not(:disabled) {
        outline: none;
        border-color: ${props => props.error ? Colors.Error : Colors.Mustard1};
        border-width: 2px;
        padding-left: ${props => Spacings.xs - 1}px;
        padding-right: ${props => Spacings.xs - 1}px;
    }

    &:disabled {
        background-color: ${Colors.Beige1};
        padding-left: ${Spacings.xs - 1}px;
        padding-right: ${Spacings.xs - 1}px;
    }
`;

export const StyledTextarea = styled.textarea<{ error: boolean | undefined; }>`
    font-family: NotoSans;
    font-size: 1.125rem;
    line-height: 1.6;
    border-color: ${props => props.error ? Colors.Error : Colors.Beige1};
    border-radius: ${BorderRadius.m}px;
    border-width: ${props => props.error ? 2 : 1}px;
    width: 100%;
    padding: 0;    
    box-sizing: border-box;
    box-shadow: none;
    border-style: solid;
    padding-top: ${props => Spacings.xs - (props.error ? 1 : 0)}px;
    padding-left: ${props => Spacings.xs - (props.error ? 1 : 0)}px;
    padding-right: ${props => Spacings.xs - (props.error ? 1 : 0)}px;
    padding-bottom: ${props => Spacings.xs - (props.error ? 1 : 0)}px;

    &:hover:not(:disabled), &:focus:not(:disabled) {
        outline: none;
        border-color: ${props => props.error ? Colors.Error : Colors.Mustard1};
        border-width: 2px;
        padding-top: ${Spacings.xs - 1}px;
        padding-left: ${Spacings.xs - 1}px;
        padding-right: ${Spacings.xs - 1}px;
        padding-bottom: ${Spacings.xs - 1}px;
    }

    &:disabled {
        background-color: ${Colors.Beige1};
        padding-left: ${Spacings.xs - 1}px;
        padding-right: ${Spacings.xs - 1}px;
    }
`;