import spacings from "@/assets/constants/spacings";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowDownSFill } from 'react-icons/ri';
import Row from "../row";
import { Small } from "../text";
import OrderByPopUp from "./order-by-popup";
import { OrderByWrapper } from "./styled";

const OrderByDropdown = ({
    popUpStyle,
    current,
    setCurrent,
}: {
    popUpStyle?: React.CSSProperties;
    current: string;
    setCurrent: (current: string) => void;
}) => {
    const { t } = useTranslation();
    const [isEditHovered, setIsEditHovered] = useState<boolean>(false);
    const optionsArray = [
        { tax: t('sortBy.alphabetical'), value: 3, slug: 'alphabet', label: t('sortBy.alphabetical'), onClick: () => { setCurrent('alphabet'); setSortBy(t('sortBy.alpha')) } },
        { tax: t('sortBy.date'), value: 1, slug: 'latest', label: t('sortBy.latestToOldest'), onClick: () => { setCurrent('latest'); setSortBy(t('sortBy.date')) } },
        { tax: t('sortBy.date'), value: 2, slug: 'oldest', label: t('sortBy.oldestToLatest'), onClick: () => { setCurrent('oldest'); setSortBy(t('sortBy.date')) } },
        { tax: t('sortBy.score'), value: 5, slug: 'scoreAsc', label: t('sortBy.scoreAsc'), onClick: () => { setCurrent('scoreAsc'); setSortBy(t('sortBy.score')) } },
        { tax: t('sortBy.score'), value: 4, slug: 'scoreDesc', label: t('sortBy.scoreDesc'), onClick: () => { setCurrent('scoreDesc'); setSortBy(t('sortBy.score')) } },

    ]
    const [sortBy, setSortBy] = useState<string | undefined>(optionsArray.find(e => e.slug === current)?.tax);

    return (
        <OrderByWrapper
            onMouseEnter={() => setIsEditHovered(true)}
            onMouseLeave={() => setIsEditHovered(false)}
            style={{
            }}
        >
            <Row>
                <Small bold mr={spacings.xxxs}>
                    {t('sortBy.sortBy')} {sortBy}
                </Small>
                <RiArrowDownSFill size={20} />
                {isEditHovered && <OrderByPopUp
                    popUpStyle={popUpStyle}
                    sortByOptions={optionsArray}
                    current={current}
                />}
            </Row>
        </OrderByWrapper>
    )
}

export default OrderByDropdown;