import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import CalvahPattern from '@/assets/images/calvah-pattern-plain.png';
import Alert from '@/common/components/alert';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import Row from '@/common/components/row';
import { Body, Headline, Small } from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import { ColoredBox } from '@/common/styled';
import { HEADER_HEIGHT } from '@/router/header/styled';
import { getOrganisation, getOrganisers, inviteOrganisers } from '@/services/api/organisation-service';
import { setOrganisation } from '@/store/auth-slice';
import { saveOrganisers } from '@/store/general-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseCircleLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

const InviteOrganisers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const organisers = useAppSelector(state => state.general.organisers);
    const [emails, setEmails] = useState<string[]>(['']);
    const [maxUsers, setMaxUsers] = useState<number>(0);
    const leftUsers = maxUsers - organisers.length - 1;
    const [loading, setLoading] = useState<boolean>(false);
    const [emailInUseError, setEmailInUseError] = useState<boolean>(false);
    const [wrongMailError, setWrongMailError] = useState<boolean>(false);
    

    const onClickInvite = async () => {
        setLoading(true);
        const [data, error] = await inviteOrganisers(emails.filter(email => email));
        setLoading(false);
        if (!error) {
            navigate('/settings/subscription?sent=true');
        } else {
            if(error.status==403){
                setEmailInUseError(true);
            }
            if(error.status==400){
                setWrongMailError(true);
            }
        }
    };

    useEffect(() => {
        const fetchOrganisation = async () => {
            const [data, error] = await getOrganisation();
            if (data) {
                dispatch(setOrganisation(data));
                if(data.paid_externally) {
                    setMaxUsers(Infinity);
                }else{
                    setMaxUsers(data.subscription_data.quantity || 0);
                }
            }
        };

        const fetchOrganisers = async () => {
            const [data, error] = await getOrganisers();
            if (data) {
                dispatch(saveOrganisers(data));
            }
        };

        fetchOrganisation();
        fetchOrganisers();
    }, []);

    return (
        <ContentWrapper>
            <img
                src={CalvahPattern}
                style={{
                    zIndex: -1,
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                }}
            />
            <Row
                mt={spacings.xxl}
                style={{
                    gap: spacings.xxxl,
                    alignItems: 'flex-start',
                }}
            >
                <div style={{ flex: 1 }}>
                    <Headline >
                        {t('organisers.inviteTitle')}
                    </Headline>
                    <Body>
                        {t('organisers.inviteDescription')}
                    </Body>
                    {emails.map((email, index) => <Row
                        key={index}
                        mt={spacings.xs}
                    >
                        <TextBox
                            style={{ flex: 1 }}
                            label={t('common.email') + ` ${index + 1}`}
                            value={email}
                            setValue={(text) => {
                                const _emails = [...emails];
                                _emails[index] = text;
                                if (text && index === emails.length - 1 && emails.length < leftUsers) {
                                    _emails.push('');
                                }
                                setEmailInUseError(false);
                                setWrongMailError(false);
                                setEmails(_emails);
                            }}
                        />
                        {emails.length > 1 && <div
                            onClick={() => {
                                const _emails = [...emails];
                                _emails.splice(index, 1);
                                setEmails(_emails);
                            }}
                            style={{
                                marginLeft: spacings.xxs,
                                marginTop: 32,
                                cursor: 'pointer'
                            }}
                        >
                            <RiCloseCircleLine
                                size={24} />
                        </div>}
                    </Row>)}
                    {emails.filter(email => email).length === leftUsers && <Alert
                        mt={spacings.xs}
                        mr={24 + spacings.xxs}
                        text={t('organisers.licenceUsedUp')}
                        bgColor={colors.Teal1}
                    />}
                    {emailInUseError && <Alert
                        mt={spacings.xs}
                        mr={24 + spacings.xxs}
                        text={t('organisers.emailInUse')}
                        bgColor={colors.Error}
                    />}
                    {wrongMailError && <Alert
                        mt={spacings.xs}
                        mr={24 + spacings.xxs}
                        text={t('organisers.wrongEmail')}
                        bgColor={colors.Error}
                    />}
                    <Button
                        loading={loading}
                        mt={spacings.m}
                        label={t('organisers.inviteButton')}
                        onClick={onClickInvite} />
                </div>
                {<ColoredBox color={colors.Teal3} style={{ width: 250 }}>
                    <Headline style={{ lineHeight: 1 }} mb={spacings.xxxs}>
                        {leftUsers!=Infinity
                            ? leftUsers - (emails.filter(email => email).length)
                            : '∞'
                        }
                    </Headline>
                    <Headline quaternary>
                        {t('organisers.organisers')}
                    </Headline>
                    <Small>
                        {t('organisers.leftToAdd')}
                    </Small>
                </ColoredBox>}
            </Row>
        </ContentWrapper >
    );
};

export default InviteOrganisers;