import TimerLobby from './timer-lobby';
import TimerRunner from './timer-runner';
import { useState } from 'react';

const Timer = () => {
    const [showTimerRunner, setShowTimerRunner] = useState<boolean>(false);

    return (
        showTimerRunner
            ? <TimerRunner />
            : <TimerLobby setShowTimerRunner={setShowTimerRunner} />
    );
};

export default Timer;