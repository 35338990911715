import { load } from '@/store/create-meeting-slice';
import { CreateMeetingState, IMeetingState, TMeetingStatus } from '@/store/create-meeting-slice/types';
import { useAppDispatch } from '@/store/hooks';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeleteMeetingPopup from '../delete-meeting-popup';
import EditMenuItem from './edit-menu-item';
import { BorderBottom, Wrapper } from './styled';

const EditMenu = ({
    meeting,
    setPageIndex,
}: {
    meeting: CreateMeetingState & IMeetingState;
    setPageIndex?: (index: number) => void;
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const status: TMeetingStatus = meeting.meetingState.state;
    const [show, setShow] = useState<boolean>(false);

    return (
        <Wrapper>
            <BorderBottom/>
            <EditMenuItem
                label={status === 'past'
                    ? t('meetings.showSummary')
                    : t('actions.edit')
                }
                onClick={() => {
                    dispatch(load(meeting));
                    if (status === 'past') {
                        navigate(`/statistics/${meeting.externalId}`);
                    } else {
                        navigate('/create-meeting/summary');
                    }
                }}
            />
            <EditMenuItem
                label={status === 'past'
                    ? t('meetings.showAgenda')
                    : t('actions.duplicate')
                }
                onClick={() => {
                    dispatch(load({
                        ...meeting,
                        id: undefined,
                        title: meeting.title+ ' (copy)',
                    }));
                    if (status === 'past') {
                        navigate(`/timer/${meeting.externalId}`);
                    } else {
                        navigate('/create-meeting/title');
                    }
                }}
            />
            {status==='past' &&
            <EditMenuItem
                label={t('actions.duplicate')}
                onClick={() => {
                    dispatch(load({
                        ...meeting,
                        id: undefined,
                        title: meeting.title+ ' (copy)',
                    }));

                    navigate('/create-meeting/title');
                    
                }}
            />
            }
            {setPageIndex && (
                <EditMenuItem
                    label={t('actions.delete')}
                    onClick={() => setShow(true)}
                />
            )}
            {show && setPageIndex && <DeleteMeetingPopup
                close={() => setShow(false)}
                meeting={meeting}
                setPageIndex={setPageIndex}
            />}
        </Wrapper>
    );
};

export default EditMenu;