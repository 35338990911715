import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const InputWrapper = styled.div`

    .StripeElement {
        display: flex;
        align-items: center;
        background-color: ${colors.White};
        border: 1px solid ${colors.Beige1};
        border-radius: ${borderRadius.m}px;
        padding-left: ${spacings.xs}px;
        padding-right: ${spacings.xs}px;
        height: 48px;

        > div {
            width: 100%;
        }

        &:hover:not(:disabled), &:focus:not(:disabled) {
            outline: none;
            border-color: ${colors.Mustard1};
            border-width: 2px;
            padding-left: ${spacings.xs - 1}px;
            padding-right: ${spacings.xs - 1}px;
            height: 46px;
        }
    }

    .StripeElement--invalid {
        border-color: ${colors.Error};
        border-width: 2px;
        padding-left: ${props => spacings.xs - 1}px;
        padding-right: ${props => spacings.xs - 1}px;
        height: 46px;
    }
`;