import colors from '@/assets/constants/colors';
import styled from 'styled-components';
import { Small } from '@/common/components/text';

const Pie = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border-radius: 100%;
`;

const InnerPie = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 86px;
    width: 86px;
    border-radius: 100%;
`;

const InnerCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: ${colors.White};
`;

const PieChart = ({
    setTime,
    actualTime,
    innerCircle,
}: {
    setTime: number;
    actualTime: number;
    innerCircle: boolean;
}) => {
    const percentage = Math.round(actualTime / setTime * 100);

    return (
        <Pie style={{
            background: innerCircle
                ? colors.Mustard2
                : `conic-gradient(${colors.Purple2} 0% ${percentage}%, ${colors.White} ${percentage}% 100%)`
        }}>
            <InnerPie style={{
                background: innerCircle
                    ? `conic-gradient(${colors.Teal2} 0% ${percentage}%, ${colors.White} ${percentage}% 100%)`
                    : colors.Mustard2,
                width: innerCircle ? 78 : 86,
                height: innerCircle ? 78 : 86,
            }}>
                <InnerCircle>
                    <Small bold={innerCircle}>
                        {innerCircle && '+'}{percentage}%
                    </Small>
                </InnerCircle>
            </InnerPie>
        </Pie>
    );
};

export default PieChart;