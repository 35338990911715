import * as Text from '../text';
import Colors from '../../../assets/constants/colors';
import Spacings from '../../../assets/constants/spacings';
import { calculateSpacings } from '../../helpers';
import { ISpacings } from '../../types';
import { ITextBox } from './types';
import { StyledInput, StyledTextarea } from './styled';

const TextBox = ({
    label,
    description,
    disabled,
    error,
    width,
    value,
    setValue,
    mt, mr, mb, ml,
    pt, pr, pb, pl,
    placeholder,
    multiline,
    mandatory,
    type,
    style,
    onKeyPress
}: ITextBox & ISpacings) => {
    return (
        <label style={{
            ...calculateSpacings({ mt, mr, mb, ml, pt, pr, pb, pl }),
            ...{ display: 'block' },
            ...width ? { width: width } : {},
            ...style,
        }}>
            <Text.Small
                mb={Spacings.xxxs}
                color={error ? Colors.Error : disabled ? Colors.Brown1 : Colors.Black}
            >
                {label || ' '}{mandatory && '*'}
            </Text.Small>
            {description
                ? <Text.Small
                    mb={Spacings.xxxs}
                    color={Colors.Black}
                >
                    {description}
                </Text.Small> : null}
            {multiline
                ? <StyledTextarea
                    placeholder={placeholder}
                    disabled={disabled}
                    error={error}
                    value={value}
                    rows={10}
                    onChange={e => setValue(e.target.value)} />
                : <StyledInput
                    onKeyPress={onKeyPress}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    error={error}
                    value={value}
                    onChange={e => setValue(e.target.value)} />
            }
        </label>
    );
}

export default TextBox;