import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import { Headline } from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import { IPopup } from '@/common/types';
import { getOrganisers, inviteOrganisers, removeUserFromOrganisation } from '@/services/api/organisation-service';
import { IOrganiserResponse } from '@/services/api/organisation-service/types';
import { saveOrganisers } from '@/store/general-slice';
import { useAppDispatch } from '@/store/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEditPopup } from './types';

const EditOrganiserPopup = ({ close, user }: IPopup & IEditPopup) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>(user.email);
    const [loading, setLoading] = useState<boolean>(false);

    const onClickEdit = async () => {
        // TODO ugly
        setLoading(true);
        const [data, error] = await removeUserFromOrganisation(user.email);
        setLoading(false);
        if (!error) {
            setLoading(true);
            const [aData, aError] = await inviteOrganisers([email]);
            setLoading(false);
            if (!aError) {
                setLoading(true);
                const [data, error] = await getOrganisers();
                setLoading(false);
                if (data) {
                    dispatch(saveOrganisers(data
                        .map((_user: IOrganiserResponse) => ({
                            id: _user.id,
                            name: _user.name,
                            email: _user.email,
                            pronouns: _user.pronouns,
                            role: _user.role,
                        })))
                    );
                    close();
                } if (error) {
                    dispatch(saveOrganisers([]));
                }
            }
        }
    };

    return (
        <Popup close={close}>
            <Headline quaternary mb={spacings.s}>
                {t('organisers.editInvitiation')}
            </Headline>
            <TextBox
                disabled={user.role === 'organiser'}
                label={t('forms.email')}
                value={email}
                setValue={setEmail}
                mt={spacings.xs} />

            <Row mt={spacings.m} style={{ justifyContent: 'flex-end' }}>
                <Button
                    mr={spacings.xxs}
                    tertiary
                    label={t('actions.cancel')}
                    onClick={close} />
                <Button
                    loading={loading}
                    disabled={!email}
                    label={t('organisers.resendInvite')}
                    onClick={() => {
                        if (!email) return;
                        onClickEdit();
                    }} />
            </Row>
        </Popup>
    );
}

export default EditOrganiserPopup;