import Button from '@/common/components/button';
import CalvahPattern from '@/assets/images/calvah-pattern-plain.png';
import ContentWrapper from '@/common/components/content-wrapper';
import Dropdown from '@/common/components/dropdown';
import InfoBox from '../components/info-box';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import TextBox from '@/common/components/textbox';
import { COUNTRIES } from '@/assets/constants/countries';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { HEADER_HEIGHT } from '@/router/header/styled';
import { Headline, Small } from '@/common/components/text';
import { setBillingDetails } from '@/store/licence-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LicenceBilling = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const _company = useAppSelector((state) => state.licence.billingDetails?.company);
    const _streetAndNo = useAppSelector((state) => state.licence.billingDetails?.streetAndNo);
    const _city = useAppSelector((state) => state.licence.billingDetails?.city);
    const _zip = useAppSelector((state) => state.licence.billingDetails?.zip);
    const _country = useAppSelector((state) => state.licence.billingDetails?.country);
    const [company, setCompany] = useState<string>(_company || '');
    const [streetAndNo, setStreetAndNo] = useState<string>(_streetAndNo || '');
    const [city, setCity] = useState<string>(_city || '');
    const [zip, setZip] = useState<string>(_zip || '');
    const [country, setCountry] = useState<string>(_country || 'DE');

    return (
        <ContentWrapper>
            <img
                src={CalvahPattern}
                style={{
                    zIndex: -1,
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                }}
            />
            <Row
                mt={spacings.xxl}
                style={{
                    alignItems: 'flex-start',
                    gap: spacings.xl,
                }}
            >
                <div style={{ flex: 1, position: 'relative' }}>
                    <Button
                        style={{ position: 'absolute', top: -spacings.l }}
                        noPadding
                        small
                        tertiary
                        label={t('actions.back')}
                        icon={<FiArrowLeftCircle size={18} style={{ marginRight: spacings.xxxs }} />}
                        onClick={() => navigate('/licences/amount')}
                    />
                    <Headline>
                        {t('licence.billing')}
                    </Headline>
                    <TextBox
                        mt={spacings.s}
                        label={t('forms.company')}
                        mandatory
                        value={company}
                        setValue={setCompany}
                    />
                    <TextBox
                        mt={spacings.xxs}
                        label={t('forms.streetAndNo')}
                        mandatory
                        value={streetAndNo}
                        setValue={setStreetAndNo}
                    />
                    <TextBox
                        mt={spacings.xxs}
                        label={t('forms.city')}
                        mandatory
                        value={city}
                        setValue={setCity}
                    />
                    <TextBox
                        mt={spacings.xxs}
                        label={t('forms.zip')}
                        mandatory
                        value={zip}
                        setValue={setZip}
                    />
                    <Dropdown
                        mt={spacings.xxs}
                        label={t('forms.country')}
                        options={COUNTRIES.map(country => ({ label: country.name, value: country.code }))}
                        mandatory
                        value={country}
                        setValue={setCountry}
                    />
                    <Row mt={spacings.m} style={{ gap: spacings.xxs }}>
                        <Button
                            disabled={!company || !streetAndNo || !city || !zip || !country}
                            secondary
                            label={t('actions.next')}
                            onClick={() => {
                                dispatch(setBillingDetails({
                                    company,
                                    streetAndNo,
                                    city,
                                    zip,
                                    country
                                }));
                                navigate('/licences/payment');
                            }}
                        />
                        <Button
                            tertiary
                            label={t('actions.cancel')}
                            onClick={() => navigate('/settings/subscription')}
                        />
                    </Row>
                    <Small mt={spacings.m}>
                        *{t('auth.mandatoryField')}
                    </Small>
                </div>
                <InfoBox />
            </Row>
        </ContentWrapper>
    );
};

export default LicenceBilling;