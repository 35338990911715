import { Arrow, InfoBox, Wrapper } from './styled';
import { IGenericElement } from '@/common/types';
import { IHoverIcon } from './types';
import { Micro } from '../text';
import { useState } from 'react';

const HoverIcon = ({ icon, hover, style, info, onClick }: IHoverIcon & IGenericElement) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    return (
        <Wrapper
            style={style}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
        >
            {isHovered ? hover : icon}
            {info && isHovered ? <InfoBox>
                <Micro>{info}</Micro>
                <Arrow />
            </InfoBox> : null}
        </Wrapper>
    );
};

export default HoverIcon;