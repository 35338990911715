import { COUNTRIES } from '@/assets/constants/countries';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Dropdown from '@/common/components/dropdown';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import { Headline } from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import { IPopup } from '@/common/types';
import { getBillingDetails, updateBillingAddress } from '@/services/api/payment-service';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setUserBillingDetails } from '@/store/licence-slice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const BillingPopup = ({ close }: IPopup) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const userBillingDetails = useAppSelector(state => state.licence.userBillingDetails);
    const [company, setCompany] = useState<string>(userBillingDetails?.name || '');
    const [streetAndNo, setStreetAndNo] = useState<string>(userBillingDetails?.address?.line1 || '');
    const [city, setCity] = useState<string>(userBillingDetails?.address?.city || '');
    const [zip, setZip] = useState<string>(userBillingDetails?.address?.postal_code || '');
    const [country, setCountry] = useState<string>(userBillingDetails?.address?.country || 'DE');
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Popup close={close}>
            <Headline quaternary>
                {t('licence.billing')}
            </Headline>
            <TextBox
                mt={spacings.s}
                label={t('forms.company')}
                mandatory
                value={company}
                setValue={setCompany}
            />
            <TextBox
                mt={spacings.xxs}
                label={t('forms.streetAndNo')}
                mandatory
                value={streetAndNo}
                setValue={setStreetAndNo}
            />
            <TextBox
                mt={spacings.xxs}
                label={t('forms.city')}
                mandatory
                value={city}
                setValue={setCity}
            />
            <TextBox
                mt={spacings.xxs}
                label={t('forms.zip')}
                mandatory
                value={zip}
                setValue={setZip}
            />
            <Dropdown
                mt={spacings.xxs}
                label={t('forms.country')}
                options={COUNTRIES.map(country => ({ label: country.name, value: country.code }))}
                mandatory
                value={country}
                setValue={setCountry}
            />
            <Row mt={spacings.m} style={{ justifyContent: 'flex-end' }}>
                <Button
                    mr={spacings.xxs}
                    tertiary
                    label={t('actions.cancel')}
                    onClick={close} />
                <Button
                    loading={loading}
                    label={t('actions.saveChanges')}
                    onClick={async () => {
                        setLoading(true);
                        const [data, error] = await updateBillingAddress({
                            name: company,
                            address: {
                                street: streetAndNo,
                                city: city,
                                postal_code: zip,
                                country: country,
                            }
                        });
                        setLoading(false);
                        if (!error) {
                            const [data, error] = await getBillingDetails();
                            if (data) {
                                dispatch(setUserBillingDetails(data));
                            }
                            close();
                        }
                    }} />
            </Row>
        </Popup>
    );
};

export default BillingPopup;