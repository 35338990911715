import { divideDuration } from '../../common/helpers';
import { Duration } from 'date-fns';
import { ISpeaker } from './types';

export const equalizeSpeakerTime = (speakers: ISpeaker[], totalDuration: Duration) => {
    if (speakers.length === 0) {
        return speakers;
    }

    const equalDuration = divideDuration(totalDuration, speakers.length);
    return speakers.map(speaker => ({
        ...speaker,
        duration: equalDuration
    }));
}