import Button from '../../../../common/components/button';
import colors from '@/assets/constants/colors';
import Row from '@/common/components/row';
import spacings from '../../../../assets/constants/spacings';
import { IoMdCheckmark } from 'react-icons/io';
import { Small } from '@/common/components/text';
import { StepIndex, STEPS } from '../../steps';
import { useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styled';

const VISIBLE_DURATION = 2000;
const FADE_DURATION = 500;

const AttendeeNavbar = ({ index }: { index: StepIndex; }) => {
    const step = STEPS[index];
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [opacity, setOpacity] = useState<number>(0);
    const attendees = useAppSelector((state) => state.createMeeting.attendees);
    const [initialCount, setInitialCount] = useState<number>(attendees.length);
    const [hasDeletedAttendee,setHasDeletedAttendee] = useState<boolean>(false);

    useEffect(() => {
        if (attendees.length !== initialCount) {
            setOpacity(1);
            setTimeout(() => setOpacity(0), VISIBLE_DURATION);
        }
    }, [attendees.length]);

    useEffect(()=>{
        attendees.forEach((attendee)=>{
            if(!attendee.email){
                setHasDeletedAttendee(true);
            } else {
                setHasDeletedAttendee(false);
            }
        })
    },[attendees])

    return (
        <Wrapper>
            <Row style={{ opacity: opacity, transition: `${FADE_DURATION}ms all ease-in-out` }}>
                <IoMdCheckmark color={colors.Brown2} size={18} />
                <Small color={colors.Brown2} ml={spacings.xxxs}>
                    {t('create.automaticSave')}
                </Small>
            </Row>
            <Row>
                {step.prev &&
                    <Button
                        label={t('actions.back')}
                        small
                        secondary
                        onClick={() => step.prev && navigate(step.prev)}
                        ml={spacings.xxs}
                        mr={spacings.xxxs} />
                }
                {step.next &&
                    <Button
                        label={t('actions.next')}
                        small
                        disabled={attendees.length === 0 || hasDeletedAttendee}
                        onClick={() => step.next && navigate(step.next)} />
                }
            </Row>
        </Wrapper>
    );
};

export default AttendeeNavbar;