import colors from "@/assets/constants/colors";
import spacings from "@/assets/constants/spacings";
import { t } from "i18next";
import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { RiCloseCircleLine } from 'react-icons/ri';
import Row from "../row";
import TextBoxBorderless from "../textbox-borderless";

const Searchbar = ({ value, setValue, style }: { value: string; setValue: (text: string) => void; style?: any; }) => {
    const [width, setWidth] = useState<number>(85);
    const [active, setActive] = useState<boolean>(false);

    const styles = {
        borderColor: colors.Black,
        borderRadius: 50,
    };

    return (
        <Row style={{
            position: 'relative',
            ...style ? style : {},
        }}>
            <TextBoxBorderless
                noLinebreak
                noIcon
                value={value}
                setValue={setValue}
                placeholder={t('common.search')}
                size="small"
                style={{
                    width: width,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: spacings.xxs,
                    paddingRight: active && value ? spacings.l : spacings.xxxs,
                    border: `2px solid`,
                    borderColor: colors.Transparent,
                    ...active ? styles : {},
                }}
                onFocus={() => {
                    setActive(true);
                    setWidth(275);
                }}
                onBlur={() => {
                    if (value) {
                        return;
                    }
                    setActive(false)
                    setWidth(85);
                }}
            />
            {active && value && <div style={{
                position: 'absolute',
                right: 38,
                top: 0,
                height: '100%',
                width: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <RiCloseCircleLine
                    size={24}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setValue('')}
                />
            </div>}
            <div style={{
                position: 'absolute',
                right: 0,
                top: active ? 0 : 2,
                backgroundColor: active ? colors.Black : colors.Transparent,
                borderRadius: 100,
                height: '100%',
                width: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <BiSearch
                    size={22}
                    color={active ? colors.White : colors.Black}
                />
            </div>
        </Row >
    );
};

export default Searchbar;