"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.abs = void 0;
var isNegative_1 = require("./isNegative");
var negate_1 = require("./negate");
var parse_1 = require("./parse");
/**
 * Get the absolute value of a duration.
 *
 * @example
 * abs({ days: -1, seconds: 1 })
 * // { days: 1, seconds: -1 }
 */
var abs = function (duration) {
    if (isNegative_1.isNegative(duration)) {
        return negate_1.negate(duration);
    }
    return parse_1.parse(duration);
};
exports.abs = abs;
