import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Alert from '@/common/components/alert';
import Button from '@/common/components/button';
import Dropdown from '@/common/components/dropdown';
import Row from '@/common/components/row';
import { Body } from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import { getPronouns } from '@/common/helpers';
import { changePassword, getSelf, selfEdit } from '@/services/api/auth-service';
import { setUser } from '@/store/auth-slice';
import { ILoginResponse } from '@/store/auth-slice/types';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseCircleLine } from 'react-icons/ri';
import DeleteAccountPopup from '../delete-account-pupup';

const Account = () => {
    const { t } = useTranslation();
    const user = useAppSelector((state) => state.auth.user);
    const dispatch = useAppDispatch();

    const [name, setName] = useState<string | undefined>(user?.name || '');
    const [email, setEmail] = useState<string | undefined>(user?.email || '');
    const [pronouns, setPronouns] = useState<string>(user?.pronouns || 'she/her');
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
    const [showDataError, setShowDataError] = useState<boolean>(false);
    const [showPasswordSuccess, setShowPasswordSuccess] = useState<boolean>(false);
    const [showDataSuccess, setShowDataSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState<boolean>(false);

    const onClickSaveProfile = async () => {
        setShowDataError(false);
        setShowDataSuccess(false);

        if (!name || !email || !pronouns) {
            setShowDataError(true);
            return;
        }

        if (name === user?.name && pronouns === user?.pronouns && email===user?.email) {
            return;
        }

        setLoading(true);
        const [data, error] = await selfEdit({ name, email, pronouns });
        setLoading(false);
        if (data) {
            setLoading(true);
            const [sData, sError] = await getSelf();
            setLoading(false);
            const response: ILoginResponse = sData;
            if (response) {
                setShowDataSuccess(true);
                dispatch(setUser({
                    id: response.id,
                    name: response.name,
                    email: response.email,
                    pronouns: response.pronouns,
                    organisatonId: response.organisation_id,
                    role: response.role,
                }));
            }
        }

        if (oldPassword && newPassword && confirmPassword) {
            setLoading(true);
            const [data, error] = await changePassword(oldPassword, newPassword, confirmPassword);
            setLoading(false);
            if (error) {
                setShowPasswordError(true);
            } else {
                setShowPasswordSuccess(true);
            }
        }
    };

    const onClickSavePassword = async () => {
        setShowPasswordError(false);
        setShowPasswordSuccess(false);

        if (oldPassword && newPassword && confirmPassword) {
            setLoading(true);
            const [data, error] = await changePassword(oldPassword, newPassword, confirmPassword);
            setLoading(false);
            if (error) {
                setShowPasswordError(true);
            } else {
                setShowPasswordSuccess(true);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            }
        }
    };

    return (
        <div style={{ marginTop: spacings.l }}>
            <div style={{ maxWidth: 650 }}>
                <Body bold mb={spacings.xxs}>
                    {t('settings.emailName')}
                </Body>
                <TextBox
                    style={{ flex: 1 }}
                    label={t('forms.email')}
                    value={email}
                    setValue={(text) => setEmail(text)}
                    mb={spacings.xxs}
                />
                <TextBox
                    style={{ flex: 1 }}
                    label={t('forms.name')}
                    value={name}
                    error={showDataError}
                    setValue={(text) => setName(text)}
                    mb={spacings.xxs}
                />
                <Dropdown
                    options={getPronouns()}
                    label={t('forms.pronouns')}
                    value={pronouns}
                    setValue={setPronouns}
                />
            </div>
            <div style={{ marginTop: spacings.l, maxWidth: 650 }}>
                <Body bold mb={spacings.xxs}>
                    {t('settings.password')}
                </Body>
                <TextBox
                    style={{ flex: 1 }}
                    label={t('common.oldPassword')}
                    value={oldPassword}
                    setValue={setOldPassword}
                    mb={spacings.xxs}
                    type="password"
                    error={showPasswordError}
                />
                <TextBox
                    style={{ flex: 1 }}
                    label={t('common.newPassword')}
                    value={newPassword}
                    setValue={setNewPassword}
                    mb={spacings.xxs}
                    type="password"
                    error={showPasswordError}
                />
                <TextBox
                    style={{ flex: 1 }}
                    label={t('common.passwordConfirm')}
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    type="password"
                    error={showPasswordError}
                />
                {showPasswordError && <Alert
                    mt={spacings.xxs}
                    bgColor={colors.Error}
                    text={t('auth.passwordError')}
                />}
                {showPasswordSuccess && <Alert
                    close={() => setShowPasswordSuccess(false)}
                    mt={spacings.xxs}
                    bgColor={colors.Teal1}
                    text={t('auth.passwordSuccess')}
                />}
                {showDataSuccess && <Alert
                    close={() => setShowDataSuccess(false)}
                    mt={spacings.xxs}
                    bgColor={colors.Teal1}
                    text={t('auth.editProfileSuccess')}
                />}
                <Row style={{ justifyContent: 'space-between' }}>
                    <Button
                        loading={loading}
                        mt={spacings.l}
                        label={t('actions.saveChanges')}
                        onClick={() => {
                            onClickSaveProfile();
                            onClickSavePassword();
                        }}
                    />
                    <Button
                        tertiary
                        mt={spacings.l}
                        label={t('settings.deleteAccount')}
                        style={{ color: colors.Error }}
                        iconRight={<RiCloseCircleLine
                            size={24}
                            style={{ marginLeft: spacings.xxxs }}
                        />}
                        onClick={()=>setShowDeleteAccountPopup(true)}
                    />
                </Row>
                {showDeleteAccountPopup &&
                <DeleteAccountPopup
                    close={() => setShowDeleteAccountPopup(false)}
                />
            }
            </div>
        </div>
    );
};

export default Account;