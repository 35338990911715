import Colors from '../../../assets/constants/colors';
import Spacings from '../../../assets/constants/spacings';
import styled from 'styled-components';

export const SIDEBAR_WIDTH = 250;

export const Wrapper = styled.div`
    border-right: 1px solid ${Colors.Beige1};
    width: ${SIDEBAR_WIDTH}px;
    height: 100vh;
    box-sizing: border-box;
    padding: ${Spacings.l}px;
    position: fixed;
    @media (max-width:1000px){
        position: absolute
    }

`;

export const Navigation = styled.div`
    border-bottom: 1px solid ${Colors.Beige1};
    margin-top: ${Spacings.s}px;
    padding-bottom: ${Spacings.s}px;
`;

export const ListItemWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;
    ${props => props.disabled && 'pointer-events: none'};

    &:not(:last-child) {
        margin-bottom: ${Spacings.s}px;
    }
`;
