import * as DurationFns from 'duration-fns';
import Button from '../../../../common/components/button';
import Datepicker from '../../../../common/components/datepicker';
import Popup from '../../../../common/components/popup';
import Row from '../../../../common/components/row';
import Spacings from '../../../../assets/constants/spacings';
import TextBox from '../../../../common/components/textbox';
import { addSeconds } from 'date-fns';
import { Headline, Small } from '../../../../common/components/text';
import { IAddUserPopup } from './types';
import { setMeetingDate } from '../../../../store/create-meeting-slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AddDatePopup = ({ close, initialDate }: IAddUserPopup) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const agendaDate = useAppSelector((state) => state.createMeeting.date);
    const items = useAppSelector((state) => state.createMeeting.agenda.items);
    const duration = items.reduce(
        (acc, cur) => DurationFns.sum(acc, cur.duration),
        { seconds: 0 }
    );
    const discussion = items.reduce(
        (acc, cur) => DurationFns.sum(acc, cur.discussion.duration),
        { seconds: 0 }
    );
    const feedback = items.reduce(
        (acc, cur) => DurationFns.sum(acc, cur.feedback.duration),
        { seconds: 0 }
    );

    const [place, setPlace] = useState<string | undefined>(agendaDate?.place || undefined);
    const [date, setDate] = useState<Date>(initialDate || new Date());
    const [starttime, setStarttime] = useState<Date>(initialDate || new Date());
    const endtime = addSeconds(
        starttime,
        DurationFns.toSeconds(duration) + DurationFns.toSeconds(discussion) + DurationFns.toSeconds(feedback)
    );

    return (
        <Popup close={close}>
            <Headline quaternary mb={Spacings.s}>
                {t('create.stepDate.setDate')}
            </Headline>
            <Datepicker
                label={t('forms.date')}
                date={date}
                setDate={setDate} />
            <Row style={{ marginTop: Spacings.xxs, gap: Spacings.xs }}>
                <Datepicker
                    timeOnly
                    label={t('forms.starttime')}
                    date={starttime}
                    setDate={setStarttime} />
                <Small mt={24}>{t('common.to')}</Small>
                <Datepicker
                    timeOnly
                    disabled
                    label={t('forms.endtime')}
                    date={endtime}
                    setDate={() => { }} />
            </Row>
            <TextBox
                mt={Spacings.xxs}
                label={t('forms.place')}
                description={t('forms.placeDescription')}
                value={place}
                setValue={setPlace} />

            <Row style={{ marginTop: Spacings.m, justifyContent: 'flex-end' }}>
                <Button
                    mr={Spacings.xxs}
                    tertiary
                    label={t('actions.cancel')}
                    onClick={close} />
                <Button
                    label={t('actions.save')}
                    onClick={() => {
                        const start = date.setHours(starttime.getHours(), starttime.getMinutes(), starttime.getSeconds());
                        const end = date.setHours(endtime.getHours(), endtime.getMinutes(), endtime.getSeconds());
                        dispatch(setMeetingDate({
                            start: new Date(start),
                            end: new Date(end),
                            place: place,
                        }));
                        close();
                    }} />
            </Row>
        </Popup>
    );
}

export default AddDatePopup;