import colors from '@/assets/constants/colors';
import { COUNTRIES } from '@/assets/constants/countries';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import Dropdown from '@/common/components/dropdown';
import Row from '@/common/components/row';
import { Small } from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import { ErrorBox } from '@/features/auth/styled';
import { getPaymentMethodDetails, getSetupIntent } from '@/services/api/payment-service';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setPaymentDetails, setPaymentMethodDetails, setSetupIntent } from '@/store/licence-slice';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { InputWrapper } from './styled';

const PaymentForm = () => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const _paymentMethod = useAppSelector((state) => state.licence.paymentDetails?.paymentMethod);
    const _cardHolder = useAppSelector((state) => state.licence.paymentDetails?.cardHolder);
    const _country = useAppSelector((state) => state.licence.paymentDetails?.country);
    const [paymentMethod, setPaymentMethod] = useState<string>(_paymentMethod || 'credit');
    const [cardHolder, setCardHolder] = useState<string>(_cardHolder || '');
    const [country, setCountry] = useState<string>(_country || 'DE');
    const [loading, setLoading] = useState<boolean>(false);
    const [cardDeclined, setCardDeclined] = useState<boolean>(false);
    const [cardError, setCardError] = useState<boolean>(false);
    

    const cardStyle = {
        style: {
            base: {
                fontFamily: 'NotoSans',
                fontSize: '18px',
                backgroundColor: colors.White,
                color: colors.Black,
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: colors.Brown2
                }
            },
            invalid: {
                color: colors.Error,
                iconColor: colors.Error,
            }
        }
    };

    const handleSubmit = (stripe: any, elements: any) => async () => {
        setCardDeclined(false);
        setCardError(false);
        const cardElement = elements.getElement(CardNumberElement);

        setLoading(true);
        const [data, error] = await getSetupIntent();
        setLoading(true);
      
        if (data && data.client_secret) {
            setLoading(true);
            const { setupIntent, error } = await stripe.confirmCardSetup(data.client_secret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: cardHolder,
                        address: {
                            country: country,
                        },
                    },
                },
            });
            setLoading(false);

            if (error) {
                console.log('[error!]', error.code);  
                if(error.code=="card_declined"){
                    console.log("nocard")
                    setCardDeclined(true);
                }else{
                    setCardError(true);
                }
         

            } else {
                dispatch(setPaymentDetails({ cardHolder, country, paymentMethod }));
                dispatch(setSetupIntent(setupIntent));

                setLoading(true);
                const [data, error] = await getPaymentMethodDetails(setupIntent.payment_method);
                setLoading(false);

                if (data) dispatch(setPaymentMethodDetails(data));
                navigate('/licences/summary');
            }
        }
    };

    return (
        <>
            <Dropdown
                mt={spacings.s}
                label={t('forms.paymentMethod')}
                options={[{ label: 'Credit Card', value: 'credit' }]}
                mandatory
                value={paymentMethod}
                setValue={setPaymentMethod}
            />
            <TextBox
                mt={spacings.xxs}
                label={t('forms.cardHolder')}
                mandatory
                value={cardHolder}
                setValue={setCardHolder}
            />
            <div>
                <Small
                    mt={spacings.xxs}
                    mb={spacings.xxxs}
                    color={colors.Black}
                >
                    {t('forms.cardNumber')}*
                </Small>
                <InputWrapper>
                    <CardNumberElement options={cardStyle} />
                </InputWrapper>
            </div>
            <Row style={{ gap: spacings.xxs }}>
                <div style={{ flex: 1 }}>
                    <Small
                        mt={spacings.xxs}
                        mb={spacings.xxxs}
                        color={colors.Black}
                    >
                        {t('forms.expiryDate')}*
                    </Small>
                    <InputWrapper>
                        <CardExpiryElement options={cardStyle} />
                    </InputWrapper>
                </div>
                <div style={{ flex: 1 }}>
                    <Small
                        mt={spacings.xxs}
                        mb={spacings.xxxs}
                        color={colors.Black}
                    >
                        {t('forms.cvc')}*
                    </Small>
                    <InputWrapper>
                        <CardCvcElement options={cardStyle} />
                    </InputWrapper>
                </div>
            </Row>
            <Dropdown
                mt={spacings.xxs}
                label={t('forms.country')}
                options={COUNTRIES.map(country => ({ label: country.name, value: country.code }))}
                mandatory
                value={country}
                setValue={setCountry}
            />
            <Row mt={spacings.m}>
                <Button
                    secondary
                    label={t('licence.checkDetails')}
                    onClick={handleSubmit(stripe, elements)}
                    loading={loading}
                />
            </Row>
            {(cardError || cardDeclined )&& <ErrorBox>
                    <Row style={{ alignItems: 'flex-start' }}>
                        <AiOutlineInfoCircle
                            size={22}
                            color={colors.Error}
                            style={{
                                marginTop: 2,
                                marginRight: spacings.xxxs,
                                flexShrink: 0,
                            }}
                        />
                        <Small>
                            {cardError && t('forms.generalCardErrorMessage')}
                            {cardDeclined && t('forms.cardDeclinedErrorMessage')}
                        </Small>
                    </Row>
                </ErrorBox>}
        </>
    );
}

export default PaymentForm;