import Button from '@/common/components/button';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Body, Headline } from '@/common/components/text';
import { IPopup } from '@/common/types';
import { removeUser } from '@/store/general-slice';
import { removeUserFromOrganisation } from '@/services/api/organisation-service';
import { useAppDispatch } from '@/store/hooks';
import { useTranslation } from 'react-i18next';
import { deleteSelf } from '@/services/api/auth-service';
import colors from '@/assets/constants/colors';
import { useNavigate } from 'react-router-dom';


const DeleteAccountPopup = ({close}: IPopup) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onClickDelete = async () => {
        const [data, error] = await deleteSelf();
        if (!error) {
            navigate('/login')
        }
    };

    return (
        <Popup close={close} alert>
            <Headline quaternary mb={spacings.s}>
                {t('settings.deleteAccount')}
            </Headline>
            <Body 
                color={colors.Error}
                mt={spacings.s}>
                {t('settings.sureToDeleteAccount')}
            </Body>
            <Row mt={spacings.m} style={{ justifyContent: 'flex-end' }}>
                <Button
                    mr={spacings.xxs}
                    tertiary
                    label={t('actions.noCancel')}
                    onClick={close} />
                <Button
                    style={{ background: colors.Error }}
                    label={t('actions.yesDelete')}
                    onClick={onClickDelete} />
            </Row>
        </Popup>
    );
}

export default DeleteAccountPopup;