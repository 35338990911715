import i18n from '@/i18n';
import { ICreateMeetingStep } from './types';


export const STEPS: ICreateMeetingStep[] = [
    {
        key: 'Title',
        title: i18n.t('create.stepTitle.title'),
        path: '/create-meeting/title',
        next: '/create-meeting/attendees',
    },
    {
        key: 'Attendees',
        title: i18n.t('create.stepAttendees.title'),
        path: '/create-meeting/attendees',
        prev: '/create-meeting/title',
        next: '/create-meeting/agenda',
    },
    {
        key: 'Agenda',
        title: i18n.t('create.stepAgenda.title'),
        path: '/create-meeting/agenda',
        prev: '/create-meeting/attendees',
        next: '/create-meeting/date',
    },
    {
        key: 'Date',
        title: i18n.t('create.stepDate.title'),
        path: '/create-meeting/date',
        prev: '/create-meeting/agenda',
        next: '/create-meeting/summary',
    },
    {
        key: 'Summary',
        title: i18n.t('create.stepSummary.title'),
        path: '/create-meeting/summary',
        prev: '/create-meeting/date',
    }
];

export enum CreateStep {
    One = 0,
    Two = 1,
    Three = 2,
    Four = 3,
    Five = 4,
}

export type StepIndex = 0 | 1 | 2 | 3 | 4 | 5;