import i18n from 'i18next';
import translationEN from './locales/en/translation.json';
import { initReactI18next } from 'react-i18next';

// translations files
const resources = {
    en: {
        translation: translationEN
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        fallbackLng: false,
        saveMissingTo: 'current',
        keySeparator: '.', // we do not use keys in form messages.welcome
        compatibilityJSON: 'v3',
        interpolation: {
            escapeValue: false // react already safes from xss
        },
    });

export default i18n;