import { CreateMeetingState } from '@/store/create-meeting-slice/types';
import { initialAgendaItems } from '@/store/create-meeting-slice';
import { nanoid } from 'nanoid';

export const TEMPLATE_OPTIONS = [
    { label: 'Plain', value: '0' },
    { label: 'Productive Team Meeting', value: '1' },
    { label: 'Half Day Brainstorming Session', value: '2' },
    { label: 'Daily Standup', value: '3' },
];

export const TEMPLATES: CreateMeetingState[] = [
    {
        id: undefined,
        userId: undefined,
        externalId: undefined,
        title: undefined,
        selfAttend: false,
        attendees: [],
        agenda: {
            goal: undefined,
            links: undefined,
            items: initialAgendaItems,
        },
        date: undefined,
    }, {
        id: undefined,
        userId: undefined,
        externalId: undefined,
        title: 'Productive Team Meeting',
        selfAttend: false,
        attendees: [],
        agenda: {
            goal: 'The goal of this team meeting is to check in with everybody, discuss recent company updates and goals progression. Every attendee is asked to prepare: their progress to goal in numbers, any wins or learnings since last team meeting, possible or actual blockers.',
            links: undefined,
            items: [
                {
                    id: nanoid(),
                    name: 'Introduction',
                    description: 'Welcome and little ice breaker. ———— Tip: For more inspiration on how to start team meetings visit calvah.com/blog.',
                    speakers: [],
                    duration: { minutes: 13 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: undefined, duration: { seconds: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Management Update',
                    description: 'Update on relevant company updates.',
                    speakers: [],
                    duration: { minutes: 5 },
                    discussion: { question:" Do you have any questions or feedback on the updates?", duration: { minutes: 5 } },
                    feedback: { question: undefined, duration: { seconds: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Team Updates',
                    description: 'Individual updates. ———— Tip: Include everyone as speaker, so everyone will get theit turn. A suggested structure for team reports include: Progress to goal (quantity and quality), Wins, Learnings, Blockers.',
                    speakers: [],
                    duration: { minutes: 25 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: undefined, duration: { seconds: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'What else is on your mind? Q&A',
                    description: 'Opportunity to share anonymous feedback to discuss any topic that is on your mind.',
                    speakers: [],
                    duration: { minutes: 1 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: "What is on your mind?", duration: { minutes: 9 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Evaluation',
                    description: 'Time for all to reflect on the meetings quality by answering 3 key questions anonymously.',
                    speakers: [],
                    duration: { minutes: 2 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: undefined, duration: { seconds: 0 } },
                    links: undefined,
                    editable: false,
                    errors: undefined,
                }
            ],
        },
        date: undefined,
    }, {
        id: undefined,
        userId: undefined,
        externalId: undefined,
        title: 'Half Day Brainstorming Session',
        selfAttend: false,
        attendees: [],
        agenda: {
            goal: 'Brainstorming session with maximum diversity of thought and productivity.',
            links: undefined,
            items: [
                {
                    id: nanoid(),
                    name: 'Introduction',
                    description: 'Introducing the workshop goal and small ice breaker. ———— Tip: Use your introduction for an ice breaker to lighten the mood and to build team spirit, you can find recommendations on calvah.com/blog. Plan 5 minutes to welcome everyone and to introduce the ice breaker. Plan 1 minute for each attendee to participate in the icebreaker.',
                    speakers: [],
                    duration: { minutes: 15 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: undefined, duration: { seconds: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Understanding our challenge',
                    description: 'Understanding our challenge to define an insight statement. ———— Tip: If you already have an idea or challenge you want to explore let everyone rephrase the original question. For example "The toaster toasts too slow" turns into "The waiting time is boring". If you want to explore a new challenge use leading questions like "Why is it a challenge?" or "What words pop into your head if you think about the challenge". Plan 5 Minutes to explain the exercise. Use the feedback round to allow everyone to write down their thoughts and share it via Calvah. Copy all answers to a virtual white board or sheet and share with the attendees.',
                    speakers: [],
                    duration: { minutes: 5 },
                    discussion: { question: undefined, duration: { minutes: 0 } },
                    feedback: { question: undefined, duration: { minutes: 10 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Narrowing our challange down',
                    description: 'Finding common ground and forming our insight statement ———— Tip: Start to group all answers into clusters. For example cluster 1: The waiting time is boring and cluster 2: I always forget my toast. Decide with the group which of the cluster(s) you want to find a solution for. Plan 5 min to explain this exercise. Plan more time for an open discussion in which you\'ll you create and discuss the clusters. Use the written feedback tool to decide which cluster(s) you want to continue with in this workshop.',
                    speakers: [],
                    duration: { minutes: 5 },
                    discussion: { question: "Can we group our feedback?", duration: { minutes: 20 } },
                    feedback: { question: "Which idea do we want to move forward with?", duration: { minutes: 2 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Short break',
                    description: 'Short Break',
                    speakers: [],
                    duration: { minutes: 15 },
                    discussion: { question: undefined, duration: { minutes: 0 } },
                    feedback: { question: undefined, duration: { minutes: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Explore opportunities',
                    description: 'How might we? Rephrasing our challenge into an opportunity. ———— Tip: Try reframing your challenge as "How Might We" questions to turn them into opportunities. How (narrow down a possible solution) - Might (that solution is ONE possibility) - We (we as a team). For example: "How might we turn the wating time into a fun activity?", "How might we altert the user when the toast is ready?" Plan 5 minutes to explain this exercise. Add more time for written feedback to allow everyone to share their "How Might We" questions.',
                    speakers: [],
                    duration: { minutes: 5 },
                    discussion: { question: undefined, duration: { minutes: 0 } },
                    feedback: { question: 'What are your "How Might We" statements?', duration: { minutes: 10 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Consolidating our opportunites',
                    description: 'Consolidating all opportunities and deciding which opportunity we want to explore further. ———— Tip: Copy all answers to a virtual white board or sheet and share with the attendees. Start to group similar answers. Plan 5 min to explain this exercise, then you\'ll start an open discussion while you group the answers. At the end use the written feedback tool to decide which opportunity / "how might we question" you want to continue with in this workshop.',
                    speakers: [],
                    duration: { minutes: 5 },
                    discussion: { question: "Grouping of similar ideas", duration: { minutes: 17 } },
                    feedback: { question: "Which idea do we want to explore further?", duration: { minutes: 2 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Short break',
                    description: 'Short Break',
                    speakers: [],
                    duration: { minutes: 15 },
                    discussion: { question: undefined, duration: { minutes: 0 } },
                    feedback: { question: undefined, duration: { minutes: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Explore solutions',
                    description: 'Brainstorm solutions. ———— Tip: Use this time to allow everyone to come up with answers to the selcted "How might we" question / opportunity. Encourage everyone to submit multiple answers and solutions. For example: "We could activate a sound when the toast is ready", "We could invent a toaster that reads entertaining short stories while toasting". Plan 5 minutes to explain the exercise. Afterwards plan time for the written feedback, so everyone can come up with their answers.Copy all answers to a virtual white board or sheet and share with the attendees.',
                    speakers: [],
                    duration: { minutes: 5 },
                    discussion: { question: undefined, duration: { minutes: 0 } },
                    feedback: { question: "Think about multiple solutions to our opportunity", duration: { minutes: 15 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Consolidating our solutions',
                    description: 'Gouping similar solutions and ranking them.  ———— Tip: Plan 5 min to explain this exercise. Plan more time for the open discussion while you group the answers. Afterwards use written feedback to vote on which solution(s) you want to continue with in this workshop.',
                    speakers: [],
                    duration: { minutes: 5 },
                    discussion: { question: "Grouping our solutions", duration: { minutes: 20 } },
                    feedback: { question: "Which solution do we want to continue working on?", duration: { minutes: 2 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Define Next Steps',
                    description: 'Defining next steps. ———— Tip: Use this time to decide on next steps to turn your solutions into action. Decide who is responsible to work on the solution(s) movoing forward. Try to come up with SMART goals (Specific, Measurable, Achievable, Relevant, Time bound). For example: We want to implement an alarm sound to inform users when their toast is ready. The product team agreed to create a prototype within 2 month and will test with the usual test framework. In 3 month we will get the results. Plan 5 minutes to explain the exercise. Use the discussion time to discuss next steps',
                    speakers: [],
                    duration: { minutes: 5 },
                    discussion: { question: "Discussing smart goals and next steps", duration: { minutes: 15 } },
                    feedback: { question: undefined, duration: { minutes: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Outro',
                    description: 'Workshop reflection. ———— Tip: Ask every attendee to summarize in one sentence how they feel about the workshop and what they have learned. Explain this exercise first and start with your summary before you go around the room. Add every attendee as speaker, so everyone will get their turn.',
                    speakers: [],
                    duration: { minutes: 15 },
                    discussion: { question: undefined, duration: { minutes: 0 } },
                    feedback: { question: undefined, duration: { minutes: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Evaluation',
                    description: 'Time for all to reflect on the meetings quality by answering 3 key questions anonymously.',
                    speakers: [],
                    duration: { minutes: 2 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: undefined, duration: { seconds: 0 } },
                    links: undefined,
                    editable: false,
                    errors: undefined,
                }
            ],
        },
        date: undefined,
    }, {
        id: undefined,
        userId: undefined,
        externalId: undefined,
        title: 'Daily Standup',
        selfAttend: false,
        attendees: [],
        agenda: {
            links: undefined,
            goal: 'Give our team a focused way to align around what is happening in our business, plan daily work accordingly, and remove blockers',
            items: [
                {
                    id: nanoid(),
                    name: 'Introduction',
                    description: 'Short mood check. Everyone can share how they feel in one word.',
                    speakers: [],
                    duration: { minutes: 2 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: undefined, duration: { seconds: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Individual updates',
                    description: 'Everonye will share their updates in turns: What did you do yesterday? What will you do today? Any blockers?',
                    speakers: [],
                    duration: { minutes: 10 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: undefined, duration: { seconds: 0 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'AOB - Any other business',
                    description: 'Feel free to share what is on your mind openly or in anonymous, written form',
                    speakers: [],
                    duration: { minutes: 1 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: undefined, duration: { minutes: 2 } },
                    links: undefined,
                    editable: true,
                    errors: undefined,
                }, {
                    id: nanoid(),
                    name: 'Evaluation',
                    description: 'Time for all to reflect on the meetings quality by answering 3 key questions anonymously.',
                    speakers: [],
                    duration: { minutes: 2 },
                    discussion: { question: undefined, duration: { seconds: 0 } },
                    feedback: { question: undefined, duration: { seconds: 0 } },
                    links: undefined,
                    editable: false,
                    errors: undefined,
                }
            ],
        },
        date: undefined,
    }
];