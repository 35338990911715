import * as DurationFns from 'duration-fns';
import colors from '@/assets/constants/colors';
import HoverIcon from '@/common/components/hover-icon';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Bar } from './styled';
import { BsExclamationTriangle, BsExclamationTriangleFill } from 'react-icons/bs';
import { formatDurationCustom } from '@/common/helpers';
import { Micro, Small } from '@/common/components/text';
import { SeparatorLine } from '../../agenda/agenda-item/styled';
import { useAppSelector } from '@/store/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SpeakerTimes = () => {
    const { t } = useTranslation();
    const attendees = useAppSelector((state) => state.createMeeting.attendees);
    const items = useAppSelector((state) => state.createMeeting.agenda.items);
    const navigate = useNavigate();

    const getSpeakerDuration = (email: string) => {
        return items.reduce((itemAcc, item) => {
            const stepDuration = item.speakers.reduce((speakerAcc, speaker) => {
                if (speaker.attendee.email === email) {
                    return DurationFns.sum(speakerAcc, speaker.duration);
                }
                return speakerAcc;
            }, { seconds: 0 });
            if (stepDuration) {
                return DurationFns.sum(itemAcc, stepDuration);
            }
            return itemAcc;
        }, { seconds: 0 });
    };

    const timesPerSpeaker = attendees.map(attendee => ({
        attendee: attendee,
        duration: getSpeakerDuration(attendee.email)
    }));
    const longestDurationInSeconds = timesPerSpeaker.reduce((acc, cur) => {
        if (acc > DurationFns.toSeconds(cur.duration)) {
            return acc;
        } else {
            return DurationFns.toSeconds(cur.duration);
        }
    }, 0);
    const shortestDurationInSeconds = timesPerSpeaker.reduce((acc, cur) => {
        if (acc <= DurationFns.toSeconds(cur.duration)) {
            return acc;
        } else {
            return DurationFns.toSeconds(cur.duration);
        }
    }, DurationFns.toSeconds(timesPerSpeaker[0].duration));
    const percentageOfShortestOnLongest = shortestDurationInSeconds / longestDurationInSeconds * 100;
    const threshold = 51;

    return (
        <div>
            <Micro color={colors.Brown1} mb={spacings.xxxs}>
                {t('create.stepSummary.timePlanned')}
            </Micro>
            <div>
                {timesPerSpeaker.map(times => {
                    const percentage = DurationFns.toSeconds(times.duration) / longestDurationInSeconds * 100;
                    return (
                        <Row
                            key={times.attendee.email}
                            mb={spacings.xxxs}
                            style={{ justifyContent: 'space-between' }}
                        >
                            {times.attendee.email?
                            <Row style={{width:'100%', justifyContent:'space-between'}}>
                                <Small bold>
                                    {times.attendee.name}
                                </Small>
                                <Small mr={spacings.m}>
                                    ({times.attendee.pronouns})
                                </Small>
                            </Row>
                            :
                            <Row>
                                <Small bold color={colors.Error}>
                                    {times.attendee.name}
                                </Small>
                                <HoverIcon
                                    info={t('create.stepSummary.attendeeDeleted')}
                                    style={{
                                        marginLeft:spacings.xxxs,
                                        opacity: percentageOfShortestOnLongest < threshold && percentage === 100 ? 1 : 0,
                                    }}
                                    icon={<BsExclamationTriangle size={16} />}
                                    hover={<BsExclamationTriangleFill size={16} />}
                                    onClick={()=>navigate('/create-meeting/attendees')}//navigate('/create-meeting/attendees')
                                />
                            </Row>
                            }
                            <Row>
                                <Small bold>
                                    {formatDurationCustom(times.duration)}
                                </Small>
                                <Small ml={spacings.xxxs} color={colors.Brown1}>
                                    {t('common.minutes')}
                                </Small>
                                <div style={{ width: 250, paddingLeft: spacings.xxs }}>
                                    <Bar
                                        color={percentageOfShortestOnLongest < threshold && percentage === 100 ? colors.Purple2 : colors.Mustard2}
                                        width={`${percentage}%`}
                                    />
                                </div>
                                <div style={{ marginLeft: spacings.xxxs }}>
                                    <HoverIcon
                                        info={t('create.stepSummary.unbalancedTimes')}
                                        style={{
                                            opacity: percentageOfShortestOnLongest < threshold && percentage === 100 ? 1 : 0,
                                        }}
                                        icon={<BsExclamationTriangle size={16} />}
                                        hover={<BsExclamationTriangleFill size={16} />}
                                    />
                                </div>
                            </Row>
                        </Row>
                    );
                })}
            </div>
            <SeparatorLine />
        </div>
    );
}

export default SpeakerTimes;