import { MdClose } from 'react-icons/md';
import styled from 'styled-components';
import { COLORS } from '../../../assets/constants';
import BorderRadius from '../../../assets/constants/border-radius';
import colors, { default as Colors } from '../../../assets/constants/colors';
import Spacings from '../../../assets/constants/spacings';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    background-color: ${Colors.Black}66;
`;

const ContentWrapper = styled.div`
    position: relative;
    width: 600px;
    background-color: ${COLORS["2.4"]};
    box-sizing: border-box;
    padding: ${Spacings.l}px;
    border-radius: ${BorderRadius.m}px;
    max-height: 100vh;
    /* overflow: scroll; */
`;

const TopBorder = styled.div`
    position: absolute;
    top: -1px;
    left: 0px;
    height: 6px;
    width: 100%;
    background-color: ${colors.Error};
    border-top-left-radius: ${BorderRadius.m}px;
    border-top-right-radius: ${BorderRadius.m}px;
`;

const BottomBorder = styled.div`
    z-index: -1;
    position: absolute;
    top: calc(100% - 4px);
    left: 0px;
    height: 14px;
    width: 100%;
    background-color: ${colors.Grey};
    border-bottom-left-radius: ${BorderRadius.m}px;
    border-bottom-right-radius: ${BorderRadius.m}px;
`;

const Popup = ({
    children,
    style,
    close,
    alert,
}: {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    close?: () => void;
    alert?: boolean;
}) => {

    return (
        <Wrapper>
            <ContentWrapper style={{
                ...style,
            }}>
                {alert && <TopBorder />}
                <MdClose
                    onClick={close}
                    size={22}
                    style={{
                        position: 'absolute',
                        top: Spacings.xs,
                        right: Spacings.xs,
                        cursor: 'pointer',
                    }} />
                {children}
                <BottomBorder />
            </ContentWrapper>
        </Wrapper>
    );
}

export default Popup;