import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Stats = styled.div`
    margin-top: ${spacings.s}px;
    margin-bottom: ${spacings.xxxs}px;
    display: flex;
    justify-content: space-between;
`;

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 24px;
`;

export const INDICATOR_WIDTH = 10;
export const INDICATOR_BORDER_WIDTH = 2;
export const Indicator = styled.div`
    position: absolute;
    height: 145%;
    width: ${INDICATOR_WIDTH - 2 * INDICATOR_BORDER_WIDTH}px;
    border-radius: ${borderRadius.s}px;
    border: ${INDICATOR_BORDER_WIDTH}px solid ${colors.Beige3};
    transition: all 0.2s ease-in-out;
`;

export const PILL_BORDER_WIDTH = 1;
export const Pill = styled.div`
    position: relative;
    color: ${colors.White};
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: ${borderRadius.xs}px;
    border: ${PILL_BORDER_WIDTH}px solid ${colors.Black};

    &:hover {
        background-color: ${colors.Brown2} !important;
    }
`;

export const Popup = styled.div`
    position: absolute;
    right: 50%;
    bottom: calc(100% + ${spacings.xxxs}px);
    transform: translateX(50%);
    background-color: ${colors.White};
    border: 1px solid ${colors.Beige1};
    border-radius: ${borderRadius.s}px;
    color: ${colors.Black};
    padding: ${spacings.xxxs}px ${spacings.xxs}px;
    width: 235px;
`;

export const Arrow = styled.div`
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${colors.White};
`;