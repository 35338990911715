import AddDatePopup from './add-date-popup';
import borderRadius from '../../../assets/constants/border-radius';
import Button from '../../../common/components/button';
import colors from '../../../assets/constants/colors';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import Row from '../../../common/components/row';
import spacings from '../../../assets/constants/spacings';
import startOfWeek from 'date-fns/startOfWeek';
import {
    addMonths,
    nextSunday,
    previousSunday,
    subMinutes,
    subMonths
    } from 'date-fns';
import { Calendar, dateFnsLocalizer, View } from 'react-big-calendar';
import { CalendarWrapper, SwitchButton, Wrapper } from './styled';
import { FaPlus } from 'react-icons/fa';
import { Headline, Small, Subheadline } from '../../../common/components/text';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useAppSelector } from '../../../store/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';

const locales = {
    'en-US': require('date-fns/locale/en-US'),
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const ChooseDate = () => {

    const { t } = useTranslation();

    const title = useAppSelector((state) => state.createMeeting.title);
    const agendaDate = useAppSelector((state) => state.createMeeting.date);
    const event = title && agendaDate && agendaDate.start && agendaDate.end ? {
        title: title,
        start: new Date(agendaDate.start),
        end: new Date(agendaDate.end),
    } : undefined;

    const now = new Date();
    const min = new Date(new Date().setHours(5, 50, 0));
    const [show, setShow] = useState<boolean>(false);
    const [initialDate, setInitialDate] = useState<Date | undefined>();

    const [view, setView] = useState<View>('week');
    const [displayDate, setDisplayDate] = useState<Date>(agendaDate?.start ? new Date(agendaDate.start) : new Date());

    useEffect(() => {
        if (agendaDate && agendaDate.start) {
            setDisplayDate(new Date(agendaDate.start));
        }
    }, [agendaDate]);

    const ToolBar = ({ date }: { date: Date; }) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: spacings.xs,
            }}>
                <Button
                    small
                    label={t('create.stepDate.setDate')}
                    onClick={() => {
                        setInitialDate(agendaDate?.start ? new Date(agendaDate.start) : new Date());
                        setShow(true);
                    }}
                    icon={<FaPlus size={16} style={{ marginRight: spacings.xxxs }} />}
                />
                <Row>
                    <Row style={{ marginRight: spacings.xxs }}>
                        <MdKeyboardArrowLeft
                            size={22}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                view === 'week' && setDisplayDate(previousSunday(displayDate));
                                view === 'month' && setDisplayDate(subMonths(displayDate, 1));
                            }}
                        />
                        <Small>
                            {view === 'week' && `${format(date, 'MMMM y')}, ${t('common.calendarWeekAbbreviation')} ${format(date, 'w')}`}
                            {view === 'month' && `${format(date, 'MMMM y')}`}
                        </Small>
                        <MdKeyboardArrowRight
                            size={22}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                view === 'week' && setDisplayDate(nextSunday(displayDate));
                                view === 'month' && setDisplayDate(addMonths(displayDate, 1));
                            }}
                        />
                    </Row>
                    <Row>
                        <SwitchButton
                            onClick={() => setView('week')}
                            style={{
                                borderTopLeftRadius: borderRadius.s,
                                borderBottomLeftRadius: borderRadius.s,
                                backgroundColor: view === 'week' ? colors.Black : colors.Beige1,
                                color: view === 'week' ? colors.White : colors.Black,
                            }}
                        >
                            <Small bold>Week</Small>
                        </SwitchButton>
                        <SwitchButton
                            onClick={() => setView('month')}
                            style={{
                                borderTopRightRadius: borderRadius.s,
                                borderBottomRightRadius: borderRadius.s,
                                backgroundColor: view === 'month' ? colors.Black : colors.Beige1,
                                color: view === 'month' ? colors.White : colors.Black,
                            }}
                        >
                            <Small bold>Month</Small>
                        </SwitchButton>
                    </Row>
                </Row>
            </div>
        );
    };

    const WeekHeader = ({ date }: { date: Date; }) => {
        return (
            <div style={{ fontWeight: 'normal' }}>
                <Small mb={spacings.xxxs}>
                    {format(date, 'EEEEEE')}
                </Small>
                <p style={{
                    fontFamily: 'Jost',
                    fontSize: '1.4rem',
                    marginBottom: spacings.xxs,
                }}>
                    {format(date, 'd')}
                </p>
            </div>
        );
    };

    const MonthHeader = ({ date }: { date: Date; }) => {
        return (
            <div style={{ fontWeight: 'normal' }}>
                <Small mb={spacings.xxxs}>
                    {format(date, 'eee')}
                </Small>
            </div>
        );
    };

    return (
        <Wrapper>
            <Headline primary>
                {t('create.stepDate.title')}
            </Headline>
            <Subheadline quaternary mb={spacings.l}>
                {t('create.stepDate.subtitle')}
            </Subheadline>

            <div>
                <CalendarWrapper>
                    <Calendar
                        components={{
                            toolbar: ToolBar,
                            week: { header: WeekHeader },
                            month: { header: MonthHeader },
                        }}
                        date={displayDate}
                        localizer={localizer}
                        events={[...event ? [event] : []]}
                        defaultView="week"
                        view={view}
                        views={['month', 'week']}
                        startAccessor="start"
                        endAccessor="end"
                        step={5}
                        style={{ height: 500 }}
                        scrollToTime={agendaDate?.start ? subMinutes(new Date(agendaDate.start), 6.5 * 60) : subMinutes(now, 6.5 * 60)} // Needed to scroll to correct time, value depends on min value of calendar
                        min={min}
                        selectable
                        onSelectSlot={(slotinfo) => {
                            if (view === 'week') {
                                setInitialDate(new Date(slotinfo.start));
                            }
                            if (view === 'month') {
                                setInitialDate(new Date(new Date(slotinfo.start).setHours(6, 0, 0)));
                            }
                            setShow(true);
                        }}
                        onSelectEvent={(event) => {
                            if (event.title !== title) {
                                return;
                            }
                            setInitialDate(new Date(event.start));
                            setShow(true);
                        }}
                    />
                </CalendarWrapper>
            </div>

            {show && <AddDatePopup close={() => setShow(false)} initialDate={initialDate} />}
        </Wrapper>
    );
}

export default ChooseDate;