"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isZero = void 0;
var toUnit_1 = require("./toUnit");
/**
 * Returns `true` if all the units of the duration sum to zero.
 *
 * Note, this function performs implicit normalization, so ambiguous
 * units, like months, work with average values.
 *
 * @example
 * isZero('P0D') // true
 * isZero({ years: 0 }) // true
 * isZero({ days: 1, hours: -24 }) // true
 * isZero({ days: 1 }) // false
 */
var isZero = function (duration) {
    return toUnit_1.toMilliseconds(duration) === 0;
};
exports.isZero = isZero;
