import colors from '@/assets/constants/colors';
import Column from '@/common/components/column';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Body, Micro } from '@/common/components/text';
import { formatDurationCustom } from '@/common/helpers';
import { ITimeRow } from './types';
import { SpeakerBar } from '@/common/styled';
import { useTranslation } from 'react-i18next';

const TimeRow = ({
    title,
    plannedTime,
    exceededTime,
    savedTime,
    longestTime,
}: ITimeRow) => {
    const { t } = useTranslation();
    const calculatedTime = plannedTime + exceededTime - savedTime;

    return (
        <Row
            style={{
                gap: spacings.xxl,
                borderBottom: `1px solid ${colors.Beige1}`
            }}
            pt={spacings.s}
            pb={spacings.s}
        >
            <Column style={{ width: 200 }}>
                <Body bold>
                    {title}
                </Body>
                <Micro>
                    {t('statistics.timeStats.setTime')}:
                    {` `}
                    <b>{formatDurationCustom({ seconds: plannedTime })}</b>
                    {` `}
                    {exceededTime > 0
                        ? <b style={{ color: colors.Purple1 }}>+ {formatDurationCustom({ seconds: exceededTime })} ({Math.round(exceededTime / plannedTime * 100)}%)</b>
                        : savedTime > 0 && <b style={{ color: colors.Teal1 }}>- {formatDurationCustom({ seconds: savedTime })} ({Math.round(savedTime / plannedTime * 100)}%)</b>
                    }
                </Micro>
            </Column>
            <Column style={{ flex: 1, gap: spacings.xxxs }}>
                <SpeakerBar style={{
                    width: `${plannedTime / longestTime * 100}%`,
                }} />
                <SpeakerBar style={{
                    width: `${calculatedTime / longestTime * 100}%`,
                    backgroundColor: exceededTime > 0 ? colors.Purple2 : savedTime > 0 ? colors.Teal2 : colors.Mustard2,
                }} />
            </Column>
        </Row>
    );
};

export default TimeRow;