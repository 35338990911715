import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import RadioButton from '@/common/components/radiobutton';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import { Body, Subheadline } from '@/common/components/text';
import { getEvaluationQuestions, sendEvaluation } from '@/services/api/meeting-service';
import { IAnswer, IQuestion, IQuestionnaire } from './types';
import { ReactComponent as ICSmileyNeutral } from '@icons/ic-smiley-neutral-alt.svg';
import { ReactComponent as ICSmileyNo } from '@icons/ic-smiley-no.svg';
import { ReactComponent as ICSmileyYes } from '@icons/ic-smiley-yes.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Questionnaire = ({ setShowEndMeeting, meetingId }: IQuestionnaire) => {
    const [index, setIndex] = useState<number>(0);
    const { t } = useTranslation();
    const [answers, setAnswers] = useState<IAnswer[]>([]);
    const [questions, setQuestions] = useState<IQuestion[]>([]);

    useEffect(() => {
        const fetchQuestions = async () => {
            const [data, error] = await getEvaluationQuestions();
            if (data) {
                setQuestions(data);
                setAnswers(data.map((q: IQuestion) => ({ question: q.id, answer: 1 })));
            }
        };
        fetchQuestions();
    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
        }}>
            {questions.length > 0 && answers.length > 0 && <>
                <Subheadline
                    mt={spacings.xxs}
                    quaternary
                >
                    <b>{index + 1}</b> {t('common.of')} {questions.length}
                </Subheadline>
                <Subheadline quaternary>
                    {questions[index].question}
                </Subheadline>
                <div style={{
                    width: 400,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: spacings.xs,
                }}>
                    <Row
                        pb={spacings.xs}
                        style={{ borderBottom: `1px solid ${colors.Beige1}` }}
                        onClick={() => {
                            const _answers = [...answers];
                            _answers[index].answer = 1;
                            setAnswers(_answers);
                        }}
                    >
                        <RadioButton active={answers[index].answer === 1} />
                        <ICSmileyYes style={{ marginLeft: spacings.s }} />
                        <Body ml={spacings.xs}>{t('actions.yes')}</Body>
                    </Row>
                    <Row
                        pt={spacings.xs}
                        pb={spacings.xs}
                        style={{ borderBottom: `1px solid ${colors.Beige1}` }}
                        onClick={() => {
                            const _answers = [...answers];
                            _answers[index].answer = 2;
                            setAnswers(_answers);
                        }}
                    >
                        <RadioButton active={answers[index].answer === 2} />
                        <ICSmileyNeutral style={{ marginLeft: spacings.s }} />
                        <Body ml={spacings.xs}>{t('actions.neutral')}</Body>
                    </Row>
                    <Row
                        pt={spacings.xs}
                        onClick={() => {
                            const _answers = [...answers];
                            _answers[index].answer = 3;
                            setAnswers(_answers);
                        }}
                    >
                        <RadioButton active={answers[index].answer === 3} />
                        <ICSmileyNo style={{ marginLeft: spacings.s }} />
                        <Body ml={spacings.xs}>{t('actions.no')}</Body>
                    </Row>
                </div>
                <Row style={{ gap: spacings.xxxs }}>
                    {index > 0 && <Button
                        onClick={() => setIndex(Math.min(index - 1, questions.length - 1))}
                        mt={spacings.s}
                        secondary
                        label={t('timer.previousQuestion')}
                    />}
                    {index < questions.length - 1 && <Button
                        onClick={() => setIndex(Math.min(index + 1, questions.length - 1))}
                        mt={spacings.s}
                        secondary
                        label={t('timer.nextQuestion')}
                    />}
                    {index === questions.length - 1 && <Button
                        onClick={async () => {
                            if (!meetingId) return;
                            const [data, error] = await sendEvaluation(meetingId, answers);
                            if (data) {
                                setShowEndMeeting(true);
                            }
                        }}
                        mt={spacings.s}
                        label={t('timer.submitEvaluationFeedback')}
                    />}
                </Row>
            </>}
        </div>
    );
};

export default Questionnaire;