import Button from '../../../../common/components/button';
import spacings from '../../../../assets/constants/spacings';
import { StepIndex, STEPS } from '../../steps';
import { useAppSelector } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styled';

const ChooseDateNavbar = ({ index }: { index: StepIndex; }) => {
    const step = STEPS[index];
    const { t } = useTranslation();
    const navigate = useNavigate();
    const date = useAppSelector((state) => state.createMeeting.date);

    return (
        <Wrapper>
            {step.prev &&
                <Button
                    label={t('actions.back')}
                    small
                    secondary
                    onClick={() => step.prev && navigate(step.prev)}
                    ml={spacings.xxs}
                    mr={spacings.xxxs} />
            }
            {step.next &&
                <Button
                    label={t('create.verifyInfo')}
                    small
                    disabled={!(date && date?.start && date.end)}
                    onClick={() => step.next && navigate(step.next)} />
            }
        </Wrapper>
    );
};

export default ChooseDateNavbar;