"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNegative = void 0;
var toUnit_1 = require("./toUnit");
/**
 * Returns `true` if a duration is negative overall.
 *
 * Note, this function performs implicit normalization, so ambiguous
 * units, like months, work with average values.
 *
 * @example
 * isNegative('P-1D') // true
 * isNegative({ days: 1, hours: -25 }) // true
 * isNegative({ days: 1, hours: -23 }) // false
 */
var isNegative = function (duration) {
    return toUnit_1.toMilliseconds(duration) < 0;
};
exports.isNegative = isNegative;
