import Footer from './footer';
import Header from './header';
import LicenceAmount from '@/features/licences/amount';
import LicenceBilling from '@/features/licences/billing';
import LicenceHeader from './licence-header';
import LicencePayment from '@/features/licences/payment';
import LicenceSummary from '@/features/licences/summary';
import NotFound from '@/features/not-found';
import Root from '@/features/root';

const unlicencedRoutes = [
    {
        // TODO does nothing right now because of public routes
        path: '*',
        component: <><Header /><NotFound /><Footer /></>,
    },
    {
        path: '/',
        component: <Root />,
    },
    {
        path: '/licences/amount',
        component: <><LicenceHeader /><LicenceAmount /><Footer /></>,
    },
    {
        path: '/licences/billing',
        component: <><LicenceHeader /><LicenceBilling /><Footer /></>,
    },
    {
        path: '/licences/payment',
        component: <><LicenceHeader /><LicencePayment /><Footer /></>,
    },
    {
        path: '/licences/summary',
        component: <><LicenceHeader /><LicenceSummary /><Footer /></>,
    },
];

export default unlicencedRoutes;
