import * as DurationFns from 'duration-fns';
import { add } from 'date-fns';
import { CreateMeetingState } from '@/store/create-meeting-slice/types';
import { IStep } from './types';

export const parseMeetingSteps = (meeting: CreateMeetingState | undefined, date: Date): IStep[] => {
    if (!meeting) {
        return [];
    }

    const steps: IStep[] = [];
    let start = new Date(date.setMilliseconds(0));
    meeting.agenda.items.forEach((item, itemIndex) => {
        item.speakers.forEach((speaker, speakerIndex) => {
            steps.push({
                item: itemIndex,
                type: speakerIndex,
                duration: speaker.duration,
                start: new Date(start.setMilliseconds(0)),
                end: add(start, DurationFns.subtract(speaker.duration, { seconds: 1 })),
            });
            start = add(start, speaker.duration);
        });
        if (item.speakers.length === 0) {
            steps.push({
                item: itemIndex,
                type: 'evaluation',
                duration: item.duration,
                start: new Date(start.setMilliseconds(0)),
                end: add(start, DurationFns.subtract(item.duration, { seconds: 1 })),
            });
            start = add(start, item.duration);
        }
        steps.push({
            item: itemIndex,
            type: 'discussion',
            duration: item.discussion.duration,
            start: new Date(start.setMilliseconds(0)),
            end: add(start, DurationFns.subtract(item.discussion.duration, { seconds: 1 })),
        });
        start = add(start, item.discussion.duration);
        steps.push({
            item: itemIndex,
            type: 'feedback',
            duration: item.feedback.duration,
            start: new Date(start.setMilliseconds(0)),
            end: add(start, DurationFns.subtract(item.feedback.duration, { seconds: 1 })),
        });
        start = add(start, item.feedback.duration);
    });
    return steps;
};

export const parseStepsResponse = (data: any) => {
    return data.map((item: IStep) => ({
        ...item,
        start: new Date(item.start),
        end: new Date(item.end),
    }))
};