import authSlice from './auth-slice';
import createMeetingSlice from './create-meeting-slice';
import generalSlice from './general-slice';
import licenceSlice from './licence-slice';
import meetingsSlice from './meetings-slice';
import storage from 'redux-persist/lib/storage';
import { combineReducers, createStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';

// combine reducers from reducer directory and slices
const rootReducer = combineReducers({
    auth: authSlice.reducer,
    general: generalSlice.reducer,
    createMeeting: createMeetingSlice.reducer,
    meetings: meetingsSlice.reducer,
    licence: licenceSlice.reducer,
});

const persistConfig = { key: 'root', storage: storage };
const persistedReducer = persistReducer(persistConfig, rootReducer);

export function createPersistStore() {
    let store = createStore(persistedReducer);
    let persistor = persistStore(store)
    return {
        store,
        persistor,
    };
}

const wholeStore = createPersistStore();
const store = wholeStore.store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = wholeStore.persistor;
export default store;
