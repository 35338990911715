import borderRadius from '../../../../assets/constants/border-radius';
import colors from '../../../../assets/constants/colors';
import spacings from '../../../../assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const ProgressBarBackground = styled.div`
    display: flex;
    align-items: center;
    padding: ${spacings.xxxs}px;
    gap: 4px;
    background-color: ${colors.Beige1};
    border-radius: ${borderRadius.s}px;
    flex: 1;
    height: 18px;
`;

export const ProgressElement = styled.div`
    background-color: ${colors.Black};
    border-radius: ${borderRadius.xs}px;
    height: 100%;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: scaleY(1.1);
        background-color: ${colors.Mustard1};
    }
`;