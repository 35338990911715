import PaymentForm from './payment-form';
import Popup from '@/common/components/popup';
import { Elements } from '@stripe/react-stripe-js';
import { Headline } from '@/common/components/text';
import { IPopup } from '@/common/types';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

const PaymentPopup = ({ close }: IPopup) => {
    const { t } = useTranslation();

    return (
        <Popup close={close}>
            <Headline quaternary>
                {t('licence.paymentDetails')}
            </Headline>
            <Elements stripe={stripe}>
                <PaymentForm close={close} />
            </Elements>
        </Popup>
    );
};

export default PaymentPopup;