import borderRadius from '@/assets/constants/border-radius';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${spacings.s}px;
`;

export const Popup = styled.div`
    position: absolute;
    bottom: calc(100% + ${spacings.xxs}px);
    right: ${-spacings.l}px;
    z-index: 99;
    background-color: ${colors.Black};
    color: ${colors.White};
    width: 300px;
    padding: ${spacings.xxxs}px ${spacings.xxs}px;
    border-radius: ${borderRadius.m}px;
`;

export const Arrow = styled.div`
    position: absolute;
    top: 100%;
    right: ${spacings.xxl}px;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${colors.Black};
`;

export const StyledExtendWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.Purple1};
    color: ${colors.White};
    padding: ${spacings.xxs}px;
`;