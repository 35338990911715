import Footer from './footer';
import ForgotPassword from '@/features/auth/forgot-password';
import Header from './header';
import Login from '@/features/auth/login';
import LogoutOnlyHeader from './logout-only-header';
import NoAccess from '@/features/no-access';
import Register from '@/features/auth/register';
import ResetPassword from '@/features/auth/reset-password';
import Root from '@/features/root';
import Timer from '@/features/timer';
import TimerSummary from '@/features/timer/timer-summary';

const publicRoutes = [
    {
        path: '*',
        component: <Root />,
    },
    {
        path: '/',
        component: <Root />,
    },
    {
        path: '/no-access',
        component: <><LogoutOnlyHeader /><NoAccess /><Footer /></>,
    },
    {
        path: '/login',
        component: <><Header /><Login /><Footer /></>,
    },
    {
        path: '/register',
        component: <><Header /><Register /><Footer /></>,
    },
    {
        path: '/register/:id',
        component: <><Header /><Register /><Footer /></>,
    },
    {
        path: '/forgot-password',
        component: <><Header /><ForgotPassword /><Footer /></>,
    },
    {
        path: '/reset-password/:token',
        component: <><Header /><ResetPassword /><Footer /></>,
    },
    {
        path: '/timer/:id',
        component: <Timer />,
    },
    {
        path: '/timer/summary/:id',
        component: <TimerSummary />,
    },
];

export default publicRoutes;
