import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import CalvahPattern from '@/assets/images/calvah-pattern.png';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import Row from '@/common/components/row';
import { Headline, Small } from '@/common/components/text';
import TextBox from '@/common/components/textbox';
import { useQuery } from '@/common/hooks';
import { HEADER_HEIGHT } from '@/router/header/styled';
import { resetPassword } from '@/services/api/auth-service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorBox } from '../styled';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const query = useQuery();
    const email = query.get('email');
    const { token } = useParams();

    const [showError, setShowError] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const onClickReset = async () => {
        setShowError(false);
        if (!email || !token || password !== confirmPassword) {
            setShowError(true);
            return;
        }
        setLoading(true);
        const [data, error] = await resetPassword(email, password, confirmPassword, token);
        setLoading(false);
        if (!error) {
            navigate('/login?alert=reset-complete')
        } else {
            setShowError(true);
        }
    };

    return (
        <ContentWrapper style={{
        }}>
            <img
                src={CalvahPattern}
                style={{
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    right: 0,
                }}
            />
            <div style={{ width: 500, marginBottom: spacings.xxl }}>
                <Headline mt={spacings.xxl}>
                    {t('auth.resetPasswordAlt')}
                </Headline>
                <TextBox
                    mt={spacings.xxs}
                    label={t('auth.newPassword')}
                    value={password}
                    setValue={setPassword}
                    type={'password'}
                    error={showError}
                />
                <TextBox
                    mt={spacings.xxs}
                    label={t('auth.repeatNewPassword')}
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    type={'password'}
                    error={showError}
                />
                {showError && <ErrorBox>
                    <Row style={{ alignItems: 'flex-start' }}>
                        <AiOutlineInfoCircle
                            size={22}
                            color={colors.Error}
                            style={{
                                marginTop: 2,
                                marginRight: spacings.xxxs,
                                flexShrink: 0,
                            }}
                        />
                        <Small>
                            {t('auth.passwordErrorMessage')}
                        </Small>
                    </Row>
                </ErrorBox>}
                <Button
                    loading={loading}
                    mt={spacings.m}
                    label={t('auth.updatePassword')}
                    onClick={onClickReset}
                />
            </div>
        </ContentWrapper>
    );
}

export default ResetPassword;