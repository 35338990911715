import spacings from '@/assets/constants/spacings';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
`;

export const BoxTitle = styled.div`
    position: absolute;
    right: calc(100% + ${spacings.s}px);
    top: 0;
    text-align: center;
`;