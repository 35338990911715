import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import Button from '@/common/components/button';
import ContentWrapper from '@/common/components/content-wrapper';
import MeetingTile from '@/common/components/meeting-tile';
import OrderByDropdown from '@/common/components/order-by-dropdown';
import Pagination from '@/common/components/pagination';
import Row from '@/common/components/row';
import Searchbar from '@/common/components/searchbar';
import { Headline, Small, Subheadline } from '@/common/components/text';
import { ToolbarArrow, ToolbarWrapper } from '@/common/styled';
import { getMeetings, getMeetingsPaginated } from '@/services/api/meeting-service';
import { getOrganisers } from '@/services/api/organisation-service';
import { IOrganiserResponse } from '@/services/api/organisation-service/types';
import { CreateMeetingState, IMeetingState, IPaginationObject } from '@/store/create-meeting-slice/types';
import { saveOrganisers } from '@/store/general-slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { saveMeetings } from '@/store/meetings-slice';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MeetingStats from './meeting-stats';
import OrganiserListItem from './organiser-list-item';
import SatisfactionStats from './satisfaction-stats';
import TimeStats from './time-stats';

const StatisticsDashboard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const meetings = useAppSelector((state) => state.meetings.meetings);
    const organisers = useAppSelector((state) => state.general.organisers);
    const user = useAppSelector((state) => state.auth.user);
    const [tab, setTab] = useState<'meetings' | 'organisers'>('meetings');
    const [search, setSearch] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('latest');
    const [pastMeetingsPaginated, setPastMeetingsPaginated] = useState<IPaginationObject>();
    const [currentPageIndexPast, setCurrentPageIndexPast] = useState<number>(0);

    const allPastMeetings = meetings.filter(
        (meeting: CreateMeetingState & IMeetingState) => meeting.meetingState.state === 'past'
    );

    const fetchMeetings = async () => {
        const [data, error] = await getMeetingsPaginated(
            currentPageIndexPast + 1,
            search,
            'PAST',
            sortBy
        );
        if (data) {
            setPastMeetingsPaginated(data);
        }
    };

    const debounceFetchMeetings = debounce(
        () => fetchMeetings(),
        500
    );

    useEffect(() => {
        const fetchMeetings = async () => {
            const [data, error] = await getMeetings();
            if (data) {
                if (user?.role === 'admin') {
                    dispatch(saveMeetings(data));
                } else {
                    dispatch(saveMeetings(data.filter((meeting: CreateMeetingState) => meeting.userId === user?.id)));
                }
            }
        };
        const fetchOrganisers = async () => {
            const [data, error] = await getOrganisers();
            if (data) {
                dispatch(saveOrganisers(data
                    .map((_user: IOrganiserResponse) => ({
                        id: _user.id,
                        name: _user.name,
                        email: _user.email,
                        pronouns: _user.pronouns,
                        role: _user.role,
                    })))
                );
            } if (error) {
                dispatch(saveOrganisers([]));
            }
        };
        fetchMeetings();
        user?.role === 'admin' && fetchOrganisers();
    }, []);

    useEffect(() => {
        fetchMeetings();
    }, [sortBy, currentPageIndexPast]);

    useEffect(() => {
        debounceFetchMeetings();
    }, [search]);

    return (
        <ContentWrapper>
            <Headline secondary mt={spacings.m} mb={spacings.m}>
                {t('statistics.headline')}
            </Headline>
            <Row mb={spacings.xl} style={{ gap: spacings.m, alignItems: 'flex-start' }}>
                <SatisfactionStats meetings={allPastMeetings} />
                <TimeStats />
                <MeetingStats />
            </Row>
            <ToolbarWrapper>
                {user?.role !== 'admin'
                    ? <Subheadline quaternary>
                        {t('statistics.statisticsPastMeetings')}
                    </Subheadline>
                    : <Row>
                        <div style={{ position: 'relative', marginRight: spacings.s }}>
                            <Button
                                noPadding
                                small
                                tertiary
                                label={t('statistics.meetings')}
                                onClick={() => setTab('meetings')}
                            />
                            {tab === 'meetings' && <ToolbarArrow />}
                        </div>
                        <div style={{ position: 'relative' }}>
                            <Button
                                noPadding
                                small
                                tertiary
                                label={t('statistics.organisers')}
                                onClick={() => setTab('organisers')}
                            />
                            {tab === 'organisers' && <ToolbarArrow />}
                        </div>
                    </Row>
                }
                {tab === 'meetings' && <Row style={{ position: 'relative', }}>
                    <Searchbar
                        value={search}
                        setValue={setSearch}
                    />
                    <OrderByDropdown
                        current={sortBy}
                        setCurrent={setSortBy}
                    />
                </Row>}
            </ToolbarWrapper>
            {tab === 'meetings'
                ? <div>
                    {!pastMeetingsPaginated || pastMeetingsPaginated.data.length === 0
                        ? <Small color={colors.Brown1}>
                            {t('meetings.noMeetings')}
                        </Small>
                        : [...pastMeetingsPaginated.data]
                            .map(meeting => {
                                return (
                                    <MeetingTile
                                        key={meeting.externalId}
                                        meeting={meeting}
                                        setPageIndex={setCurrentPageIndexPast}
                                    />
                                );
                            })
                    }
                    {pastMeetingsPaginated && pastMeetingsPaginated.last_page > 1 && (
                        <Pagination
                            pages={pastMeetingsPaginated.last_page}
                            current={currentPageIndexPast}
                            setCurrent={setCurrentPageIndexPast}
                            mt={spacings.s}
                        />
                    )}
                </div>
                : <div>
                    {organisers.length === 0
                        ? <Small color={colors.Brown1}>
                            {t('statistics.noOrganisers')}
                        </Small>
                        : organisers.map((organiser, index) => <OrganiserListItem
                            key={organiser.email}
                            organiser={organiser}
                            isFirst={index === 0}
                            isLast={index === organisers.length - 1}
                        />)
                    }
                </div>
            }
        </ContentWrapper>
    );
};

export default StatisticsDashboard;