import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import ContentWrapper from '@/common/components/content-wrapper';
import Row from '@/common/components/row';
import {
    Body,
    Headline,
    Micro,
    Small
} from '@/common/components/text';
import {
    formatDurationCustom,
    getExceededTime,
    getPlannedTime,
    getSavedTime
} from '@/common/helpers';
import { SpeakerBar } from '@/common/styled';
import { getMeeting, setMeetingScore } from '@/services/api/meeting-service';
import { CreateMeetingState, IMeetingState } from '@/store/create-meeting-slice/types';
import { ReactComponent as ICSmileyBad } from '@icons/ic-smiley-bad.svg';
import { ReactComponent as ICSmileyGood } from '@icons/ic-smiley-good.svg';
import { ReactComponent as ICSmileyNeutral } from '@icons/ic-smiley-neutral.svg';
import { ReactComponent as ICSmileyOkay } from '@icons/ic-smiley-okay.svg';
import audioGrade1 from '@sounds/Calvah_celebration_a.mp3';
import audioGrade2 from '@sounds/Calvah_celebration_b.mp3';
import audioGrade3 from '@sounds/Calvah_fail_a.mp3';
import audioGrade4 from '@sounds/Calvah_fail_b.mp3';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Sound from 'react-sound';
import { parseStepsResponse } from '../timer-runner/helpers';
import { IStep } from '../timer-runner/types';
import { calculateGrade } from './helpers';
import {
    BarWrapper,
    Dot,
    LegendInnerWrapper,
    LegendWrapper,
    Wrapper
} from './styled';

const TimerSummary = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const meetingId = id;
    const [meeting, setMeeting] = useState<CreateMeetingState & IMeetingState>();
    const [steps, setSteps] = useState<IStep[]>();

    const totalDuration = meeting ? getPlannedTime(meeting) : 0;
    const overflow = meeting ? getExceededTime(meeting) : 0;
    const percentage = overflow / totalDuration * 100;
    const grade = calculateGrade(percentage);

    useEffect(() => {
        const fetchMeeting = async () => {
            if (!meetingId) return;

            const [data, error] = await getMeeting(meetingId);
            const meeting: CreateMeetingState & IMeetingState = data;
            if (meeting) {
                setMeeting(meeting);
                setSteps(parseStepsResponse(JSON.parse(meeting.meetingState.steps)));
            }
        };
        fetchMeeting();
    }, []);

    useEffect(() => {
        if (!meeting) return;
        const setScore = async () => {
            if (!meetingId) return;
            if (meeting?.meetingState.score !== 0) return;

            const [data, error] = await setMeetingScore(meetingId, Math.round(percentage));
        };
        setScore();
    }, [meeting]);

    const timePerAttendees = meeting ? meeting.attendees.map(attendee =>
        getPlannedTime(meeting, attendee.email)
        + getExceededTime(meeting, attendee.email)
    ) : [];
    const longestTimeInSeconds = timePerAttendees ? timePerAttendees.reduce((acc, cur) => acc > cur ? acc : cur, 0) : 0;

    return (
        <Wrapper>
            <>
                {grade === 1 ? <Sound
                    url={audioGrade1}
                    playStatus={Sound.status.PLAYING}
                /> : null}
                {grade === 2 ? <Sound
                    url={audioGrade2}
                    playStatus={Sound.status.PLAYING}
                /> : null}
                {grade === 3 ? <Sound
                    url={audioGrade3}
                    playStatus={Sound.status.PLAYING}
                /> : null}
                {grade === 4 ? <Sound
                    url={audioGrade4}
                    playStatus={Sound.status.PLAYING}
                /> : null}
            </>
            <MdClose
                onClick={() => navigate('/')}
                size={34}
                style={{
                    position: 'absolute',
                    top: spacings.s,
                    right: spacings.s,
                    cursor: 'pointer',
                }}
            />
            <ContentWrapper style={{
                textAlign: 'center',
                paddingTop: spacings.l,
                paddingBottom: spacings.l,
            }}>
                {grade === 1 && <ICSmileyGood />}
                {grade === 2 && <ICSmileyNeutral />}
                {grade === 3 && <ICSmileyOkay />}
                {grade === 4 && <ICSmileyBad />}
                <Headline
                    tertiary
                    mt={spacings.xs}
                    mb={spacings.xxxs}
                >
                    {grade === 1 && t('timer.summary.goodTitle')}
                    {grade === 2 && t('timer.summary.neutralTitle')}
                    {grade === 3 && t('timer.summary.okayTitle')}
                    {grade === 4 && t('timer.summary.badTitle')}
                </Headline>
                <Body mb={spacings.xs}>
                    {grade === 1 && t('timer.summary.goodDescription')}
                    {grade === 2 && t('timer.summary.neutralDescription')}
                    {grade === 3 && t('timer.summary.okayDescription')}
                    {grade === 4 && t('timer.summary.badDescription')}
                </Body>
                <Body bold>
                    {t('timer.exceededTime')}
                    {` `}
                    <b style={{ color: overflow === 0 ? colors.Teal1 : colors.Purple1 }}>
                        +{formatDurationCustom({ seconds: overflow })} (+{Math.round(percentage)}%)
                    </b>
                </Body>
            </ContentWrapper>
            <ContentWrapper
                bgColor={colors.White}
                outerStyle={{
                    flex: 1,
                }}
                style={{
                    paddingTop: spacings.m,
                    paddingBottom: spacings.m - spacings.xs,
                }}
            >
                {steps && meeting?.attendees.map((attendee) => {
                    const plannedTime = getPlannedTime(meeting, attendee.email);
                    const savedTime = getSavedTime(meeting, attendee.email);
                    const exceededTime = getExceededTime(meeting, attendee.email);
                    const calculated = exceededTime - savedTime;

                    const percentage = exceededTime > 0
                        ? exceededTime / plannedTime * 100
                        : savedTime / plannedTime * 100;

                    return (<Row
                        key={attendee.email}
                        mb={spacings.xs}
                    >
                        <div style={{ width: 250 }}>
                            <Body bold>
                                {attendee.name}
                            </Body>
                            <Row>
                                <Small bold>
                                    {formatDurationCustom({ seconds: plannedTime })}
                                </Small>
                                {exceededTime === 0
                                    ? Math.abs(calculated) !== 0 && <Small bold color={colors.Teal1} ml={spacings.xxs}>
                                        −{formatDurationCustom({ seconds: Math.abs(calculated) })}
                                        {` `}
                                        {t('common.minutes')}
                                        {` `}
                                        (−{Math.round(percentage)}%)
                                    </Small>
                                    : <Small bold color={colors.Purple1} ml={spacings.xxs}>
                                        +{formatDurationCustom({ seconds: exceededTime })}
                                        {` `}
                                        {t('common.minutes')}
                                        {` `}
                                        (+{Math.round(percentage)}%)
                                    </Small>
                                }
                            </Row>
                        </div>
                        <BarWrapper>
                            <SpeakerBar style={{
                                width: `${plannedTime / longestTimeInSeconds * 100}%`,
                            }} />
                            <SpeakerBar style={{
                                width: `${(plannedTime - savedTime + exceededTime) / longestTimeInSeconds * 100}%`,
                                backgroundColor: exceededTime > 0 ? colors.Purple2 : savedTime > 0 ? colors.Teal2 : colors.Mustard2,
                            }} />
                        </BarWrapper>
                    </Row>);
                })}
            </ContentWrapper>
            <LegendWrapper>
                <LegendInnerWrapper>
                    <Row>
                        <Dot color={colors.Mustard2} />
                        <Micro>{t('timer.legend.timePlanned')}</Micro>
                    </Row>
                    <Row>
                        <Dot color={colors.Teal2} />
                        <Micro>{t('timer.legend.timeUsed')}</Micro>
                    </Row>
                    <Row>
                        <Dot color={colors.Purple2} />
                        <Micro>{t('timer.legend.timeExceeded')}</Micro>
                    </Row>
                </LegendInnerWrapper>
            </LegendWrapper>
        </Wrapper>
    );
};

export default TimerSummary;