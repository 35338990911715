import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import Popup from '@/common/components/popup';
import Row from '@/common/components/row';
import spacings from '@/assets/constants/spacings';
import TextBox from '@/common/components/textbox';
import { Body, Headline, Micro } from '@/common/components/text';
import { formatDurationCustom } from '@/common/helpers';
import { intervalToDuration, isPast } from 'date-fns';
import { sendFeedback } from '@/services/api/meeting-service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FADE_DURATION = 500;
const SHOW_DURATION = 2000;

const GiveFeedbackPopup = ({ question, end, close, meetingId, itemIndex }: any) => {
    const { t } = useTranslation();
    const intervalToEnd = intervalToDuration({ start: new Date(new Date().setMilliseconds(0)), end: new Date(end) });
    const endIsPast = isPast(new Date(end));
    const [feedback, setFeedback] = useState<string>();
    const [justSent, setJustSent] = useState<boolean>(false);
    const [opacity, setOpacity] = useState<number>(0);

    useEffect(() => {
        if (!justSent) return;
        setTimeout(() => {
            setOpacity(0);
        }, SHOW_DURATION);
        setTimeout(() => {
            setJustSent(false);
        }, SHOW_DURATION + FADE_DURATION);
    }, [justSent]);

    return (
        <Popup close={close}>
            <Headline
                secondary
                color={endIsPast ? colors.Purple1 : colors.Black}
            >
                {endIsPast && '+'}{formatDurationCustom(intervalToEnd)}
            </Headline>
            <Body mt={spacings.xxxs}>
                {t('timer.feedbackFor')}:
            </Body>
            <Headline quaternary mb={spacings.s}>
                {question}
            </Headline>
            <TextBox
                multiline
                placeholder={t('timer.feedbackPlaceholder')}
                value={feedback}
                setValue={setFeedback}
            />
            <Row style={{ marginTop: spacings.s, gap: spacings.xs }}>
                <Button
                    disabled={!feedback}
                    label={t('timer.sendFeedback')}
                    onClick={async () => {
                        if (!meetingId || !feedback) return;
                        const [data, error] = await sendFeedback(meetingId, itemIndex, feedback);
                        if (data) {
                            setJustSent(true);
                            setOpacity(1);
                        }
                    }}
                />
                {justSent && <Micro style={{
                    opacity: opacity,
                    transition: `all ${FADE_DURATION}ms ease-in-out`,
                }}>
                    {t('timer.feedbackSent')}
                </Micro>}
            </Row>
        </Popup>
    );
};

export default GiveFeedbackPopup;