import Colors from '../../../../../assets/constants/colors';
import Spacings from '../../../../../assets/constants/spacings';
import styled from 'styled-components';

export const ListItem = styled.div`
    position: relative;
    background-color: ${Colors.White};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -1px;
    border: 1px solid ${Colors.Beige1};
    overflow: hidden;
    margin-left: ${16 + Spacings.xs}px; // 16 is icon width of edit icon in <TextBoxBorderless>
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: ${Spacings.xxxs}px;
    padding-right: ${65 + Spacings.xs}px;
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.Beige3};
    height: 100%;
    width: 45px;
    border-left: 1px solid ${Colors.Beige1};
`;

export const DurationWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 45px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: ${Spacings.xs}px;
    padding-right: ${Spacings.xs}px;
    background-color: ${Colors.Beige3};
    height: 100%;
    border-left: 1px solid ${Colors.Beige1};
`;