import Button from '@/common/components/button';
import colors from '@/assets/constants/colors';
import spacings from '@/assets/constants/spacings';
import { ColoredBox } from '@/common/styled';
import { formatPrice } from '@/common/helpers';
import { getProductPrices } from '@/services/api/payment-service';
import { Headline, Small, Subheadline } from '@/common/components/text';
import { setProductPrices } from '@/store/licence-slice';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const InfoBox = ({ showEdit }: { showEdit?: boolean; }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const amount = useAppSelector((state) => state.licence.amount);
    const paymentType = useAppSelector((state) => state.licence.paymentType);

    const productPrices = useAppSelector(state => state.licence.productPrices);
    const pricePerLicence = productPrices?.find(price => price.interval === paymentType)?.price
        ? productPrices?.find(price => price.interval === paymentType)?.price
        : productPrices?.find(price => price.interval === paymentType)?.tiers?.find(tier => tier.up_to >= amount)?.unit_amount;
    const pricePerLicenceUnlimited = productPrices?.find(price => price.interval === paymentType)?.tiers?.find(tier => !tier.up_to)?.unit_amount;

    useEffect(() => {
        const fetchPrices = async () => {
            const [data, error] = await getProductPrices();
            if (data) dispatch(setProductPrices(data));
        };
        fetchPrices();
    }, []);

    const calculatedPrice = pricePerLicence
        ? (pricePerLicence / 100) * amount
        : pricePerLicenceUnlimited && (pricePerLicenceUnlimited / 100) * amount;

    const singleP = productPrices?.find(price => price.interval === paymentType)?.tiers?.find(tier => tier.up_to)?.unit_amount;
    const singlePrice = singleP?singleP/100:0;
    const groupPrice = pricePerLicenceUnlimited?pricePerLicenceUnlimited/100:0;

    return (
        <ColoredBox color={colors.Teal3} style={{ width: 300, flexShrink: 0, }}>
            <Headline style={{ lineHeight: 1 }}>
                {amount}
            </Headline>
            <Headline quaternary>
                {t('organisers.organisersAlt')}
            </Headline>
            <Small>
                {paymentType === 'year'
                    ? t('licence.eachPerYearInfo', { singlePrice: singlePrice })
                    : t('licence.eachPerMonthInfo', { singlePrice: singlePrice})
                }
            </Small>
            <div style={{
                width: '100%',
                height: 1,
                background: colors.Black,
                marginTop: spacings.xs,
                marginBottom: spacings.xs,
            }} />
            <Subheadline secondary style={{ lineHeight: 1 }}>
                {calculatedPrice} €
            </Subheadline>
            <Small mt={spacings.xxxs}>
                {paymentType === 'year'
                    ? t('licence.perYearInfoAlt')
                    : t('licence.perMonthInfoAlt')
                }
            </Small>
            <Small mt={spacings.xxxs}>
                <b>{calculatedPrice && formatPrice(calculatedPrice * 1.19)}</b> {paymentType === 'year'
                    ? t('licence.perYearWithVAT', { vat: 19 })
                    : t('licence.perMonthWithVAT', { vat: 19 })
                }
            </Small>
            {showEdit && <Button
                small
                secondary
                mt={spacings.xxs}
                style={{ background: colors.Beige3 }}
                label={t('actions.edit')}
                onClick={() => navigate('/licences/amount')}
            />}
        </ColoredBox>
    );
};

export default InfoBox;