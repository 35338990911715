import { useTranslation } from 'react-i18next';
import { AiFillCaretRight } from 'react-icons/ai';
import { IoIosClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import Spacings from '../../../assets/constants/spacings';
import Logo from '../../../assets/images/calvah-logo.svg';
import * as Text from '../../../common/components/text';
import { useAppSelector } from '../../../store/hooks';
import { CreateStep, STEPS } from '../steps';
import { ListItemWrapper, Navigation, Wrapper } from './styled';
import { ISidebar } from './types';

const Sidebar = ({
    stepIndex
}: ISidebar) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const title = useAppSelector((state) => state.createMeeting.title);
    const attendees = useAppSelector((state) => state.createMeeting.attendees);
    const date = useAppSelector((state) => state.createMeeting.date);
    const goal = useAppSelector((state) => state.createMeeting.agenda.goal);
    const meetings = useAppSelector((state) => state.meetings.meetings);

    return (
        <Wrapper>
            <img src={Logo} alt="" style={{ marginLeft: -26 }} />
            <Text.Headline quaternary>
                {t('create.createMeeting')}
            </Text.Headline>

            <Navigation>
                {STEPS.map((s, index) =>
                    <ListItemWrapper
                        key={s.key}
                        onClick={() => {
                            if (index === CreateStep.One) {
                                navigate(s.path);
                            }
                            if (index === CreateStep.Two && title) {
                                navigate(s.path);
                            }
                            if (index === CreateStep.Three && attendees.length > 0 && title) {
                                navigate(s.path);
                            }
                            if (index === CreateStep.Four && attendees.length > 0 && title) {
                                navigate(s.path);
                            }
                            if (index === CreateStep.Five && attendees.length > 0 && title && date && goal) {
                                navigate(s.path);
                            }
                        }}
                        style={{}}
                    >
                        <div style={{
                            marginRight: Spacings.s,
                            width: 20,
                            flexShrink: 0,
                        }} >
                            {stepIndex === index
                                ? <AiFillCaretRight />
                                : <Text.Small pl={4}>{index + 1}</Text.Small>
                            }
                        </div>
                        <Text.Small bold={stepIndex === index}>{s.title}</Text.Small>
                    </ListItemWrapper>)
                }
            </Navigation>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: Spacings.s,
                cursor: 'pointer',
            }}>
                <IoIosClose size={32} style={{
                    marginBottom: -2,
                    marginLeft: -6,
                    marginRight: Spacings.xxs,
                }} />
                <Text.Body onClick={() => {
                    if(meetings.length==0){
                        navigate('/')
                    }else{
                        navigate('/meetings/upcoming')
                    }
                    }}>
                    {t('create.saveAndExit')}
                </Text.Body>
            </div>
        </Wrapper>
    );
}

export default Sidebar;