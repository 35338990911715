import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IBillingDetails,
    IPaymentDetails,
    IProductPrice,
    ISetupIntent,
    IUserBillingDetails,
    LicenceState,
    TPaymentType
    } from './types';
import { IPaymentMethodDetails } from '@/services/api/payment-service/types';

const initialState = {
    amount: 5,
    min: 1,
    max: 50,
    paymentType: 'month',
} as LicenceState;

const licenceSlice = createSlice({
    name: 'licence',
    initialState: initialState,
    reducers: {
        resetLicenceState: () => {
            return initialState;
        },
        resetPaymentFlow: (state) => {
            return {
                ...state,
                paymentType: 'month',
                billingDetails: undefined,
                paymentDetails: undefined,
                setupIntent: undefined,
                paymentMethodDetails: undefined,
                productPrices: undefined,
            }
        },
        setAmount: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                amount: action.payload,
            };
        },
        setMin: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                min: action.payload,
            };
        },
        setMax: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                max: action.payload,
            };
        },
        setPaymentType: (state, action: PayloadAction<TPaymentType>) => {
            return {
                ...state,
                paymentType: action.payload,
            };
        },
        setProductPrices: (state, action: PayloadAction<IProductPrice[]>) => {
            return {
                ...state,
                productPrices: action.payload,
            };
        },
        setBillingDetails: (state, action: PayloadAction<IBillingDetails>) => {
            return {
                ...state,
                billingDetails: action.payload,
            };
        },
        setPaymentDetails: (state, action: PayloadAction<IPaymentDetails>) => {
            return {
                ...state,
                paymentDetails: action.payload,
            };
        },
        setSetupIntent: (state, action: PayloadAction<ISetupIntent>) => {
            return {
                ...state,
                setupIntent: action.payload,
            };
        },
        setPaymentMethodDetails: (state, action: PayloadAction<IPaymentMethodDetails>) => {
            return {
                ...state,
                paymentMethodDetails: action.payload,
            };
        },
        setUserBillingDetails: (state, action: PayloadAction<IUserBillingDetails>) => {
            return {
                ...state,
                userBillingDetails: action.payload,
            };
        },
    }
})

export const {
    resetLicenceState,
    resetPaymentFlow,
    setAmount,
    setMin,
    setMax,
    setPaymentType,
    setBillingDetails,
    setPaymentDetails,
    setSetupIntent,
    setProductPrices,
    setPaymentMethodDetails,
    setUserBillingDetails
} = licenceSlice.actions;
export default licenceSlice;